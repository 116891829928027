import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import warning from "../../../assets/img/warning.svg";
import { useNavigate } from "react-router";

const PortalApplyAlert = (props) => {
  const { modal, toggle, title, themeColor, param } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/tp/portal/${param}/profile/edit`);
    toggle();
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        style={{ width: "574px", height: "315px" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={toggle}
      >
        <div className="job-detail">
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody className="alert">
            <div className="d-flex justify-content-center">
              <img src={warning} alt="warning" />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="btn"
                style={{ background: themeColor, margin: "auto" }}
                onClick={() => handleClick()}
              >
                Complete profile now
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default PortalApplyAlert;
