import { notificationApi } from "../../api/notificationApi";
import { initLoad, stopLoad } from "../loader/loaderActions";
import {
  DELETE_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  LOAD_MORE_GET_ALL_NOTIFICATIONS,
  LOAD_MORE_UNREAD_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  UPDATE_READ_STATUS_NOTIFICATIONS,
  UPDATE_PINNED_STATUS_NOTIFICATIONS,
  UPDATE_ALL_AS_READ_NOTIFICATIONS,
  UPDATE_ALL_AS_UNREAD_NOTIFICATIONS,
} from "../types";

export const getAllNotification =
  (params, currentCount, type) => (dispatch) => {
    dispatch(initLoad());
    return notificationApi.getNoitfication(params).then(
      (response) => {
        console.log(response);
        if (type === "all") {
          if (currentCount === 0) {
            dispatch({
              type: GET_ALL_NOTIFICATIONS,
              payload: response,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          } else {
            dispatch({
              type: LOAD_MORE_GET_ALL_NOTIFICATIONS,
              payload: response,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          }
        }
        if (type === "unread") {
          if (currentCount === 0) {
            dispatch({
              type: GET_UNREAD_NOTIFICATIONS,
              payload: response,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          } else {
            dispatch({
              type: LOAD_MORE_UNREAD_NOTIFICATIONS,
              payload: response.data,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          }
        }
      },
      (error) => {
        console.log(error);
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };

export const updateNotification = (id, data, type) => (dispatch) => {
  return notificationApi.updateNotification(id, data).then(
    (response) => {
      console.log(response);
      if (type === "read") {
        dispatch({
          type: UPDATE_READ_STATUS_NOTIFICATIONS,
          payload: { id, data, count: response?.count },
        });
        return Promise.resolve(response);
      } else if (type === "pinned") {
        dispatch({
          type: UPDATE_PINNED_STATUS_NOTIFICATIONS,
          payload: { id, data },
        });
        return Promise.resolve(response);
      }
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateAsReadNotification = () => (dispatch) => {
  return notificationApi.updateAsreadNotification().then(
    (response) => {
      dispatch({
        type: UPDATE_ALL_AS_READ_NOTIFICATIONS,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateAsUnreadNotification = () => (dispatch) => {
  return notificationApi.updateAsUnreadNotification().then(
    (response) => {
      console.log("response", response);
      dispatch({
        type: UPDATE_ALL_AS_UNREAD_NOTIFICATIONS,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteNotification = (id) => (dispatch) => {
  return notificationApi.deleteNotification(id).then(
    (response) => {
      dispatch({
        type: DELETE_NOTIFICATIONS,
        payload: { id },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
