import { toast } from "react-toastify";
import { pageApi } from "../../api/pageAPI";
import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  ADD_PAGE_ADMIN,
  ADD_PAGE_URL,
  CREATE_PAGE_SUCCESS,
  DELETE_PAGE_ADMIN,
  FOLLOW_PAGE_SUCCESS,
  FOLLOW_PAGE_SUCCESS_HOME,
  GET_ALL_PAGES_FAIL,
  GET_ALL_PAGES_SUCCESS,
  GET_PAGES_PERMISSION,
  GET_PAGE_ADMINS,
  GET_PAGE_INSIGHTS,
  GET_SINGLE_PAGES_SUCCESS,
  PASS_VERIFY_SUCCESS,
  SEND_PAGE_INVITATION,
  TRANSFER_PAGE_ADMIN,
  UNFOLLOW_PAGE_SUCCESS,
  UNFOLLOW_PAGE_SUCCESS_HOME,
  UPDATE_PAGE_FAIL,
  UPDATE_PAGE_SUCCESS,
  UNFOLLOW_SINGLEPAGE_SUCCESS,
  FOLLOW_SINGLEPAGE_SUCCESS,
  CREATE_TALENT_POOL,
  UPDATE_TALENT_POOL,
  GET_ALL_PRODUCT_LIST,
  CREATE_PAGE_PRODUCT,
  UPDATE_PAGE_PRODUCT,
  GET_PAGE_FOLLOWERS,
} from "../types";

export const getAllPages = (query) => (dispatch, getState) => {
  const { pageData } = getState().pages;
  return pageApi.getPagesList(query).then(
    (response) => {
      dispatch({
        type: GET_ALL_PAGES_SUCCESS,
        payload:
          pageData && (query?.page || query?.filter) && query?.page > 1
            ? { ...response, data: [...pageData?.data, ...response?.data] }
            : response,
      });
      return Promise?.resolve(response);
    },
    (error) => {
      if (query?.page == 1) {
        dispatch({
          type: GET_ALL_PAGES_FAIL,
        });
      }
      return Promise?.reject(error);
    }
  );
};

export const getPageProductList = (page_id, query) => (dispatch, getState) => {
  const { productData } = getState().pages;
  return pageApi.getProductList(page_id, query).then(
    (response) => {
      dispatch({
        type: GET_ALL_PRODUCT_LIST,
        payload:
          productData.length > 1
            ? [...productData, ...response?.data]
            : response?.data,
      });
      return Promise?.resolve(response);
    },
    (error) => {
      return Promise?.reject(error);
    }
  );
};

export const CreatePageProduct = (data) => (dispatch) => {
  return pageApi.createProduct(data).then(
    (response) => {
      dispatch({
        type: CREATE_PAGE_PRODUCT,
        payload: { data: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const updatePageProduct = (data, id) => (dispatch) => {
  return pageApi.updateProduct(data, id).then(
    (response) => {
      dispatch({
        type: UPDATE_PAGE_PRODUCT,
        payload: { data, id },
      });
      return Promise?.resolve(response);
    },
    (error) => {
      return Promise?.reject(error);
    }
  );
};

export const getsinglePage = (query) => (dispatch, getState) => {
  const { singlePageData } = getState().pages;
  return pageApi.getPagesList(query).then(
    (response) => {
      dispatch({
        type: GET_SINGLE_PAGES_SUCCESS,
        payload:
          singlePageData && (query?.page || query?.filter) && query?.page > 1
            ? {
                ...response,
                data: [...singlePageData?.data, ...response?.data],
              }
            : response.data,
      });
      return Promise?.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise?.reject(error);
    }
  );
};

export const updatePageData = (data, pageId) => (dispatch) => {
  return pageApi.updatePage(data, pageId).then(
    (response) => {
      dispatch({
        type: UPDATE_PAGE_SUCCESS,
        payload: response?.data,
      });
      return Promise?.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_PAGE_FAIL,
      });
      console.log(error);
      return Promise?.reject(error);
    }
  );
};

export const CreateNewPage = (data) => (dispatch) => {
  return pageApi.createPage(data).then(
    (response) => {
      dispatch({
        type: CREATE_PAGE_SUCCESS,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      {
        error.response.data.message ===
        `"custom_button_url" is not allowed to be empty`
          ? alert("Please enter url (custom button is on)")
          : alert(error.response.data.message);
      }
      return Promise.reject(error);
    }
  );
};

export const followPage = (pageId, mode, post_id) => (dispatch) => {
  return pageApi.followPage(pageId).then(
    (response) => {
      if (mode === "home") {
        dispatch({
          type: FOLLOW_PAGE_SUCCESS_HOME,
          payload: { pageId: pageId, post_id: post_id },
        });
      } else
        dispatch({
          type: FOLLOW_PAGE_SUCCESS,
          payload: pageId,
        });
      dispatch({
        type: FOLLOW_SINGLEPAGE_SUCCESS,
        payload: pageId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
export const unFollowPage = (pageId, mode, post_id) => (dispatch) => {
  return pageApi.unFollowPage(pageId).then(
    (response) => {
      if (mode === "home") {
        dispatch({
          type: UNFOLLOW_PAGE_SUCCESS_HOME,
          payload: { pageId: pageId, post_id: post_id },
        });
      } else
        dispatch({
          type: UNFOLLOW_PAGE_SUCCESS,
          payload: pageId,
        });
      dispatch({
        type: UNFOLLOW_SINGLEPAGE_SUCCESS,
        payload: pageId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const sendInvitationPage = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return pageApi.pageInvitation(data).then(
    (response) => {
      dispatch({
        type: SEND_PAGE_INVITATION,
        payload: data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const addPageAdmin = (data) => (dispatch) => {
  return pageApi.addPageAdminList(data).then(
    (response) => {
      dispatch({
        type: ADD_PAGE_ADMIN,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getPageAdmins = (pageID) => (dispatch) => {
  return pageApi.getPageAdminList(pageID).then(
    (response) => {
      dispatch({
        type: GET_PAGE_ADMINS,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getPageFollowers = (pageID) => (dispatch) => {
  return pageApi.getPageFollowerList(pageID).then(
    (response) => {
      dispatch({
        type: GET_PAGE_FOLLOWERS,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const DeletePageAdmin = (data) => (dispatch) => {
  return pageApi.deletePageAdminList(data).then(
    (response) => {
      dispatch({
        type: DELETE_PAGE_ADMIN,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const transferPageAdmin = (pageID, userId) => (dispatch) => {
  dispatch(initPageLoad());
  return pageApi.transferPageAdmin(pageID, userId).then(
    (response) => {
      dispatch({
        type: TRANSFER_PAGE_ADMIN,
        payload: response,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);

      alert(error.response.data.message);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
export const confirmPassTransferPage = (data) => (dispatch) => {
  return pageApi.confirmPassTransferPage(data).then(
    (response) => {
      dispatch({
        type: PASS_VERIFY_SUCCESS,
        payload: data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      if (error.response.data.message === "Password is not verified")
        toast.error("Please enter correct password");
      return Promise.reject(error);
    }
  );
};

export const addPageURL = (data) => (dispatch) => {
  dispatch(initPageLoad());
  return pageApi.addPageURL(data).then(
    (response) => {
      dispatch({
        type: ADD_PAGE_URL,
        payload: data.page_url,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};
export const getAllPagesInsights = (query) => (dispatch) => {
  return pageApi.getPagesInsightsList(query).then(
    (response) => {
      dispatch({
        type: GET_PAGE_INSIGHTS,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getPagesPermission = (pageId) => (dispatch, getState) => {
  const { pageData } = getState().pages;
  return pageApi.getPagePermissions(pageId).then(
    (response) => {
      dispatch({
        type: GET_PAGES_PERMISSION,
        payload:
          // pageData && (query?.page || query?.filter) && query?.page > 1
          //   ? { ...response, data: [...pageData?.data, ...response?.data] }
          response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: GET_ALL_PAGES_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const CreatePortal = (data) => (dispatch) => {
  return pageApi.createTalentPool(data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const UpdatePortal = (id, data) => (dispatch) => {
  return pageApi.updateTalentPool(id, data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
