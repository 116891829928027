import React, { useRef, useState, useEffect } from "react";
import "./JobCard.scss";
import PropTypes from "prop-types";
import googleIcon from "../../../../assets/img/google.jpg";
import noblePage from "../../../../assets/img/nobelPage.svg";
import savejobIcon from "../../../../assets/img/saveJob.svg";
import shareJobicon from "../../../../assets/img/shareJobicon.png";
import unsaveIcon from "../../../../assets/img/unsaveJob.svg";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import applied from "../../../../assets/img/done_circular.svg";
import featured from "../../../../assets/img/ad_circular.svg";
import confidential from "../../../../assets/img/confidentialIcon.svg";
import { GetTime } from "../../../../core/components/Utility/Utility";
import sendIcon from "../../../../assets/img/sendIconGrey.svg";
import { useNavigate } from "react-router-dom";
import ReportIcon from "../../../../assets/img/FillReportIcon.svg";
import ReportModal from "../../../dashboard/components/reportTypeModal";
import { useSelector } from "react-redux";
export default function JobCard(props) {
  const {
    onClick,
    companyIcon,
    jobTitle,
    companyName,
    saveIcon,
    jobType,
    location,
    timeAgo,
    smartApplyIcon,
    smartApplyText,
    jobData,
    selectedJob,
    notShowFeatured,
    index,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick({
        companyIcon,
        jobTitle,
        companyName,
        saveIcon,
        jobType,
        location,
        timeAgo,
        smartApplyIcon,
        smartApplyText,
      });
    }
  };
  const [reportModel, setReportModel] = useState(false);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const dropRefShare = useRef(null);
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state?.profile);
  const handleShareClick = (e, idx) => {
    e.stopPropagation();
    setDropDownShare(dropDownShare === idx ? -1 : idx);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropRefShare.current &&
        !dropRefShare.current.contains(event.target)
      ) {
        setDropDownShare(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSendClick = () => {
    navigate("/message");
  };

  return (
    <div
      className={
        "job-card-layout" +
        (selectedJob?.id === jobData?.id ? " job-card-layout-active" : "")
      }
      onClick={handleClick}
    >
      <div className="job-card bg-white p-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ width: "70%" }}>
            {console.log(jobData?.user_id, profileData)}
            {jobData?.company_visible ? (
              <img
                src={
                  jobData?.company?.icon
                    ? jobData?.company?.icon
                    : pagePlaceholder
                }
                alt="company_icon"
                className="img-icon"
              />
            ) : (
              <img src={confidential} alt="company_icon" className="img-icon" />
            )}
            <div className="ml-5">
              <span
                className="job-title card-control-text-oneline"
                style={{ marginRight: "10px" }}
              >
                {jobData?.title}
              </span>
              <span className="company-title card-control-text-oneline">
                {jobData?.company_visible
                  ? jobData?.company_name
                  : "Confidential"}
              </span>
            </div>
          </div>
          <div className="d-flex" style={{ columnGap: "10px" }}>
            {jobData?.user_id !== profileData?.id && (
              <div className="">
                <img
                  src={ReportIcon}
                  className="save-icon"
                  alt="report_icon"
                  onClick={(e) => setReportModel(!reportModel)}
                />
              </div>
            )}
            <div className="dropdown-container" ref={dropRefShare}>
              <img
                src={shareJobicon}
                alt="share_icon"
                className="save-icon"
                onClick={(e) => handleShareClick(e, index)}
              />
              {dropDownShare === index && (
                <div
                  className="drop"
                  style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    marginTop: "10px",
                    zIndex: 1000,
                    right: "10px",
                  }}
                >
                  <div
                    className="box box-share"
                    style={{
                      width: "150px",
                      bottom: "40px",
                    }}
                  >
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li
                        className="shareListing"
                        onClick={handleSendClick}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px 12px",
                          cursor: "pointer",
                          borderRadius: "4px",
                          transition: "background-color 0.3s",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                        }}
                      >
                        <img
                          src={sendIcon}
                          alt=""
                          style={{ marginRight: "8px" }}
                        />{" "}
                        Send this job
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <img
              src={jobData?.savedJob?.status ? savejobIcon : unsaveIcon}
              alt="save_icon"
              className="save-icon mt-1"
            />
            {jobData?.appliedJob?.apply_status && (
              <span
                className="apllied"
                style={{ color: "white", fontSize: "10px" }}
              >
                <div style={{ marginTop: "3px" }}>
                  <img
                    src={applied}
                    alt="applied"
                    style={{
                      height: "12px",
                      width: "12px",
                      marginRight: "2px",
                    }}
                  />
                  <span>Applied</span>
                </div>
              </span>
            )}
            {jobData?.featuredJob && !notShowFeatured && (
              <span className="featured">
                <img src={featured} alt="applied" />
                Featured
              </span>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between bottom-bar">
          <div className="ml-5">
            <span className="grey-color">
              {jobData?.employment_type}
              {jobData?.workspace_type ? ` | ` + jobData?.workspace_type : ""}
            </span>
          </div>
          <span className="blue-color">{GetTime(jobData?.createdAt)}</span>
        </div>
      </div>
      <div className="bottom-card">
        <div className="d-flex justify-content-between mr-20">
          <span className="blue-color address-text ml-20">
            {jobData?.job_location}
          </span>
          {jobData?.apply_smart && (
            <div className="d-flex smart-button" style={{ padding: "2px 0px" }}>
              <img src={noblePage} alt="smart_apply" />
              <span className="blue-color address-text ml-5">Smart Apply</span>
            </div>
          )}
        </div>
      </div>

      {reportModel && (
        <ReportModal
          modal={reportModel}
          toggle={() => setReportModel(!reportModel)}
          reportType={"job"}
          reportTypeId={jobData?.id}
        />
      )}
    </div>
  );
}

// JobCard.propTypes = {
//   onClick: PropTypes.func,
//   companyIcon: PropTypes.string,
//   jobTitle: PropTypes.string,
//   companyName: PropTypes.string,
//   saveIcon: PropTypes.string,
//   jobType: PropTypes.string,
//   location: PropTypes.string,
//   timeAgo: PropTypes.string,
//   smartApplyIcon: PropTypes.string,
//   smartApplyText: PropTypes.string,
//   jobData: PropTypes.object,
//   selectedJob: PropTypes.object,
//   notShowFeatured: PropTypes.bool,
//   index: PropTypes.number.isRequired,
// };

// JobCard.propTypes = {
//   onClick: PropTypes.func,
//   companyIcon: PropTypes.string.isRequired,
//   jobTitle: PropTypes.string.isRequired,
//   companyName: PropTypes.string.isRequired,
//   saveIcon: PropTypes.string.isRequired,
//   jobType: PropTypes.string.isRequired,
//   location: PropTypes.string.isRequired,
//   timeAgo: PropTypes.string.isRequired,
//   smartApplyIcon: PropTypes.string.isRequired,
//   smartApplyText: PropTypes.string.isRequired,
// };
