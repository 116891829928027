import React, { useState } from "react";
import OutsideNavbar from "../navbar/OutsideNavbar";
import "./Directory.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSinglCompany } from "../../store/directory/directoryAction";
import tick from "../../assets/img/tick-icon.svg";
import add from "../../assets/img/add-icon.svg";
import { useEffect } from "react";
import pageIcon from "../../assets/img/page-placeholder.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import PagePeople from "../../routes/mynetwork/Pages/components/PagePeople";
import PageEvent from "../../routes/mynetwork/Pages/components/PageEvent";
import { sendConnectionRequest } from "../../store/connections/connectionsActions";
import { getOthersEvents, pinnedEvents } from "../../store/events/eventActions";
import { getPageFollowers } from "../../store/pages/pagesAction";
import { getJobs, getPublicJobs } from "../../store/jobs/jobAction";
import PageJobs from "../../routes/mynetwork/Pages/components/PageJobs";
import PublicJob from "../../routes/mynetwork/Pages/components/PublicJob";
import { jobApi } from "../../api/jobs";
import { directoryApi } from "../../api/directoryApi";
import SimilarPage from "../../routes/mynetwork/Pages/components/SimilarPage";
import FeaturedPage from "../../routes/mynetwork/Pages/components/FeaturedPage";

const CompanyDetails = (props) => {
  const dispatch = useDispatch();
  const { singleCompanyData } = useSelector((state) => state.directory);
  const { getAllJobs } = useSelector((state) => state.jobs);
  const { id } = useParams();
  const [tab, setTab] = useState("people");
  const navigate = useNavigate();
  const { page_events } = useSelector((state) => state.events);
  const { pageFollowers } = useSelector((state) => state.pages);
  const [similarJob, setSimilarJob] = useState({});
  const [featuredJob, setFeaturedJob] = useState({});

  const sendRequest = (id) => {
    dispatch(sendConnectionRequest(id));
  };
  const token = localStorage.getItem("accessToken");
  const pinHandle = (id, type) => {
    dispatch(pinnedEvents(id, token, type));
  };
  useEffect(() => {
    dispatch(getSinglCompany({ id: id }));
    suggestedPeoplePage();
  }, []);

  useEffect(() => {
    getSimilarJobs();
    getfeaturedJobs();
  }, [singleCompanyData]);
  const createExploreLink = (url) => {
    window.open(url, "exploremore");
  };

  const getPageEvents = () => {
    let query = "page";
    dispatch(getOthersEvents(query, { page_id: parseInt(id) }));
  };

  const suggestedPeoplePage = () => {
    dispatch(getPageFollowers(id));
  };

  const getJobsList = () => {
    dispatch(getJobs({ company_id: id }, 0));
  };

  const getSimilarJobs = async () => {
    try {
      const res = await directoryApi.getCompanyList({
        category: singleCompanyData[0]?.category,
        size: 10,
      });
      if (res?.success) {
        setSimilarJob(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getfeaturedJobs = async () => {
    try {
      const res = await directoryApi.getCompanyList({
        filter: "featured",
        size: 10,
      });
      if (res?.success) {
        setFeaturedJob(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <OutsideNavbar />
      <div className="main-bg admin-bg mt-4">
        <div className="company-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="wrapper ">
                  <div className="big-img">
                    {singleCompanyData[0]?.background_img && (
                      <img
                        src={singleCompanyData[0]?.background_img}
                        className="img-cover"
                        alt="NP_Background_Img"
                      />
                    )}
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12 pe-0">
                      <div className="left ml-20 mb-20">
                        <div className="profile-details">
                          <div className="d-lg-flex gap-3 ">
                            <div className="img">
                              <img
                                src={
                                  singleCompanyData[0]?.icon
                                    ? singleCompanyData[0]?.icon
                                    : pageIcon
                                }
                                className="image-fit"
                                alt="NP_Profile_Img"
                              />
                            </div>
                            <div
                              className="details"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                                width: "80%",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <h3 className="text-dark">
                                  {singleCompanyData[0]?.name}
                                </h3>
                                <span>
                                  {singleCompanyData[0]?.category &&
                                    singleCompanyData[0]?.category
                                      ?.slice(0, 1)
                                      .toUpperCase() +
                                      singleCompanyData[0]?.category?.slice(1) +
                                      " " +
                                      "|" +
                                      " "}
                                  {singleCompanyData[0]?.industry &&
                                    singleCompanyData[0]?.industry}
                                </span>
                                <span>
                                  {singleCompanyData[0]?.location
                                    ? singleCompanyData[0]?.location + ","
                                    : ""}{" "}
                                  {singleCompanyData[0]?.followers_count > 0 &&
                                  singleCompanyData[0]?.followers_count > 999
                                    ? `${Math.floor(
                                        singleCompanyData[0]?.followers_count /
                                          1000
                                      )}k followers`
                                    : `${
                                        singleCompanyData[0]?.followers_count
                                      } ${
                                        singleCompanyData[0]?.followers_count >
                                        1
                                          ? "followers"
                                          : "follower"
                                      }`}
                                </span>
                              </div>

                              {singleCompanyData[0]?.Talent_Pool && (
                                <li className="d-flex talent_link justify-content-end">
                                  <Link
                                    to={`${process.env.REACT_APP_WEB_STAGE_URL}tp/portal/${singleCompanyData[0]?.Talent_Pool?.id}/dashboard`}
                                    // onClick={() =>
                                    //   singleCompanyData[0]?.Talent_Pool
                                    //     ?.status &&
                                    //   navigate("/", {
                                    //     state: {
                                    //       navigateto: `/tp/${singleCompanyData[0]?.Talent_Pool?.id}/dashboard`,
                                    //     },
                                    //   })
                                    // }
                                    target="_blank"
                                  >
                                    Talent Pool
                                  </Link>
                                  {/* <FormGroup switch>
                                      <Input
                                        type="switch"
                                        role="switch"
                                        checked={
                                          singleCompanyData[0]?.Talent_Pool
                                            ?.status
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handlePortalActivate(
                                            singleCompanyData[0]?.Talent_Pool
                                              ?.id,
                                            !singleCompanyData[0]?.Talent_Pool
                                              ?.status
                                          )
                                        }
                                      />
                                    </FormGroup> */}
                                </li>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 col-sm-12 ps-0 d-flex">
                      <div className="left ml-20 mb-20">
                        <ul className="nav nav-tabs mt-2" role="tablist">
                          <li
                            className="nav-item nav-insight-item"
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              className={
                                tab == "people" ? "nav-link active" : "nav-link"
                              }
                              data-bs-toggle="tab"
                              onClick={() => {
                                // setTab("people");
                                // suggestedPeoplePage();
                                navigate("/", {
                                  state: {
                                    navigateto: `/pageadminview/${singleCompanyData[0]?.id}`,
                                  },
                                });
                              }}
                            >
                              People
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="left ml-20 mb-20">
                        <ul className="nav nav-tabs mt-2" role="tablist">
                          <li
                            className="nav-item nav-insight-item"
                            style={{ cursor: "pointer" }}
                          >
                            <p
                              className={
                                tab == "job" ? "nav-link active" : "nav-link"
                              }
                              data-bs-toggle="tab"
                              onClick={() => {
                                // setTab("job");
                                // getJobsList();
                                navigate("/", {
                                  state: {
                                    navigateto: `/pageadminview/${singleCompanyData[0]?.id}`,
                                  },
                                });
                              }}
                            >
                              Jobs
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="left ml-20 mb-20">
                        <ul className="nav nav-tabs mt-2" role="tablist">
                          <li
                            className="nav-item nav-insight-item"
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              className={
                                tab == "event" ? "nav-link active" : "nav-link"
                              }
                              data-bs-toggle="tab"
                              onClick={() => {
                                // setTab("event");
                                // getPageEvents();
                                navigate("/", {
                                  state: {
                                    navigateto: `/pageadminview/${singleCompanyData[0]?.id}`,
                                  },
                                });
                              }}
                            >
                              Events
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-content px-3">
                      <div
                        id="brief-details"
                        className="container tab-pane active"
                      >
                        <div className="middle">
                          {/* {singleCompanyData[0]?.description ? ( */}
                          <div className="bg-white b-5 p-20">
                            <div className="about">
                              <h2 className="text-dark">About</h2>
                              <p>{singleCompanyData[0]?.description}</p>
                            </div>
                            <div className="other-details row">
                              <div className="col-lg-6"></div>
                              <div className="col-lg-6"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ms-4 brief">
                    <div className="col-lg-6">
                      {singleCompanyData[0]?.website && (
                        <p className="text-dark fw-bold">Website</p>
                      )}
                      {singleCompanyData[0]?.industry && (
                        <p className="mt-3 text-dark fw-bold">Industry</p>
                      )}
                      {singleCompanyData[0]?.company_size && (
                        <p className="mt-3 text-dark fw-bold">Company Size</p>
                      )}
                      {singleCompanyData[0]?.Talent_Pool && (
                        <p className="mt-3 text-dark fw-bold">
                          TalentPool Link
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <p
                        className="website text-dark"
                        onClick={() =>
                          createExploreLink(singleCompanyData[0]?.website)
                        }
                      >
                        {singleCompanyData[0]?.website}
                      </p>
                      <p
                        className=" text-dark"
                        style={{ marginTop: "12px", fontSize: "15px" }}
                      >
                        {singleCompanyData[0]?.industry}
                      </p>
                      <p
                        className=" text-dark"
                        style={{ marginTop: "12px", fontSize: "15px" }}
                      >
                        {singleCompanyData[0]?.company_size} employees
                      </p>
                      <p
                        className=" text-dark"
                        style={{ marginTop: "12px", fontSize: "15px" }}
                      >
                        {`${process.env.REACT_APP_WEB_STAGE_URL}tp/portal/${singleCompanyData[0]?.Talent_Pool?.id}/dashboard`}
                      </p>
                    </div>
                  </div>
                  <div className="row p-4">
                    {/* {tab == "people" && pageFollowers?.data?.length > 0 && (
                      <PagePeople
                        suggested_people={pageFollowers?.data}
                        sendRequest={sendRequest}
                      />
                    )} */}

                    {/* {tab == "event" && page_events?.length > 0 && (
                      <PageEvent
                        page_events={page_events}
                        pageID={id}
                        pinHandle={pinHandle}
                        pageName={singleCompanyData[0]?.name}
                        createdAt="page"
                        pageIcon={singleCompanyData[0]?.icon}
                      />
                    )} */}

                    {/* {tab == "job" && getAllJobs?.length > 0 && (
                      <PublicJob pageID={id} updatedList={getAllJobs} />
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-4">
                <FeaturedPage list={featuredJob} />
                <SimilarPage list={similarJob} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
