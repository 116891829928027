import React, { useEffect, useRef, useState } from "react";
import store from "../../store/store.js";
import resumeImg1 from "../../assets/img/resumeImg1.png";
import resumeImg2 from "../../assets/img/resumeImg2.png";
import resumeImg3 from "../../assets/img/resumeImg3.png";
import resumeImg4 from "../../assets/img/resumeImg4.png";
import checkMark from "../../assets/img/check-mark.png";
import lock from "../../assets/img/lock.svg";
import pdfFileIcon from "../../assets/img/pdfFileIcon.png";
import Eyeresume from "../../assets/img/Eyeresume.svg";
import "./JobSearch.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getExploreData } from "../../store/explore/exploreActions";
import { Modal, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { SmartApplyData, getQuestions } from "../../store/jobs/jobAction";
// import "../../core/components/Inputs/input.scss";
import axios from "axios";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import postalCodes from "postal-codes-js";
import MobileInput from "../../core/components/Inputs/MobileInput";
import ClassicTemplate, {
  classicRef,
} from "../../pages/CV/CVTemplates/ClassicTemplate";
import { functionalRef } from "../../pages/CV/CVTemplates/FunctionalTemplate";
import { combinedRef } from "../../pages/CV/CVTemplates/CombinedTemplate";
import { modernRef } from "../../pages/CV/CVTemplates/ModernTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Resume } from "../../pages/CV/Resume";
// import { getUserProfile } from "../../store/profile/profileActions";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import Cv from "../../pages/CV/Cv";
import styled from "styled-components";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader.jsx";
import { fetchAllCvTypesPdf, fetchCvPdf } from "../../store/cv/cvActions.js";
import { getUserProfile } from "../../store/profile/profileActions.js";
import ResumeForSmartApply from "../../pages/CV/ResumeForSmartApply.js";

const CustomScrollbarContainer = styled.div`
  max-height: calc(85vh - 150px);
  scrollbar-width: thin;
  scrollbar-color: #363b99 rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #363b99;
    border-radius: 10px;
  }
`;

const PersonalDetailsStep = ({ onNext, formData, onInputChange }) => {
  const [emailError, setEmailError] = useState("");
  const [exploreData, setExploreData] = useState();
  const [phoneCount, setPhoneCount] = useState("");
  const [errorPostCode, setErrorPostCode] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailValue.trim() === "" || !emailRegex.test(emailValue)) {
      setEmailError("Please enter a valid email address.");
      setIsValid(false);
    } else {
      setEmailError("");
      setIsValid(true);
    }
    onInputChange("step1", "email", emailValue);
  };

  const handlePhoneNumberChange = (value) => {
    // const phoneValue = value;
    // console.log(value);
    // const phoneRegex = /^((0|\+[1-9][0-9]{0,3})(-|( ))?)?([0-9]{10,15})$/;
    // if (phoneValue.trim() === "" || !phoneRegex.test(phoneValue)) {
    //   setErrorPhoneNumber("Please enter a valid phone no.");
    // } else {
    //   setErrorPhoneNumber("");
    // }

    if (value.length === 0) {
      setErrorPhoneNumber("Please enter the phone number.");
      setIsValid(false);
    } else if (value?.length < 10) {
      setErrorPhoneNumber("Please enter the valid number.");
      setIsValid(false);
    } else if (!phoneCountValidation()) {
      setErrorPhoneNumber("Please enter the valid number.");
      setIsValid(false);
    } else {
      setErrorPhoneNumber("");
      setIsValid(true);
    }

    onInputChange("step1", "phoneNumber", value);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (!emailError && isValid) {
      onNext();
    } else {
      return;
    }
  };

  const phoneCountValidation = () => {
    let num = JSON.stringify(formData.step1.phoneNumber);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const postCodeValidator = (pin_code, countryCode) => {
    return postalCodes.validate(countryCode, pin_code);
  };

  return (
    <div className="personal-details-pop-up search-default-pop-up">
      <div className="search-default-pop-up-inner">
        <h3>Personal Details</h3>
        <form>
          <div className="input-form">
            <label
              className="text-secondary"
              style={{ fontWeight: "600", fontSize: "17px" }}
            >
              Email
            </label>
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              value={formData.step1.email}
              onChange={handleEmailChange}
            />
            {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          </div>
          <div className="input-form">
            <MobileInput
              label="Phone Number"
              onChange={(value, country, e, formattedValue) => {
                handlePhoneNumberChange(
                  `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                );
                setPhoneCount(country.countryCode);
              }}
              value={formData.step1.phoneNumber}
              placeholder="Number"
              inputType="page"
              containerClassName="pt-0"
              labelStyle={{
                fontWeight: "600",
                fontSize: "17px",
                color: "#676A79",
              }}
            />
            {/* <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              country={"us"}
              placeholder="Enter phone number"
              value={formData.step1.phoneNumber}
              onChange={handlePhoneNumberChange}
            /> */}
            {errorPhoneNumber && (
              <div style={{ color: "red" }}>{errorPhoneNumber}</div>
            )}
            {/* 
                        <MobileInput
                label="Phone Number"
                onChange={(value, country, e, formattedValue) => {
                  setContactData({
                    ...formData,
                    phone: `+${country.dialCode}-${value.slice(
                      country.dialCode.length
                    )}`,
                  });
                  setPhoneCount(country.countryCode);
                }}
                value={formData.step1.phoneNumber}
                error={errorPhoneNumber}
                placeholder="Number"
                inputType="page"
                containerClassName="pt-0"
              /> */}
          </div>
          <div className="blue-btn-outer">
            <button
              className="blue-btn fw-bold"
              onClick={(e) => handleNextClick(e)}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const AddResumeStep = ({
  onNext,
  onBack,
  onInputChange,
  formData,
  userData,
}) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  // const [urlData, setUrlData] = useState();
  // const [fileName, setFileName] = useState();
  // const [isUserResumeSelected, setIsUserResumeSelected] = useState(
  //   !!userData?.resume_url
  // );
  // const [isUploadedResumeSelected, setIsUploadedResumeSelected] =
  //   useState(false);
  const [previewResumeModal, setPreviewResumeModal] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  const [totalPdfPages, setTotalPdfPages] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.auth?.user);
  const token = window.localStorage.getItem("accessToken");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  // useEffect(() => {
  //   dispatch(getUserProfile(userData?.id, token));
  // }, []);

  const handleChange = (value, url = "") => {
    if (formData?.step2?.selectedResume == value) {
      onInputChange("step2", "selectedResume", "");
      value == "user-resume" && onInputChange("step2", "uploadedResume", url);
    } else onInputChange("step2", "selectedResume", value);
  };

  const onChangeResume = async (e) => {
    let tempimage = [];
    let type = "file";
    Array.from(e.target.files).map((file) => {
      return tempimage.push({ type, file });
    });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (var i = 0; i < tempimage.length; i++) {
        formData.append("image", tempimage[i].file);
      }

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        onInputChange(
          "step2",
          "fileName",
          res?.data?.filesArray[0].originalname
        );
        onInputChange("step2", "urlData", res?.data?.urlsArray[0]);
        handleChange("upload-resume");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (!formData?.step2?.selectedResume) {
      setErrorMessage("Please select or upload a resume before proceeding.");
      return;
    }

    const uploadedResume =
      formData?.step2?.selectedResume == "upload-resume"
        ? formData?.step2?.urlData
        : formData?.step2?.selectedResume == "user-resume"
        ? formData?.step2?.uploadedResume
        : formData?.step2?.cvUrl;
    onInputChange("step2", "uploadedResume", uploadedResume);
    setTimeout(() => {
      onNext();
    }, 1000);
  };

  const handlePreview = async (value) => {
    dispatch(initPageLoad());
    if (
      userData?.membership?.id > 1 ||
      (userData?.membership?.id === 1 && value === "classic")
    ) {
      const templateRef =
        value === "classic"
          ? classicRef?.current
          : value === "functional"
          ? functionalRef?.current
          : value === "combined"
          ? combinedRef?.current
          : modernRef?.current;
      const pixelRatio = 3;
      const canvas = await html2canvas(templateRef, { scale: pixelRatio });
      const pageHeight = 295;
      const imgWidth = 210;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const urls = [];
      let heightLeft = imgHeight;
      let position = 0;

      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        hotfixes: ["px_scaling"],
        putOnlyUsedFonts: true,
        viewerPreferences: {
          HideMenubar: true,
          HideToolbar: true,
          HideWindowUI: true,
        },
      });

      while (heightLeft > 0) {
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        position -= pageHeight;
        heightLeft -= pageHeight;
        if (heightLeft > 0) doc.addPage();
      }
      const pdfBlob = doc.output("blob");
      const pdfDataUrl = URL.createObjectURL(pdfBlob);
      const totalPages = doc.internal.getNumberOfPages();

      dispatch(stopPageLoad());
      setPdfUrl(pdfDataUrl);
      setPreviewResumeModal(true);
      setTotalPdfPages(totalPages);
    } else {
      navigate("/membership");
    }
  };

  const handlePdfUpload = async (value) => {
    const token = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/user/get-single-pdf-upload-url/${value}`,
        template[value]?.first_name == ""
          ? {
              first_name: userData?.first_name || "",
              last_name: userData?.last_name || "",
              profile_img: userData?.profile_img || "",
              profile_headline: userData?.profile_headline || "",
              email: userData?.email || "",
              phone: userData?.phone || "",
              profile_url: userData?.profile_url || "",
              address: userData?.address || "",
              about: userData?.about || "",
              linkedin_url: userData?.linkedin_url || "",
              altranate_phone: userData?.altranate_phone || "",
              altranate_email: userData?.altranate_email || "",
              experiences: userData?.experiences || [],
              educations: userData?.educations || [],
              exps_and_skills: userData?.exps_and_skills || [],
              interests: userData?.interests || [],
              cv_interestColor: userData?.cv_interestColor || "",
              languages: userData?.languages || [],
              honors_and_awards: userData?.honors_and_awards || [],
              cv_skillColor: userData?.cv_skillColor || "",
              cv_color: userData?.cv_color || "",
            }
          : template[value],
        {
          headers: {
            "Accept-Language": "en",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response?.data?.success);
      if (response?.data?.success) {
        setLoading(false);
        onInputChange("step2", "cvUrl", response?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const classicRef = useRef();
  const functionalRef = useRef();
  const modernRef = useRef();
  const combinedRef = useRef();
  const { isLoading, cvTypes, template, templates } = useSelector(
    (state) => state.cv
  );
  const [editCv, setEditCv] = useState("");

  useEffect(() => {
    const data = {
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      profile_img: userData?.profile_img || "",
      profile_headline: userData?.profile_headline || "",
      email: userData?.email || "",
      phone: userData?.phone || "",
      profile_url: userData?.profile_url || "",
      address: userData?.address || "",
      about: userData?.about || "",
      linkedin_url: userData?.linkedin_url || "",
      altranate_phone: userData?.altranate_phone || "",
      altranate_email: userData?.altranate_email || "",
      experiences: userData?.experiences || [],
      educations: userData?.educations || [],
      exps_and_skills: userData?.exps_and_skills || [],
      interests: userData?.interests || [],
      cv_interestColor: userData?.cv_interestColor || "",
      languages: userData?.languages || [],
      honors_and_awards: userData?.honors_and_awards || [],
      cv_skillColor: userData?.cv_skillColor || "",
      cv_color: userData?.cv_color || "",
    };
    if (userData !== undefined) {
      dispatch(
        fetchCvPdf(
          "classic",
          template["classic"]?.first_name == "" ? data : template["classic"]
        )
      );
      dispatch(
        fetchCvPdf(
          "functional",
          template["functional"]?.first_name == ""
            ? data
            : template["functional"]
        )
      );
      dispatch(
        fetchCvPdf(
          "modern",
          template["modern"]?.first_name == "" ? data : template["modern"]
        )
      );
      dispatch(
        fetchCvPdf(
          "combined",
          template["combined"]?.first_name == "" ? data : template["combined"]
        )
      );
    }
  }, [userData]);

  console.log(userData?.resumes);

  return (
    <CustomScrollbarContainer>
      <div className="add-resume-pop-up search-default-pop-up resume-cv">
        <div className="search-default-pop-up-inner">
          <h3>Add Resume / CV</h3>
          <h4>Use NobelPage ATS-Friendly Template</h4>
          <p>
            By selecting this option, you can also customize colors according to
            your preference.
          </p>
          <p
            onClick={() => navigate("/cv", { state: { fromSmartApply: true } })}
            className="blue-link"
          >
            Edit My Resume / CV
          </p>
          <form>
            {loading ? (
              <AppLoader />
            ) : (
              <div
                className="resume-list-outer"
                // onClick={() =>
                //   alert("This feature will implement in future milestone")
                // }
              >
                <div className="resume-list">
                  <span className="title">Classic</span>
                  <img
                    src={resumeImg1}
                    alt="Image"
                    style={{ width: "175px" }}
                  />
                  {/* <div style={{ width: "200px" }}>
                <ClassicTemplate />
              </div> */}
                  <div className="eye-checkbox">
                    <img
                      src={Eyeresume}
                      alt="Image"
                      onClick={() => setPreviewResumeModal("classic")}
                    />
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={
                        formData?.step2?.selectedResume == "classic-resume"
                        // selectedTemplate == "classic"
                      }
                      onChange={(e) => {
                        handlePdfUpload("classic");
                        onInputChange(
                          "step2",
                          "selectedResume",
                          `classic-resume`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="resume-list">
                  <span className="title">Functional</span>
                  <img
                    src={resumeImg3}
                    alt="Image"
                    style={{ width: "175px" }}
                  />
                  {userData?.membership?.id > 1 ? (
                    <div className="eye-checkbox">
                      <img
                        src={Eyeresume}
                        alt="Image"
                        onClick={() => setPreviewResumeModal("functional")}
                      />
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={
                          formData?.step2?.selectedResume == "functional-resume"
                          // selectedTemplate == "functional"
                        }
                        onChange={(e) => {
                          handlePdfUpload("functional");
                          onInputChange(
                            "step2",
                            "selectedResume",
                            `functional-resume`
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span className="lock-icon">
                      <img src={lock} alt="Image" />
                      <span>Unlock More Features</span>
                    </span>
                  )}
                </div>
                <div className="resume-list">
                  <span className="title">Modern</span>
                  <img
                    src={resumeImg2}
                    alt="Image"
                    style={{ width: "175px" }}
                  />
                  {userData?.membership?.id > 1 ? (
                    <div className="eye-checkbox">
                      <img
                        src={Eyeresume}
                        alt="Image"
                        onClick={() => setPreviewResumeModal("modern")}
                      />
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={
                          formData?.step2?.selectedResume == "modern-resume"
                          // selectedTemplate == "modern"
                        }
                        onChange={(e) => {
                          handlePdfUpload("modern");
                          onInputChange(
                            "step2",
                            "selectedResume",
                            `modern-resume`
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span className="lock-icon">
                      <img src={lock} alt="Image" />
                      <span>Unlock More Features</span>
                    </span>
                  )}
                </div>
                <div className="resume-list">
                  <span className="title">Combined</span>
                  <img
                    src={resumeImg4}
                    alt="Image"
                    style={{ width: "175px" }}
                  />
                  {userData?.membership?.id > 1 ? (
                    <div className="eye-checkbox">
                      <img
                        src={Eyeresume}
                        alt="Image"
                        onClick={() => setPreviewResumeModal("combined")}
                      />
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={
                          formData?.step2?.selectedResume == "combined-resume"
                        }
                        onChange={(e) => {
                          handlePdfUpload("combined");
                          onInputChange(
                            "step2",
                            "selectedResume",
                            `combined-resume`
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <span className="lock-icon">
                      <img src={lock} alt="Image" />
                      <span>Unlock More Features</span>
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="or">
              <span>OR</span>
            </div>
            <div className="content-box">
              <p>
                Choose your resume/CV to apply for the job. <br />
                You can choose nobelpage generated resume or you can upload a
                new resume here
              </p>
            </div>
            {userData?.resumes?.length > 0 &&
              !formData?.step2?.urlData &&
              userData?.resumes?.map((ele, i) => {
                return (
                  <>
                    {console.log(
                      formData?.step2?.uploadedResume,
                      formData?.step2?.selectedResume,
                      ele?.resume_url,
                      formData?.step2?.uploadedResume == ele?.resume_url
                    )}
                    <div className="upload-resume-list-box">
                      <div className="upload-resume-list">
                        <div className="checkbox">
                          <input
                            name="user-resume"
                            type="checkbox"
                            checked={
                              formData?.step2?.selectedResume ==
                                "user-resume" &&
                              formData?.step2?.uploadedResume == ele?.resume_url
                            }
                            className="form-check-input"
                            onChange={() => {
                              handleChange("user-resume", ele?.resume_url);
                            }}
                          />
                        </div>
                        <div className="img-icon">
                          <img src={pdfFileIcon} alt="Image" />
                        </div>
                        <div className="img-txt">
                          <a href={ele?.resume_url} target="_blank">
                            <div className="head card-control-text-oneline">
                              {ele?.resume_name}
                            </div>
                          </a>
                          <div className="date">
                            Last updated on{" "}
                            {ele?.resume_date
                              ? new Date(ele?.resume_date).toLocaleDateString()
                              : new Date().toLocaleDateString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {formData?.step2?.urlData && (
              <div className="upload-resume-list-box">
                <div className="upload-resume-list">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      checked={
                        formData?.step2?.selectedResume == "upload-resume"
                      }
                      className="form-check-input"
                      onChange={(e) => {
                        handleChange("upload-resume");
                      }}
                    />
                  </div>
                  <div className="img-icon">
                    <img src={pdfFileIcon} alt="Image" />
                  </div>
                  <div className="img-txt">
                    <a href={formData?.step2?.urlData} target="_blank">
                      <div className="head card-control-text-oneline">
                        {formData?.step2?.fileName}
                      </div>
                    </a>
                    <div className="date">
                      <span style={{ marginRight: "3px" }}>
                        Last updated on
                      </span>
                      <span>{new Date().toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="upload-btn">
              <label htmlFor="uploadresume" style={{ color: "#0432A3" }}>
                Upload Resume / CV
              </label>
              <input
                type="file"
                id="uploadresume"
                accept=".pdf"
                name="uploadresume"
                onChange={onChangeResume}
              />
            </div>

            {errorMessage && (
              <div className="error-message" style={{ color: "red" }}>
                {errorMessage}
              </div>
            )}

            <div className="blue-btn-outer">
              <button className="white-btn fw-bold" onClick={onBack}>
                Back
              </button>
              <button className="blue-btn fw-bold" onClick={handleNext}>
                Next
              </button>
            </div>
          </form>
        </div>

        {previewResumeModal && (
          <ResumeForSmartApply
            url={cvTypes[previewResumeModal]}
            previewResumeModal={previewResumeModal}
            setPreviewResumeModal={setPreviewResumeModal}
          />
        )}
        {/* <div className="">
          <Cv />
        </div> */}
      </div>
    </CustomScrollbarContainer>
  );
};

const AddCoverLetterStep = ({
  onNext,
  onBack,
  onInputChange,
  formData,
  jobDetail,
  questionsList,
  id,
  setStep,
  setSelectedJob,
  selectedJob,
}) => {
  const [subject, setSubject] = useState(formData.step3.coverSubject || "");
  const [description, setDescription] = useState(
    formData.step3.coverDescription || ""
  );
  const [errorSubjectMessage, setErrorSubjectMessage] = useState("");
  const [errorDescriptionMessage, setErrorDescriptionMessage] = useState("");
  // const [convertedContent, setConvertedContent] = useState("");
  // const [dbFormat, setDbFormat] = useState()
  const dispatch = useDispatch();
  const handleSubjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
    setErrorSubjectMessage("");

    onInputChange("step3", "coverSubject", value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    setErrorDescriptionMessage("");
    onInputChange("step3", "coverDescription", value);
  };

  const validateStep3 = () => {
    const { coverSubject, coverDescription } = formData.step3;

    if (!coverSubject) {
      setErrorSubjectMessage("Please enter the cover letter description.");
      return false;
    }
    if (!coverDescription) {
      setErrorDescriptionMessage("Please enter the cover letter description.");
      return false;
    }

    return true;
  };
  // const handleNext = (e) => {
  //   e.preventDefault(); // Prevent the default form submission behavior
  //   if (!validateStep3()) {
  //     return; // Stop proceeding if validation fails
  //   }
  //   console.log(questionsList);
  //   // Continue with the next step
  //   if (questionsList?.length) onNext();
  //   else {
  //     const payload = {
  //       type: "smart",
  //       email: formData?.step1.email,
  //       phone: formData?.step1.phoneNumber,
  //       resume: formData?.step2.uploadedResume,
  //       cover_letter_desc: formData?.step3.coverDescription,
  //       cover_letter_subject: formData?.step3.coverSubject,
  //       apply_answers: [],
  //     };

  //     dispatch(SmartApplyData(payload, id))
  //       .then(() => {
  //         toast.success("Successfully Applied", {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });
  //         setSelectedJob({
  //           ...selectedJob,
  //           appliedJob: true,
  //         });
  //         return <ApplicationSuccessStep onClose={closeModal} />;
  //       })
  //       .catch((error) => {
  //         toast.error(error?.response?.data?.message, {
  //           position: toast.POSITION.BOTTOM_LEFT,
  //         });
  //       });
  //   }
  // };

  console.log(formData, "formDatataatta");

  const handleNext = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (!validateStep3()) {
      return; // Stop proceeding if validation fails
    }

    // If there are questions, proceed to the next step
    if (questionsList?.length) {
      onNext();
    } else {
      // Prepare the payload for submission
      const payload = {
        type: "smart",
        email: formData?.step1.email,
        phone: formData?.step1.phoneNumber,
        resume: formData?.step2.uploadedResume,
        cover_letter_desc: formData?.step3.coverDescription,
        cover_letter_subject: formData?.step3.coverSubject,
        apply_answers: [],
      };

      dispatch(SmartApplyData(payload, id))
        .then(() => {
          toast.success("Successfully Applied", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setStep(5);
          setSelectedJob({
            ...selectedJob,
            appliedJob: true,
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  };

  return (
    <CustomScrollbarContainer>
      <div className="add-cover-pop-up search-default-pop-up">
        <div className="search-default-pop-up-inner">
          <h3>Add Cover Letter</h3>
          <form>
            <div className="input-form">
              <input
                placeholder="Subject"
                type="text"
                value={subject}
                onChange={handleSubjectChange}
              />
            </div>
            {errorSubjectMessage && (
              <div className="error-message" style={{ color: "red" }}>
                {errorSubjectMessage}
              </div>
            )}

            <div className="input-form">
              <ReactQuill
                style={{ height: "250px" }} // Set the desired height here
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>

            {errorDescriptionMessage && (
              <div className="error-message mt-5" style={{ color: "red" }}>
                {errorDescriptionMessage}
              </div>
            )}
          </form>

          <div className="blue-btn-outer mt-5">
            <button className="white-btn" onClick={onBack}>
              Back
            </button>
            <button className="blue-btn" onClick={handleNext}>
              {questionsList?.length > 0 ? "Next" : "Apply"}
            </button>
            {!jobDetail?.cover_letter ||
              (questionsList?.length > 0 && (
                <button className="white-btn" onClick={() => onNext()}>
                  Skip
                </button>
              ))}
          </div>
        </div>
      </div>
    </CustomScrollbarContainer>
  );
};

const QuestionsAnswersStep = ({
  onNext,
  onBack,
  questionsList,
  onQuestionAnswer,
  id,
  formData,
  dispatch,
  setSelectedJob,
  selectedJob,
}) => {
  const [isAgree, setIsAgree] = useState(true);
  const [answers, setAnswers] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const handleQuestionAnswer = (question, answer) => {
    if (answer) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [question?.id]: answer,
      }));
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [question?.id]: "", // Clear error message when the user provides an answer
      }));
    } else if (question?.required) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [question?.id]: "Please provide the answer", // Clear error message when the user provides an answer
      }));
    }
  };

  const validateAnswers = () => {
    const newErrorMessages = {};
    let hasErrors = false;

    // Check for unanswered questions

    questionsList?.map((questionObject) => {
      const question = questionObject?.id;
      const answer = answers[question];

      if (!answer && questionObject?.required) {
        newErrorMessages[question] = "Please provide an answer.";
        hasErrors = true;
      }
      if (!isAgree) hasErrors = true;
    });

    setErrorMessages(newErrorMessages);

    return !hasErrors;
  };

  const handleNext = (e) => {
    e.preventDefault();

    const isValid = validateAnswers();

    if (isValid) {
      console.log(isValid, "isValid");
      const questionAnswersArray = Object.entries(answers).map(
        ([question, answer]) => ({
          question_id: question,
          answer: answer,
        })
      );
      const payload = {
        type: "smart",
        email: formData?.step1.email,
        phone: formData?.step1.phoneNumber,
        resume: formData?.step2.uploadedResume,
        cover_letter_desc: formData?.step3.coverDescription,
        cover_letter_subject: formData?.step3.coverSubject,
        apply_answers: questionAnswersArray,
      };

      dispatch(SmartApplyData(payload, id))
        .then(() => {
          toast.success("Successfully Applied", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setSelectedJob({
            ...selectedJob,
            appliedJob: true,
          });
          onNext();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    } else {
      console.log("Please answer all questions before proceeding.");
    }
  };

  return (
    <div className="questions-ans-pop-up search-default-pop-up">
      <div className="search-default-pop-up-inner">
        <h3>Questions</h3>
        <form>
          {questionsList?.map((questionObject, index) => (
            <div className="" key={index}>
              <label style={{ fontWeight: "450", fontSize: "18px" }}>
                {`${index + 1}. `}
                {questionObject.question}{" "}
                {questionObject?.required && (
                  <span className="text-danger">*</span>
                )}
              </label>
              <input
                style={{ marginBottom: "15px" }}
                type="text"
                placeholder="Answer"
                onChange={(e) =>
                  handleQuestionAnswer(questionObject, e.target.value)
                }
              />
              {console.log(errorMessages[questionObject?.id])}
              {errorMessages[questionObject?.id] && (
                <div className="error-message" style={{ color: "red" }}>
                  {errorMessages[questionObject?.id]}
                </div>
              )}
            </div>
          ))}

          <div className="input-form" style={{ display: "flex" }}>
            <input
              type="checkbox"
              className="form-check-input"
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <span style={{ marginTop: "2px", color: "#000" }}>
              I agree for self-paste and smart interviews, which appear by the
              job poster as information.
            </span>
          </div>
          {!isAgree && (
            <div className="error-message" style={{ color: "red" }}>
              Please check this box to indicate that you are aware of our terms.
            </div>
          )}
          <div className="blue-btn-outer">
            <button className="white-btn" onClick={onBack}>
              Back
            </button>

            <button className="blue-btn" onClick={handleNext}>
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ApplicationSuccessStep = ({ onClose }) => {
  const [tabsList, setTabsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(getExploreData({ category_id: "2" }))
      .then((resp) => {
        setTabsList(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error!!", err);
        setLoading(false);
      });
  }, []);

  //for more button

  return (
    <div className="application-pop-up search-default-pop-up pb-0">
      {loading ? ( // Show loader if loading is true
        <AppLoader />
      ) : (
        <>
          <div className="search-default-pop-up-inner">
            <div className="text-center">
              <img src={checkMark} alt="Image" />
            </div>
            <h3>Your application was sent successfully</h3>
            <div className="content-box">
              <p>
                Expect to hear back soon from the hiring team or job poster. In
                the meantime, <br />
                explore our additional tips and resources. Best of luck!
              </p>
              <p>
                Prepare for an Interview{" "}
                <button
                  style={{ all: "unset", cursor: "pointer", color: "#0432a3" }}
                  onClick={(e) => {
                    navigate("/interview-preperation");
                    e.preventDefault();
                  }}
                >
                  Click Here
                </button>
              </p>
            </div>
            <div
              className="img-content-box"
              //style={{overflow:"auto",height:"200px"}}
            >
              <h4>Explore our blogs for more information</h4>
              {tabsList.map((data, index) => {
                return (
                  <div key={index}>
                    {data.explores.slice(0, 2).map((exploreItem, ind) => (
                      <div className="img-content-column" key={ind}>
                        <div className="img-box">
                          <img
                            src={exploreItem.image_url}
                            alt="Image"
                            style={{ height: "84px", width: "112px" }}
                          />
                        </div>
                        <div
                          className="right-box"
                          style={{ paddingLeft: "0px", marginLeft: "-25px" }}
                        >
                          <h5>{exploreItem.title}</h5>
                          <p className="card-control-text-twoline">
                            {exploreItem.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <div className="TestRam d-flex justify-content-between align-items-baseline mt-3">
              <Link
                to="/explore?category_id=2"
                className="explore-more-btn blue-clr"
              >
                Explore More
              </Link>
              <div className="blue-btn-outer end" style={{ marginTop: 0 }}>
                <button className="blue-btn" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const JobModal = (props) => {
  const {
    modal,
    toggle,
    id,
    setSelectedJob,
    selectedJob,
    jobDetail,
    setJobActive,
    userData,
  } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { questionsList } = useSelector((state) => state.jobs);

  console.log(user.data.email, user.data.phone, questionsList);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: {
      email: user.data.email || "",
      phoneNumber: user.data.phone || "",
    },
    step2: {
      uploadedResume: "",
      selectedResume: "",
      urlData: "",
      fileName: "",
      cvUrl: "",
    },
    step3: { coverSubject: null, coverDescription: null },
    step4: questionsList?.reduce((acc, question) => {
      acc[question.question] = "";
      return acc;
    }, {}),
  });
  // const handleInputChange = (stepKey, field, value) => {
  //     console.log(stepKey, field, value, "stepKey, field, value");
  //     setFormData((prevData) => ({
  //         ...prevData,
  //         [stepKey]: { ...prevData[stepKey], [field]: value },
  //     }));
  // };
  const handleInputChange = (stepKey, field, value) => {
    setFormData((prevData) => {
      const updatedStepData = { ...prevData[stepKey] };
      updatedStepData[field] = value;
      return {
        ...prevData,
        [stepKey]: updatedStepData,
      };
    });
  };

  useEffect(() => {
    dispatch(getQuestions(id));
  }, []);

  const validateStep1 = () => {
    const { email, phoneNumber } = formData.step1;
    if (!email || !phoneNumber) {
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    // Implement your validation logic here
    return true;
  };

  const validateStep3 = () => {
    const { coverSubject, coverDescription } = formData.step3;

    // if (!coverSubject || !coverDescription) {
    //   alert("Please fill in all the required fields.");
    //   return false;
    // }

    return true;
  };

  const validateStep4 = () => {
    // Implement your validation logic here
    return true;
  };

  const handleFormDataChange = (stepKey, newData) => {
    setFormData((prevData) => ({ ...prevData, [stepKey]: newData }));
  };

  // const handleNext = () => {
  //   let isValid = false;
  //   switch (step) {
  //     case 1:
  //       isValid = validateStep1();
  //       break;
  //     case 2:
  //       isValid = validateStep2();
  //       break;
  //     case 3:
  //       isValid = validateStep3();
  //       break;
  //     case 4:
  //       isValid = validateStep4();
  //       break;
  //     default:
  //       break;
  //   }

  //   if (isValid) {
  //     setStep((prevStep) => prevStep + 1);
  //   }
  // };
  const handleNext = (stepName) => {
    let isValid = false;

    if (stepName === "success") {
      setStep(5);
      return;
    }

    switch (step) {
      case 1:
        isValid = validateStep1();
        break;
      case 2:
        isValid = validateStep2();
        break;
      case 3:
        isValid = validateStep3();
        break;
      case 4:
        isValid = validateStep4();
        break;
      default:
        break;
    }

    if (isValid) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const closeModal = () => {
    toggle();
  };
  const handleQuestionAnswer = (question, answer) => {
    setFormData((prevData) => ({
      ...prevData,
      step4: {
        ...prevData.step4,
        [question]: answer,
      },
    }));
  };

  const [openModel, setOpenModel] = useState(false);
  const handleToggle = () => {
    setOpenModel(!openModel);
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <PersonalDetailsStep
            onNext={handleNext}
            onInputChange={handleInputChange}
            formData={formData}
          />
        );
      case 2:
        return (
          <AddResumeStep
            onNext={handleNext}
            onBack={handleBack}
            onInputChange={handleInputChange}
            formData={formData}
            userData={userData}
          />
        );
      case 3:
        return (
          <AddCoverLetterStep
            onNext={handleNext}
            onBack={handleBack}
            onInputChange={handleInputChange}
            formData={formData}
            jobDetail={jobDetail}
            questionsList={questionsList}
            id={id}
            setJobActive={setJobActive}
            onClose={closeModal}
            setStep={setStep}
            setOpenModel={setOpenModel}
            toggle={toggle}
            setSelectedJob={setSelectedJob}
            selectedJob={selectedJob}
          />
        );
      case 4:
        return (
          <QuestionsAnswersStep
            onNext={handleNext}
            onBack={handleBack}
            id={id}
            dispatch={dispatch}
            formData={formData}
            questionsList={questionsList}
            onQuestionAnswer={handleQuestionAnswer}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
          />
        );
      case 5: // Render success step
        return <ApplicationSuccessStep onClose={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        centered
        // style={{ maxWidth: "574px" }}
      >
        {/* <ModalHeader toggle={() => toggle()}>
                </ModalHeader> */}
        <ModalBody>{renderContent()}</ModalBody>
      </Modal>

      {openModel && (
        <Modal
          isOpen={openModel}
          toggle={handleToggle}
          size="lg"
          centered
          // style={{ maxWidth: "574px" }}
        >
          {/* <ModalHeader toggle={() => toggle()}>
                </ModalHeader> */}
          <ModalBody>
            <ApplicationSuccessStep onClose={handleToggle} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default JobModal;
