import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import pass from "../../../assets/img/shield-security.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import email from "../../../assets/img/a.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { call } from "../../../api/apiCall";
import { toast } from "react-toastify";
const MergeAccountModal = (props) => {
  const { modal, toggle, title } = props;
  const [btnStatus, setBtnStatus] = useState(false);
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const { profileData } = useSelector((state) => state.profile);
  const checkValidation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (type === "emailName" || type === "all") {
      if (!emailName) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailvalid.test(emailName)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    }
    if (type === "password" || type === "all") {
      if (!password) {
        setErrorPassword("Please enter your password.");
        formIsValid = false;
      } else if (!passwordValid.test(password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setErrorPassword("");
      }
    }
    return formIsValid;
  };

  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      };
      const res = await call(
        "POST",
        `api/v1/user/merge-account`,
        {},
        { email: emailName, password },
        token
      );
      if (res?.data?.success) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        toggle();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (!emailName || !password) {
      setBtnStatus(false);
    }
    if (emailName && password) {
      setBtnStatus(true);
    }
  }, [emailName, password]);

  const handleMergeSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      handleFormSubmit();
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box merge-account">
                <p>Current account you are logged in</p>
                <div className="mt-3"></div>
                <div className="d-flex align-items-start">
                  <img
                    src={profileData?.profile_img}
                    width={70}
                    height={70}
                    className="rounded-circle"
                  />
                  <div className="mx-3">
                    <h6 className="mt-2 text-dark">
                      {profileData?.first_name + " " + profileData?.last_name}
                    </h6>
                    <p>{profileData?.profile_headline}</p>
                  </div>
                </div>

                <p className="mt-3 mb-3">
                  Insert the email and password from the account you wish to
                  have it merged!
                </p>
                <FormInput
                  type="email"
                  onChange={(e) => {
                    setEmailName(e.target.value);
                    setErrorEmail("");
                  }}
                  placeholder="Email"
                  onBlur={() => checkValidation("emailName")}
                  error={errorEmail}
                  containerClassName={
                    "inputfields" + (errorEmail ? "error-form" : "")
                  }
                  // icon={email}
                />
                <div className="mt-3"></div>

                <FormInput
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorPassword("");
                  }}
                  placeholder="Enter Password"
                  onBlur={() => checkValidation("password")}
                  error={errorPassword}
                  // containerClassName="inputfields"
                  containerClassName={
                    "inputfields" + (errorPassword ? "error-form" : "")
                  }
                  // icon={pass}
                />

                <div className="text-center mt-20">
                  <div
                    className={
                      btnStatus ? "btn btn-blue" : "btn btn-blue disabledItem"
                    }
                    onClick={(e) => handleMergeSubmit(e)}
                    disabled={btnStatus}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MergeAccountModal;
