import {
  CLOSE_JOB,
  GET_APPLICANTS,
  GET_COMPANY_JOBS,
  GET_TALENT_POOL,
  LOAD_MORE_GET_COMPANY_JOBS,
  UPDATE_APPLICANTS,
  UPDATE_JOB,
  UPDATE_TALENT_POOL,
  GET_ALL_APPLICANTS_DETAILS_BYID,
  UPDATE_APPLICANTS_BY_ID,
  GET_MEMBER_LIST_SUCCESS,
  LOAD_MORE_MEMBER_LIST_SUCCESS,
  GET_SINGLE_MEMBER_DATA,
} from "../types";

export const initialState = {
  portal: {},
  jobs: [],
  applicantDetail: {},
  members: [],
  memberDetail: {},
};
const talentPoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TALENT_POOL:
      return {
        ...state,
        portal: action.payload,
      };
    case GET_COMPANY_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case LOAD_MORE_GET_COMPANY_JOBS:
      return {
        ...state,
        jobs: [...state.jobs, ...action.payload],
      };
    // case UPDATE_JOB:
    //   return {
    //     ...state,
    //     jobs: state.jobs?.map((item) =>
    //       item.id === action.payload?.id ? { ...action.payload } : item
    //     ),
    //   };
    case CLOSE_JOB:
      console.log("Closing job", action.payload);
      return {
        ...state,
        jobs: state.jobs?.filter((item) => item.id !== action.payload?.id),
      };
    case GET_APPLICANTS:
      return {
        ...state,
        jobs: state.jobs?.map((item) =>
          item.id === action.payload?.id
            ? { ...item, applicants: action.payload?.data }
            : item
        ),
      };
    case UPDATE_APPLICANTS:
      return {
        ...state,
        jobs: state.jobs?.map((item) =>
          item.id === action.payload?.jobId
            ? {
                ...item,
                applicants: item?.applicants?.map((applicant) =>
                  applicant?.id === action.payload?.id
                    ? {
                        ...applicant,
                        apply_status: action.payload?.status,
                      }
                    : applicant
                ),
              }
            : item
        ),
      };
    case UPDATE_APPLICANTS_BY_ID:
      return {
        ...state,
        applicantDetail: {
          ...state?.applicantDetail,
          apply_status: action.payload?.status,
        },
      };
    case UPDATE_TALENT_POOL:
      return {
        ...state,
        portal: { ...state?.portal, ...action.payload },
      };
    case GET_ALL_APPLICANTS_DETAILS_BYID:
      return {
        ...state,
        applicantDetail: action.payload,
      };
    case GET_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.payload,
      };
    case GET_SINGLE_MEMBER_DATA:
      return {
        ...state,
        memberDetail: action.payload,
      };
    case LOAD_MORE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: [...state.members, ...action.payload],
      };
    default:
      return state;
  }
};
export default talentPoolReducer;
