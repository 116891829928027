import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { toast } from "react-toastify";
import SearchSettings from "../components/SearchSettings";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";

const Visibility = (props) => {
  const AccountSearchList = [
    { id: "1", name: "Profile viewing options" },
    { id: "2", name: "Contact viewing option" },
    { id: "3", name: "Last name visibility" },
    { id: "4", name: "Who can see members you follow" },
    { id: "5", name: "Representing your organization and interests" },
    { id: "6", name: "Profile visibility outside NobelPage" },
    { id: "7", name: "Profile discovery using E-Mail" },
    { id: "8", name: "Profile discovery using Phone" },
    { id: "9", name: "Manage active status" },
    { id: "10", name: "Share profile updates with your network" },
    { id: "11", name: "Notify connection when you're in the news" },
    { id: "12", name: "Mentions or tags" },
    { id: "13", name: "Followers" },
  ];
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = localStorage.getItem("accessToken");
  const { isActive } = props;
  const { settingData } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [profileViewingSetting, setProfileViewingSetting] = useState([]);
  const [contactViewingSetting, setContactViewingSetting] = useState([]);
  const [lastNameSetting, setLastNameSetting] = useState([]);
  const [followViewingSetting, setFollowViewingSetting] = useState([]);
  const [representSetting, setRepresentSetting] = useState([]);
  const [shareSetting, setShareSetting] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [phoneSetting, setPhoneSetting] = useState([]);
  const [activitySetting, setActivitySetting] = useState([]);
  const [updatesSetting, setUpdatesSetting] = useState([]);
  const [notifySetting, setNotifySetting] = useState([]);
  const [tagSetting, setTagSetting] = useState([]);
  const [followerSetting, setFollowerSetting] = useState([]);
  const [userData, setUserData] = useState("");
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [modalOpen7, setModalOpen7] = useState(false);
  const [modalOpen8, setModalOpen8] = useState(false);
  const [modalOpen9, setModalOpen9] = useState(false);
  const [modalOpen10, setModalOpen10] = useState(false);
  const [modalOpen16, setModalOpen16] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen11, setModalOpen11] = useState(false);
  const [modalOpen12, setModalOpen12] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  useEffect(() => {
    if (selectedProfile.id === "1") {
      setModalOpen3(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "2") {
      setModalOpen10(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "3") {
      setModalOpen16(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "4") {
      setModalOpen1(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "5") {
      setModalOpen2(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "6") {
      setModalOpen4(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "7") {
      setModalOpen5(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "8") {
      setModalOpen6(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "9") {
      setModalOpen7(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "10") {
      setModalOpen8(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "11") {
      // setModalOpen2(true);
      // setDefaultValue("");
    }

    if (selectedProfile.id === "12") {
      // setModalOpen2(true);
      // setDefaultValue("");
    }

    if (selectedProfile.id === "13") {
      // setModalOpen2(true);
      // setDefaultValue("");
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (settingData) {
      setProfileViewingSetting(settingData?.data[11]?.settings);
      setContactViewingSetting(settingData?.data[12]?.settings);
      setLastNameSetting(settingData?.data[13]?.settings);
      setFollowViewingSetting(settingData?.data[14]?.settings);
      setRepresentSetting(settingData?.data[15]?.settings);
      setShareSetting(settingData?.data[16]?.settings);
      setEmailSetting(settingData?.data[17]?.settings);
      setPhoneSetting(settingData?.data[18]?.settings);
      setActivitySetting(settingData?.data[19]?.settings);
      setUpdatesSetting(settingData?.data[20]?.settings);
      setNotifySetting(settingData?.data[21]?.settings);
      setTagSetting(settingData?.data[22]?.settings);
      setFollowerSetting(settingData?.data[23]?.settings);
    }
  }, [settingData]);

  useEffect(() => {
    if (user) {
      setUserData(user.data);
    }
  }, [user]);

  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const toggleModal10 = () => {
    setModalOpen10(!modalOpen10);
  };

  const toggleModal16 = () => {
    setModalOpen16(!modalOpen16);
  };

  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const toggleModal6 = () => {
    setModalOpen6(!modalOpen6);
  };

  const toggleModal7 = () => {
    setModalOpen7(!modalOpen7);
  };

  const toggleModal8 = () => {
    setModalOpen8(!modalOpen8);
  };

  const toggleModal9 = () => {
    setModalOpen9(!modalOpen9);
  };

  const toggleModal11 = () => {
    setModalOpen11(!modalOpen11);
  };

  const toggleModal12 = () => {
    setModalOpen12(!modalOpen12);
  };

  const handleValueChange = (value) => {
    const updatedArray = profileViewingSetting.map((obj, i) => {
      if (i === openItem) {
        return { ...obj, value: value };
      }
      return obj;
    });
    console.log("updatedArray", updatedArray);
    setProfileViewingSetting(updatedArray);
  };

  const handleRepresentChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = representSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setRepresentSetting(updatedArray);
  };

  const handleShareChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = shareSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setShareSetting(updatedArray);
  };

  const handleUpdatesChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = updatesSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setUpdatesSetting(updatedArray);
  };

  const handleNotifyChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = notifySetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setNotifySetting(updatedArray);
  };

  const handleValueChangeContact = (value) => {
    const updatedArray = contactViewingSetting.map((obj, i) => {
      if (i === openItem) {
        return { ...obj, value: value };
      }
      return obj;
    });
    setContactViewingSetting(updatedArray);
  };

  const handleValueChangeLast = (value) => {
    const updatedArray = lastNameSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setLastNameSetting(updatedArray);
  };

  const handleValueChangeFollow = (value) => {
    const updatedArray = followViewingSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setFollowViewingSetting(updatedArray);
  };

  const handleFollowerChange = (value, checkSetting = false) => {
    if (checkSetting) {
      let targetValue;
      if (value) {
        targetValue = "1";
      } else {
        targetValue = "0";
      }

      const updatedArray = followerSetting.map((obj, i) => {
        if (i == 1) {
          return { ...obj, value: targetValue };
        } else {
          return { ...obj };
        }
      });
      setFollowerSetting(updatedArray);
    } else {
      const updatedArray = followerSetting.map((obj, i) => {
        if (i == 0) {
          return { ...obj, value: value };
        } else {
          return { ...obj };
        }
      });
      setFollowerSetting(updatedArray);
    }
  };

  const handleEmailChange = (value) => {
    const updatedArray = emailSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setEmailSetting(updatedArray);
  };

  const handlePhoneChange = (value) => {
    const updatedArray = phoneSetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setPhoneSetting(updatedArray);
  };

  const handleActivityChange = (value) => {
    const updatedArray = activitySetting.map((obj, i) => {
      return { ...obj, value: value };
    });
    setActivitySetting(updatedArray);
  };

  const handleTagChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = tagSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setTagSetting(updatedArray);
  };

  const handleProfileViewClose = () => {
    console.log(settingData);
    setOpenItem(null);
    setProfileViewingSetting(settingData.data[11].settings);
    setModalOpen3(false);
  };

  const handleActivityClose = () => {
    setModalOpen7(false);
    setActivitySetting(settingData.data[19].settings);
  };

  const handleContactViewClose = () => {
    setOpenItem(null);
    setContactViewingSetting(settingData.data[12].settings);
    setModalOpen10(false);
  };

  const handleLastClose = () => {
    setModalOpen16(false);
    setLastNameSetting(settingData.data[13].settings);
  };

  const handleFollowClose = () => {
    setModalOpen1(false);
    setFollowViewingSetting(settingData.data[14].settings);
  };

  const handleFollowerClose = () => {
    setModalOpen12(false);
    setFollowerSetting(settingData.data[23].settings);
  };

  const handleEmailClose = () => {
    setModalOpen5(false);
    setEmailSetting(settingData.data[17].settings);
  };

  const handlePhoneClose = () => {
    setModalOpen6(false);
    setPhoneSetting(settingData.data[18].settings);
  };

  const handleRepresentClose = () => {
    setModalOpen2(false);
    setRepresentSetting(settingData.data[15].settings);
  };

  const handleSharingClose = () => {
    setModalOpen4(false);
    setShareSetting(settingData.data[16].settings);
  };

  const handleUpdatesClose = () => {
    setModalOpen8(false);
    setUpdatesSetting(settingData.data[20].settings);
  };

  const handleNotifyClose = () => {
    setModalOpen9(false);
    setNotifySetting(settingData.data[21].settings);
  };

  const handleTagClose = () => {
    setModalOpen11(false);
    setTagSetting(settingData.data[22].settings);
  };

  const handleProfileViewSave = () => {
    let updatedItems = [];

    for (let i = 0; i < profileViewingSetting.length; i++) {
      let myObj = {
        setting_master_id: profileViewingSetting[i].id,
        value: profileViewingSetting[i].value,
      };

      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };
    setModalOpen3(false);
    setOpenItem(null);

    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile Viewing Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleEmailSave = () => {
    let updatedItems = [];

    for (let i = 0; i < emailSetting.length; i++) {
      let myObj = {
        setting_master_id: emailSetting[i].id,
        value: emailSetting[i].value,
      };

      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen5(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile Discovery Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handlePhoneSave = () => {
    let updatedItems = [];

    for (let i = 0; i < phoneSetting.length; i++) {
      let myObj = {
        setting_master_id: phoneSetting[i].id,
        value: phoneSetting[i].value,
      };

      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen6(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile Discovery Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleContactViewSave = () => {
    let updatedItems = [];

    for (let i = 0; i < contactViewingSetting.length; i++) {
      let myObj = {
        setting_master_id: contactViewingSetting[i].id,
        value: contactViewingSetting[i].value,
      };

      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen10(false);
    setOpenItem(null);

    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Contact viewing Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleLastSave = () => {
    let updatedItems = [];

    for (let i = 0; i < lastNameSetting.length; i++) {
      let myObj = {
        setting_master_id: lastNameSetting[i].id,
        value: lastNameSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen16(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Last name visibility Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleRepresentSave = () => {
    let updatedItems = [];

    for (let i = 0; i < representSetting.length; i++) {
      let myObj = {
        setting_master_id: representSetting[i].id,
        value: representSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen2(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Representing Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleSharingSave = () => {
    let updatedItems = [];

    for (let i = 0; i < shareSetting.length; i++) {
      let myObj = {
        setting_master_id: shareSetting[i].id,
        value: shareSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen4(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile visbility settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleUpdatesSave = () => {
    let updatedItems = [];

    for (let i = 0; i < updatesSetting.length; i++) {
      let myObj = {
        setting_master_id: updatesSetting[i].id,
        value: updatesSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen8(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Share profile updates Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleFollowSave = () => {
    let updatedItems = [];

    for (let i = 0; i < followViewingSetting.length; i++) {
      let myObj = {
        setting_master_id: followViewingSetting[i].id,
        value: followViewingSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen1(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Follow visibility Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleFollowerSave = () => {
    let updatedItems = [];

    for (let i = 0; i < followerSetting.length; i++) {
      let myObj = {
        setting_master_id: followerSetting[i].id,
        value: followerSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen12(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Followers Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleActivitySave = () => {
    let updatedItems = [];

    for (let i = 0; i < activitySetting.length; i++) {
      let myObj = {
        setting_master_id: activitySetting[i].id,
        value: activitySetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen7(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Manage Activity Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleNotifySave = () => {
    let updatedItems = [];

    for (let i = 0; i < notifySetting.length; i++) {
      let myObj = {
        setting_master_id: notifySetting[i].id,
        value: notifySetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen9(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Notify connection Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleTagSave = () => {
    let updatedItems = [];

    for (let i = 0; i < tagSetting.length; i++) {
      let myObj = {
        setting_master_id: tagSetting[i].id,
        value: tagSetting[i].value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen11(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Mention or Tags Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  let firstName = userData.first_name;
  let lastName = userData.last_name;
  let shortLast, finalShort;
  if (lastName) {
    shortLast = lastName.split("")[0];
    finalShort = shortLast.toUpperCase();
  }

  return (
    <>
      {isPageLoading && <AppLoader />}
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div className="heading">
              <h1>Visibility</h1>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="search">
              <SearchSettings
                results={results}
                value={selectedProfile?.name}
                renderItem={(item) => <p>{item.name}</p>}
                onChange={handleChange}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                onSelect={(item) => setSelectedProfile(item)}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="profile-information">
            <h4>Profile and Network</h4>
            <ul>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal3()}
              >
                Profile viewing options
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal10()}
              >
                Contact viewing options
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal16()}
              >
                Last name visibility
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal1()}
              >
                Who can see members you follow
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal2()}
              >
                Representing your organization and interests
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal4()}
              >
                Profile discovery and visibility
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal5()}
              >
                Profile discovery using E-Mail
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal6()}
              >
                Profile discovery using Phone
              </li>
            </ul>
          </div>
          <div className="line"></div>
          <div className="profile-information">
            <h4>NobelPage Activity</h4>
            <ul>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal7()}
              >
                Manage active status
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal8()}
              >
                Share profile updates with your network
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal9()}
              >
                Notify connections when you're in the news
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal11()}
              >
                Mentions or tags
              </li>
              <li
                className={isActive ? "active" : null}
                onClick={() => toggleModal12()}
              >
                Followers
              </li>
            </ul>
          </div>
        </div>
        <Modal
          backdrop="static"
          isOpen={modalOpen3}
          className="add-email-account-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleProfileViewClose}>
            <h4 style={{ color: "white" }}>Profile Viewing Options</h4>
          </ModalHeader>
          <ModalBody>
            <div className="accordion">
              <div className={`accordion-item ${openItem === 0 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(0)}
                >
                  Background and Profile Image Visibility
                </h6>
                {openItem === 0 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[0].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[0].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[0].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[0].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 1 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(1)}
                >
                  Connection
                </h6>
                {openItem === 1 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[1].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[1].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[1].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[1].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 2 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(2)}
                >
                  Followers
                </h6>
                {openItem === 2 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[2].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[2].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[2].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[2].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>

              <div className={`accordion-item ${openItem === 3 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(3)}
                >
                  Connection Count
                </h6>
                {openItem === 3 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[3].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[3].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[3].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[3].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 4 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(4)}
                >
                  Followers Count
                </h6>
                {openItem === 4 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[4].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[4].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[4].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[4].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 5 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(5)}
                >
                  Badge Visibility
                </h6>
                {openItem === 5 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[5].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[5].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[5].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[5].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>

              <div className={`accordion-item ${openItem === 6 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(6)}
                >
                  About Me Visibility
                </h6>
                {openItem === 6 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[6].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[6].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[6].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[6].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 7 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(7)}
                >
                  Experience Visibility
                </h6>
                {openItem === 7 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[7].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[7].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[7].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[7].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 8 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(8)}
                >
                  Education Visibility
                </h6>
                {openItem === 8 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[8].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[8].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[8].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[8].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 9 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(9)}
                >
                  CV Visibility
                </h6>
                {openItem === 9 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[9].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[9].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[9].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[9].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 10 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(10)}
                >
                  Share Celebration
                </h6>
                {openItem === 10 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[10].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[10].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[10].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[10].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 11 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(11)}
                >
                  Honors and Awards Visibility
                </h6>
                {openItem === 11 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[11].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[11].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[11].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[11].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 12 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(12)}
                >
                  Licenses and Certification Visibility
                </h6>
                {openItem === 12 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[12].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[12].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[12].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[12].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 13 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(13)}
                >
                  Courses Visibility
                </h6>
                {openItem === 13 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[13].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[13].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[13].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[13].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 14 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(14)}
                >
                  Skills Visibility
                </h6>
                {openItem === 14 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[14].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[14].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[14].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[14].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 15 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(15)}
                >
                  Language Visibility
                </h6>
                {openItem === 15 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[15].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[15].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[15].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[15].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 16 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(16)}
                >
                  Introduction Video Visibility
                </h6>
                {openItem === 16 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[16].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[16].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[16].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[16].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 17 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(17)}
                >
                  Interests Visibility
                </h6>
                {openItem === 17 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[17].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[17].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[17].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[17].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 18 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(18)}
                >
                  Invitation/Connection Request Settings
                </h6>
                {openItem === 18 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[18].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[18].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[18].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[18].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`accordion-item ${openItem === 19 ? "open" : ""}`}
              >
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(19)}
                >
                  Who Can Recommend Me
                </h6>
                {openItem === 19 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={profileViewingSetting[19].value == "1"}
                        onChange={() => handleValueChange("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={profileViewingSetting[19].value == "2"}
                        onChange={() => handleValueChange("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={profileViewingSetting[19].value == "3"}
                        onChange={() => handleValueChange("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={profileViewingSetting[19].value == "4"}
                        onChange={() => handleValueChange("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div
                className="text-end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: "10px",
                }}
              >
                <div
                  className="btn btn-outline"
                  onClick={() => handleProfileViewClose()}
                >
                  Close
                </div>
                <div
                  className="btn btn-blue"
                  onClick={() => handleProfileViewSave()}
                >
                  Save
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen10}
          className="add-email-account-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleContactViewClose}>
            <h4 style={{ color: "white" }}>Contact Visibility</h4>
          </ModalHeader>
          <ModalBody>
            <div className="accordion">
              <div className={`accordion-item ${openItem === 0 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(0)}
                >
                  Phone Number
                </h6>
                {openItem === 0 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={contactViewingSetting[0].value == "1"}
                        onChange={() => handleValueChangeContact("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={contactViewingSetting[0].value == "2"}
                        onChange={() => handleValueChangeContact("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={contactViewingSetting[0].value == "3"}
                        onChange={() => handleValueChangeContact("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={contactViewingSetting[0].value == "4"}
                        onChange={() => handleValueChangeContact("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 1 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(1)}
                >
                  Email
                </h6>
                {openItem === 1 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={contactViewingSetting[1].value == "1"}
                        onChange={() => handleValueChangeContact("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={contactViewingSetting[1].value == "2"}
                        onChange={() => handleValueChangeContact("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={contactViewingSetting[1].value == "3"}
                        onChange={() => handleValueChangeContact("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={contactViewingSetting[1].value == "4"}
                        onChange={() => handleValueChangeContact("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 2 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(2)}
                >
                  Address
                </h6>
                {openItem === 2 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={contactViewingSetting[2].value == "1"}
                        onChange={() => handleValueChangeContact("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={contactViewingSetting[2].value == "2"}
                        onChange={() => handleValueChangeContact("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={contactViewingSetting[2].value == "3"}
                        onChange={() => handleValueChangeContact("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={contactViewingSetting[2].value == "4"}
                        onChange={() => handleValueChangeContact("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 3 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(3)}
                >
                  Birthday
                </h6>
                {openItem === 3 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={contactViewingSetting[3].value == "1"}
                        onChange={() => handleValueChangeContact("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={contactViewingSetting[3].value == "2"}
                        onChange={() => handleValueChangeContact("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={contactViewingSetting[3].value == "3"}
                        onChange={() => handleValueChangeContact("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={contactViewingSetting[3].value == "4"}
                        onChange={() => handleValueChangeContact("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
              <div className={`accordion-item ${openItem === 4 ? "open" : ""}`}>
                <h6
                  className="gray-light600 accordion-title"
                  onClick={() => toggleItem(4)}
                >
                  Website
                </h6>
                {openItem === 4 && (
                  <div className="accordion-content">
                    <label for="everyone">
                      <input
                        type="radio"
                        id="everyone"
                        name="radio"
                        checked={contactViewingSetting[4].value == "1"}
                        onChange={() => handleValueChangeContact("1")}
                      />{" "}
                      Visible To Everyone
                    </label>
                    <label for="network">
                      <input
                        type="radio"
                        id="network"
                        name="radio"
                        checked={contactViewingSetting[4].value == "2"}
                        onChange={() => handleValueChangeContact("2")}
                      />{" "}
                      Visible To My Network
                    </label>
                    <label for="scouts">
                      <input
                        type="radio"
                        id="scouts"
                        name="radio"
                        checked={contactViewingSetting[4].value == "3"}
                        onChange={() => handleValueChangeContact("3")}
                      />{" "}
                      Scouts only
                    </label>
                    <label for="hidden">
                      <input
                        type="radio"
                        id="hidden"
                        name="radio"
                        checked={contactViewingSetting[4].value == "4"}
                        onChange={() => handleValueChangeContact("4")}
                      />{" "}
                      Hidden
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleContactViewClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleContactViewSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen16}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleLastClose}>
            <h4 style={{ color: "white" }}>Last name visibility</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Choose your preferred visibility option for your name.
            </p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  style={{ width: "20px", height: "20px" }}
                  className="my-input-custom"
                  type="radio"
                  id="checkbox1 last-name-custom"
                  name="checkbox"
                  checked={
                    lastNameSetting?.length > 0
                      ? lastNameSetting[0]?.value == "1"
                      : false
                  }
                  onChange={() => handleValueChangeLast("1")}
                />
                <img src={userData.profile_img} alt="Profile Img" />
                <h3 style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {firstName} {lastName}
                </h3>
              </div>
              <div className="radio-list" style={{ marginTop: "10px" }}>
                <input
                  style={{ width: "20px", height: "20px" }}
                  className="my-input-custom"
                  type="radio"
                  id="checkbox2 last-name-custom"
                  name="checkbox"
                  checked={
                    lastNameSetting?.length > 0
                      ? lastNameSetting[0]?.value == "2"
                      : false
                  }
                  onChange={() => handleValueChangeLast("2")}
                />
                <img src={userData.profile_img} alt="Profile Img" />
                <h3 style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {firstName} {finalShort}
                </h3>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleLastClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleLastSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen1}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleFollowClose}>
            <h4 style={{ color: "white" }}>Follow visibility</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Select the Control how others can see your follow activity.
            </p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    followViewingSetting?.length &&
                    followViewingSetting[0]?.value == "1"
                  }
                  onChange={() => handleValueChangeFollow("1")}
                />
                <label for="checkbox1">Anyone on NoblePage</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    followViewingSetting?.length &&
                    followViewingSetting[0]?.value == "2"
                  }
                  onChange={() => handleValueChangeFollow("2")}
                />
                <label for="checkbox2">Only visible to me</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleFollowClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleFollowSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen2}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={toggleModal2}>
            <h4>Represent company</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Activating the toggle allows your name and/or picture to appear
              with employer-related content and topics you've shown interest in.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Profile information on content
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          representSetting?.length &&
                            parseInt(representSetting[0]?.value)
                        )}
                        onChange={(e) =>
                          handleRepresentChange(e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleRepresentClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleRepresentSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen4}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={toggleModal4}>
            <h4>Data sharing</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Select the desired setting. Your profile could be visible on
              search engines.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Profile visibility outside NobelPage
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          shareSetting?.length &&
                            parseInt(shareSetting[0]?.value)
                        )}
                        onChange={(e) => handleShareChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleSharingClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleSharingSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen5}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleEmailClose}>
            <h4>Discover by email</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">Select the desired setting.</p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    emailSetting?.length && emailSetting[0]?.value == "1"
                  }
                  onChange={() => handleEmailChange("1")}
                />
                <label for="checkbox1">Anyone on NoblePage</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    emailSetting?.length && emailSetting[0]?.value == "2"
                  }
                  onChange={() => handleEmailChange("2")}
                />
                <label for="checkbox2">My Connections</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleEmailClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleEmailSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen6}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handlePhoneClose}>
            <h4>Discover by Phone</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">Select the desired setting.</p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    phoneSetting?.length && phoneSetting[0]?.value == "1"
                  }
                  onChange={() => handlePhoneChange("1")}
                />
                <label for="checkbox1">Anyone on NoblePage</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    phoneSetting?.length && phoneSetting[0]?.value == "2"
                  }
                  onChange={() => handlePhoneChange("2")}
                />
                <label for="checkbox2">My Connections</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handlePhoneClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handlePhoneSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen7}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleActivityClose}>
            <h4>Manage active status</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Decide whether you want your activity to be visible to others.
            </p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    activitySetting?.length && activitySetting[0]?.value == "1"
                  }
                  onChange={() => handleActivityChange("1")}
                />
                <label for="checkbox1">Your Connections Only</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    activitySetting?.length && activitySetting[0]?.value == "2"
                  }
                  onChange={() => handleActivityChange("2")}
                />
                <label for="checkbox2">All NoblePage members</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    activitySetting?.length && activitySetting[0]?.value == "3"
                  }
                  onChange={() => handleActivityChange("3")}
                />
                <label for="checkbox2">Nobody</label>
              </div>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleActivityClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleActivitySave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen8}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleUpdatesClose}>
            <h4>Sharing profile edits</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              By activating the toggle you agree to share your profile edits to
              your network.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Share key profile updates
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          updatesSetting?.length &&
                            parseInt(updatesSetting[0]?.value)
                        )}
                        onChange={(e) => handleUpdatesChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleUpdatesClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleUpdatesSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen9}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleNotifyClose}>
            <h4>In the news</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Activate the toggle if you wish your network to be notified if
              your name has been mentioned in news, articles and NobelPage
              organizations.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Notify network if mentioned in news
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          notifySetting?.length &&
                            parseInt(notifySetting[0]?.value)
                        )}
                        onChange={(e) => handleNotifyChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleNotifyClose()}
              >
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleNotifySave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen11}
          className="profile-data-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleTagClose}>
            <h4>Mentions or tags</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Activate the toggle to allow to be mentioned from NobelPage
              members.
            </p>
            <div className="checkbox-box mt-3">
              <ul>
                <li>
                  <span className="gray-light600 title-list mt-3">
                    Allow mentions
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          tagSetting?.length && parseInt(tagSetting[0]?.value)
                        )}
                        onChange={(e) => handleTagChange(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div className="btn btn-outline" onClick={() => handleTagClose()}>
                Close
              </div>
              <div className="btn btn-blue" onClick={() => handleTagSave()}>
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalOpen12}
          className="last-name-radio-list-modal setting-modal-default-modal"
        >
          <ModalHeader toggle={handleFollowerClose}>
            <h4 style={{ color: "white" }}>Who can follow you</h4>
          </ModalHeader>
          <ModalBody>
            <p className="gray-light400 mt-0">
              Choose whom you want to allow to follow you.
            </p>
            <div className="radio-list-outer">
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox1"
                  name="checkbox"
                  checked={
                    followerSetting?.length && followerSetting[0]?.value == "1"
                  }
                  onChange={() => handleFollowerChange("1")}
                />
                <label for="checkbox1">Everyone on NobelPage</label>
              </div>
              <div className="radio-list">
                <input
                  type="radio"
                  id="checkbox2"
                  name="checkbox"
                  checked={
                    followerSetting?.length && followerSetting[0]?.value == "2"
                  }
                  onChange={() => handleFollowerChange("2")}
                />
                <label for="checkbox2">Your connections</label>
              </div>
            </div>

            <div className="checkbox-box mt-3">
              <ul>
                <li className="custom-check">
                  <span className="gray-light600 title-list mt-3">
                    Make follow primary
                  </span>
                  <div className="switch-box mt-4">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={Boolean(
                          followerSetting?.length &&
                            parseInt(followerSetting[1]?.value)
                        )}
                        onChange={(e) =>
                          handleFollowerChange(e.target.checked, true)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <p className="gray-light400 mt-0">
              Enabling this option makes 'Follow' the primary action without
              requiring a connection request.
            </p>
            <div
              className="text-end"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "10px",
              }}
            >
              <div
                className="btn btn-outline"
                onClick={() => handleFollowerClose()}
              >
                Close
              </div>
              <div
                className="btn btn-blue"
                onClick={() => handleFollowerSave()}
              >
                Save
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Visibility;
