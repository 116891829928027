import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CoverletterModal = ({
  openLetter,
  setOpenLetter,
  subject,
  description,
}) => {
  return (
    <>
      <Modal
        size="md"
        isOpen={openLetter}
        style={{ maxWidth: "600px" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader
          className="dicardModalHeader text-dark"
          toggle={() => setOpenLetter(!openLetter)}
        >
          Cover Letter
        </ModalHeader>
        <ModalBody>
          <div className="">
            <label className="fs-5 mb-2 text-dark fw-bold">Subject</label>
            <p>{subject}</p>
          </div>
          <div className="mt-4">
            <label className="fs-5 mb-2 text-dark fw-bold">Description</label>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </ModalBody>
        <div style={{ padding: "5px" }}></div>
      </Modal>
    </>
  );
};

export default CoverletterModal;
