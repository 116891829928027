import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CommentsContainer from "./comments/Comments.container";
import ImageCarousel from "./ImageCarousel";
import ViewReactionModal from "./viewReactionModal";
import like from "../../../assets/img/like_1.svg";
import likeGrey from "../../../assets/img/like_grey.svg";
import hand from "../../../assets/img/clapping 3.svg";
import heart from "../../../assets/img/ant-design_heart-filled.svg";
import hat from "../../../assets/img/hat 1.svg";
import bulb from "../../../assets/img/idea_1.svg";
import emoj from "../../../assets/img/smile_1.svg";
import surp from "../../../assets/img/facebook-reactions_1.svg";
import comment from "../../../assets/img/comment.svg";
import share from "../../../assets/img/share.svg";
import tick from "../../../assets/img/tick-icon.svg";
import add from "../../../assets/img/add-icon.svg";
import union from "../../../assets/img/Union-blue.svg";
import message from "../../../assets/img/message-icon.svg";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import moment from "moment";
import repost from "../../../assets/img/repost.svg";
import edit from "../../../assets/img/edit-icon.svg";
import sendIcon from "../../../assets/img/sendIconGrey.svg";
import ShareModal from "../../../core/components/sharePost/sharePost";
import {
  GetTime,
  abbreviateNumber,
} from "../../../core/components/Utility/Utility";

const FileLargerView = (props) => {
  const {
    modal,
    toggle,
    title,
    deatils,
    index,
    type,
    userName,
    privacy_type,
    commentBoxStatus,
    setCommentBoxStatus,
    dropRefShare,
    dropDownShare,
    setDropDownShare,
    repostPost,
    getHashtagText,
    handleReaction,
    likeResult,
    sendRequest,
    peopleFollowRequest,
    peopleUnFollowRequest,
    handlePageFollow,
    handlePageUnFollow,
    userId,
    postNow,
    commentStatus,
    setCommentStatus,
    handleCommentStatus,
    closeModal,
    setCloseModal,
    setDeleteId,
    postViewCounter,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
  } = props;
  const { Feed_Documents, Tags } = deatils;
  const [showTags, setShowTags] = useState(false);
  const [isReaction, setIsReaction] = useState(-1);
  const [isViewReaction, setViewReaction] = useState(false);
  const [postId, setPostID] = useState();
  const [dropDown, setDropDown] = useState(-1);
  const [reportModal, setReportModal] = useState(false);
  const [reportType, setReportType] = useState();
  const [reportCommentId, setReportCommentId] = useState();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareData, setShareData] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { settingData } = useSelector((state) => state.setting);
  const createLink = (user) => {
    navigate(`/profile/${user.user_id}`);
  };

  const rePost = (id) => {
    repostPost(id);
    toggle();
  };

  return (
    <Modal isOpen={modal} size="xl" centered>
      <ModalHeader
        style={{ padding: "10px 35px", border: "none" }}
        toggle={toggle}
        className="file-larger-title"
      >
        <div className="larger-view-main-box row" key={index}>
          <div className="col-8">
            <div className="main-post mt-3">
              <div className="main-Post-div">
                <div className="prof-img">
                  <img
                    src={
                      deatils?.Page?.icon
                        ? deatils?.Page?.icon
                        : deatils?.User?.profile_img
                        ? deatils?.User?.profile_img
                        : placeholderUser
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="details">
                  {deatils?.Page ? (
                    <>
                      <h4
                        className="userNameTextWrap"
                        onClick={() =>
                          navigate(`/pageadminview/${deatils?.Page?.id}`)
                        }
                      >
                        {deatils?.Page?.name}
                      </h4>
                      <p>{deatils?.Page?.industry}</p>
                      <p>
                        <span>{moment(deatils?.updatedAt).fromNow(true)}</span>
                        <span>&#x2022;</span>
                        <span>
                          <>{privacy_type(deatils?.privacy_id)}</>
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <h4
                          className="userNameTextWrap"
                          onClick={() =>
                            navigate(`/profile/${deatils?.user_id}`)
                          }
                        >
                          {deatils?.User?.first_name +
                            " " +
                            deatils?.User?.last_name}
                        </h4>
                        {deatils?.User?.is_email_verified && (
                          <div className="varify"></div>
                        )}
                      </div>
                      {deatils?.User?.profile?.profile_headline ? (
                        <p className=" card-control-text-oneline maxWid">
                          {deatils?.User?.profile?.profile_headline}
                        </p>
                      ) : (
                        ""
                      )}
                      {/* <p>
                        <span>{moment(deatils?.updatedAt).fromNow(true)}</span>
                        <span>&#x2022;</span>
                        <span>
                          <>{privacy_type(deatils?.privacy_id)}</>
                        </span>
                      </p> */}
                      {type === "scheduledPost" ? (
                        <p>
                          Scheduled on{" "}
                          {moment(deatils?.scheduled_time).format("MMMM")}{" "}
                          {moment(deatils?.scheduled_time).format("DD")} at{" "}
                          {moment(deatils?.scheduled_time).format("hh:mm A")}
                        </p>
                      ) : (
                        <p
                          className="card-control-text-twoline"
                          style={{ width: "105px" }}
                        >
                          <span>{GetTime(deatils?.updatedAt)}</span>
                          <span className="ml-5">&#x2022;</span>
                          <span>
                            <>{privacy_type(deatils?.privacy_id)}</>
                          </span>
                        </p>
                      )}
                    </>
                  )}
                </div>
                {deatils?.is_email_verified && (
                  <div className="varify mt-1">
                    <div className="checkMark"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xs-8 mt-3 p-0">
            {deatils?.Page?.name ? (
              <div>
                {deatils?.Page?.is_following ? (
                  <div
                    className="btn btn-light"
                    style={{ cursor: "defult" }}
                    onClick={() => {
                      handlePageUnFollow(
                        deatils?.Page?.id,
                        "home",
                        deatils?.id
                      );
                    }}
                  >
                    <span>
                      <img src={tick} alt="" className="followicon" />
                      {"   "}
                      Following
                    </span>
                  </div>
                ) : (
                  <div
                    className="btn btn-light"
                    onClick={() => {
                      handlePageFollow(deatils?.Page?.id, "home", deatils?.id);
                    }}
                  >
                    <span>
                      <img src={add} alt="" className="followicon" />
                      {"   "}
                      Follow
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <>
                {user.data.id === deatils.user_id ? (
                  <></>
                ) : (
                  <div className="connect">
                    {deatils?.User?.creator_mode ? (
                      <div>
                        {deatils?.User?.follow_status ? (
                          <div
                            className="btn btn-light"
                            style={{ cursor: "defult" }}
                            onClick={() => {
                              peopleUnFollowRequest(
                                deatils.user_id,
                                "home",
                                deatils.id
                              );
                            }}
                          >
                            <span>
                              <img src={tick} alt="" className="followicon" />
                              {"   "}
                              Following
                            </span>
                          </div>
                        ) : (
                          <div
                            className="btn btn-light"
                            onClick={() => {
                              peopleFollowRequest(
                                deatils.user_id,
                                "home",
                                deatils.id
                              );
                            }}
                          >
                            <span>
                              <img src={add} alt="" className="followicon" />
                              {"   "}
                              Follow
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {deatils?.User?.connection_status ? (
                          <div className="btn btn-light">
                            <span>
                              {deatils.User.connection_status === "confirm" ? (
                                <>
                                  <img
                                    src={message}
                                    alt=""
                                    className="followicon"
                                  />{" "}
                                  Message
                                </>
                              ) : (
                                <>
                                  <img
                                    src={union}
                                    alt=""
                                    className="followicon"
                                  />
                                  {deatils?.User.connection_status ===
                                    "pending" && "Pending"}
                                </>
                              )}
                            </span>
                          </div>
                        ) : (
                          <div
                            className="btn btn-light"
                            onClick={() =>
                              sendRequest(
                                deatils?.shared_by || deatils?.user_id,
                                "home",
                                deatils?.id
                              )
                            }
                          >
                            <span>
                              <img src={union} alt="" className="followicon" />{" "}
                              Connect
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {/* )} */}
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{ paddingTop: "0px" }}>
        <div className="row">
          <div
            className="col-lg-5 col-md-12 col-sm-12"
            style={{ position: "relative" }}
          >
            <div className="larger-view-main-box" key={index}>
              <div
                id="descriptionOverFlow"
                className="text form-control border-none"
              >
                <span className="descriptionOverFlowSpan">
                  {deatils?.description
                    ? getHashtagText(deatils?.description)
                    : ""}
                </span>
                {/* <div className="translate">
                          <span>translate</span>
                        </div> */}
              </div>
              {/* {deatils?.Feed_Documents && (
                <div className="post-img row">
                  {index === isReaction ? (
                    <div className="likes1">
                      {deatils?.reaction?.byUser?.type === "Like" ? (
                        <img src={like} alt="" />
                      ) : (
                        <img
                          src={like}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Like");
                          }}
                        />
                      )}
                      {deatils?.reaction?.byUser?.type === "Love" ? (
                        <img src={heart} alt="" />
                      ) : (
                        <img
                          src={heart}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Love");
                          }}
                        />
                      )}
                      {deatils?.reaction?.byUser?.type === "Celebrate" ? (
                        <img src={hand} alt="" />
                      ) : (
                        <img
                          src={hand}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Celebrate");
                          }}
                        />
                      )}
                      {deatils?.reaction?.byUser?.type === "Insightful" ? (
                        <img src={bulb} alt="" />
                      ) : (
                        <img
                          src={bulb}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Insightful");
                          }}
                        />
                      )}
                      {deatils?.reaction?.byUser?.type === "Curious" ? (
                        <img src={emoj} alt="" />
                      ) : (
                        <img
                          src={emoj}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Curious");
                          }}
                        />
                      )}
                      {deatils?.reaction?.byUser?.type === "Surprise" ? (
                        <img src={surp} alt="" />
                      ) : (
                        <img
                          src={surp}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Surprise");
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )} */}
              <div className="post-main-box p-0">
                <div className="flex mt-3 curserPointer ">
                  <div className="d-flex">
                    <div className="like_main_comment_post">
                      {/* <div className="likes_comment_post">
                        {deatils?.reaction?.byUser?.type === "Like" ? (
                          <img src={like} alt="" />
                        ) : (
                          <img
                            src={like}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Like");
                            }}
                          />
                        )}
                        {deatils?.reaction?.byUser?.type === "Love" ? (
                          <img src={heart} alt="" />
                        ) : (
                          <img
                            src={heart}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Love");
                            }}
                          />
                        )}
                        {deatils?.reaction?.byUser?.type === "Celebrate" ? (
                          <img src={hand} alt="" />
                        ) : (
                          <img
                            src={hand}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Celebrate");
                            }}
                          />
                        )}
                        {deatils?.reaction?.byUser?.type === "Insightful" ? (
                          <img src={bulb} alt="" />
                        ) : (
                          <img
                            src={bulb}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Insightful");
                            }}
                          />
                        )}
                        {deatils?.reaction?.byUser?.type === "Curious" ? (
                          <img src={emoj} alt="" />
                        ) : (
                          <img
                            src={emoj}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Curious");
                            }}
                          />
                        )}
                        {deatils?.reaction?.byUser?.type === "Surprise" ? (
                          <img src={surp} alt="" />
                        ) : (
                          <img
                            src={surp}
                            alt=""
                            onClick={() => {
                              handleReaction(deatils, "Surprise");
                            }}
                          />
                        )}
                      </div>
                      <div className="like me-4">
                        {deatils?.reaction?.byUser?.type === "Like" ? (
                          <span
                            onClick={() =>
                              handleReaction(deatils, "Like", "deleteReaction")
                            }
                            style={{ color: "#0432A3" }}
                          >
                            <img src={like} alt="" /> Likes
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Celebrate" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Celebrate",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#198754" }}
                          >
                            <img src={hand} alt="" /> Celebrate
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Curious" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Curious",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#ffc107" }}
                          >
                            <img src={emoj} alt="" /> Curious
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Love" ? (
                          <span
                            onClick={() =>
                              handleReaction(deatils, "Love", "deleteReaction")
                            }
                            style={{ color: "#dc3545" }}
                          >
                            <img src={heart} alt="" /> Love
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Insightful" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Insightful",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#fd7e14" }}
                          >
                            <img src={bulb} alt="" /> Insightful
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Surprise" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Surprise",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#ffc107" }}
                          >
                            <img src={surp} alt="" /> Surprise
                          </span>
                        ) : deatils?.reaction?.byUser === null ? (
                          <span>
                            <img src={likeGrey} alt="" /> <span>Like</span>
                          </span>
                        ) : (
                          <></>
                        )}
                      </div> */}
                      <div className="likes_comment_post">
                        <img
                          src={like}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Like");
                          }}
                        />

                        <img
                          src={heart}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Love");
                          }}
                        />
                        <img
                          src={hat}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Respect");
                          }}
                        />

                        <img
                          src={hand}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Celebrate");
                          }}
                        />

                        <img
                          src={bulb}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Insightful");
                          }}
                        />

                        <img
                          src={emoj}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Curious");
                          }}
                        />

                        <img
                          src={surp}
                          alt=""
                          onClick={() => {
                            handleReaction(deatils, "Surprise");
                          }}
                        />
                      </div>
                      <div className={"like me-4"}>
                        {deatils?.as_a_page ? (
                          deatils.reaction.byUsers?.find(
                            ({ page_id }) => page_id === deatils?.as_a_page?.id
                          ) ? (
                            deatils?.reaction?.byUsers.map((reactionDel) =>
                              // console.log(deatils?.as_a_page?.id, reactionDel?.page_id)
                              deatils?.as_a_page?.id ===
                              reactionDel?.page_id ? (
                                reactionDel?.type === "Like" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Like",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#0432A3" }}
                                  >
                                    <img
                                      src={like}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Likes
                                  </span>
                                ) : reactionDel?.type === "Celebrate" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Celebrate",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#198754" }}
                                  >
                                    <img
                                      src={hand}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Celebrate
                                  </span>
                                ) : reactionDel?.type === "Respect" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Respect",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#DF6B00" }}
                                  >
                                    <img
                                      src={hat}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Respect
                                  </span>
                                ) : reactionDel?.type === "Curious" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Curious",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#ffc107" }}
                                  >
                                    <img
                                      src={emoj}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Curious
                                  </span>
                                ) : reactionDel?.type === "Love" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Love",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#dc3545" }}
                                  >
                                    <img
                                      src={heart}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Love
                                  </span>
                                ) : reactionDel?.type === "Insightful" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Insightful",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#3F2DAF" }}
                                  >
                                    <img
                                      src={bulb}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Insightful
                                  </span>
                                ) : reactionDel?.type === "Surprise" ? (
                                  <span
                                    onClick={() =>
                                      handleReaction(
                                        deatils,
                                        "Surprise",
                                        "deleteReaction"
                                      )
                                    }
                                    style={{ color: "#ffc107" }}
                                  >
                                    <img
                                      src={surp}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    Surprise
                                  </span>
                                ) : deatils?.reaction?.byUsers === null ? (
                                  <span>
                                    <img
                                      src={likeGrey}
                                      alt=""
                                      style={{ width: "20px", height: "20px" }}
                                    />{" "}
                                    <span>Like</span>
                                  </span>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )
                            )
                          ) : (
                            <span>
                              <img src={likeGrey} alt="" /> <span>Like</span>
                            </span>
                          )
                        ) : deatils?.reaction?.byUser?.type === "Like" ? (
                          <span
                            onClick={() =>
                              handleReaction(deatils, "Like", "deleteReaction")
                            }
                            style={{ color: "#0432A3" }}
                          >
                            <img
                              src={like}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Likes
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Celebrate" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Celebrate",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#198754" }}
                          >
                            <img
                              src={hand}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Celebrate
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Respect" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Respect",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#DF6B00" }}
                          >
                            <img
                              src={hat}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Respect
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Curious" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Curious",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#ffc107" }}
                          >
                            <img
                              src={emoj}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Curious
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Love" ? (
                          <span
                            onClick={() =>
                              handleReaction(deatils, "Love", "deleteReaction")
                            }
                            style={{ color: "#dc3545" }}
                          >
                            <img
                              src={heart}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Love
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Insightful" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Insightful",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#3F2DAF" }}
                          >
                            <img
                              src={bulb}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Insightful
                          </span>
                        ) : deatils?.reaction?.byUser?.type === "Surprise" ? (
                          <span
                            onClick={() =>
                              handleReaction(
                                deatils,
                                "Surprise",
                                "deleteReaction"
                              )
                            }
                            style={{ color: "#ffc107" }}
                          >
                            <img
                              src={surp}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />{" "}
                            Surprise
                          </span>
                        ) : deatils?.reaction?.byUser === null ? (
                          <span>
                            <img
                              src={likeGrey}
                              alt=""
                              style={{ width: "15px", height: "15px" }}
                            />{" "}
                            <span
                            //  style={{ fontSize: "14px" }}
                            >
                              Like
                            </span>
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <div
                      className="others viewReactionsType"
                      onClick={() => {
                        setViewReaction(!isViewReaction);
                        setPostID(deatils?.id);
                      }}
                    >
                      <div className="curserPointer">
                        {deatils.reaction.count <= 1 ? (
                          deatils?.reaction?.byUser?.type === "Like" ? (
                            <img src={like} alt="" />
                          ) : deatils?.reaction?.byUser?.type ===
                            "Celebrate" ? (
                            <img src={hand} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Love" ? (
                            <img src={heart} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Curious" ? (
                            <img src={emoj} alt="" />
                          ) : deatils?.reaction?.byUser?.type ===
                            "Insightful" ? (
                            <img src={bulb} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Surprise" ? (
                            <img src={surp} alt="" />
                          ) : (
                            ""
                          )
                        ) : (
                          likeResult(
                            deatils?.reaction?.byUser?.type,
                            deatils?.reaction?.type
                          )?.map((deatils, idx) =>
                            idx < 3 ? (
                              <>
                                {deatils?.type === "Like" ? (
                                  <img src={like} alt="" />
                                ) : deatils?.type === "Celebrate" ? (
                                  <img src={hand} alt="" />
                                ) : deatils?.type === "Love" ? (
                                  <img src={heart} alt="" />
                                ) : deatils?.type === "Curious" ? (
                                  <img src={emoj} alt="" />
                                ) : deatils?.type === "Insightful" ? (
                                  <img src={bulb} alt="" />
                                ) : deatils?.type === "Surprise" ? (
                                  <img src={surp} alt="" />
                                ) : (
                                  ""
                                )}{" "}
                              </>
                            ) : (
                              ""
                            )
                          )
                        )}
                        <span>
                          <span className="txt">
                            {deatils.reaction.count > 0 ? (
                              <>({deatils.reaction.count})</>
                            ) : (
                              <></>
                            )}
                          </span>
                        </span>
                      </div>
                    </div> */}
                    <div
                      className="others viewReactionsType"
                      onClick={() => {
                        setViewReaction(!isViewReaction);
                        setPostID(deatils?.id);
                      }}
                    >
                      <div className="curserPointer">
                        {deatils?.reaction?.count < 1 ? (
                          deatils?.reaction?.byUser?.type === "Like" ? (
                            <img src={like} alt="" />
                          ) : deatils?.reaction?.byUser?.type ===
                            "Celebrate" ? (
                            <img src={hand} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Respect" ? (
                            <img src={hat} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Love" ? (
                            <img src={heart} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Curious" ? (
                            <img src={emoj} alt="" />
                          ) : deatils?.reaction?.byUser?.type ===
                            "Insightful" ? (
                            <img src={bulb} alt="" />
                          ) : deatils?.reaction?.byUser?.type === "Surprise" ? (
                            <img src={surp} alt="" />
                          ) : (
                            ""
                          )
                        ) : (
                          likeResult(
                            deatils?.reaction?.byUser?.type,
                            deatils?.reaction?.byUsers
                          )?.map((deatils, idx) =>
                            idx < 3 ? (
                              <>
                                {deatils?.type === "Like" ? (
                                  <img src={like} alt="" />
                                ) : deatils?.type === "Celebrate" ? (
                                  <img src={hand} alt="" />
                                ) : deatils?.type === "Respect" ? (
                                  <img src={hat} alt="" />
                                ) : deatils?.type === "Love" ? (
                                  <img src={heart} alt="" />
                                ) : deatils?.type === "Curious" ? (
                                  <img src={emoj} alt="" />
                                ) : deatils?.type === "Insightful" ? (
                                  <img src={bulb} alt="" />
                                ) : deatils?.type === "Surprise" ? (
                                  <img src={surp} alt="" />
                                ) : (
                                  ""
                                )}{" "}
                              </>
                            ) : (
                              ""
                            )
                          )
                        )}

                        <span className="txt">
                          {deatils?.reaction?.count > 0 ? (
                            `(${abbreviateNumber(deatils?.reaction.count)})`
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex" style={{ gap: "15px" }}>
                    <div
                      className="comment"
                      onClick={() => {
                        setCommentBoxStatus((prevState) => {
                          const ind = prevState?.findIndex(
                            (i) => i?.id == index
                          );
                          if (ind == -1)
                            return [...prevState, { id: index, status: true }];
                          else
                            return prevState?.map((item) =>
                              item?.id == index
                                ? { ...item, status: !item?.status }
                                : item
                            );
                        });
                      }}
                    >
                      <span className="curserPointer">
                        <img src={comment} alt="" />
                        {deatils?.comment_count > 1 ? (
                          <span> </span>
                        ) : (
                          <span> </span>
                        )}
                        <span> ({deatils?.comment_count})</span>
                      </span>
                    </div>
                    <div
                      className="share"
                      ref={dropRefRepost}
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              if (dropDownRepost === index) {
                                setDropDownRepost(-1);
                              } else {
                                setDropDownRepost(index);
                              }
                            }
                      }
                    >
                      <img src={repost} alt="" />
                      <span>
                        {deatils?.shared_count > 1 ? "  " : "  "}
                        {deatils?.shared_count > 0 &&
                          `(${deatils?.shared_count})`}
                      </span>
                      <span style={{ marginLeft: "4px" }}></span>
                      <div className="drop">
                        {dropDownRepost === index && (
                          <div className="box box-repost">
                            <ul ref={dropRefRepost}>
                              <li
                                className="shareListing"
                                onClick={() => repostPost(deatils?.id, type)}
                              >
                                <img src={repost} alt="" /> Repost
                              </li>
                              <li
                                className="shareListing"
                                onClick={() => {
                                  setOpenShareModal(!openShareModal);
                                  setShareData(deatils);
                                }}
                                style={{ gap: "17px" }}
                              >
                                <img src={edit} alt="" /> Repost with own
                                thoughts
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="share"
                      ref={dropRefShare}
                      onClick={
                        type === "groupPost"
                          ? () =>
                              alert(
                                "This feature will be implemented in upcoming milestones"
                              )
                          : () => {
                              if (dropDownShare === index) {
                                setDropDownShare(-1);
                              } else {
                                setDropDownShare(index);
                              }
                            }
                      }
                    >
                      <img src={share} alt="" />
                      <div className="drop">
                        {dropDownShare === index && (
                          <div
                            className="box box-share"
                            // style={{ bottom: "0 !important" }}
                          >
                            <ul ref={dropRefShare}>
                              <li
                                className="shareListing"
                                onClick={() => {
                                  navigate("/message");
                                }}
                              >
                                <img src={sendIcon} alt="" /> Send this post
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {commentBoxStatus?.find((x) => x.id === index)?.status === true ? (
              <CommentsContainer
                commentCount={deatils?.comment_count}
                postId={deatils}
                deatils={deatils}
                dropDown={dropDown}
                setDropDown={setDropDown}
                userId={userId}
                setPostID={setPostID}
                reportModal={reportModal}
                setReportModal={setReportModal}
                reportType={reportType}
                setReportType={setReportType}
                reportCommentId={reportCommentId}
                setReportCommentId={setReportCommentId}
              />
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-7 col-md-12 col-sm-12 mb-5">
            {type === "image" && (
              <ImageCarousel
                images={Feed_Documents}
                fromDashboard={true}
                largerView={true}
                showTags={showTags}
                setShowTags={setShowTags}
                Tags={Tags}
                createLink={createLink}
              />
            )}
            {type === "video" && (
              <ReactPlayer
                url={Feed_Documents[0]?.document_url}
                playing={
                  settingData?.data?.find((s) => s.id == 8)?.settings?.length
                    ? settingData?.data?.find((s) => s.id == 8)?.settings[0]
                        ?.value == "1"
                    : false
                }
                controls={true}
                width="100%"
              />
              // <video controls width="100%" >
              //   <source src={Feed_Documents[0].document_url} />
              // </video>
            )}
          </div>
        </div>
        {isViewReaction && (
          <ViewReactionModal
            modal={isViewReaction}
            toggle={() => setViewReaction(!isViewReaction)}
            title="View Reactions"
            userName={userName}
            postId={postId}
          />
        )}
        {openShareModal && (
          <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            shareData={shareData}
            type="shared_post"
            source="dashboard"
            toggleSuccessful={() => setOpenShareModal(!openShareModal)}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default FileLargerView;
