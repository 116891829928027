import { call } from "./apiCall";

class ProfileApi {
  getUserDetails(id, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/profile/${id}`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  postUserDetails(userData, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/user/profile`,
            null,
            userData,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  deleteMedia(mediaId) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/media/${mediaId}`,
            {},
            {},
            token
          );
          if (res.data.success === true) {
            console.log("delete media ", res.data);
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserSkill(skillId, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/skill-edu-exp/${skillId}`,
            {},
            {},
            token
          );
          if (res.data.success === true) {
            console.log("delete skill ", res.data);
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserExperience(userId, token) {
    console.log(userId);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/experience/${userId}`,
            {},
            {},
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserEducation(userId, token) {
    console.log(userId);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/education/${userId}`,
            {},
            {},
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getViewProfileList(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        let token = localStorage.getItem("accessToken");
        try {
          const res = await call(
            "get",
            "api/v1/user/view-profile-list/",
            params,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getViewProfileCount(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            "api/v1/user/view-log",
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  verifyEmail() {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/auth/verify-email`,
            null,
            null,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  changeEmail(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/auth/change-email`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  public_posts_list(data) {
    let token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/public/posts-list`,
            data,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAllCompanyList(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/public/page-list`,
            data,
            null,
            null
          );
          resolve(res?.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserHonors(userId, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/honors-awards/${userId}`,
            {},
            {},
            token
          );
          console.log(res, "redred");
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserLanguage(userId, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/language/${userId}`,
            {},
            {},
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  postBackgroundDetail(data, token) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/screening-request`,
            null,
            data,
            token
          );
          if (res.data.success === true) {
            resolve(res.data);
          }
          if (res.data.success === false) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getBackgroundList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/screening-request`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getGlobalSearch(search) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/global-search?search=${search}`,
            {},
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRecentSearch() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/recent-search`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteRecentSearch(params) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/search-history`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  createRecentSearch(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user/search-history`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAllSkills(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/user/get-skills`,
            data,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  multipleResume(data) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `api/v1/user//multi-url`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteUserResume(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "delete",
            `api/v1/user/resume/${id}`,
            {},
            {},
            token
          );

          if (res.data.success === true) {
            resolve(res.data);
          }
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const profileApi = new ProfileApi();
