import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../../assets/img/gallery-add.png";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import "./DragNDrop.scss";

function Dropzone({
  open,
  handleBannerPicture,
  bannerImage,
  deletePicture,
  className,
}) {
  const [files, setFiles] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      acceptedFiles.map((file) => {
        handleBannerPicture(file);
      });
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          alt=""
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <div className={`text-center`}>
        {bannerImage !== "" ? (
          <div className={`image-upload`}>
            <img
              src={bannerImage}
              className="image_drag_drop"
              alt="articleBanner"
            />
            <div className="del_button" onClick={() => deletePicture()}>
              <img src={deleteIcon} alt="del_icon" />
              <div>Remove</div>
            </div>
          </div>
        ) : (
          <div {...getRootProps({ className: "dropzone" })}>
            <input className="input-zone" {...getInputProps()} />
            <div className={`image-upload ${className}`}>
              {isDragActive ? (
                <p className="dropzone-content">
                  Release to drop the files here
                </p>
              ) : className === "article-imagebox-banner" &&
                windowWidth <= 576 ? (
                <div className="mobile-dropzone">
                  <img
                    src={drop}
                    alt="drag-n-drop"
                    style={{ maxWidth: "70px" }}
                  />
                  <div className="pt-2">
                    Click here{" "}
                    <span className="blue-txt">
                      <u>browse</u>
                    </span>{" "}
                    your files
                  </div>
                </div>
              ) : (
                <div className="desktop-dropzone">
                  <img src={drop} alt="drag-n-drop" />
                  <div className="pt-2">
                    Drag and drop or{" "}
                    <span className="blue-txt">
                      <u>browse</u>
                    </span>{" "}
                    your files
                  </div>
                  <div className="pt-2">
                    We recommend uploading an image of at least 1920x1080
                    pixels.
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dropzone;
