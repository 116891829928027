import React, { useEffect, useState } from "react";
import cover from "../../../assets/img/portal-cover.png";
import "./PortalDashboard.scss";
import PortalFooter from "../portalFooter/PortalFooter";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import user_logout from "../../../assets/img/Logout.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
  logoutPortal,
} from "../../../store/portal/portalAction";

const PortalLegal = () => {
  const [themeColor, setThemeColor] = useState();
  const { profileData } = useSelector((state) => state.profile);
  const { isLoading } = useSelector((state) => state.loader);
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  const [profileDropdown, setProfileDropdown] = useState(false);
  const { param, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessTpToken");
  useEffect(() => {
    dispatch(getTalentPoolDetails(param))
      .then((res) => console.log(res))
      .catch((err) =>
        err?.request?.status == 404 ? navigate("/error") : console.log(err)
      );
    if (token) dispatch(getTalentPoolDetailsByToken());
  }, []);

  useEffect(() => {
    if (talentPoolData)
      setThemeColor(
        talentPoolData?.theme ? talentPoolData.theme : "rgb(246, 138, 24)"
      );
  }, [talentPoolData]);

  const handleLogout = async () => {
    try {
      dispatch(logoutPortal())
        .then(() => {
          navigate(`/tp/portal/${param}/login`);
        })
        .catch(() => {
          navigate(`/tp/portal/${param}/login`);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const types = {
    privacy: {
      title: "Privacy Policy",
      key: "privacy_policy",
    },
    cookies: {
      title: "Cookies Policy",
      key: "cookie_policy",
    },
    term: {
      title: "Terms & Conditions",
      key: "terms",
    },
  };

  return (
    <div className="portal-dashboard">
      {isLoading && <AppLoader />}
      <div
        className="portal-cover-img"
        style={{ minHeight: "20vh", maxHeight: "20vh" }}
      >
        <img
          src={
            talentPoolData?.background_img
              ? talentPoolData?.background_img
              : cover
          }
          style={{ minHeight: "20vh", maxHeight: "20vh" }}
          alt="big-img"
          className="img-cover"
        />
        <div className="d-flex justify-content-center">
          <div className="header-row ">
            <div
              className="d-flex justify-content-sm-center cursor-pointer"
              style={{ height: "40px", alignItems: "center" }}
              onClick={() => navigate(`/tp/portal/${param}/dashboard`)}
            >
              <img
                src={
                  talentPoolData?.icon ? talentPoolData?.icon : pagePlaceholder
                }
                alt="company-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h4 className="card-control-text-oneline ms-2">
                {talentPoolData?.company_name}
              </h4>
            </div>
            <div className="authenticate">
              {talentPoolData?.id != talentPoolDataByToken?.id ? (
                <>
                  <p
                    className="login"
                    onClick={() => navigate(`/tp/portal/${param}/login`)}
                  >
                    Login
                  </p>
                  <div
                    className="btn btn-orange"
                    onClick={() => navigate(`/tp/portal/${param}/signup`)}
                    style={{ background: themeColor, whiteSpace: "nowrap" }}
                  >
                    Register Now
                  </div>
                </>
              ) : (
                <a className="nav-link">
                  <div className="prof" style={{ position: "sticky" }}>
                    <img
                      src={
                        profileData?.profile_img
                          ? profileData.profile_img
                          : placeholderUser
                      }
                      className="rounded-circle"
                      height={40}
                      width={40}
                      alt=""
                      onClick={() => setProfileDropdown(!profileDropdown)}
                    />
                    {profileDropdown ? (
                      <div className="admin" style={{ width: "170px" }}>
                        <ul>
                          <li className="dropdown-span">
                            <img
                              src={
                                profileData?.profile_img
                                  ? profileData.profile_img
                                  : placeholderUser
                              }
                              alt=""
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span
                              onClick={() =>
                                navigate(`/tp/portal/${param}/profile/edit`)
                              }
                            >
                              View Profile
                            </span>
                          </li>
                          <li className="dropdown-span">
                            <img
                              src={user_logout}
                              alt="log_out"
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span onClick={() => handleLogout()}>Log out</span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: "white", minHeight: "40vh" }}
      >
        <div className="heading" style={{ textAlign: "justify" }}>
          <h3 style={{ textTransform: "capitalize", marginBottom: "30px" }}>
            {types[type]?.title || "No content available"}
          </h3>

          <div className="content">
            <p
              dangerouslySetInnerHTML={{
                __html: types[type]?.key
                  ? talentPoolData[types[type]?.key]
                  : "",
              }}
            />
          </div>
        </div>
      </div>
      <PortalFooter talentPoolData={talentPoolData} />
      <ToastContainer />
    </div>
  );
};

export default PortalLegal;
