import React, { useState } from "react";
import "./Input.scss";
import eyeOff from "../../../assets/img/eyeOff.svg";
import eye from "../../../assets/img/eye.svg";
const FormInput = ({
  label,
  icon,
  type,
  onChange,
  error,
  containerClassName,
  imageCustomClass,
  labelImage,
  required,
  tooltipMessage,
  labelStyle,
  ...otherProps
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className={`pt-3 ${containerClassName}`}>
      <label
        htmlFor="exampleInputEmail1"
        className="form-label d-flex align-items-center"
        style={labelStyle}
      >
        {labelImage && (
          <button
            type="button"
            className="bg-transparent border-0"
            data-toggle="tooltip"
            data-placement="top"
            title={tooltipMessage}
          >
            <img src={labelImage} width={20} className="me-2" />
          </button>
        )}{" "}
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="d-flex position-relative form-input">
        {icon && (
          <img
            className={imageCustomClass ? imageCustomClass : ""}
            src={icon}
            alt=""
          />
        )}
        <input
          className="form-control form-input-input"
          type={type === "password" && passwordShown ? "text" : type}
          onChange={onChange}
          {...otherProps}
        />
        {type === "password" && (
          <span onClick={togglePassword} className="form-input-eye">
            {passwordShown ? (
              <img src={eye} alt="" className="form-input-eye-icon" />
            ) : (
              <img src={eyeOff} alt="" className="form-input-eye-icon" />
            )}
          </span>
        )}
      </div>
      {error && <label className="label-input100 text-danger">{error}</label>}
    </div>
  );
};

export default FormInput;
