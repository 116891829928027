import React, { useEffect, useRef, useState } from "react";
import "./JobSearch.scss";
import { FormGroup, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { getAllCompanyList } from "../../store/profile/profileActions";
import buld from "../../assets/img/circle-building.svg";
import { getLocationsData } from "../../store/connections/connectionsActions";
import { salaryComparision } from "../../store/jobs/jobAction";
import { toast } from "react-toastify";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";

const SalaryComparision = (props) => {
  const { toggle, isOpen, isLoading } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    jobTitle: user?.data.highestExperience?.title || "",
    company: user?.data.highestExperience?.company_name || "",
    location: user?.data.highestExperience?.location || "",
    salary: null,
    salaryType: "",
    period: "",
    compensation: null,
    experience: null,
  });

  const [errorData, setErrorData] = useState({
    jobTitle: "",
    company: "",
    location: "",
    salary: "",
    salaryType: "",
    period: "",
    compensation: "",
    experience: "",
  });

  const [companyList, setCompanyList] = useState([]);
  const [listImage, setListImage] = useState("");
  const [locationOpen, setLocationOpen] = useState(false);
  const [locationsAPIData, setLocationsAPIData] = useState([]);

  const [salaryData, setSalaryData] = useState();

  useEffect(() => {
    if (user?.data.highestExperience?.Page)
      setListImage(user.data.highestExperience.Page?.icon);
  }, []);

  useEffect(() => {
    if (formData.company) {
      dispatch(getAllCompanyList({ search: formData.company }))
        .then((res) => {
          if (res?.success === true) {
            setCompanyList(res?.data);
          } else setCompanyList([]);
        })
        .catch(() => {
          setCompanyList([]);
        });
    }
  }, [formData.company]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  const SalaryComparisionData = () => {
    const {
      jobTitle,
      company,
      location,
      salary,
      salaryType,
      period,
      compensation,
      experience,
    } = formData;
    const requestData = {
      job_title: jobTitle || "",
      company: company || "",
      location: location || "",
      salary: salary || null,
      salary_type: salaryType || "",
      period: period || "",
      compensation: compensation || null,
      experience: experience || null,
    };
    dispatch(salaryComparision(requestData))
      .then((data) => {
        if (data?.success) {
          setSalaryData(data?.data);
          toast.success("Salary Comparision fetched Successfully...", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const validate = () => {
    const {
      jobTitle,
      company,
      location,
      salary,
      salaryType,
      period,
      compensation,
      experience,
    } = formData;
    let isValid = true;

    // Validate title
    if (!jobTitle || jobTitle.length < 3) {
      setErrorData((val) => ({
        ...val,
        jobTitle:
          "Job title must not be empty and should be at least 3 characters long.",
      }));
      isValid = false;
    } else setErrorData((val) => ({ ...val, jobTitle: "" }));

    // // Validate company
    // if (!company) {
    //   setErrorData((val) => ({
    //     ...val,
    //     company: "Company must not be empty.",
    //   }));
    //   isValid = false;
    // } else setErrorData((val) => ({ ...val, company: "" }));

    // // Validate location
    // if (!location) {
    //   setErrorData((val) => ({
    //     ...val,
    //     location: "Location must not be empty.",
    //   }));
    //   isValid = false;
    // } else setErrorData((val) => ({ ...val, location: "" }));

    // Validate salary
    if (!salary) {
      setErrorData((val) => ({
        ...val,
        salary: "Salary must not be empty.",
      }));
      isValid = false;
    } else setErrorData((val) => ({ ...val, salary: "" }));

    return isValid;
  };

  const handleNext = () => {
    if (validate()) {
      SalaryComparisionData();
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setFormData({});
    setListImage("");
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const renderYearOptions = () => {
    const yearOptions = [];
    for (let i = 0; i <= 25; i++) {
      yearOptions.push({
        value: i,
        name: i === 0 ? "Fresher" : `${i} year${i > 1 ? "s" : ""}`,
      });
    }
    return yearOptions;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        style={{ maxWidth: "574px" }}
      >
        {/* <ModalHeader toggle={() => toggle()}></ModalHeader> */}
        <ModalBody style={{ maxHeight: "100%" }}>
          {isLoading && <AppLoader />}
          {step === 1 ? (
            <>
              <div className="survey-pop-up search-default-pop-up p-0">
                <div className="search-default-pop-up-inner p-0">
                  <h3 className="text-start">
                    See if you are being paid like other professionals
                  </h3>
                  <p>
                    Submit your information to see thousands of salaries and get{" "}
                    <br />
                    personalized recommendations.
                  </p>
                  <h4>What is your most recent position?</h4>
                  <FormGroup>
                    <Input
                      type="text"
                      value={formData.jobTitle}
                      onChange={(e) =>
                        handleInputChange("jobTitle", e.target.value)
                      }
                      placeholder="Job title"
                      style={{ height: "40px", paddingLeft: "20px" }}
                    />
                    {errorData.jobTitle && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorData.jobTitle}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <div className="company-custom-dropdown mb-20">
                      <div
                        className="autocomplete-wrapper"
                        style={{ height: "40px", zIndex: 99999 }}
                      >
                        <Autocomplete
                          value={formData.company}
                          items={companyList}
                          getItemValue={(item) => item.name}
                          renderMenu={(items, value) =>
                            items.length === 0 ? (
                              <></>
                            ) : (
                              <div className="dropdown">{items} </div>
                            )
                          }
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${
                                isHighlighted ? "selected-item" : ""
                              }`}
                              style={{ display: "flex" }}
                            >
                              <img
                                src={item.icon ? item.icon : buld}
                                alt="dropdown_pic"
                                className="image-fit mr-10"
                                style={{ height: "36px", width: "36px" }}
                              />
                              <div>
                                <div className="name-heading-drop ">
                                  {item.name}
                                </div>
                              </div>
                            </div>
                          )}
                          onChange={(event, val) => {
                            handleInputChange("company", val);
                            setListImage("");
                          }}
                          onSelect={(val, obj) => {
                            handleInputChange("company", val);
                            setListImage(obj.icon);
                          }}
                          wrapperStyle={{}}
                          renderInput={(params) => {
                            return (
                              <div class="fake-input">
                                <input
                                  {...params}
                                  type="text"
                                  placeholder="Company"
                                />
                                <img
                                  src={listImage ? listImage : buld}
                                  alt=""
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                      {errorData.company && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData.company}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="company-custom-dropdown mb-20">
                      <div
                        className="autocomplete-wrapper"
                        style={{ height: "40px" }}
                      >
                        <div className="search">
                          <Autocomplete
                            style={{ height: "40px" }}
                            open={locationOpen}
                            value={formData.location}
                            // placeholder={"Location"}
                            items={locationsAPIData}
                            getItemValue={(item) => item.name}
                            shouldItemRender={() => locationsAPIData}
                            renderMenu={(items, value) => (
                              <div className="dropdown">
                                {items.length === 0
                                  ? `No matches for ${value}`
                                  : items}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.name}
                              </div>
                            )}
                            inputProps={{ placeholder: "Location" }}
                            onChange={(e, val) => {
                              if (val.length === 0) {
                                if (locationOpen) setLocationOpen(false);
                              } else {
                                if (!locationOpen) setLocationOpen(true);
                              }
                              handleInputChange("location", e.target.value);
                              getLocations(e.target);
                            }}
                            onSelect={(value, obj) => {
                              handleInputChange("location", value);
                              setLocationOpen(false);
                            }}
                            wrapperStyle={{}}
                          />
                        </div>
                      </div>
                      {errorData.location && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData.location}
                        </div>
                      )}
                    </div>
                  </FormGroup>

                  <h4>What is your base salary?</h4>
                  <FormGroup>
                    <Input
                      style={{ height: "40px", paddingLeft: "20px" }}
                      type="number"
                      name="basicSalary"
                      placeholder="Basic Salary"
                      value={formData.salary}
                      onChange={(e) =>
                        handleInputChange("salary", e.target.value)
                      }
                      min="1"
                      // max="999"
                    />
                    {errorData.salary && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorData.salary}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      style={{ height: "40px", paddingLeft: "20px" }}
                      type="select"
                      value={formData.salaryType}
                      onChange={(e) =>
                        handleInputChange("salaryType", e.target.value)
                      }
                    >
                      <option value="">Currency</option>
                      <option value="dollar">Dollar</option>
                    </Input>
                    {/* {errorData.salaryType && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorData.salaryType}
                      </div>
                    )} */}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      style={{ height: "40px", paddingLeft: "20px" }}
                      type="select"
                      value={formData.period}
                      onChange={(e) =>
                        handleInputChange("period", e.target.value)
                      }
                    >
                      <option value="">Pay period</option>
                      <option value="year">Per Annum</option>
                      <option value="month">Per Month</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      style={{ height: "40px", paddingLeft: "20px" }}
                      type="number"
                      name="compensation"
                      placeholder="Any compensations?"
                      value={formData.compensation}
                      onChange={(e) =>
                        handleInputChange("compensation", e.target.value)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      style={{ height: "40px", paddingLeft: "20px" }}
                      type="select"
                      onChange={(e) =>
                        handleInputChange("experience", e.target.value)
                      }
                      value={formData.experience}
                    >
                      <option value={null}>Experience</option>
                      {renderYearOptions().map(({ value, name }, i) => (
                        <option value={value}>{name}</option>
                      ))}
                    </Input>
                  </FormGroup>

                  <div className="blue-btn-outer end">
                    <button className="white-btn" onClick={() => toggle()}>
                      Close
                    </button>
                    <button className="blue-btn" onClick={() => handleNext()}>
                      Compare salary
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="thankyou-pop-up search-default-pop-up p-0">
                <div className="search-default-pop-up-inner p-0">
                  <div className="head">
                    <h2>Thank You!</h2>
                    <p>For being a part of this salary comparison</p>
                  </div>
                  {salaryData?.averageSalary ? (
                    <>
                      <div className="range-outer">
                        <div className="range low">
                          {salaryData?.lowSalary
                            ? (salaryData?.lowSalary / 100000).toFixed(2)
                            : 0}{" "}
                          LPA (low)
                        </div>
                        <div className="range avrg">
                          {salaryData?.averageSalary
                            ? (salaryData?.averageSalary / 100000).toFixed(2)
                            : 0}{" "}
                          LPA (average)
                        </div>
                        <div className="range high">
                          {salaryData?.highSalary
                            ? (salaryData?.highSalary / 100000).toFixed(2)
                            : 0}{" "}
                          LPA (high)
                        </div>
                      </div>

                      <div className="pay-box">
                        <span className="title">Average</span>
                        <h3>
                          {salaryData?.averageSalary
                            ? (salaryData?.averageSalary / 100000).toFixed(2)
                            : 0}{" "}
                          LPA
                        </h3>
                        <p>
                          Is the average payment in your location for your role
                          and experience
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3>No Jobs Found</h3>
                    </>
                  )}

                  <div className="pay-box">
                    <span className="title">Your Pay</span>
                    <h3>
                      {salaryData?.yourSalary
                        ? (salaryData?.yourSalary / 100000).toFixed(2)
                        : 0}{" "}
                      LPA
                    </h3>
                  </div>

                  <div className="blue-btn-outer">
                    <button
                      className="white-btn fw-bold"
                      style={{ backgroundColor: "#0432A30D", border: 0 }}
                      onClick={() => toggle()}
                    >
                      Close
                    </button>
                    <button className="blue-btn" onClick={() => handleBack()}>
                      Check Another Location
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SalaryComparision;
