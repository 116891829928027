import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import FormInput from "../../../core/components/Inputs/FormInput";
import "../welcome/PortalAuth.scss";

const LoginModal = (props) => {
  const {
    modal,
    toggle,
    data,
    handleNPSubmit,
    formDataNp,
    setFormDataNp,
    errorNp,
    setErrorNp,
    errorMessageNp,
    setErrorMessageNp,
    handleValidationNp,
  } = props;
  // const [formDataNp, setFormDataNp] = useState({
  //   email: " ",
  //   password: "",
  //   rememberMe: false,
  // });
  // const [errorNp, setErrorNp] = useState({
  //   email: "",
  //   password: "",
  // });
  // const [errorMessageNp, setErrorMessageNp] = useState("");

  const handleChange = (e) => {
    setFormDataNp({ ...formDataNp, [e.target.name]: e.target.value });
    setErrorMessageNp("");
    setErrorNp({
      email: "",
      password: "",
    });
  };

  // const handleValidationNp = (type) => {
  //   let formIsValid = true;
  //   let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   let passwordValid =
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

  //   if (type === "email" || type === "all") {
  //     if (!formDataNp?.email.trim()) {
  //       setErrorNp({ ...errorNp, email: "Please enter email" });
  //       formIsValid = false;
  //       return;
  //     } else if (!emailRegex.test(formDataNp?.email.trim())) {
  //       setErrorNp({
  //         ...errorNp,
  //         email: "Please enter your email in a valid format.",
  //       });
  //       formIsValid = false;
  //       return;
  //     } else {
  //       setErrorNp({
  //         ...errorNp,
  //         email: "",
  //       });
  //     }
  //   }
  //   if (type === "all" || type === "password") {
  //     if (!formDataNp?.password) {
  //       setErrorNp({
  //         ...errorNp,
  //         password: "Please enter password",
  //       });
  //       formIsValid = false;
  //       return;
  //     } else if (!passwordValid.test(formDataNp?.password.trim())) {
  //       setErrorNp({
  //         ...errorNp,
  //         password:
  //           'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
  //       });
  //       formIsValid = false;
  //       return;
  //     } else {
  //       setErrorNp({
  //         ...errorNp,
  //         password: "",
  //       });
  //     }
  //   }

  //   return formIsValid;
  // };

  return (
    <Modal
      isOpen={modal}
      // style={{ width: "464px" }}
      style={{ width: "464px", left: "17%" }}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={true}
      centered
      toggle={toggle}
    >
      <div className="login-modal">
        <ModalBody>
          <div className="login-form">
            <form
              className="portal-login-form auth-form"
              style={{
                padding: "0",
                width: "auto",
                marginBottom: "0px",
                float: "none",
              }}
            >
              <h3>Log In </h3>
              {errorMessageNp && (
                <label className="label-input100 text-danger">
                  {errorMessageNp}
                </label>
              )}
              <FormInput
                label="Email"
                name="email"
                type="email"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Email"
                onBlur={() => handleValidationNp("email")}
                errorNp={errorNp?.email}
                imageCustomClass="img-prop"
                value={formDataNp?.email}
              />
              <FormInput
                label="Password"
                name="password"
                type="password"
                onChange={(e) => handleChange(e)}
                placeholder="Enter Password"
                onBlur={() => handleValidationNp("password")}
                errorNp={errorNp?.password}
                value={formDataNp?.password}
              />
              {/* <div className="d-flex login-checkbox ">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={formDataNp?.rememberMe}
                    onClick={() =>
                      setFormDataNp({
                        ...formDataNp,
                        rememberMe: !formDataNp?.rememberMe,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Keep me logged in
                  </label>
                </div>
                <small>
                  <span className="forgot">Forgot password?</span>
                </small>
              </div> */}
              <div className="text-center d-grid mt-3">
                <button
                  type="submit"
                  className="btn btn-orange"
                  onClick={(e) => handleNPSubmit(e)}
                  style={{ background: `${data?.theme}` }}
                >
                  Login with NobelPage
                </button>
              </div>
            </form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default LoginModal;
