import { call } from "./apiCall";

class PdfAPI {
  fetchCvPdf(type, userData) {
    console.log(userData);
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const cvData = {};
          const addIfExists = (key, value) => {
            if (value !== undefined && value !== null) {
              cvData[key] = value;
            }
          };
          addIfExists("email", userData?.email);
          addIfExists("phone", userData?.phone);
          addIfExists("altranate_email", userData?.altranate_email);
          addIfExists("altranate_phone", userData?.altranate_phone);
          addIfExists("profile_url", userData?.profile_url);
          addIfExists("linkedin_url", userData?.linkedin_url);

          addIfExists("first_name", userData?.first_name);
          addIfExists("last_name", userData?.last_name);
          addIfExists("profile_img", userData?.profile_img);
          addIfExists("country_id", userData?.country?.id);
          addIfExists("state_id", userData?.state?.id);
          addIfExists("city_id", userData?.city?.id);
          addIfExists("phone", userData?.phone);
          addIfExists("gender", userData?.gender);
          addIfExists("profile_headline", userData?.profile_headline);
          addIfExists("designation", userData?.designation);
          addIfExists("about", userData?.about);
          addIfExists(
            "dob",
            userData?.dob ? userData.dob.substring(0, 10) : undefined
          );
          addIfExists("address", userData?.address);
          addIfExists("pin_code", userData?.pin_code);
          addIfExists("location", userData?.location);
          addIfExists("website", userData?.website);
          addIfExists("nationality_id", userData?.nationality?.id);

          addIfExists("interests", userData?.interests);

          if (userData?.experiences) {
            cvData.experiences = userData.experiences?.map((experience) => {
              return {
                title: experience.title,
                employment_type: experience?.employment_type,
                company_name: experience?.company_name,
                page_id: experience?.Page?.id,
                start_date: experience?.start_date?.substring(0, 10),
                end_date: experience?.end_date?.substring(0, 10),
                location: experience?.location,
                description: experience?.description,
                is_current: experience?.is_current,
                country_id: experience?.country?.id,
                state_id: experience?.state?.id,
                city_id: experience?.city?.id,
              };
            });
          }

          console.log(userData?.interests);

          if (userData?.educations) {
            cvData.educations = userData?.educations?.map((education) => {
              return {
                school_name: education?.school_name,
                degree: education?.degree,
                field_of_study: education?.field_of_study,
                start_date: education?.start_date?.substring(0, 10),
                end_date: education?.end_date?.substring(0, 10),
                grade: education?.grade,
                description: education?.description,
                is_highest: education?.is_highest,
              };
            });
          }

          addIfExists("languages", userData?.languages);

          if (userData?.exps_and_skills) {
            cvData.exps_and_skills = userData?.exps_and_skills?.map(
              (exp_and_skill) => {
                return {
                  id: exp_and_skill.id,
                  skills: exp_and_skill.skills
                    ? exp_and_skill.skills
                    : exp_and_skill,
                };
              }
            );
          }

          if (userData?.honors_and_awards) {
            cvData.honors_and_awards = userData?.honors_and_awards.map(
              (honor_and_award) => {
                return {
                  id: honor_and_award.id,
                  name: honor_and_award.name,
                  industry: honor_and_award?.industry?.id,
                  year: honor_and_award?.year,
                };
              }
            );
          }

          addIfExists("cv_color", userData?.cv_color);
          addIfExists("cv_skillColor", userData?.cv_skillColor);
          addIfExists("cv_interestColor", userData?.cv_interestColor);

          const res = await call(
            "post",
            `api/v1/user/pdf/${type}`,
            null,
            cvData,
            token
          );
          console.log(res);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  fetchAllCvTypesPdf() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call("get", `api/v1/user/pdfs`, null, null, token);
          console.log(res);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const pdfAPI = new PdfAPI();
