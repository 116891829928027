import React, { useEffect, useState } from "react";
import ClassicTemplate from "./CVTemplates/ClassicTemplate";
import FunctionalTemplate from "./CVTemplates/FunctionalTemplate";
import CombinedTemplate from "./CVTemplates/CombinedTemplate";
import ModernTemplate from "./CVTemplates/ModernTemplate";
import { Input } from "reactstrap";
import {
  addeducationdetails,
  deleteUserEducation,
} from "../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../core/components/DatePicker/DatePickerReact";
import { call } from "../../api/apiCall";
import moment from "moment";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import {
  addEducation,
  deleteEducation,
  editEducation,
} from "../../store/cv/cvActions";
import ThumbnailResumePreview from "./ThumbnailResumePreview";

export const EditResumeEducation = ({
  pageNo,
  setPageNo,
  editCV,
  user,
  token,
  color,
  skillBackground,
  interestBackground,
  setPreviewCV,
  setPreviewResumeModal,
}) => {
  const { isPageLoading } = useSelector((state) => state.loader);
  const [degreeList, setDegreeList] = useState([]);
  const [isEdit, setIsEdit] = useState(-1);
  const dispatch = useDispatch();
  const { isLoading, cvTypes } = useSelector((state) => state.cv);
  const [eduData, setEduData] = useState({
    school_name: "",
    degree: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  // const [studyFieldList, setStudyFieldList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleChange = (e) => {
    setEduData({ ...eduData, [e.target.name]: e.target.value });
  };

  const handleEdit = (id) => {
    setIsEdit(id);
    const data = user?.educations?.find((ele, i) => i == id);
    setEduData({
      school_name: data?.school_name,
      degree: data?.degree,
      start_date: data?.start_date,
      end_date: data?.end_date,
      description: data?.description,
    });
  };

  const handleSubmit = () => {
    if (checkValidation("all")) {
      dispatch(initPageLoad());
      if (isEdit > -1) dispatch(editEducation(eduData, isEdit, editCV));
      else dispatch(addEducation(eduData, editCV));
      setEduData({
        school_name: "",
        degree: "",
        start_date: "",
        end_date: "",
        description: "",
      });
      setIsEdit(-1);
      dispatch(stopPageLoad());
    }
  };

  const [error, setError] = useState({
    school_name: "",
    degree: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  const checkValidation = (type) => {
    let formIsValid = true;

    if (type === "degree" || type === "all") {
      if (!eduData?.degree) {
        setError({ ...error, degree: "Degree is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, degree: "" });
      }
    }
    if (type === "school_name" || type === "all") {
      if (!eduData?.school_name?.trim()) {
        setError({ ...error, school_name: "School name is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, school_name: "" });
      }
    }

    if (type === "description" || type === "all") {
      if (!eduData?.description?.trim()) {
        setError({ ...error, description: "Description is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, description: "" });
      }
    }

    if (type === "start_date" || type === "all") {
      if (!eduData?.start_date) {
        setError({ ...error, start_date: "Start date is mandatory" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, start_date: "" });
      }
    }

    if (type === "end_date" || type === "all") {
      if (!eduData?.end_date) {
        setError({ ...error, end_date: "End date is mandatory" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, end_date: "" });
      }
    }

    return formIsValid;
  };

  const handleDelete = (id) => {
    dispatch(deleteEducation(id, editCV));
  };

  const [deleteId, setDeleteId] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <div>
      {isPageLoading && <AppLoader />}
      <div className="edit-resume">
        <div className="container">
          <div className="row mt-30">
            <div className="col-lg-2 "></div>
            <div className="col-lg-6">
              <form>
                <div className="row">
                  <div className="col-12">
                    <h6>Education</h6>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Degree
                      </label>
                      <Input
                        type="select"
                        className="form-control"
                        name="degree"
                        onChange={(e) => handleChange(e)}
                        value={eduData?.degree}
                        onBlur={() => checkValidation("degree")}
                      >
                        <option value="">Select Degree</option>
                        {degreeList?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item?.name}>
                              {item?.fullName}({item?.name})
                            </option>
                          );
                        })}
                      </Input>
                      {error?.degree && (
                        <p className="text-danger">{error?.degree}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        School Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="50"
                        value={eduData.school_name}
                        name="school_name"
                        onChange={(e) => handleChange(e)}
                        onBlur={() => checkValidation("school_name")}
                      />
                      {error?.school_name && (
                        <p className="text-danger">{error?.school_name}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        maxLength="300"
                        value={eduData?.description}
                        name="description"
                        onChange={(e) => handleChange(e)}
                        onBlur={() => checkValidation("description")}
                      ></textarea>
                      {error?.description && (
                        <p className="text-danger">{error?.description}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Graduation Start Date
                      </label>
                      <DatePickerCustom
                        value={
                          eduData?.start_date
                            ? new Date(eduData?.start_date)
                            : ""
                        }
                        onChange={(date) => {
                          date
                            ? setEduData({
                                ...eduData,
                                start_date: moment(date).format("YYYY-MM-DD"),
                                end_date: "",
                              })
                            : setEduData({
                                ...eduData,
                                start_date: "",
                                end_date: "",
                              });
                        }}
                        max={new Date()}
                        placeholder=""
                        onBlur={() => checkValidation("start_date")}
                      />
                      {error?.start_date && (
                        <p className="text-danger">{error?.start_date}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Select Graduation End Date
                      </label>
                      <DatePickerCustom
                        value={
                          eduData?.end_date ? new Date(eduData?.end_date) : ""
                        }
                        onChange={(date) => {
                          date
                            ? setEduData({
                                ...eduData,
                                end_date: moment(date).format("YYYY-MM-DD"),
                              })
                            : setEduData({ ...eduData, end_date: "" });
                        }}
                        placeholder=""
                        disabled={eduData?.start_date === "" ? true : false}
                        min={
                          eduData?.start_date
                            ? new Date(eduData?.start_date)
                            : ""
                        }
                        max={new Date()}
                        onBlur={() => checkValidation("end_date")}
                      />
                      {error?.end_date && (
                        <p className="text-danger">{error?.end_date}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div></div>
                    <div className="mb-3 form-check cv_check d-none">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        I currently attend here
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="float-end">
                      <div
                        style={{ cursor: "pointer" }}
                        className="text-blue bg-transparent fw-bold"
                        onClick={handleSubmit}
                      >
                        {isEdit > -1 ? "Update" : "Add"}
                      </div>
                    </div>
                  </div>
                </div>
                {user?.educations?.map((ele, i) => {
                  return (
                    <>
                      <div
                        className="row mt-2"
                        // style={
                        //   user?.educations?.length > 1
                        //     ? { borderBottom: "1px solid #d2d2d2" }
                        //     : {}
                        // }
                      >
                        <div className="col-sm-6">
                          <div className="">
                            <span className="tick">
                              <img src={require("../../assets/img/tick.png")} />
                            </span>
                            <span className="ui ms-3">
                              {ele?.degree} at{" "}
                              {ele?.school_name?.length > 10
                                ? ele?.school_name.slice(0, 10) + "..."
                                : ele?.school_name}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="float-end">
                            <div className="d-flex gap-3">
                              <div
                                className="edit"
                                onClick={() => {
                                  handleEdit(i);
                                  setIsEdit(i);
                                }}
                              ></div>
                              <div
                                className="delete"
                                onClick={() => {
                                  setDeleteId(i);
                                  setConfirmDelete(true);
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="text-center mx-4 mt-5">
                  <div className="" style={{ marginLeft: "25%" }}>
                    <button
                      className=" btn btn-light text-blue me-3"
                      onClick={() => setPageNo(2)}
                    >
                      Back
                    </button>
                    <button
                      className=" btn btn-blue"
                      onClick={() => setPageNo(4)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="resume ">
                <div className="container">
                  <div className="bg-white main-box">
                    <div className="row header ">
                      <div className="col-lg-12 ">
                        {/* {editCV == "classic" ? (
                          <ClassicTemplate
                            userData={user}
                            color={color?.classic}
                            skillBackground={skillBackground?.classic}
                            interestBackground={interestBackground?.classic}
                          />
                        ) : editCV == "functional" ? (
                          <FunctionalTemplate
                            userData={user}
                            color={color?.functional}
                            skillBackground={skillBackground?.functional}
                            interestBackground={interestBackground?.functional}
                          />
                        ) : editCV == "combined" ? (
                          <CombinedTemplate
                            userData={user}
                            color={color?.combined}
                            skillBackground={skillBackground?.combined}
                            interestBackground={interestBackground?.combined}
                          />
                        ) : (
                          <ModernTemplate
                            userData={user}
                            color={color?.modern}
                            skillBackground={skillBackground?.modern}
                            interestBackground={interestBackground?.modern}
                          />
                        )} */}
                        <div>
                          {isLoading ? (
                            <div className="position-relative">
                              <AppLoader />
                            </div>
                          ) : (
                            <div className="" style={{ position: "relative" }}>
                              <ThumbnailResumePreview url={cvTypes[editCV]} />
                              <button
                                style={{
                                  position: "absolute",
                                  zIndex: 999999,
                                  right: "10px",

                                  bottom: "10px",
                                  background: "#0432A3",
                                  color: "#fff",
                                  borderRadius: "20px",
                                  padding: "5px",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  setPreviewCV(editCV);
                                  setPreviewResumeModal(true);
                                }}
                              >
                                Preview
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bullets mt-30">
                <div className="dot active"></div>
                <div className="dot"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {confirmDelete && (
        <DeleteModal
          text="Are you sure you want to delete education"
          title="Delete Education"
          modal={confirmDelete}
          toggle={() => setConfirmDelete(!confirmDelete)}
          deleteConfirm={() => handleDelete(deleteId)}
        />
      )}
    </div>
  );
};
