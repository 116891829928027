import React, { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import bold from "../../../assets/img/bold.svg";
import italic from "../../../assets/img/italic.svg";
import underline from "../../../assets/img/underline.svg";
import leftAlignIcon from "../../../assets/img/left-align.svg";
import centerIcon from "../../../assets/img/center.svg";
import OrderedListIcon from "../../../assets/img/ordered-list.svg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../mynetwork/Newsletter/newsletter.scss";

function EditorBox(props) {
  const {
    convertedContent,
    setConvertedContent,
    setDbFormat,
    setHasText,
    setOnlySpaces,
    dbFormat,
  } = props;

  const [className, setClassName] = useState("RichEditor-editor");

  const [editorState, setEditorState] = useState(
    dbFormat
      ? () => {
          const blocksFromHTML = convertFromHTML(dbFormat);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          return EditorState.createWithContent(contentState);
        }
      : () => EditorState.createEmpty()
  );

  useEffect(() => {
    var contentState = editorState.getCurrentContent();

    let isEditorEmpty = !contentState.hasText();
    let currentPlainText = contentState.getPlainText();
    let lengthOfTrimmedContent = currentPlainText.trim().length;
    let isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;

    setHasText(contentState.hasText());
    setOnlySpaces(isContainOnlySpaces);

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        setClassName("RichEditor--hide-placeholder");
      } else setClassName("RichEditor-editor");
    }
  }, [editorState]);

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html); //html format

    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
    setDbFormat(html); //database format
  }, [editorState]);

  return (
    <div className="">
      <header className="App-header">{/* Rich Text Editor Example */}</header>
      <div className={className} style={{ backgroundColor: "#FFFFFF" }}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          handlePastedText={() => false}
          stripPastedStyles={true}
          wrapperClassName="wrapper-class-talent"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          type="textbox"
          placeholder="Description"
          toolbar={{
            options: ["inline", "textAlign", "list"],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
              bold: { icon: bold, className: undefined },
              italic: { icon: italic, className: undefined },
              underline: { icon: underline, className: undefined },
            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center"],
              left: { icon: leftAlignIcon, className: undefined },
              center: { icon: centerIcon, className: undefined },
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["ordered"],
              ordered: { icon: OrderedListIcon, className: undefined },
            },

            // link: {
            //   inDropdown: false,
            //   className: undefined,
            //   component: undefined,
            //   popupClassName: undefined,
            //   dropdownClassName: undefined,
            //   showOpenOptionOnHover: true,
            //   defaultTargetOption: '_self',
            //   options: ['link'],

            // },
          }}
          hashtag={{}}
        />
      </div>
    </div>
  );
}

export default EditorBox;
