import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { EditResumeTwo } from "./EditResumeTwo";
import { EditResumeEducation } from "./EditResumeEducation";
import { EditResumeSkill } from "./EditResumeSkill";
import { Resume } from "./Resume";
import ClassicTemplate from "./CVTemplates/ClassicTemplate";
import FunctionalTemplate from "./CVTemplates/FunctionalTemplate";
import CombinedTemplate from "./CVTemplates/CombinedTemplate";
import ModernTemplate from "./CVTemplates/ModernTemplate";
import {
  deleteUserEducation,
  deleteUserExperience,
  updateUserDetails,
} from "../../store/profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../core/components/Loaders/ComponentLoader";
import { toast } from "react-toastify";
import ProfilePhotoModal from "../../routes/profile-pages/profile/components/ProfilePhotoModal";
import axios from "axios";
import { getLocationsData } from "../../store/connections/connectionsActions";
import Autocomplete from "react-autocomplete";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../store/setting/SettingAction";
import { call } from "../../api/apiCall";
import closeIcon from "../../assets/img/Closeicon.svg";
import DeleteModal from "../../core/components/modal/DeleteModal";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import ThumbnailResumePreview from "./ThumbnailResumePreview.jsx";
import { editBasicInfo } from "../../store/cv/cvActions.js";

export const EditResume = (props) => {
  const {
    modal,
    toggle,
    title,
    editCV,
    pageNo,
    setPageNo,
    loader,
    user,
    token,
    skillBackground,
    interestBackground,
    color,
    setPreviewCV,
    setPreviewResumeModal,
  } = props;

  const { isPageLoading } = useSelector((state) => state.loader);
  const { isLoading, cvTypes } = useSelector((state) => state.cv);
  const dispatch = useDispatch();
  const [locationList, setLocationList] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    profile_img: user?.profile_img || "",
    email: user?.email || "",
    phone: user?.phone || "",
    profile_url: user?.profile_url || "",
    address: user?.address || "",
    about: user?.about || "",
    linkedin_url: user?.linkedin_url || "",
    altranate_phone: user?.altranate_phone || "",
    profile_headline: user?.profile_headline || "",
    altranate_email: user?.altranate_email || "",
    cv_color: user?.cv_color || "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getLocation = (e) => {
    dispatch(getLocationsData(e))
      .then((res) => {
        if (res?.data) {
          setLocationList(res?.data);
        } else setLocationList([]);
      })
      .catch(() => {
        setLocationList([]);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      dispatch(initPageLoad());
      dispatch(editBasicInfo(formData, editCV));
      setPageNo(2);
    }
    dispatch(stopPageLoad());
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formDatas = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formDatas.append("image", newImage);

      await axios.post(UPLOAD_URL, formDatas, { headers }).then((res) => {
        if (type === "profile") {
          setFormData({ ...formData, profile_img: res?.data?.urlsArray[0] });
        } else if (type === "background") {
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemovePicture = (type) => {
    if (type === "profile") {
      setFormData({ ...formData, profile_img: "" });
    } else if (type === "background") {
    }
  };

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    profile_img: "",
    email: "",
    phone: "",
    profile_url: "",
    address: "",
    about: "",
    linkedin_url: "",
    altranate_phone: "",
    profile_headline: "",
    altranate_email: "",
  });
  const checkValidation = (type) => {
    let emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let stringName = /^[a-zA-Z\s._]+$/;
    let stringNameLast = /^[a-zA-Z\s._]+$/;
    let url = /^[A-Za-z0-9\s!@#$%^&*()-_+=]*$/;
    let profileUrlRegex = /^[A-Za-z0-9_-]+$/;
    let linkedinUrlRegex =
      /https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?/;
    let formIsValid = true;

    if (type === "all" || type === "first_name") {
      if (!formData?.first_name?.trim()) {
        setError({ ...error, first_name: "First name is required" });
        formIsValid = false;
        return;
      } else if (!stringName.test(formData.first_name.trim())) {
        setError({
          ...error,
          first_name: "First name contains alphabates only",
        });
        formIsValid = false;
        return;
      } else if (formData?.first_name?.length > 50) {
        setError({ ...error, first_name: "Max 50 charactors allowed" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, first_name: "" });
      }
    }
    if (type === "all" || type === "last_name") {
      if (!formData?.last_name?.trim()) {
        setError({ ...error, last_name: "Last name is required" });
        formIsValid = false;
        return;
      } else if (!stringNameLast?.test(formData?.last_name?.trim())) {
        setError({ ...error, last_name: "Last name contains alphabates only" });
        formIsValid = false;
        return;
      } else if (formData?.last_name?.length > 50) {
        setError({ ...error, last_name: "Max 50 charactors allowed" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, last_name: "" });
      }
    }

    if (type === "all" || type === "profile_headline") {
      if (!formData?.profile_headline?.trim()) {
        setError({ ...error, profile_headline: "Profession is required" });
        formIsValid = false;
        return;
      } else if (formData?.profile_headline?.length > 100) {
        setError({ ...error, profile_headline: "Max 100 charactors allowed" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, profile_headline: "" });
      }
    }

    if (type === "all" || type === "about") {
      if (!formData?.about?.trim()) {
        setError({ ...error, about: "About is required" });
        formIsValid = false;
        return;
      } else if (formData.about?.length > 300) {
        setError({ ...error, about: "Max 300 charactors allowed" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, about: "" });
      }
    }

    if (type === "all" || type === "address") {
      if (!formData?.address) {
        setError({ ...error, address: "Address is required" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, address: "" });
      }
    }

    if (type === "all" || type === "profile_url") {
      if (!formData?.profile_url?.trim()) {
        setError({ ...error, profile_url: "Profile url is required" });
        formIsValid = false;
        return;
      } else if (!profileUrlRegex.test(formData?.profile_url?.trim())) {
        setError({ ...error, profile_url: "Invalid Url" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, profile_url: "" });
      }
    }
    if (type === "all" || type === "linkedIn") {
      if (!formData?.linkedin_url?.trim()) {
        setError({ ...error, linkedin_url: "LinkedIn is required" });
        formIsValid = false;
        return;
      } else if (!linkedinUrlRegex.test(formData?.linkedin_url?.trim())) {
        setError({ ...error, linkedin_url: "Invalid Url" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, linkedin_url: "" });
      }
    }

    if (type === "all" || type === "phone") {
      if (formData?.phone?.length === 0) {
        setError({ ...error, phone: "Phone No. is required" });
        formIsValid = false;
        return;
      } else if (formData?.phone?.length < 10) {
        setError({ ...error, phone: "Invalid Phone No." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, phone: "" });
      }
    }

    if (type === "all" || type === "email") {
      if (!formData?.email?.trim()) {
        setError({ ...error, email: "Email is required." });
        formIsValid = false;
        return;
      } else if (!emailValid.test(formData?.email?.trim())) {
        setError({ ...error, email: "Invalid email format" });

        formIsValid = false;
        return;
      } else {
        setError({ ...error, email: "" });
      }
    }

    if (type === "all" || type === "altranate_phone") {
      if (formData?.altranate_phone && formData?.altranate_phone?.length < 10) {
        setError({ ...error, altranate_phone: "Invalid Phone No." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, altranate_phone: "" });
      }
    }

    if (type === "all" || type === "altranate_email") {
      if (
        formData?.altranate_email &&
        !emailValid.test(formData?.altranate_email?.trim())
      ) {
        setError({
          ...error,
          altranate_email: "Invalid altranate_email format",
        });

        formIsValid = false;
        return;
      } else {
        setError({ ...error, altranate_email: "" });
      }
    }

    return formIsValid;
  };

  return (
    <>
      <Modal isOpen={modal} size="xl">
        <ModalHeader
          className="dicardModalHeader"
          toggle={() => {
            toggle();
            setPageNo(1);
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody>
          {(isPageLoading || loader) && <AppLoader />}
          {pageNo === 1 && (
            <div className="edit-resume">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex gap-3 flex-wrap my-3">
                      <div className="change">Change Colour</div>
                      <div className="d-flex gap-2 flex-wrap">
                        <div
                          className="box bg-white"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "" })
                          }
                        >
                          {formData?.cv_color == "" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-blue"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#0432A3" })
                          }
                        >
                          {formData?.cv_color == "#0432A3" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-primary"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#19BCF4" })
                          }
                        >
                          {formData?.cv_color == "#19BCF4" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-orange"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#F68A18" })
                          }
                        >
                          {formData?.cv_color == "#F68A18" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-purple"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#8430C8" })
                          }
                        >
                          {formData?.cv_color == "#8430C8" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-red"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#CD001A" })
                          }
                        >
                          {formData?.cv_color == "#CD001A" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className="box bg-green"
                          onClick={() =>
                            setFormData({ ...formData, cv_color: "#008C39" })
                          }
                        >
                          {formData?.cv_color == "#008C39" && (
                            <div className="tick d-flex justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M12.5 3.75L5.625 10.625L2.5 7.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2 text-center">
                    <div className=" my-3">
                      <div className="profile-img">
                        <img
                          src={
                            formData?.profile_img ||
                            "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                          }
                          className="image-fit"
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-outline"
                      onClick={() => setProfilePhotoModal(!profilePhotoModal)}
                    >
                      Upload Photo
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("first_name")}
                              onChange={(e) => handleChange(e)}
                              name="first_name"
                              value={formData?.first_name}
                              maxLength={50}
                            />
                            {error?.first_name && (
                              <p className="text-danger">{error?.first_name}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Surname
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("last_name")}
                              onChange={(e) => handleChange(e)}
                              name="last_name"
                              value={formData?.last_name}
                              maxLength={50}
                            />
                            {error?.last_name && (
                              <p className="text-danger">{error?.last_name}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Profession
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("profile_headline")}
                              onChange={(e) => handleChange(e)}
                              name="profile_headline"
                              value={formData?.profile_headline}
                              maxLength={100}
                            />
                            {error?.profile_headline && (
                              <p className="text-danger">
                                {error?.profile_headline}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              About
                            </label>
                            <textarea
                              className="form-control"
                              onBlur={() => checkValidation("about")}
                              onChange={(e) => handleChange(e)}
                              name="about"
                              value={formData?.about}
                              maxLength={300}
                            ></textarea>
                            {error?.about && (
                              <p className="text-danger">{error?.about}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Location
                            </label>
                            <div className="autocomplete-wrap w-100">
                              <Autocomplete
                                inputProps={{
                                  placeholder: "Location",
                                  onBlur: () => checkValidation("address"),
                                }}
                                value={formData?.address}
                                items={locationList}
                                getItemValue={(item) => item?.name}
                                renderMenu={(items, value) => (
                                  <div className="dropdown border border-1 p-2">
                                    {!items?.length && value?.trim() !== ""
                                      ? `No matches for ${value}`
                                      : items}
                                  </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                  <div
                                    className={`item mt-2 ${
                                      isHighlighted ? "selected-item" : ""
                                    }`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item?.name}
                                  </div>
                                )}
                                onChange={(obj, val) => {
                                  getLocation(val);
                                  setFormData({
                                    ...formData,
                                    address: val,
                                  });
                                }}
                                onSelect={(val, obj) => {
                                  setLocationInput(val);
                                  setFormData({
                                    ...formData,
                                    address: obj?.name,
                                  });
                                }}
                                wrapperStyle={{}}
                              />
                            </div>
                            {error?.address && (
                              <p className="text-danger">{error?.address}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              NobelPage Profile Link
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              name="profile_url"
                              value={formData?.profile_url}
                              onChange={(e) => handleChange(e)}
                              onBlur={() => checkValidation("profile_url")}
                            />
                            {error?.profile_url && (
                              <p className="text-danger">
                                {error?.profile_url}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Website or LinkedIn Profile Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="linkedin_url"
                              value={formData?.linkedin_url}
                              onChange={(e) => handleChange(e)}
                              onBlur={() => checkValidation("linkedIn")}
                            />
                            {error?.linkedin_url && (
                              <p className="text-danger">
                                {error?.linkedin_url}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("phone")}
                              onChange={(e) => handleChange(e)}
                              name="phone"
                              value={formData?.phone}
                              maxLength={10}
                            />
                            {error?.phone && (
                              <p className="text-danger">{error?.phone}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("email")}
                              onChange={(e) => handleChange(e)}
                              name="email"
                              value={formData?.email}
                            />
                            {error?.email && (
                              <p className="text-danger">{error?.email}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Alternate Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("altranate_phone")}
                              onChange={(e) => handleChange(e)}
                              name="altranate_phone"
                              value={formData?.altranate_phone}
                              maxLength={10}
                            />
                            {error?.altranate_phone && (
                              <p className="text-danger">
                                {error?.altranate_phone}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Alternate Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onBlur={() => checkValidation("altranate_email")}
                              onChange={(e) => handleChange(e)}
                              name="altranate_email"
                              value={formData?.altranate_email}
                            />
                            {error?.altranate_email && (
                              <p className="text-danger">
                                {error?.altranate_email}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="text-center mx-4 mt-5">
                          <div className="" style={{ marginLeft: "25%" }}>
                            <button
                              style={{ cursor: "pointer" }}
                              className="btn btn-light text-blue  me-3"
                              onClick={() => toggle()}
                            >
                              Back
                            </button>
                            <button
                              className=" btn btn-blue"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <div className="resume ">
                      <div className="container">
                        <div className="bg-white main-box">
                          <div className="row header ">
                            <div className="col-lg-12 ">
                              {/* <div className="first">
                                {editCV == "classic" ? (
                                  <ClassicTemplate
                                    userData={user}
                                    color={color?.classic}
                                    skillBackground={skillBackground?.classic}
                                    interestBackground={
                                      interestBackground?.classic
                                    }
                                  />
                                ) : editCV == "functional" ? (
                                  
                                  <FunctionalTemplate
                                    userData={user}
                                    color={color?.functional}
                                    skillBackground={
                                      skillBackground?.functional
                                    }
                                    interestBackground={
                                      interestBackground?.functional
                                    }
                                  />
                                ) : editCV == "combined" ? (
                                  <CombinedTemplate
                                    userData={user}
                                    color={color?.combined}
                                    skillBackground={skillBackground?.combined}
                                    interestBackground={
                                      interestBackground?.combined
                                    }
                                  />
                                ) : (
                                  <ModernTemplate
                                    userData={user}
                                    color={color?.modern}
                                    skillBackground={skillBackground?.modern}
                                    interestBackground={
                                      interestBackground?.modern
                                    }
                                  />
                                )}
                              </div> */}
                              <div>
                                {isLoading ? (
                                  <div className="position-relative">
                                    <AppLoader />
                                  </div>
                                ) : (
                                  <div
                                    className=""
                                    style={{ position: "relative" }}
                                  >
                                    <ThumbnailResumePreview
                                      url={cvTypes[editCV]}
                                    />
                                    <button
                                      style={{
                                        position: "absolute",
                                        zIndex: 999999,
                                        right: "10px",
                                        bottom: "10px",
                                        background: "#0432A3",
                                        color: "#fff",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        fontSize: "12px",
                                      }}
                                      onClick={() => {
                                        setPreviewCV(editCV);
                                        setPreviewResumeModal(true);
                                      }}
                                    >
                                      Preview
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="bullets mt-30">
                      <div className="dot active"></div>
                      <div className="dot"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pageNo === 2 && (
            <EditResumeTwo
              pageNo={pageNo}
              setPageNo={setPageNo}
              editCV={editCV}
              user={user}
              color={color}
              token={token}
              skillBackground={skillBackground}
              interestBackground={interestBackground}
              // setSkillBackground={handleSkillBgColor}
              // setinterestBackground={handleInterestBgColor}
              setPreviewCV={setPreviewCV}
              setPreviewResumeModal={setPreviewResumeModal}
            />
          )}
          {pageNo === 3 && (
            <EditResumeEducation
              pageNo={pageNo}
              setPageNo={setPageNo}
              editCV={editCV}
              user={user}
              color={color}
              token={token}
              skillBackground={skillBackground}
              interestBackground={interestBackground}
              setPreviewCV={setPreviewCV}
              setPreviewResumeModal={setPreviewResumeModal}
              // setSkillBackground={handleSkillBgColor}
              // setinterestBackground={handleInterestBgColor}
            />
          )}
          {pageNo === 4 && (
            <EditResumeSkill
              loader={loader}
              toggle={toggle}
              pageNo={pageNo}
              setPageNo={setPageNo}
              editCV={editCV}
              user={user}
              color={color}
              token={token}
              skillBackground={skillBackground}
              interestBackground={interestBackground}
              setPreviewCV={setPreviewCV}
              setPreviewResumeModal={setPreviewResumeModal}
              // setSkillBackground={handleSkillBgColor}
              // setinterestBackground={handleInterestBgColor}
            />
          )}
          {pageNo === 5 && (
            <Resume
              pageNo={pageNo}
              setPageNo={setPageNo}
              editCV={editCV}
              user={user}
              color={color}
              token={token}
              skillBackground={skillBackground}
              interestBackground={interestBackground}
              // setSkillBackground={handleSkillBgColor}
              // setinterestBackground={handleInterestBgColor}
            />
          )}
        </ModalBody>

        {profilePhotoModal && (
          <ProfilePhotoModal
            modal={profilePhotoModal}
            toggle={() => setProfilePhotoModal(!profilePhotoModal)}
            title="Profile Photo"
            editProfileImage={formData?.profile_img}
            handleBannerPicture={handleBannerPicture}
            handleRemovePicture={handleRemovePicture}
          />
        )}
      </Modal>
    </>
  );
};
