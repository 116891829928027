import React from "react";
import { useDispatch } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import FormInput from "../../../core/components/Inputs/FormInput";
import { confirmPassTransferPage } from "../../../store/pages/pagesAction";
import { call } from "../../../api/apiCall";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../../core/components/modal/DeleteModal";

const ConfirmPassCloseAccount = (props) => {
  const { modal, toggle, title, setCloseAccountModal } = props;
  const [password, setPassword] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeAccount = async () => {
    try {
      const res = await call(
        "delete",
        `api/v1/user/close-account`,
        null,
        null,
        token
      );
      if (res.data.success === true) {
        toast.success("You have deleted your account.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else alert(res.data.message);
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return;
    }
  };

  // const closeAccount = () => {
  //   alert("This feature will be implemented in the future milestone");
  //   closeModals();
  // };

  const validation = (password) => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    handleSubmit();
  };

  const confirmPass = () => {
    const temp = { password };
    dispatch(confirmPassTransferPage(temp)).then((res) => {
      if (res.success == true) {
        setCloseModal(true);
      }
    });
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
  };
  const closeModals = () => {
    setCloseAccountModal(false);
    toggle();
  };
  const handleSubmit = async () => {
    confirmPass(password);
  };
  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "520px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={closeModals}>{title}</ModalHeader>
            <ModalBody>
              <div className="admin-popup">
                <div className="container">
                  <div className="p-0 bg-white">
                    <div className="row mb-4">
                      <div className="col-lg-12">
                        <p>
                          Please authenticate and confirm by inserting the
                          password of your NobelPage. Note your account will be
                          deleted.
                        </p>
                        <div className="d-flex position-relative form-input">
                          <FormInput
                            maxLength="24"
                            type="password"
                            placeholder="Password"
                            error={passErrorMessage}
                            onChange={handelPassChange}
                            // containerClassName ="confirmpass"
                            containerClassName={
                              "confirmpass " +
                              (passErrorMessage ? "error-form" : "")
                            }
                          />
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-blue mt-4"
                            onClick={() => validation(password)}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title=" Close Account Confirmation"
          deleteConfirm={() => {
            closeAccount();
          }}
          text={"Are you sure? You will close this account permanenetly"}
          transform={true}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default ConfirmPassCloseAccount;
