import React, { useEffect, useState } from "react";
import Heading from "../../../../../core/components/layout/Heading";
import logo from "../../../../../assets/img/np-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../Otp/Otp.scss";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { call } from "../../../../../api/apiCall";
import FormInput from "../../../../../core/components/Inputs/FormInput";
import { ToastContainer, toast } from "react-toastify";
import { verify_email } from "../../../../../store/profile/profileActions";
import { useDispatch } from "react-redux";
const OTPModal = (props) => {
  const { modal, toggle, email } = props;
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState("");
  const [showResentError, setShowResentError] = useState("");
  let token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const validation = () => {
    if (!otp) {
      setErrorMessage("Please Enter Your OTP");
    } else {
      handleSubmit();
    }
  };
  const handelResent = async () => {
    let data = { email: email };
    try {
      const res = await call("post", "api/v1/auth/send-otp", null, data, token);
      if (res.data.success == true) {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const handleSubmit = async () => {
    let otpEmailData = {
      email: email,
      otp: otp,
    };

    try {
      const res = await call(
        "post",
        "api/v1/auth/verify-otp",
        null,
        otpEmailData,
        token
      );
      if (res.data.success === true) {
        try {
          const verifyRes = dispatch(verify_email());
          if (verifyRes.sucess === true)
            //    { toast.success(verifyRes.message, {
            //         position: toast.POSITION.BOTTOM_LEFT
            //     })}
            toggle();
        } catch (err) {
          console.log(err);
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        toast.success("You have successfully verified your email address", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        toggle();
      }
      //   if (res.data.success === false) {
      //   //  alert("Please Enter valid OTP")
      // //   { <div className="alert alert-success show" role="alert">
      // //   <strong>Error!</strong> {res.data.message}
      // // </div>}

      //   }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      //   setShowError(err.response.data.message);
    }
  };
  const handelChangeOtp = (e) => {
    setOtp(e.target.value);
    setErrorMessage("");
    setShowError("");
    setShowResentError("");
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        style={{ maxWidth: "574px", maxHeight: "680px" }}
        centered
      >
        <ModalHeader toggle={toggle} className="mb-0">
          Enter OTP
          {/* <p className="fs-6 mt-2 mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
        </ModalHeader>
        <ModalBody className="pt-0 verification">
          {showError && (
            <label className="label-input100 text-danger">{showError}</label>
          )}
          {showResentError && (
            <label className="label-input100 text-primary">
              {showResentError}
            </label>
          )}
          {/* {showMessage && (
            <label className="label-input100 text-primary">
              OTP has been sent successfully to registered email Id.
            </label>
          )} */}
          <FormInput
            type="text"
            label="OTP"
            maxLength="4"
            onChange={handelChangeOtp}
            placeholder="Enter otp"
            error={errorMessage}
            style={{ padding: ".375rem .75rem" }}
            containerClassName={errorMessage ? "error-form" : ""}
          />

          <p className="otp-span" onClick={handelResent}>
            {" "}
            Resend OTP
          </p>
          <div className="text-center d-grid mt">
            <div className="btn btn-blue" onClick={validation}>
              Verify
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default OTPModal;
