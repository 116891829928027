import React, { useEffect, useState } from "react";
import Chat from "../../../../pages/chat/chat";
import Navbar from "../../../../pages/navbar/navbar";
import NetworkSidebar from "../../Layout/NetworkSidebar";
import photo from "../../../../assets/img/photo.svg";
import video from "../../../../assets/img/video.svg";
import join from "../../../../assets/img/join.png";
import CreateGroupModal from "../components/createGroup";
import PostOnDashboard from "../../../dashboard/components/postComponent/PostOnDashboard";
import CreatePostModal from "../../../dashboard/components/createPost/CreatePostModal";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import defaultGroupIcon from "../../../../assets/img/default_group_icon.svg";
import slash from "../../../../assets/img/slash.svg";
import bell from "../../../../assets/img/bell.svg";
import set from "../../../../assets/img/setting.svg";
import left from "../../../../assets/img/Left-arrow-black.svg";
import cross_circle from "../../../../assets/img/cross_circle.svg";
import AddMembers from "./AddMembers";
import moment from "moment";
import Ad from "../../../../core/components/layout/Sidebar/Ad";
import ShowGroupMembers from "./ShowGroupMembers";
import { useDispatch, useSelector } from "react-redux";
import { getAllMemeber } from "../../../../store/memberGroup/memberAction";
import { editGroup } from "../../../../store/groups/groupsActions";
import ReportModal from "../../../dashboard/components/reportTypeModal";
import {
  joinGroups,
  leaveGroup,
  withdrawRequest,
} from "../../../../store/groups/groupsActions";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import { useRef } from "react";
import {
  comment_status,
  createPost,
  deletePost,
  hide_user_post,
  repost_post,
  savePost,
  savePostDelete,
} from "../../../../store/dashboard/dashboardActions";
import { reactionsData } from "../../../../store/reactionPost/reactionAction";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import EditPage from "../../../../core/components/layout/Sidebar/EditUrl";
import EditUrl from "../../../../core/components/layout/Sidebar/EditUrl";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import { call } from "../../../../api/apiCall";
import GroupViewSidebar from "../../../../core/components/layout/Sidebar/GroupViewSidebar";
import RoleChangeModal from "../../../dashboard/components/RoleChangeModal";
import SearchMemberModal from "./SearchMember";

const GroupDetails = (props) => {
  const {
    group_detail,
    openCreateModal,
    setOpenCreateModal,
    editGroupData,
    setEditGroupData,
    user,
    postData,
    getTime,
    privacy_type,
    likeResult,
    createPostModal,
    setCreatePostModal,
    isDataAvailable,
    setDataAvailable,
    groupId,
    navigate,
    addMemberModal,
    setAddMemberModal,
    showMemberModal,
    setShowMemberModal,
    createPostClick,
    setCreatePostClick,
    isSticky,
    EditGroupURL,
    singleGroupDetailsbyURL,
    isGroupMember,
    roleShareModal,
    setRoleShareModal,
    rolePageId,
    setRolePageId,
    getPageDetailData,
    isPagePosts,
    setSelectedUserId,
    selectedUserId,
  } = props;

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.memberList.memberData);
  let token = window.localStorage.getItem("accessToken");

  const [searchMembersModal, setSearchMembersModal] = useState(false);
  const [reportGroupModal, setReportGroupModal] = useState(false);
  const [reportCommentId, setReportCommentId] = useState();
  const [postId, setPostID] = useState();
  const [isDropDown, setIsDropDown] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [withdrawGroupId, setWithdrawGroupId] = useState();

  // all group post on post on dashboard
  const [dropDown, setDropDown] = useState(-1);
  const [isSaved, setSaved] = useState(-1);
  const dropRef = useRef(null);
  const [isReaction, setIsReaction] = useState(-1);
  const [isComments, setIsComments] = useState(-1);
  const [isViewReaction, setViewReaction] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);
  const [shareData, setShareData] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isEdit, setIsEdit] = useState();
  const [updateData, setUpdateData] = useState();
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [draftDetails, setDraftDetails] = useState();
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  // Edit group url
  const [editURL, setEditURL] = useState(true);
  const [pageNavURL, setPageNavURL] = useState("");
  const [textURL, setTextURL] = useState("");
  const [pageNavURLStatus, setPageNavURLStatus] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isEnable, setIsEnable] = useState(true);

  useEffect(() => {
    if (textURL) checkUrlExist();
  }, [textURL]);

  useEffect(() => {
    if (group_detail[0]?.group_url) {
      setPageNavURL(group_detail[0]?.group_url);
      setTextURL(group_detail[0]?.group_url);
    }
  }, [group_detail]);

  const getMemeberRequested = () => {
    dispatch(getAllMemeber({ group_id: groupId, type: "recieved" }));
  };

  const getMemeberPending = () => {
    dispatch(getAllMemeber({ group_id: groupId, type: "sent" }));
  };

  const getMemeberFinal = () => {
    dispatch(getAllMemeber({ group_id: groupId }));
  };
  const joinHandle = (id, type) => {
    dispatch(joinGroups(id, type)).then((response) => {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const leaveHandle = (id) => {
    dispatch(leaveGroup(id)).then((response) => {
      if (response.success) {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setIsDropDown(!isDropDown);
      }
    });
  };

  // page post methods
  const editPost = (postData) => {
    if (postData.Celebration) {
      alert("Cannot edit celebration post and feed docs ,docs");
    } else {
      setIsEdit(postData.id);
      setCreatePostModal(!createPostModal);
      setUpdateData(postData);
      setDropDown(-1);
    }
  };
  const delete_post = (_id) => {
    dispatch(deletePost(_id, token)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
    setDropDown(-1);
  };
  const save_post = (id, i) => {
    dispatch(savePost(id, token)).then((response) => {
      toast.success("Post Saved", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const removeSavePost = (id) => {
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    setCreatePostModal(false);
  };

  const saveDraft = () => {
    let token = window.localStorage.getItem("accessToken");
    draftDetails.feed_status = "draft";
    dispatch(createPost(draftDetails, token));
    setCreatePostModal(false);
    closeAllModal();
  };

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
      // id: byUserId !== 0 ? byUserId : item?.reaction?.byUser?.id,
    };
    // if (item?.as_a_page) {
    //   Object.assign(data, { page_id: item?.as_a_page?.id });
    // } else if (isPagePosts && selectedUserId) {
    //   Object.assign(data, { page_id: item?.Page?.id });
    // }
    dispatch(reactionsData(data, type)).then((res) => {
      // setByUserId(res.data[0]?.id);
    });
    // setIsReaction(!isReaction);
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }
  const withdrawRequestGroup = (groupId) => {
    const data = {
      group_id: groupId,
      request_status: "cancel",
    };
    dispatch(withdrawRequest(data)).then((response) => {
      toast.success("Request Withdraw Successfull!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setIsDropDown(!isDropDown);
    });
  };

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };
  const handleCommentStatus = (postData, type) => {
    dispatch(comment_status(postData, type));
    setDropDown(-1);
  };

  const repostPost = (id) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
      shared_by_type: "user",
      shared_by: user?.data?.id,
    };
    dispatch(repost_post(repost_data)).then((res) => {
      setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  // Edit group url
  const checkUrlExist = async () => {
    try {
      dispatch(initLoad());
      const res = await call(
        "get",
        `api/v1/group/url-exist/${textURL}`,
        null,
        null,
        token
      );
      dispatch(stopLoad());
      if (res.data.success === true) {
        setPageNavURLStatus("available");
        // handleSubmit();
      }
      if (res.data.success === false) {
        setPageNavURLStatus("Unavailable");
        setUrlErrorMessage(
          "This group url already exist. Please enter another url"
        );
        setIsEnable(true);
        return;
      }
    } catch (err) {
      setPageNavURLStatus("Unavailable");
      // setErrorMessage("This page url already exist. Please enter another url");
      setIsEnable(true);
      console.log(err);
      dispatch(stopLoad());
      return;
    }
  };

  const handleSubmit = () => {
    const data = {
      group_url: textURL,
      group_id: groupId,
    };
    EditGroupURL(data);
    setEditURL(!editURL);
    // checkUrlExist();
    setPageNavURLStatus("");
    setUrlErrorMessage("");
  };

  const addGroupMembers = (data, type) => {
    const token = localStorage.getItem("accessToken");
    if (type === "add") {
      dispatch(editGroup(groupId, data, token)).then((res) => {
        if (res) {
          toast.success("Group join request sent successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
      setSearchMembersModal(!searchMembersModal);
    } else if (type === "remove") {
      dispatch(editGroup(groupId, data, token));
    }
  };

  return (
    <React.Fragment>
      <Navbar isJobs={false} />
      <div className="main-bg ">
        <div className="page-details bg-grey ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="wrapper ">
                  <div className="big-img">
                    {group_detail[0]?.background_img && (
                      <img
                        src={group_detail[0]?.background_img}
                        className="img-cover"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="left ml-20 mb-20">
                        <div className="profile-details">
                          <div className="d-flex gap-3 ">
                            <div className="img">
                              <img
                                src={
                                  group_detail[0]?.icon
                                    ? group_detail[0]?.icon
                                    : defaultGroupIcon
                                }
                                className="image-fit"
                                alt=""
                              />
                            </div>
                            <div className="details mt-20 ">
                              <h1>{group_detail[0]?.name}</h1>
                              <small>
                                <strong>
                                  {group_detail[0]?.type === "public" ? (
                                    <>Public Group</>
                                  ) : (
                                    <>Private Group</>
                                  )}
                                </strong>
                              </small>
                              <br />
                              <small>
                                Joined group:{" "}
                                {moment(group_detail[0]?.createdAt)?.format(
                                  "MMM, YYYY"
                                )}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="right mr-20 mb-20 ml-20 ">
                        <div className="float-xl-end more">
                          {group_detail[0]?.admin.id === user?.data?.id && (
                            <button
                              className="btn btn-blue mt-20 text-white mb-5"
                              onClick={() => {
                                setOpenCreateModal(!openCreateModal);
                                setEditGroupData(group_detail[0]);
                              }}
                            >
                              Edit Group
                            </button>
                          )}
                          {group_detail[0]?.is_joined === "confirm" &&
                          group_detail[0]?.admin.id === user?.data?.id ? (
                            <div></div>
                          ) : group_detail[0]?.is_joined === "confirm" &&
                            group_detail[0]?.admin.id !== user?.data?.id ? (
                            <div style={{ marginTop: "93px" }}></div>
                          ) : group_detail[0]?.is_joined === "pending" ||
                            group_detail[0]?.is_joined === "recieved" ? (
                            <div
                              className="btn btn-light btn-margin-bottom mt-3 mb-5"
                              onClick={() => {
                                alert("Please wait for group admin response");
                              }}
                            >
                              Requested{" "}
                            </div>
                          ) : group_detail[0]?.members?.length ===
                            group_detail[0]?.members_limit ? (
                            <div className="btn btn-light disabled-btn-blue btn-margin-bottom mt-3 mb-4">
                              Join
                            </div>
                          ) : (
                            <div
                              className="btn btn-light btn-margin-bottom mt-3 mb-5"
                              onClick={() =>
                                joinHandle(group_detail[0]?.id, "joinTrue")
                              }
                            >
                              <img
                                style={{
                                  marginRight: "9px",
                                }}
                                src={join}
                                alt="join"
                              />
                              Join
                            </div>
                          )}
                          <p
                            className="text-blue text-end mb-3"
                            onClick={() => setIsDropDown(!isDropDown)}
                          >
                            <strong className="curserPointer">More</strong>
                          </p>
                          {isDropDown && (
                            <div className="box">
                              <ul id="dropdownBox">
                                {group_detail[0]?.is_joined === "confirm" && (
                                  <>
                                    <li
                                      className="listing"
                                      onClick={() => navigate(`/notifications`)}
                                    >
                                      <img src={bell} alt="" />
                                      Manage Notification
                                    </li>
                                    <li
                                      className="listing"
                                      onClick={() => navigate(`/setting`)}
                                    >
                                      <img src={set} alt="" />
                                      Update your settings
                                    </li>

                                    {group_detail[0]?.admin.id !==
                                      user?.data?.id && (
                                      <li
                                        className="listing"
                                        onClick={() =>
                                          setConfirmDeleteModal(true)
                                        }
                                      >
                                        <img
                                          src={left}
                                          alt=""
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                        Leave this group
                                      </li>
                                    )}
                                  </>
                                )}
                                {group_detail[0]?.admin.id !==
                                  user?.data?.id && (
                                  <>
                                    <li
                                      className="listing"
                                      onClick={() => {
                                        setReportGroupModal(true);
                                      }}
                                    >
                                      <img
                                        src={slash}
                                        alt=""
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />{" "}
                                      Report this Group
                                    </li>
                                    {group_detail[0]?.is_joined ===
                                      "pending" && (
                                      <li
                                        className="listing"
                                        onClick={() => {
                                          setRemoveModal(!removeModal);
                                          setWithdrawGroupId(
                                            group_detail[0]?.id
                                          );
                                        }}
                                      >
                                        <img
                                          src={cross_circle}
                                          alt=""
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                        Cancel Request
                                      </li>
                                    )}
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-26">
              <div className="col-lg-3">
                <div
                  className={
                    "group-details-sidebar" +
                    (isSticky ? " sidebar-sticky-left" : "")
                  }
                >
                  <NetworkSidebar />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="middle">
                  <div className="bg-white b-5 p-20">
                    <div className="about">
                      <h2 className="text-dark">About</h2>
                      <p className="mt-20">{group_detail[0]?.description}</p>
                      {/* <div className="see mt-20">
                        <>See more</>
                      </div> */}
                    </div>
                  </div>
                  {isGroupMember && (
                    <div
                      className="msg-box mt-20"
                      style={{ boxShadow: "0px 4px 4px 0px #0432A326" }}
                    >
                      <div className="type">
                        <div className="profile">
                          <img
                            src={
                              user?.data?.profile_img
                                ? user?.data?.profile_img
                                : placeholderUser
                            }
                            alt="profile img"
                            className="img-fluid"
                          />
                        </div>
                        <textarea
                          id="w3review"
                          rows="5"
                          className="curserPointer"
                          onKeyDown={() => {
                            setCreatePostModal(!createPostModal);
                            setIsEdit(0);
                            setCreatePostClick("");
                          }}
                          placeholder="Share your thoughts..."
                          onClick={() => {
                            setUpdateData({});
                            setCreatePostModal(!createPostModal);
                            setIsEdit(0);
                            setCreatePostClick("");
                          }}
                        ></textarea>
                      </div>
                      <div className="social mt-2">
                        <div
                          className="box"
                          onClick={() => {
                            setCreatePostModal(!createPostModal);
                            setCreatePostClick("photo");
                          }}
                        >
                          <img src={photo} alt="" />
                          <p>Photo</p>
                        </div>
                        <div
                          className="box"
                          onClick={() => {
                            setCreatePostModal(!createPostModal);
                            setCreatePostClick("video");
                          }}
                        >
                          <img src={video} alt="" />
                          <p>Video</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {postData?.data?.map((item, index) => (
                    <div>
                      <PostOnDashboard
                        item={item}
                        index={index}
                        dropDown={dropDown}
                        user={user}
                        userId={user?.data?.id}
                        editPost={editPost}
                        delete_post={delete_post}
                        dropRef={dropRef}
                        dropRefShare={dropRefShare}
                        privacy_type={privacy_type}
                        save_post={save_post}
                        removeSavePost={removeSavePost}
                        setDropDown={setDropDown}
                        handleReaction={handleReaction}
                        isViewReaction={isViewReaction}
                        setViewReaction={setViewReaction}
                        setPostID={setPostID}
                        type="groupPost"
                        reportModal={reportModal}
                        setReportModal={setReportModal}
                        hideUserPost={hideUserPost}
                        commentBoxStatus={commentBoxStatus}
                        setCommentBoxStatus={setCommentBoxStatus}
                        dropDownShare={dropDownShare}
                        reportCommentId={reportCommentId}
                        setReportCommentId={setReportCommentId}
                        setDropDownShare={setDropDownShare}
                        repostPost={repostPost}
                        handleCommentStatus={handleCommentStatus}
                        dropDownRepost={dropDownRepost}
                        setDropDownRepost={setDropDownRepost}
                        openShareModal={openShareModal}
                        setOpenShareModal={setOpenShareModal}
                        shareData={shareData}
                        setShareData={setShareData}
                        likeResult={likeResult}
                        getTime={getTime}
                        closeModal={closeModal}
                        setCloseModal={setCloseModal}
                        setDeleteId={setDeleteId}
                        dropRefRepost={dropRefRepost}
                        roleShareModal={roleShareModal}
                        setRoleShareModal={setRoleShareModal}
                        rolePageId={rolePageId}
                        setRolePageId={setRolePageId}
                        selectedUserId={selectedUserId}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 bottomSpaceFromChatBox">
                <div className="side-admin">
                  <div className="bg-white p-20">
                    <h6 className="text-dark">Admin</h6>
                    <div className="main-post">
                      <div className="prof-img">
                        <img
                          onClick={() =>
                            navigate(`/profile/${group_detail[0]?.admin?.id}`)
                          }
                          src={
                            group_detail[0]?.admin?.profile_img
                              ? group_detail[0]?.admin?.profile_img
                              : placeholderUser
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="details">
                        <h4
                          // style={{ marginBottom: "0px", cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/profile/${group_detail[0]?.admin?.id}`)
                          }
                          className="group-admin-name"
                        >
                          {group_detail[0]?.admin?.first_name +
                            " " +
                            group_detail[0]?.admin?.last_name}
                        </h4>
                        <p>
                          {group_detail[0]?.admin?.profile?.profile_headline}
                        </p>
                      </div>
                    </div>
                    <div className="imgs d-flex justify-content-center align-items-center">
                      <div className="img">
                        {group_detail[0]?.members?.length > 0 &&
                          group_detail[0]?.members?.map((members, idx) => {
                            return (
                              idx < 5 && (
                                <span
                                  key={idx}
                                  onClick={() =>
                                    navigate(`/profile/${members?.user_id}`)
                                  }
                                >
                                  <img
                                    src={
                                      members?.profile_img
                                        ? members?.profile_img
                                        : placeholderUser
                                    }
                                    alt=""
                                    className="image-fit"
                                  />
                                </span>
                              )
                            );
                          })}
                      </div>
                    </div>
                    <p className="text-blue text-center mt-3">
                      {console.log(group_detail, "groupDetails")}
                      <strong
                        className="curserPointer"
                        onClick={
                          // group_detail[0]?.admin.id === user?.data?.id

                          () => setShowMemberModal(!showMemberModal)
                        }
                      >
                        {group_detail[0]?.members?.length > 5
                          ? group_detail[0]?.members?.length - 5 === 1
                            ? `+${group_detail[0]?.members?.length - 5} member`
                            : `+${group_detail[0]?.members?.length - 5} members`
                          : group_detail[0]?.members?.length > 1
                          ? group_detail[0]?.members?.length + " members"
                          : group_detail[0]?.members?.length + " member"}
                      </strong>
                    </p>
                    {group_detail[0]?.admin.id === user?.data?.id ||
                    (group_detail[0]?.is_joined === "confirm" &&
                      group_detail[0]?.type === "public") ? (
                      <button
                        className="btn btn-blue d-block w-100 mt-20"
                        // onClick={() => setAddMemberModal(!addMemberModal)}
                        onClick={() =>
                          setSearchMembersModal(!searchMembersModal)
                        }
                      >
                        Add Members
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="mt-20">
                  <Ad />
                  <GroupViewSidebar />
                </div>
                {(group_detail[0]?.admin.id === user?.data?.id ||
                  pageNavURL) && (
                  <div
                    className="mt-20 sidebar-sticky-left"
                    style={{ top: "10px" }}
                  >
                    <EditUrl
                      EditPageURL={EditGroupURL}
                      pageID={group_detail[0]?.id}
                      pageNavURL={pageNavURL}
                      setPageNavURL={setPageNavURL}
                      singlePageDetailsbyURL={singleGroupDetailsbyURL}
                      title={
                        group_detail[0]?.admin.id === user?.data?.id
                          ? "Edit Group URL"
                          : "Group URL"
                      }
                      staticUrlText={"/groupdetails/"}
                      handleSubmit={handleSubmit}
                      editURL={editURL}
                      setEditURL={setEditURL}
                      textURL={textURL}
                      setTextURL={setTextURL}
                      pageNavURLStatus={pageNavURLStatus}
                      setPageNavURLStatus={setPageNavURLStatus}
                      urlErrorMessage={urlErrorMessage}
                      setUrlErrorMessage={setUrlErrorMessage}
                      checkUrlExist={checkUrlExist}
                      isEnable={isEnable}
                      setIsEnable={setIsEnable}
                      urlCanEdit={
                        group_detail[0]?.admin.id === user?.data?.id
                          ? true
                          : false
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Chat />
        <ToastContainer />
      </div>
      {createPostModal && (
        <CreatePostModal
          modal={createPostModal}
          toggle={() => {
            if (isDataAvailable) {
              setDiscardModalStatus(true);
            } else {
              setCreatePostModal(!createPostModal);
            }
          }}
          toggleOff={() => {
            setCreatePostModal(!createPostModal);
          }}
          title="Create Post"
          userName={user?.data?.first_name + " " + user?.data?.last_name}
          userId={groupId}
          createPostClick={createPostClick}
          discardModalStatus={discardModalStatus}
          setDiscardModalStatus={setDiscardModalStatus}
          closeAllModal={closeAllModal}
          updateData={updateData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isDataAvailable={isDataAvailable}
          setDataAvailable={setDataAvailable}
          isGroupPosts={true}
          refGroupName={group_detail[0]?.name}
          draftDetails={draftDetails}
          setDraftDetails={setDraftDetails}
          dashboardData={user?.data}
          saveDraft={saveDraft}
        />
      )}

      {openCreateModal && (
        <CreateGroupModal
          modal={openCreateModal}
          toggle={() => setOpenCreateModal(!openCreateModal)}
          title="Edit Group"
          editGroupData={editGroupData}
          setEditGroupData={setEditGroupData}
        />
      )}
      {confirmDeleteModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
          title="Leave Confirmation"
          deleteConfirm={() => {
            leaveHandle(group_detail[0]?.id);
          }}
          text="Are you want to sure leave this group?"
        />
      )}

      {addMemberModal && (
        <AddMembers
          modal={addMemberModal}
          toggle={() => setAddMemberModal(!addMemberModal)}
          title={
            group_detail[0]?.admin.id === user?.data?.id
              ? "Add or Remove Group Members"
              : "Group members"
          }
          groupData={group_detail[0]}
          memberData={memberData}
          groupId={groupId}
          getMemeberFinal={getMemeberFinal}
          getMemeberRequested={getMemeberRequested}
          getMemeberPending={getMemeberPending}
          // getMemberList={getMemberList}
          user={user}
        />
      )}
      {showMemberModal && (
        <ShowGroupMembers
          modal={showMemberModal}
          memberData={memberData}
          toggle={() => setShowMemberModal(!showMemberModal)}
          groupData={group_detail[0]}
          user={user}
          isAdmin={group_detail[0]?.admin.id === user?.data?.id}
          groupId={groupId}
          getMemeberFinal={getMemeberFinal}
          getMemeberRequested={getMemeberRequested}
          getMemeberPending={getMemeberPending}
        />
      )}
      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
        />
      )}
      {reportGroupModal && (
        <ReportModal
          modal={reportGroupModal}
          toggle={() => setReportGroupModal(!reportGroupModal)}
          reportType={"group"}
          reportTypeId={group_detail[0]?.id}
        />
      )}
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            delete_post(deleteId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}
      {removeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setRemoveModal(!removeModal)}
          title="Withdraw Confirmation"
          deleteConfirm={() => {
            withdrawRequestGroup(withdrawGroupId);
          }}
          text="Are you want to sure withdraw request?"
        />
      )}

      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={postId}
          getPageDetailData={getPageDetailData}
          rolePageId={rolePageId}
          setSelectedUserId={setSelectedUserId}
          isPagePosts={isPagePosts}
        />
      )}

      {searchMembersModal && (
        <SearchMemberModal
          modal={searchMembersModal}
          toggle={() => setSearchMembersModal(!searchMembersModal)}
          title={"Add Members"}
          addGroupMembers={addGroupMembers}
          editGroupData={group_detail[0]}
        />
      )}
    </React.Fragment>
  );
};
export default GroupDetails;
