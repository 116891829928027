import React, { useState } from "react";
import "./Footer.scss";
import { subscribeForJob } from "../../../store/portal/portalAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const FooterJobUpdates = (props) => {
  const { themeColor } = props;
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const handleValidation = () => {
    let formIsValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if (type === "email" || type === "all") {
    if (!email.trim()) {
      setError("Please enter email");
      formIsValid = false;
    } else if (!emailRegex.test(email.trim())) {
      setError("Please enter your email in a valid format.");
      formIsValid = false;
    } else {
      setError("");
    }
    return formIsValid;
  };

  const handleSubscribe = () => {
    if (handleValidation()) {
      const data = { email: email };
      dispatch(subscribeForJob(data)).then((res) => {
        if (res.success) {
          toast.success("You have successfully subscribe us. Thank you!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setEmail("");
        }
      });
    } else return;
  };
  return (
    <div className="job-updates row">
      <div className="col-sm-12 col-lg-6 d-flex align-items-center text-center">
        <span>
          Never Want to Miss Any
          <h4 style={{ color: themeColor }}>Job Updates?</h4>
        </span>
      </div>
      <div className="col-sm-12 col-lg-6" style={{ position: "relative" }}>
        <input
          type="text"
          placeholder="Enter your email..."
          value={email}
          onChange={(e) => {
            setError("");
            setEmail(e.target.value);
          }}
        />
        <div
          className="btn"
          style={{
            backgroundColor: themeColor,
            borderRadius: "0",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => handleSubscribe()}
        >
          Subscribe
        </div>
        {error && (
          <label className="label-input100 text-danger error-message">
            {error}
          </label>
        )}
      </div>
    </div>
  );
};

export default FooterJobUpdates;
