import React, { useState, useEffect } from "react";
import { Modal, Input, ModalHeader } from "reactstrap";
import EditorBox from "./components/Editor";
import "./article.scss";
import { createArticle } from "../../../store/articles/articleActions";
import { editArticle } from "../../../store/articles/articleActions";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "./components/DragNDrop";
import axios from "axios";
import ArticlePreview from "./components/articlePreview";
import { getIndustryData } from "../../../store/master/masterActions";
import ShareModal from "../../../core/components/sharePost/sharePost";
import DiscardArticleModal from "./components/DiscardModal";
import { toast } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import { EDITOR_IMAGE_CLEAR_URL } from "../../../store/types";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import Navbar from "../../../pages/navbar/navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArticleSuccessfulModal from "./components/articleSuccessfulModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { call } from "../../../api/apiCall";
import DropzoneMobile from "./components/DragNdropMobile";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append("file", file);

          fetch(
            "https://nobelpageapi.dev.vinove.com/api/v1/upload-editor-image",
            {
              method: "POST",
              body: data,
            }
          )
            .then((response) => response.json())
            .then((result) => {
              resolve({
                default: result.url,
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
    );
  }

  abort() {
    // Handle aborting the upload process
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

const CreateArticleNew = () => {
  // const {
  //   // toggle,
  //   editArticleData,
  //   setEditArticleData,
  //   source,
  //   successfulStatus,
  //   pageName,
  //   createdAt,
  //   pageID,
  //   pageIcon,
  // } = props;

  const { state } = useLocation();
  const { editData, pageName, createdAt, pageID, pageIcon } = state || {};

  const navigate = useNavigate();

  const [convertedContent, setConvertedContent] = useState(null);

  const toggle = () => {
    console.log("dfdfdf");
    navigate(-1);
  };

  const EditorImageUrls = useSelector((state) => state?.article?.editorImgUrls);

  useEffect(() => {
    dispatch({
      type: EDITOR_IMAGE_CLEAR_URL,
      payload: [],
    });
  }, []);
  useEffect(() => {
    // console.log("EditorImageUrls", EditorImageUrls);
  }, [EditorImageUrls]);

  const [editArticleData, setEditArticleData] = useState(editData || null);
  const [successModal, setSuccessModal] = useState(false);
  const [popupShareData, setPopupShareData] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [textLength, setTextLength] = useState(
    editArticleData?.title ? editArticleData.title.split(" ").length : 0
  );

  const [dbFormat, setDbFormat] = useState(
    editArticleData?.content ? editArticleData?.content : ""
  );

  const [bannerImage, setBannerImage] = useState(
    editArticleData ? editArticleData?.media : ""
  );

  const [industryInput, setIndustryInput] = useState(
    editArticleData ? editArticleData?.industry : ""
  );

  const [hashTagData, setHashTagData] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  // const [shareData, setShareData] = useState([]);
  const [formStatus, setFormStatus] = useState(false);
  const [hasText, setHasText] = useState(false);
  const [discardModalStatus, setDiscardModalStatus] = useState(false);

  const { industryData } = useSelector((state) => state.master);
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.loader);

  const { source, token } = useParams();

  const [articleData, setArticleData] = useState(
    editArticleData
      ? {
          id: editArticleData?.id,
          title: editArticleData?.title,
          industry: editArticleData?.industry,
          content: "",
          media: editArticleData?.media,
          privacy_id: 1,
          hashtags: ["string"],
        }
      : {
          title: "",
          industry: "",
          content: "",
          media: bannerImage,
          privacy_id: 1,
          hashtags: [],
        }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  function wordLimit(limit) {
    console.log(limit);
    var val = articleData.title;
    var words = val.split(/\s+/);
    var legal = "";
    for (let i = 0; i < words.length; i++) {
      if (i < limit) {
        legal += words[i] + " ";
      }
      if (i >= limit) {
        setArticleData({ ...articleData, title: legal.trim() });
      }
    }
  }
  function getWordCount(str) {
    return str.split(" ").filter(function (n) {
      return n != "";
    }).length;
  }

  useEffect(() => {
    checkValidation();
  }, [articleData.title, articleData.industry, industryInput, hasText]);

  const checkValidation = () => {
    setFormStatus(true);
    if (!articleData.title.trim()) {
      setFormStatus(false);
    }
    // if (!articleData.industry || articleData.industry !== industryInput) {
    //   setFormStatus(false);
    // }
    if (!hasText) {
      setFormStatus(false);
    }
  };

  const publishArticle = () => {
    console.log("fdhfbh");

    const newDataFormat = {
      title: articleData.title,
      industry: articleData.industry,
      content: convertedContent,
      article_status: "published",
      media: bannerImage,
      privacy_id: 1,
      hashtags: hashTagData?.map((s) => s.slice(1)),
    };
    console.log(source);
    // setPopupShareData(newDataFormat);
    if (source === "network") {
      if (articleData.id) {
        newDataFormat.id = articleData.id;
        dispatch(editArticle(newDataFormat, token))
          .then(() => {
            toast.success("Updated Successfully.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          })
          .catch((e) => {});
      } else {
        dispatch(createArticle(newDataFormat, token))
          .then((data) => {
            setPopupShareData(data);
          })
          .catch((e) => {
            alert(e.response.data.message);
          });
      }
    } else if (source === "home") {
      setOpenShareModal(true);
    }
  };

  const handleBannerPicture = async (file) => {
    console.log(file);
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      formData.append("image", file);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        setBannerImage(res?.data?.urlsArray[0]);
        console.log(res?.data?.urlsArray[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deletePicture = () => {
    setBannerImage("");
  };

  const checkDiscardChanges = () => {
    if (articleData?.title || articleData?.content || articleData?.industry) {
      return true;
    } else return false;
  };

  const closeModal = () => {
    if (createdAt === "page") {
      toggle();
    }
    if (editArticleData) {
      setEditArticleData(null);
    }
    console.log(checkDiscardChanges());
    if (!editArticleData && checkDiscardChanges()) {
      setDiscardModalStatus(true);
    } else {
      toggle();
    }
  };

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    toggle();
  };

  const saveDraft = () => {
    const newDataFormat = {
      title: articleData.title,
      industry: articleData.industry,
      content: dbFormat,
      media: bannerImage,
      privacy_id: 1,
      hashtags: hashTagData?.map((s) => s.slice(1)),
    };

    newDataFormat.article_status = "draft";
    dispatch(createArticle(newDataFormat, token))
      .then(() => {
        setDiscardModalStatus(!discardModalStatus);
        toggle();
      })
      .catch((e) => {});
  };

  function handleQuery(query) {
    return new Promise((resolve) => {
      setTimeout(() => {
        call(
          "get",
          `api/v1/user/mention-list`,
          { search: query, limit: 10, skip: 0 },
          null,
          token
        ).then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            const myData = res.data.data.map((ele) => {
              return { ...ele, id: `@${ele.display}`, userId: ele.id };
            });
            resolve(myData);
          } else {
            // resolve(['@Barney', '@Lily', '@Marry Ann', '@Marshall', '@Robin', '@Ted'])
            resolve([]);
          }
        });
      }, 100);
    });
  }

  function handleHashQuery(query) {
    return new Promise((resolve) => {
      setTimeout(() => {
        call(
          "get",
          `api/v1/feed/mention-hash`,
          { search: query, limit: 10, skip: 0 },
          null,
          token
        ).then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            const myData = res.data.data.map((ele) => {
              return { ...ele, id: `#${ele.display}`, userId: ele.id };
            });
            resolve(myData);
          } else {
            // resolve(['@Barney', '@Lily', '@Marry Ann', '@Marshall', '@Robin', '@Ted'])
            resolve([{ id: `#${query}`, userId: null }]);
          }
        });
      }, 100);
    });
  }

  function customItemRenderer(item) {
    const itemElement = document.createElement("div");
    itemElement.id = `mention-list-item-id-${item.userId}`;
    itemElement.classList.add("render-ele");

    const img = document.createElement("img");
    img.classList.add("item-img");
    img.setAttribute("src", item.profile_img);
    img.setAttribute("alt", "DP Image");
    img.setAttribute("width", "40px");
    img.setAttribute("height", "40px");

    const displayBox = document.createElement("div");
    displayBox.classList.add("info-block");

    const displayElement = document.createElement("span");
    displayElement.classList.add("custom-item");
    displayElement.textContent = `${item.display} `;

    const usernameElement = document.createElement("span");
    usernameElement.classList.add("custom-item-username");
    usernameElement.textContent = item?.profile?.profile_headline || "";

    displayBox.appendChild(displayElement);
    displayBox.append(usernameElement);

    itemElement.appendChild(img);
    itemElement.appendChild(displayBox);

    return itemElement;
  }

  function MentionCustomization(editor) {
    // The upcast converter will convert view <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        attributes: {
          href: true,
          "data-user-id": true,
        },
      },
      model: {
        key: "mention",
        value: (viewItem) => {
          // The mention feature expects that the mention attribute value
          // in the model is a plain object with a set of additional attributes.
          // In order to create a proper object use the toMentionAttribute() helper method:
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              // Add any other properties that you need.
              link: viewItem.getAttribute("href"),
              userId: viewItem.getAttribute("data-user-id"),
            });

          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue) {
          return;
        }

        let className, url;

        if (modelAttributeValue.id.split("")[0] == "#") {
          className = "mention hash-mention";
          let query = modelAttributeValue.id;
          let newQuery = query.split("").slice(1).join("");
          url = `${process.env.REACT_APP_WEB_STAGE_URL}hashtag/feed/?keyword=${newQuery}`;
        } else {
          className = "mention user-mention";
          url = `${process.env.REACT_APP_WEB_STAGE_URL}profile/${modelAttributeValue.userId}`;
        }

        return writer.createAttributeElement(
          "a",
          {
            class: className,
            "data-mention": modelAttributeValue.id,
            "data-user-id": modelAttributeValue.userId,
            href: url,
          },
          {
            // Make mention attribute to be wrapped by other attribute elements.
            priority: 20,
            // Prevent merging mentions together.
            id: modelAttributeValue.uid,
          }
        );
      },
      converterPriority: "high",
    });
  }

  useEffect(() => {
    // const handleScroll = () => {
    //   const fixEditor = document.querySelectorAll(".create-newsletter");
    //   const editorControler = document.querySelectorAll(".ck-editor__top");
    //   let scrollPosition = window.scrollY;
    //   fixEditor.forEach((editor) => {
    //     if (scrollPosition > 100) {
    //       editor.classList.add("sticky-header");
    //     } else {
    //       editor.classList.remove("sticky-header");
    //     }
    //   });
    //   editorControler.forEach((controller) => {
    //     controller.style.transition = "top 0.05s";
    //     if (window.innerWidth > 767) {
    //       if (scrollPosition >= 0 && scrollPosition <= 250) {
    //         controller.style.top = `${125 - scrollPosition}px`;
    //       } else {
    //         controller.style.top = "20px";
    //       }
    //     }
    //     if (window.innerWidth < 767) {
    //       if (scrollPosition >= 0 && scrollPosition <= 250) {
    //         controller.style.top = `${180 - scrollPosition}px`;
    //       } else {
    //         controller.style.top = "80px";
    //       }
    //     }
    //   });
    // };

    const handleScroll = () => {
      const fixEditor = document.querySelector(".create-newsletter");
      const editorController = document.querySelector(".ck-editor__top");
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold) {
        fixEditor.classList.add("sticky-header");
      } else {
        fixEditor.classList.remove("sticky-header");
      }

      const transitionTop = () => {
        if (window.innerWidth > 767) {
          if (scrollPosition <= threshold) {
            return `${125 - scrollPosition}px`;
          } else {
            return "25px";
          }
        } else {
          if (scrollPosition <= threshold) {
            return `${180 - scrollPosition}px`;
          } else {
            return "75px";
          }
        }
      };

      editorController.style.transition = "top 0.05s";
      editorController.style.top = transitionTop();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isLoading && <AppLoader />}
      {/* <Navbar isJobs={false} /> */}
      <div className="main-center mt-1 pt-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="create-newsletter">
                <div className="wrapper-class px-0">
                  <div className="fixHeaderBox">
                    <div className="row justify-content-between align-items-center header-shadow">
                      <div className="col-10">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={
                              user?.data?.profile_img
                                ? user?.data?.profile_img
                                : placeholderUser
                            }
                            className="image-fit"
                            alt=""
                            style={{ width: "36px", height: "36px" }}
                          />
                          <h6 className="name card-control-text-oneline">
                            {user?.data?.first_name} {user?.data?.last_name}
                          </h6>
                        </span>
                      </div>

                      <div className="col-2 d-flex justify-content-end">
                        <div className="preview-button">
                          {" "}
                          <span
                            className="preview"
                            onClick={() => setPreviewModal(!previewModal)}
                          >
                            Preview
                          </span>
                        </div>

                        {formStatus ? (
                          <div class="next-button-row">
                            <div
                              className="btn btn-blue d-block next-button"
                              style={{
                                width: "100px",
                                height: "34px",
                                padding: "7px 19px",
                              }}
                              onClick={publishArticle}
                            >
                              {
                                // source === "home"
                                //   ? "Next Step"
                                //   : editArticleData
                                //   ? "Next"
                                //   :
                                "Next"
                              }
                            </div>{" "}
                          </div>
                        ) : (
                          <div class="next-button-row">
                            <div
                              className="btn disabled-btn-blue d-block next-button"
                              style={{
                                height: "34px",
                                padding: "7px 19px",
                              }}
                            >
                              {
                                // source === "home"
                                //   ? "Next Step"
                                //   : editArticleData
                                //   ? "Next"
                                //   :
                                "Next"
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <span className="article-imagebox">
                    <DropzoneMobile
                      handleBannerPicture={handleBannerPicture}
                      bannerImage={bannerImage}
                      deletePicture={deletePicture}
                      className={"article-imagebox-banner"}
                    />
                  </span>

                  {/* <div className="autocomplete-wrapper"> 
             <Autocomplete
                  inputProps={{ placeholder: "Industry*" }}
                  value={industryInput}
                  items={industryData}
                  getItemValue={(item) => item.name}
                  shouldItemRender={(item, value) =>
                    item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                  }
                  renderMenu={(items, value) => (
                    <div className="dropdown custom-scrollbar">
                      {items.length === 0 ? `No matches for ${value}` : items}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item.name}
                    </div>
                  )}
                  onChange={(event, val) => setIndustryInput(val)}
                  onSelect={(val, obj) => {
                    setArticleData({ ...articleData, industry: val });
                    setIndustryInput(val);
                  }}
                  wrapperStyle={{}}
                />
              </div> */}

                  <div className="dropzone writenTextBox">
                    <div style={{ marginBottom: "0" }}>
                      <Input
                        className="input-type"
                        type="text"
                        style={{ border: "none", fontSize: "30px" }}
                        value={articleData.title}
                        onkeyup={wordLimit(20)}
                        placeholder="Title*"
                        onChange={(e) => {
                          setArticleData({
                            ...articleData,
                            title: e.target.value,
                          });
                          setTextLength(
                            getWordCount(e.target.value) > 20
                              ? 20
                              : getWordCount(e.target.value)
                          );
                        }}
                      />
                    </div>

                    {/* <span><p className="title-count">{textLength}/20</p></span></> */}
                    <div style={{ marginTop: "0" }}>
                      {" "}
                      {/* <EditorBox
                    convertedContent={convertedContent}
                    setConvertedContent={setConvertedContent}
                    dbFormat={dbFormat}
                    setDbFormat={setDbFormat}
                    hashtagData={hashTagData}
                    setHashTagData={setHashTagData}
                    setHasText={setHasText}
                    setArticleData={setArticleData}
                  /> */}
                      <CKEditor
                        editor={Editor}
                        config={{
                          placeholder: "Write Here...",
                          extraPlugins: [
                            MentionCustomization,
                            MyCustomUploadAdapterPlugin,
                          ],
                          mention: {
                            feeds: [
                              {
                                marker: "@",
                                feed: handleQuery,
                                itemRenderer: customItemRenderer,
                              },
                              {
                                marker: "#",
                                feed: handleHashQuery,
                              },
                            ],
                          },
                        }}
                        onReady={(editor) => {
                          editor.plugins.get(
                            "FileRepository"
                          ).createUploadAdapter = null;
                          MyCustomUploadAdapterPlugin(editor);
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "width",
                              "100%",
                              editor.editing.view.document.getRoot()
                            );
                            writer.setStyle(
                              "height",
                              "500px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          setConvertedContent(editor.getData());
                          setHasText(true);
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                        onInit={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "500px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {previewModal && (
            <ArticlePreview
              modal={previewModal}
              toggle={() => setPreviewModal(!previewModal)}
              title="Preview Article"
              convertedContent={convertedContent}
              bannerImage={bannerImage}
              articleData={articleData}
            />
          )}
          {openShareModal && (
            <ShareModal
              modal={openShareModal}
              toggle={() => {
                setOpenShareModal(!openShareModal);
                toggle();
              }}
              title="Share"
              type="article"
              shareData={popupShareData}
              source="home"
              toggleCase={toggle}
              atriclePageName={pageName}
              createdAt={createdAt}
              pageID={pageID}
              pageIcon={pageIcon}
            />
          )}
          {discardModalStatus && (
            <DiscardArticleModal
              modal={discardModalStatus}
              toggle={closeAllModal}
              close={closeAllModal}
              title="Discard Changes."
              saveDraft={saveDraft}
            />
          )}
          {successModal && (
            <ArticleSuccessfulModal
              modal={successModal}
              toggle={() => {
                setSuccessModal(!successModal);
                toggle();
              }}
              title=""
              shareData={popupShareData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateArticleNew;
