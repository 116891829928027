import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/np-logo.svg";
import signupimage from "../../../assets/img/signup-portal.svg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../core/components/layout/Header/Header";
import Heading from "../../../core/components/layout/Heading";
import Footer from "../../../core/components/layout/footer/Footer";
import FormInput from "../../../core/components/Inputs/FormInput";
import "./Forget.scss";
import { call } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
} from "../../../store/portal/portalAction";
import { toast } from "react-toastify";

export default function ForgotPass() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  useEffect(() => {
    // if (talentPoolData?.id===talentPoolDataByToken?.id) navigate(`/tp/portal/${param}/dashboard`);
    dispatch(getTalentPoolDetails(param));
    if (token) dispatch(getTalentPoolDetailsByToken());
  }, []);
  useEffect(() => {
    if (talentPoolData?.id === talentPoolDataByToken?.id)
      navigate(`/tp/portal/${param}/dashboard`);
  }, [talentPoolDataByToken, talentPoolData]);
  const handleSubmit = async () => {
    let forgotPassData = {
      email: email,
      id: param,
    };
    try {
      const res = await call(
        "post",
        "api/v1/auth/portal/send-otp",
        null,
        forgotPassData
      );

      if (res.data.success === true) {
        toast.success("Otp send Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate(`/tp/portal/${param}/verify-otp`, { state: { email: email } });
      } else {
        console.log("forgot res unsuccess", res);
        return;
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message === "No record found.")
        setShowErrorMessage(
          `We couldn't find an account associated with
           ${email}. Please try with an alternate email.`
        );
      else setShowErrorMessage(err.response.data.message);
    }
  };

  const handelChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const validation = () => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setErrorMessage("Please enter your email address.");
    } else if (!emailvalid.test(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <div
        className=" login-portal forgot_pass_container"
        style={{ height: "100vh", background: "white", overflowY: "auto" }}
      >
        <div
          className="row"
          style={{ margin: "auto", width: "100%", height: "100%" }}
        >
          <div className="col-lg-4 forgot-left">
            <svg
              width="393"
              height="374"
              viewBox="0 0 393 374"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="center"
            >
              <g clip-path="url(#clip0_908_18720)">
                <path
                  d="M183.281 373.036H174.809V4.67456C174.809 2.33384 176.707 0.445312 179.04 0.445312C181.382 0.445312 183.272 2.34271 183.272 4.67456V373.036H183.281Z"
                  fill={talentPoolData?.theme}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M174.568 49.5078H100.324V75.6369H174.568V49.5078Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M257.666 21.5H183.422V47.6291H257.666V21.5Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M183.422 93.5051L224.878 98.7717V62.1094L183.422 67.376V93.5051Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M216.521 81.1357L207.374 71.0547V75.4524L188.266 76.206V85.7817L207.374 86.7304V91.1281L216.521 81.1357Z"
                  fill={talentPoolData?.theme}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M246.346 32.6716L232.24 24.5234V28.0966H195.043V37.2466H232.24V40.8109L246.346 32.6716Z"
                  fill={talentPoolData?.theme}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M112.23 62.7643L126.336 54.625V58.1893H163.533V67.3393H126.336V70.9036L112.23 62.7643Z"
                  fill={talentPoolData?.theme}
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M294.387 75.7391C311.378 75.7391 325.153 61.9726 325.153 44.9907C325.153 28.0087 311.378 14.2422 294.387 14.2422C277.395 14.2422 263.621 28.0087 263.621 44.9907C263.621 61.9726 277.395 75.7391 294.387 75.7391Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M314.622 65.2891C312.572 67.3283 310.213 69.0661 307.613 70.4138"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M303.515 72.1584C300.649 73.116 297.58 73.6391 294.386 73.6391C278.551 73.6391 265.723 60.8095 265.723 44.992C265.723 29.1744 278.559 16.3359 294.386 16.3359C310.221 16.3359 323.049 29.1655 323.049 44.9831C323.049 50.3384 321.585 55.3478 319.022 59.6303"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M294.388 47.2583C295.643 47.2583 296.659 46.2421 296.659 44.9885C296.659 43.735 295.643 42.7188 294.388 42.7188C293.134 42.7188 292.117 43.735 292.117 44.9885C292.117 46.2421 293.134 47.2583 294.388 47.2583Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M297.651 57.7297L294.387 44.9888L313.442 31.9375"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M92.2202 124.758V170.189H58.3672"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.5898 163.055V112.375H92.219V120.754"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M53.5045 170.19H30.5898V166.484"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3585 126.602H34.3438V132.613H40.3585V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.378 126.602H42.3633V132.613H48.378V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M56.3976 126.602H50.3828V132.613H56.3976V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M64.4171 126.602H58.4023V132.613H64.4171V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.4366 126.602H66.4219V132.613H72.4366V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M80.4562 126.602H74.4414V132.613H80.4562V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M88.4757 126.602H82.4609V132.613H88.4757V126.602Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3585 136.094H34.3438V142.105H40.3585V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.378 136.094H42.3633V142.105H48.378V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M56.3976 136.094H50.3828V142.105H56.3976V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M64.4171 136.094H58.4023V142.105H64.4171V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.4366 136.094H66.4219V142.105H72.4366V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M80.4562 136.094H74.4414V142.105H80.4562V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M88.4757 136.094H82.4609V142.105H88.4757V136.094Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3585 145.578H34.3438V151.59H40.3585V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.378 145.578H42.3633V151.59H48.378V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M56.3976 145.578H50.3828V151.59H56.3976V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M64.4171 145.578H58.4023V151.59H64.4171V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.4366 145.578H66.4219V151.59H72.4366V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M80.4562 145.578H74.4414V151.59H80.4562V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M88.4757 145.578H82.4609V151.59H88.4757V145.578Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.3585 155.062H34.3438V161.074H40.3585V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.378 155.062H42.3633V161.074H48.378V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M56.3976 155.062H50.3828V161.074H56.3976V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M64.4171 155.062H58.4023V161.074H64.4171V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M72.4366 155.062H66.4219V161.074H72.4366V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M80.4562 155.062H74.4414V161.074H80.4562V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M88.4757 155.062H82.4609V161.074H88.4757V155.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M41.4924 116.453H38.8398V106.727H41.4924V116.453ZM52.1114 106.727H49.4588V116.453H52.1114V106.727ZM62.7303 106.727H60.0778V116.453H62.7303V106.727ZM73.3493 106.727H70.6968V116.453H73.3493V106.727ZM83.9683 106.727H81.3158V116.453H83.9683V106.727Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M61.4075 209.047C48.4287 209.047 37.8984 219.562 37.8984 232.543C37.8984 245.514 48.4198 256.038 61.4075 256.038C74.3862 256.038 84.9165 245.523 84.9165 232.543C84.9076 219.562 74.3862 209.047 61.4075 209.047ZM61.4075 247.403C53.1926 247.403 46.5391 240.753 46.5391 232.543C46.5391 224.332 53.1926 217.683 61.4075 217.683C69.6223 217.683 76.2758 224.332 76.2758 232.543C76.2758 240.744 69.6134 247.403 61.4075 247.403Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M61.8867 209.062V217.707C69.8709 217.964 76.276 224.499 76.276 232.541C76.276 236.61 74.6348 240.29 71.9823 242.976L78.76 248.376C82.5747 244.2 84.8989 238.641 84.8989 232.541C84.9078 219.729 74.6437 209.311 61.8867 209.062Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52.7656 231.586H69.5591"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52.7656 235.891H69.5591"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M274.629 127.086H320.361"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 127.086H261.108"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M289.852 134.344H320.36"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 134.344H261.481"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M304.523 141.594H320.359"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 141.594H261.108"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M317.531 148.844H320.361"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 148.844H261.108"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M317.531 157.281H320.361"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 157.281H261.108"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M317.531 165.719H320.361"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M256.406 165.719H261.108"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M272.918 122.07H262.316V166.491H272.918V122.07Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M287.422 130.438H276.82V166.497H287.422V130.438Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M301.933 138.242H291.332V166.49H301.933V138.242Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M316.449 148.289H305.848V166.501H316.449V148.289Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M76.9659 22.4336C72.6278 19.1974 67.2341 17.2734 61.4056 17.2734C47.0163 17.2734 35.3594 28.9238 35.3594 43.305C35.3594 57.6862 47.0163 69.3366 61.4056 69.3366C75.7949 69.3366 87.4518 57.6862 87.4518 43.305C87.4518 36.4513 84.7993 30.2183 80.4701 25.5635"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M49.1367 55.2393C52.2506 58.4312 56.5975 60.4173 61.4058 60.4173C70.8626 60.4173 78.5186 52.7568 78.5186 43.3141C78.5186 33.8715 70.8537 26.2109 61.4058 26.2109C51.9578 26.2109 44.293 33.8715 44.293 43.3141C44.293 46.7897 45.3309 50.0259 47.1141 52.7302"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M61.4061 52.2396C66.3399 52.2396 70.3395 48.2422 70.3395 43.3112C70.3395 38.3802 66.3399 34.3828 61.4061 34.3828C56.4723 34.3828 52.4727 38.3802 52.4727 43.3112C52.4727 48.2422 56.4723 52.2396 61.4061 52.2396Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M288.662 220.873C291.527 221.281 292.849 223.506 292.849 226.671V227.31H290.383V226.503C290.383 224.411 289.54 223.134 287.651 223.134C285.796 223.134 284.954 224.419 284.954 226.503C284.954 231.796 292.885 231.832 292.885 238.473C292.885 241.647 291.536 243.899 288.671 244.307V246.098H286.515V244.307C283.579 243.899 282.266 241.673 282.266 238.473V237.018H284.732V238.641C284.732 240.769 285.61 241.975 287.5 241.975C289.425 241.975 290.268 240.76 290.268 238.641C290.268 233.383 282.372 233.312 282.372 226.671C282.372 223.435 283.685 221.307 286.524 220.873V218.984H288.68V220.873H288.662Z"
                  fill="#263238"
                />
                <path
                  d="M296.195 252.506C297.863 251.779 299.424 250.857 300.853 249.758"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M279.862 212.195C271.647 215.299 265.801 223.243 265.801 232.544C265.801 244.557 275.542 254.293 287.562 254.293C289.07 254.293 290.543 254.142 291.971 253.849"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M303.594 247.24C307.151 243.365 309.325 238.205 309.325 232.53C309.325 220.516 299.584 210.781 287.563 210.781C285.718 210.781 283.935 211.012 282.223 211.437"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M299.688 245.598C306.916 238.906 307.347 227.624 300.651 220.4C293.955 213.176 282.667 212.745 275.439 219.437C268.211 226.13 267.78 237.411 274.476 244.635C281.172 251.859 292.46 252.29 299.688 245.598Z"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M200.393 161.141C200.393 161.141 200.615 161.114 201.005 161.07C203.578 160.751 213.656 159.403 219.431 157.125C226.085 154.491 241.255 155.883 241.255 155.883L247.908 160.68L240.323 165.627C240.323 165.627 229.642 162.072 222.217 162.382C214.791 162.693 211.225 164.856 206.585 164.546C201.946 164.235 196.215 161.141 196.215 161.141H200.393Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M171.953 146.085V156.042H188.365V144.516L171.953 146.085Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M167.472 136.432C167.472 136.432 163.586 133.843 164.882 138.373C166.177 142.904 167.472 144.633 169.415 144.42C171.358 144.208 172.44 143.56 171.792 141.831C171.145 140.094 167.472 136.432 167.472 136.432Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M193.438 136.432C193.438 136.432 197.324 133.843 196.028 138.373C194.733 142.904 193.438 144.633 191.495 144.42C189.552 144.208 188.47 143.56 189.118 141.831C189.765 140.094 193.438 136.432 193.438 136.432Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M175.892 115.703C175.892 115.703 169.407 117.857 167.251 123.904C165.087 129.951 167.038 137.292 168.112 140.316C169.185 143.339 174.375 149.164 174.375 149.164H186.041C186.041 149.164 190.361 145.059 192.738 139.668C195.116 134.269 195.116 126.715 195.116 126.715C195.116 126.715 197.059 127.362 197.059 124.773C197.059 122.184 191.009 117.219 191.009 117.219C191.009 117.219 193.821 116.137 191.869 114.63C189.926 113.123 183.015 113.77 183.015 113.77C183.015 113.77 182.155 109.221 175.892 115.703Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M201.448 363.742L203.222 364.85C203.222 364.85 205.218 364.85 206.327 365.959C207.436 367.067 207.028 372.573 207.028 372.573C207.028 372.573 206.14 373.229 201.927 373.229C197.713 373.229 188.433 374.258 187.546 372.706C186.659 371.154 187.697 364.629 187.697 364.629L201.448 363.742Z"
                  fill="#858585"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M152.878 224.312C152.878 224.312 151.547 234.065 151.547 236.059C151.547 238.054 151.104 255.344 151.104 255.344C151.104 255.344 151.326 300.784 151.326 301.67C151.326 302.557 147.555 314.97 148.886 323.393C150.217 331.816 152.878 364.178 152.878 364.178C152.878 364.178 151.991 371.271 152.656 371.936C153.322 372.601 161.528 373.488 163.302 372.601C165.076 371.714 163.967 363.07 163.967 363.07C163.967 363.07 165.076 359.302 165.076 356.642C165.076 353.982 165.076 351.765 165.076 349.549C165.076 347.332 166.185 327.604 167.294 319.846C168.403 312.088 169.068 301.67 169.068 301.67L173.726 253.792L177.274 253.571C177.274 253.571 181.71 304.995 181.932 308.763C182.153 312.532 181.266 320.068 183.262 328.713C185.258 337.357 186.367 360.853 186.367 360.853C186.367 360.853 185.924 363.07 186.367 363.956C186.811 364.843 187.92 367.503 187.92 367.503C187.92 367.503 189.694 367.725 192.577 367.503C195.008 367.317 196.472 367.069 199.71 365.561C201.289 364.107 201.99 362.112 201.147 360.055C199.816 356.81 199.231 356.198 199.231 356.198C199.231 356.198 201.227 328.269 201.227 321.398C201.227 314.527 200.118 306.104 200.118 305.439C200.118 304.774 201.449 254.9 201.449 249.802C201.449 244.704 201.005 230.961 198.787 224.977C196.569 218.992 161.084 220.543 157.536 220.765C153.987 220.987 152.878 224.312 152.878 224.312Z"
                  fill="#263238"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M165.965 253.457L185.038 252.57"
                  stroke="#757575"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M177.055 155.602L165.079 158.483C165.079 158.483 147.78 166.906 144.01 169.123C140.239 171.339 125.38 201.263 126.045 202.815C126.71 204.366 129.594 206.361 132.477 206.805C135.36 207.248 149.998 190.624 149.998 190.624C149.998 190.624 154.212 211.46 154.212 212.346C154.212 213.233 151.772 223.872 152.881 225.646C153.99 227.419 196.572 225.646 196.572 225.646C196.572 225.646 199.677 225.867 200.121 222.986C200.564 220.104 199.234 211.681 199.234 211.681L204.113 186.19C204.113 186.19 213.437 200.323 215.521 202.593C219.15 206.548 223.665 204.89 226.069 201.928C228.163 199.348 226.291 194.392 225.626 192.84C224.96 191.289 213.206 164.911 209.657 163.803C206.109 162.695 191.249 157.375 191.249 157.375L177.055 155.602Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M165.078 158.484C165.078 158.484 179.734 158.183 182.173 158.183C184.613 158.183 193.52 158.103 193.52 158.103L191.266 153.307C191.266 153.307 186.609 151.977 179.512 151.977C172.415 151.977 167.092 154.193 167.092 154.193L165.078 158.484Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M145.481 178.938L149.997 190.623L142.234 182.865"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M144.008 175.109L144.549 176.501"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M168.962 210.414C168.19 211.052 167.418 211.629 166.629 212.125"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M189.252 176.883C189.252 176.883 181.144 199.04 170.285 209.254"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M209.21 173.555L204.109 186.189L212.315 177.323"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M124.047 208.359C124.047 208.359 125.599 213.457 130.479 217.226C135.358 220.994 148.443 233.407 148.443 233.407C148.443 233.407 148.221 234.293 148.443 235.402C148.665 236.51 152.213 239.391 152.213 239.391V237.175L153.1 235.402L153.988 233.185C153.988 233.185 156.205 232.298 156.871 231.855C157.536 231.412 157.98 230.747 157.98 230.747C157.98 230.747 160.419 231.19 161.972 231.19C163.524 231.19 165.742 229.638 164.411 229.195C163.081 228.752 161.75 229.195 159.976 228.308C158.202 227.422 157.314 226.535 155.54 226.535C153.766 226.535 150.883 226.535 150.883 226.535L138.685 206.586L124.047 208.359Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M129.299 194.727C129.299 194.727 126.708 195.498 126.043 196.163C125.378 196.828 122.051 201.926 122.716 204.586C123.382 207.246 124.269 211.014 130.035 212.122C135.802 213.231 138.907 210.792 139.35 210.127C139.794 209.462 140.885 201.616 139.998 201.616C139.111 201.616 136.689 204.586 136.689 204.586C136.689 204.586 130.08 203.088 128.749 201.979C127.418 200.871 129.299 194.727 129.299 194.727Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M231.046 208.358C231.046 208.358 229.494 213.456 224.614 217.224C219.735 220.993 206.65 233.406 206.65 233.406C206.65 233.406 206.872 234.292 206.65 235.4C206.428 236.509 201.54 239.922 201.54 239.922V236.695L201.203 234.656L201.105 233.193C201.105 233.193 198.888 232.306 198.222 231.863C197.557 231.42 197.113 230.755 197.113 230.755C197.113 230.755 194.674 231.198 193.121 231.198C191.569 231.198 189.351 229.646 190.682 229.203C192.012 228.76 193.343 229.203 195.117 228.316C196.891 227.43 197.779 226.543 199.553 226.543C201.327 226.543 204.21 226.543 204.21 226.543L216.408 206.594L231.046 208.358Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M226.723 195.819C226.723 195.819 228.382 195.5 229.048 196.165C229.713 196.83 233.04 201.928 232.374 204.588C231.709 207.247 230.822 211.016 225.056 212.124C219.289 213.232 216.184 210.794 215.741 210.129C215.297 209.464 214.942 201.919 215.519 202.593C216.557 203.798 218.402 204.588 218.402 204.588C218.402 204.588 225.277 204.233 226.36 200.349C226.821 198.683 226.723 195.819 226.723 195.819Z"
                  fill="white"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M163.305 372.608C164.706 371.907 164.307 366.375 164.076 364.078C162.932 364.61 161.184 365.266 159.091 365.514C155.32 365.958 152.881 364.185 152.881 364.185C152.881 364.185 152.171 371.854 152.774 372.572C153.422 373.352 161.53 373.494 163.305 372.608Z"
                  fill="#858585"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M320.687 373.504C320.492 373.504 320.332 373.345 320.332 373.15V327.417C320.332 327.222 320.492 327.062 320.687 327.062C320.882 327.062 321.042 327.222 321.042 327.417V373.15C321.042 373.345 320.882 373.504 320.687 373.504Z"
                  fill="#263238"
                />
                <path
                  d="M320.343 369.021C320.343 369.021 317.938 365.581 314.833 363.861C311.737 362.141 306.574 362.141 306.574 362.141C306.574 362.141 309.333 372.115 320.343 369.021Z"
                  fill="#263238"
                />
                <path
                  d="M320.688 369.021C320.688 369.021 323.092 365.581 326.197 363.861C329.293 362.141 334.456 362.141 334.456 362.141C334.456 362.141 331.697 372.115 320.688 369.021Z"
                  fill="#263238"
                />
                <path
                  d="M320.343 356.646C320.343 356.646 317.938 353.206 314.833 351.486C311.737 349.766 306.574 349.766 306.574 349.766C306.574 349.766 309.333 359.74 320.343 356.646Z"
                  fill="#263238"
                />
                <path
                  d="M320.688 356.646C320.688 356.646 323.092 353.206 326.197 351.486C329.293 349.766 334.456 349.766 334.456 349.766C334.456 349.766 331.697 359.74 320.688 356.646Z"
                  fill="#263238"
                />
                <path
                  d="M320.343 344.263C320.343 344.263 317.938 340.823 314.833 339.103C311.737 337.383 306.574 337.383 306.574 337.383C306.574 337.383 309.333 347.366 320.343 344.263Z"
                  fill="#263238"
                />
                <path
                  d="M320.688 344.263C320.688 344.263 323.092 340.823 326.197 339.103C329.293 337.383 334.456 337.383 334.456 337.383C334.456 337.383 331.697 347.366 320.688 344.263Z"
                  fill="#263238"
                />
                <path
                  d="M320.343 331.88C320.343 331.88 317.938 328.44 314.833 326.72C311.737 325 306.574 325 306.574 325C306.574 325 309.333 334.975 320.343 331.88Z"
                  fill="#263238"
                />
                <path
                  d="M320.688 331.88C320.688 331.88 323.092 328.44 326.197 326.72C329.293 325 334.456 325 334.456 325C334.456 325 331.697 334.975 320.688 331.88Z"
                  fill="#263238"
                />
                <path
                  d="M320.687 327.414C320.687 327.414 317.937 323.708 317.795 320.171C317.653 316.633 319.987 312.031 319.987 312.031C319.987 312.031 328.414 318.991 320.687 327.414Z"
                  fill="#263238"
                />
                <path
                  d="M17.4674 373.498C17.2545 373.498 17.0859 373.33 17.0859 373.117V323.678C17.0859 323.465 17.2545 323.297 17.4674 323.297C17.6803 323.297 17.8489 323.465 17.8489 323.678V373.117C17.8489 373.33 17.6803 373.498 17.4674 373.498Z"
                  fill="#263238"
                />
                <path
                  d="M17.096 368.658C17.096 368.658 14.4967 364.943 11.1433 363.081C7.79882 361.219 2.21875 361.219 2.21875 361.219C2.21875 361.219 5.19065 372 17.096 368.658Z"
                  fill="#263238"
                />
                <path
                  d="M17.4688 368.658C17.4688 368.658 20.068 364.943 23.4214 363.081C26.7659 361.219 32.346 361.219 32.346 361.219C32.346 361.219 29.3652 372 17.4688 368.658Z"
                  fill="#263238"
                />
                <path
                  d="M17.096 355.283C17.096 355.283 14.4967 351.568 11.1433 349.706C7.79882 347.844 2.21875 347.844 2.21875 347.844C2.21875 347.844 5.19065 358.625 17.096 355.283Z"
                  fill="#263238"
                />
                <path
                  d="M17.4688 355.283C17.4688 355.283 20.068 351.568 23.4214 349.706C26.7659 347.844 32.346 347.844 32.346 347.844C32.346 347.844 29.3652 358.625 17.4688 355.283Z"
                  fill="#263238"
                />
                <path
                  d="M17.096 341.9C17.096 341.9 14.4967 338.185 11.1433 336.323C7.79882 334.461 2.21875 334.461 2.21875 334.461C2.21875 334.461 5.19065 345.242 17.096 341.9Z"
                  fill="#263238"
                />
                <path
                  d="M17.4688 341.9C17.4688 341.9 20.068 338.185 23.4214 336.323C26.7659 334.461 32.346 334.461 32.346 334.461C32.346 334.461 29.3652 345.242 17.4688 341.9Z"
                  fill="#263238"
                />
                <path
                  d="M17.096 328.509C17.096 328.509 14.4967 324.794 11.1433 322.932C7.79882 321.07 2.21875 321.07 2.21875 321.07C2.21875 321.07 5.19065 331.861 17.096 328.509Z"
                  fill="#263238"
                />
                <path
                  d="M17.4688 328.509C17.4688 328.509 20.068 324.794 23.4214 322.932C26.7659 321.07 32.346 321.07 32.346 321.07C32.346 321.07 29.3652 331.861 17.4688 328.509Z"
                  fill="#263238"
                />
                <path
                  d="M17.4691 323.68C17.4691 323.68 14.4883 319.681 14.3464 315.851C14.1955 312.03 16.715 307.047 16.715 307.047C16.715 307.047 25.8259 314.574 17.4691 323.68Z"
                  fill="#263238"
                />
                <path
                  d="M49.4309 373.288V350.803C49.4309 350.59 49.2623 350.422 49.0494 350.422C48.8365 350.422 48.668 350.59 48.668 350.803V373.288H49.4309Z"
                  fill="#263238"
                />
                <path
                  d="M48.678 369.009C48.678 369.009 46.0787 365.294 42.7253 363.432C39.3809 361.57 33.8008 361.57 33.8008 361.57C33.8008 361.57 36.7727 372.361 48.678 369.009Z"
                  fill="#263238"
                />
                <path
                  d="M49.0508 369.009C49.0508 369.009 51.6501 365.294 55.0034 363.432C58.3479 361.57 63.928 361.57 63.928 361.57C63.928 361.57 60.9561 372.361 49.0508 369.009Z"
                  fill="#263238"
                />
                <path
                  d="M48.678 355.626C48.678 355.626 46.0787 351.911 42.7253 350.049C39.3809 348.188 33.8008 348.188 33.8008 348.188C33.8008 348.188 36.7727 358.969 48.678 355.626Z"
                  fill="#263238"
                />
                <path
                  d="M49.0508 355.626C49.0508 355.626 51.6501 351.911 55.0034 350.049C58.3479 348.188 63.928 348.188 63.928 348.188C63.928 348.188 60.9561 358.969 49.0508 355.626Z"
                  fill="#263238"
                />
                <path
                  d="M49.0511 350.797C49.0511 350.797 46.0703 346.799 45.9284 342.968C45.7776 339.147 48.297 334.164 48.297 334.164C48.297 334.164 57.4079 341.692 49.0511 350.797Z"
                  fill="#263238"
                />
                <path
                  d="M290.471 373.304V352.503C290.471 352.308 290.312 352.148 290.117 352.148C289.921 352.148 289.762 352.308 289.762 352.503V373.304H290.471Z"
                  fill="#263238"
                />
                <path
                  d="M289.772 369.349C289.772 369.349 287.368 365.909 284.263 364.189C281.167 362.469 276.004 362.469 276.004 362.469C276.004 362.469 278.763 372.443 289.772 369.349Z"
                  fill="#263238"
                />
                <path
                  d="M290.117 369.349C290.117 369.349 292.521 365.909 295.626 364.189C298.722 362.469 303.885 362.469 303.885 362.469C303.885 362.469 301.127 372.443 290.117 369.349Z"
                  fill="#263238"
                />
                <path
                  d="M289.772 356.966C289.772 356.966 287.368 353.526 284.263 351.806C281.167 350.086 276.004 350.086 276.004 350.086C276.004 350.086 278.763 360.061 289.772 356.966Z"
                  fill="#263238"
                />
                <path
                  d="M290.117 356.966C290.117 356.966 292.521 353.526 295.626 351.806C298.722 350.086 303.885 350.086 303.885 350.086C303.885 350.086 301.127 360.061 290.117 356.966Z"
                  fill="#263238"
                />
                <path
                  d="M290.117 352.5C290.117 352.5 287.367 348.794 287.225 345.256C287.083 341.719 289.416 337.117 289.416 337.117C289.416 337.117 297.844 344.077 290.117 352.5Z"
                  fill="#263238"
                />
                <path
                  d="M350.389 373.304V352.503C350.389 352.308 350.23 352.148 350.035 352.148C349.839 352.148 349.68 352.308 349.68 352.503V373.304H350.389Z"
                  fill="#263238"
                />
                <path
                  d="M349.69 369.349C349.69 369.349 347.286 365.909 344.181 364.189C341.085 362.469 335.922 362.469 335.922 362.469C335.922 362.469 338.681 372.443 349.69 369.349Z"
                  fill="#263238"
                />
                <path
                  d="M350.035 369.349C350.035 369.349 352.439 365.909 355.544 364.189C358.64 362.469 363.803 362.469 363.803 362.469C363.803 362.469 361.044 372.443 350.035 369.349Z"
                  fill="#263238"
                />
                <path
                  d="M349.69 356.966C349.69 356.966 347.286 353.526 344.181 351.806C341.085 350.086 335.922 350.086 335.922 350.086C335.922 350.086 338.681 360.061 349.69 356.966Z"
                  fill="#263238"
                />
                <path
                  d="M350.035 356.966C350.035 356.966 352.439 353.526 355.544 351.806C358.64 350.086 363.803 350.086 363.803 350.086C363.803 350.086 361.044 360.061 350.035 356.966Z"
                  fill="#263238"
                />
                <path
                  d="M350.035 352.5C350.035 352.5 347.285 348.794 347.143 345.256C347.001 341.719 349.334 337.117 349.334 337.117C349.334 337.117 357.762 344.077 350.035 352.5Z"
                  fill="#263238"
                />
                <path
                  d="M386.562 373.211H392.56"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M69.543 373.211H378.372"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.445312 373.211H61.3559"
                  stroke="#263238"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_908_18720">
                  <rect width="393" height="374" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="col-lg-8 right" style={{ display: "flex" }}>
            <div className="forgot" style={{ margin: "auto" }}>
              <form
                className="auth-form tp-forgot"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{
                  background: "#FFFFFF",
                  padding: "24px",
                  WebkitBackdropFilter: "blur(5px)",
                  backdropFilter: "blur(5px)",
                  borderRadius: "5px",
                  position: "relative",
                  maxWidth: "454px",
                  width: "100%",
                  // marginBottom: "55px",
                  // float: "right",
                  // marginTop: "20%",
                  // marginLeft: "30vh",
                  // marginRight: "100px",
                }}
              >
                <h2 style={{ color: "black" }}>Forgot Password</h2>
                <p style={{ fontSize: "14px" }}>
                  Enter your registered email address below, and we'll send you
                  a one-time password (OTP) to reset your password.
                </p>

                <FormInput
                  type="email"
                  label="Email"
                  onChange={handelChangeEmail}
                  placeholder="Enter Email"
                  error={errorMessage}
                  containerClassName={errorMessage ? "error-form" : ""}
                />
                <span className="use-phone-text">
                  {/* <Link to="/">Use phone number </Link> */}
                  <Link to={`/tp/portal/${param}/login`}>
                    <div
                      style={{
                        color: "rgb(4, 50, 163)",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Back to login
                    </div>
                  </Link>
                </span>

                <div className="text-center d-grid mt-5">
                  <div
                    className="btn btn-orange"
                    onClick={validation}
                    style={{ background: `${talentPoolData?.theme}` }}
                  >
                    Send OTP
                  </div>
                </div>
                {showErrorMessage && (
                  <label className="label-input100 text-danger">
                    {showErrorMessage}
                  </label>
                )}
                <div
                  className="d-flex justify-content-center mt-4"
                  style={{ height: "40px", alignItems: "center" }}
                >
                  <img
                    src={
                      talentPoolData?.icon
                        ? talentPoolData?.icon
                        : pagePlaceholder
                    }
                    alt="company-logo"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                  <h4 className="card-control-text-oneline ms-2">
                    {talentPoolData?.company_name}
                  </h4>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="minHeight"></div>
      </div>
    </div>
  );
}
