import React, { useState } from "react";
import building from "../../../assets/img/building-grey.svg";
import location from "../../../assets/img/location.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createMarkup } from "../../../core/components/Utility/Utility";
import "./PortalDashboard.scss";
const JobCard = (props) => {
  const { themeColor, jobs, param, isLoggedIn } = props;
  const navigate = useNavigate();
  const handleShare = () => {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    toast.success("Job URL Copied!", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const handleView = () => {
    if (isLoggedIn) {
      navigate("/portal/job/details", {
        state: { jobData: jobs, themeColor, param },
      });
    } else navigate(`/tp/portal/${param}/login`);
  };

  console.log(jobs);

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <div>
          <h4 className="job-title">{jobs?.title}</h4>
          <div className="d-flex  flex-column flex-sm-row">
            <div
              className="d-flex align-items-baseline"
              // style={{ width: "235px" }}
            >
              <img
                src={jobs?.comapny?.icon ? jobs?.comapny?.icon : building}
                alt="building"
              />
              <p className="ms-1 me-3 overflow-ellipsis text-secondary">
                {jobs?.company_name}
              </p>
            </div>
            <div
              className="d-flex align-items-baseline"
              // style={{ width: "235px" }}
            >
              <img src={location} alt="location" />
              <p className="ms-1 overflow-ellipsis text-secondary">
                {jobs?.job_location}
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* <img
            src={share}
            alt="share"
            style={{ cursor: "pointer" }}
            onClick={() => handleShare()}
          /> */}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => handleShare()}
          >
            <path
              d="M4.58214 12.4972C5.31102 12.495 6.01233 12.2183 6.54631 11.7222L11.763 14.703C11.5882 15.386 11.6652 16.1091 11.98 16.7399C12.2948 17.3708 12.8263 17.8671 13.4771 18.1382C14.128 18.4092 14.8546 18.4367 15.5241 18.2158C16.1936 17.9948 16.7611 17.5401 17.1228 16.9349C17.4844 16.3297 17.616 15.6145 17.4934 14.9202C17.3708 14.2259 17.0023 13.599 16.4553 13.1543C15.9082 12.7095 15.2193 12.4766 14.5147 12.4983C13.81 12.5199 13.1367 12.7947 12.618 13.2722L7.40131 10.2913C7.45631 10.083 7.48631 9.86716 7.49131 9.65133L12.6163 6.72216C13.1098 7.17114 13.7427 7.43675 14.4088 7.47443C15.0749 7.5121 15.7337 7.31956 16.2746 6.92909C16.8156 6.53863 17.2058 5.974 17.3798 5.32993C17.5538 4.68586 17.5011 4.00154 17.2303 3.39177C16.9596 2.78201 16.4874 2.28389 15.893 1.98099C15.2986 1.67809 14.618 1.58884 13.9656 1.72821C13.3131 1.86758 12.7285 2.22709 12.3097 2.74643C11.8909 3.26577 11.6634 3.91334 11.6655 4.5805C11.6688 4.8205 11.7013 5.05966 11.763 5.29133L7.02631 7.99716C6.75162 7.57221 6.37111 7.22604 5.92216 6.99264C5.4732 6.75925 4.97125 6.64666 4.46562 6.66595C3.95999 6.68525 3.46808 6.83575 3.03822 7.10267C2.60835 7.3696 2.25532 7.74376 2.01381 8.18841C1.77231 8.63305 1.65063 9.13287 1.66074 9.63877C1.67085 10.1447 1.81239 10.6392 2.07147 11.0739C2.33055 11.5085 2.69824 11.8683 3.13843 12.1178C3.57861 12.3674 4.07615 12.4981 4.58214 12.4972Z"
              fill={themeColor}
            />
          </svg>
          <input
            type="text"
            value={`${process.env.REACT_APP_WEB_STAGE_URL}jobdetails/${jobs?.id}`}
            id="myInput"
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title">Responsibilities</h5>
        {/* <p className="card-text"> */}
        <div
          dangerouslySetInnerHTML={createMarkup(jobs?.description)}
          style={{ wordBreak: "break-all" }}
          // className="my-3"
          className="job-description overflow-scroll text-secondary"
        ></div>
        {/* </p> */}
        {console.log(new Date() > new Date(jobs.expiry_date))}
        <div className="d-flex justify-content-between align-items-baseline">
          <div
            className="btn mt-3"
            style={{ backgroundColor: themeColor }}
            onClick={() => handleView()}
          >
            View and Apply
          </div>
          {new Date() > new Date(jobs.expiry_date) && (
            <p className="text-danger fw-bold">Expired</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
