import React, { useEffect, useRef, useState } from "react";
import Navbar from "../navbar/navbar";
import "./cv.scss";
import { EditResume } from "./EditResume";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteResume,
  getUserProfile,
  multiResume,
  updateUserDetails,
} from "../../store/profile/profileActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClassicTemplate, { classicRef } from "./CVTemplates/ClassicTemplate";
import FunctionalTemplate, {
  functionalRef,
} from "./CVTemplates/FunctionalTemplate";
import ModernTemplate, { modernRef } from "./CVTemplates/ModernTemplate";
import CombinedTemplate, { combinedRef } from "./CVTemplates/CombinedTemplate";
import { Resume } from "./Resume";

import axios from "axios";
import deleteIcon from "../../assets/img/deleteLeadIcon.svg";
import Dropzone from "./draganddrop/Drag";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import tickIcon from "../../assets/img/tick-icon.svg";

import {
  fetchCvPdf,
  fetchAllCvTypesPdf,
  fillInitialData,
} from "../../store/cv/cvActions";
import { toast, ToastContainer } from "react-toastify";
function Cv() {
  const classicRef = useRef();
  const functionalRef = useRef();
  const modernRef = useRef();
  const combinedRef = useRef();
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);
  const [uploadCvLoading, setUploadCvLoading] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
  const { isLoading, cvTypes, template, templates } = useSelector(
    (state) => state.cv
  );
  const [editResumeModal, seteditResumeModal] = useState(false);
  const [previewResumeModal, setPreviewResumeModal] = useState(false);
  const [totalPdfPages, setTotalPdfPages] = useState(false);
  const token = window.localStorage.getItem("accessToken");
  const [cvType, setCVType] = useState("classic");
  const [editCV, setEditCV] = useState();
  const [previewCV, setPreviewCV] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pdfUrl, setPdfUrl] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [userResumes, setUserResumes] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [resumes, setResumes] = useState([]);
  const navigate = useNavigate();
  const defaultColor = "#0432A3";
  const { state } = useLocation();
  console.log(state);

  useEffect(() => {
    dispatch(getUserProfile(user?.data?.id, token));
  }, []);

  useEffect(() => {
    setCVType(profileData?.cv_type || "classic");
  }, [profileData]);

  const handleCV = (e) => {
    e.preventDefault();
    if (
      profileData?.membership?.id > 1 ||
      (profileData?.membership?.id == 1 && e.target.value == "classic")
    ) {
      setCVType(e.target.value);
      dispatch(updateUserDetails({ cv_type: e.target.value }, token));
    } else {
      navigate("/membership");
    }
  };

  const handleEdit = (value) => {
    if (state && state?.fromSmartApply) {
      if (
        profileData?.membership?.id > 1 ||
        (profileData?.membership?.id == 1 && value == "classic")
      ) {
        setEditCV(value);
        seteditResumeModal(!editResumeModal);
      } else {
        navigate("/membership");
      }
    } else {
      toast.warning(
        "NobelPage doesn't store CV edits. Please download, save and upload your final version to keep it ready for applications."
      );
      if (
        profileData?.membership?.id > 1 ||
        (profileData?.membership?.id == 1 && value == "classic")
      ) {
        setEditCV(value);
        seteditResumeModal(!editResumeModal);
      } else {
        navigate("/membership");
      }
    }
  };

  const handlePreview = async (value) => {
    dispatch(initPageLoad());
    if (
      profileData?.membership?.id > 1 ||
      (profileData?.membership?.id === 1 && value === "classic")
    ) {
      const templateRef =
        value === "classic"
          ? classicRef.current
          : value === "functional"
          ? functionalRef.current
          : value === "combined"
          ? combinedRef.current
          : modernRef.current;

      const pixelRatio = 3;
      const canvas = await html2canvas(templateRef, { scale: pixelRatio });
      const pageHeight = 295;
      const imgWidth = 210;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const urls = [];
      let heightLeft = imgHeight;
      let position = 0;

      // const doc = new jsPDF("p", "mm");
      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        hotfixes: ["px_scaling"],
        // Add viewer preferences to hide controls and sidebar
        putOnlyUsedFonts: true,
        viewerPreferences: {
          HideMenubar: true,
          HideToolbar: true,
          HideWindowUI: true,
        },
      });

      while (heightLeft > 0) {
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        position -= pageHeight;
        heightLeft -= pageHeight;
        if (heightLeft > 0) doc.addPage();

        // urls.push(pdfDataUrl);
      }
      const pdfBlob = doc.output("blob");
      const pdfDataUrl = URL.createObjectURL(pdfBlob);
      const totalPages = doc.internal.getNumberOfPages();

      dispatch(stopPageLoad());
      setPdfUrl(pdfDataUrl);
      setPreviewResumeModal(true);
      setTotalPdfPages(totalPages);
    } else {
      navigate("/membership");
    }
  };

  const handleDeleteResume = () => {
    dispatch(
      updateUserDetails({ resume_url: "", resume_name: "" }, token)
    ).then((res) => {
      if (res?.success) {
        setFileName("");
        setFileUrl("");
      }
      setBannerImage("");
    });
  };

  const handleBannerPicture = async (e) => {
    try {
      e.preventDefault();
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      for (let i = 0; i < files?.length; i++)
        formData.append("image", files[i]);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        const resumes = res?.data?.filesArray?.map((_, i) => {
          return {
            resume_name: res?.data?.filesArray[i].originalname,
            resume_url: res?.data?.urlsArray[i],
          };
        });

        setResumes(resumes);
        // dispatch(
        //   updateUserDetails(
        //     {
        //       resume_url: res?.data?.urlsArray[0],
        //       resume_name: res?.data?.filesArray[0].originalname,
        //     },
        //     token
        //   )
        // );
        dispatch(multiResume({ resumes }));
        setFiles([]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownload = async (value) => {
    dispatch(initPageLoad());
    if (
      profileData?.membership?.id > 1 ||
      (profileData?.membership?.id === 1 && value === "classic")
    ) {
      const templateRef =
        value === "classic"
          ? classicRef.current
          : value === "functional"
          ? functionalRef.current
          : value === "combined"
          ? combinedRef.current
          : modernRef.current;

      const pixelRatio = 3;
      const canvas = await html2canvas(templateRef, { scale: pixelRatio });
      const pageHeight = 295;
      const imgWidth = 210;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const doc = new jsPDF("p", "mm");

      while (heightLeft > 0) {
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        position -= pageHeight;
        heightLeft -= pageHeight;
        if (heightLeft > 0) doc.addPage();
      }

      doc.save("Download.pdf");
      dispatch(stopPageLoad());
    } else {
      navigate("/membership");
    }
  };

  const handlePdfUpload = async () => {
    setUploadCvLoading(true);

    if (
      profileData?.membership?.id > 1 ||
      (profileData?.membership?.id === 1 && cvType === "classic")
    ) {
      // Assuming you have the base64 URL stored in a variable called `pdfBase64Url`
      const pdfBase64Url = cvTypes[cvType];

      // Function to convert base64 URL to Blob
      const base64ToBlob = (base64Url) => {
        const [metadata, base64Data] = base64Url?.split(",");
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const mimeType = metadata.split(":")[1].split(";")[0];
        return new Blob([byteArray], { type: mimeType });
      };

      const pdfBlob = base64ToBlob(pdfBase64Url);

      const formData = new FormData();
      formData.append("image", pdfBlob, "cv.pdf");

      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        setFileName(`Resume(${cvType}).pdf`);
        setFileUrl(res?.data?.urlsArray[0]);
        dispatch(
          updateUserDetails(
            {
              resume_url: res?.data?.urlsArray[0],
              resume_name: `Resume(${cvType}).pdf`,
            },
            token
          )
        );
      });
      setUploadCvLoading(false);
    } else {
      navigate("/membership");
    }
  };

  const handlePDFDownload = (cvType) => {
    if (profileData && cvTypes[cvType]) {
      let base64String = cvTypes[cvType];

      const downloadLink = document.createElement("a");
      downloadLink.href = base64String;
      downloadLink.download = `${profileData?.first_name}-${cvType}.pdf`;
      downloadLink.click();
    }
  };

  useEffect(() => {
    dispatch(fetchAllCvTypesPdf());
  }, []);

  useEffect(() => {
    if (profileData) {
      dispatch(
        fillInitialData({
          first_name: profileData?.first_name || "",
          last_name: profileData?.last_name || "",
          profile_img: profileData?.profile_img || "",
          email: profileData?.email || "",
          phone: profileData?.phone || "",
          profile_url: profileData?.profile_url || "",
          address: profileData?.address || "",
          about: profileData?.about || "",
          linkedin_url: "",
          altranate_phone: "",
          profile_headline: profileData?.profile_headline || "",

          altranate_email: "",
          experiences: profileData?.experiences || [],
          educations: profileData?.educations || [],
          exps_and_skills: profileData?.exps_and_skills || [],
          languages: profileData?.languages || [],
          honors_and_awards: profileData?.honors_and_awards || [],
          cv_skillColor: profileData?.cv_skillColor || "#0432a3",
          cv_color: profileData?.cv_color || "#0432a3",
        })
      );
      // setFileName(profileData?.resume_name);
      // setFileUrl(profileData?.resume_url);

      setUserResumes(profileData?.resumes);
    }
  }, [profileData]);

  useEffect(() => {
    if (editCV) {
      dispatch(fetchCvPdf(editCV, template[editCV]));
    }
  }, [pageNo, editCV]);
  console.log(cvTypes);
  return (
    <div>
      <Navbar isJobs={false} />
      {isPageLoading ? (
        <AppLoader />
      ) : (
        <>
          <div className="resume admin-bg">
            <div className="container pt-26">
              <div className="bg-white p-30 main-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="top">
                      <h3 className="resume-title">
                        <span className="text-blue">ATS-Friendly</span> Resume /
                        CV
                      </h3>
                      <p className="resume-info">
                        Utilize NobelPage's dedicated ATS-friendly template for
                        seamless job application submissions. By selecting this
                        option, you can also customize colors according to your
                        preference.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="row header mb-30 resume-card-container"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="col-lg-6  mb-5 default">
                    <div className="row">
                      <div className="col-6">
                        <h2>Classic</h2>
                      </div>
                      <div className="col-6">
                        <div className="float-end">
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              Make Default
                            </label>
                            <input
                              type="radio"
                              name="cv_type"
                              value="classic"
                              onChange={(e) => handleCV(e)}
                              checked={cvType == "classic"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="first">
                      <div>
                        <ClassicTemplate
                          classicRef={classicRef}
                          userData={template["classic"]}
                          cvUrl={cvTypes["classic"]}
                        />
                      </div>

                      <div className="container download">
                        <div className="d-flex">
                          <div className="">
                            <div
                              className="prev"
                              onClick={() => {
                                setPreviewCV("classic");
                                setPreviewResumeModal(true);
                              }}
                            >
                              <div className="icon"></div>
                              <p>Preview</p>
                            </div>
                          </div>
                          <div className="mx-5">
                            <div
                              className="edit"
                              onClick={() => handleEdit("classic")}
                            >
                              <div className="icon"></div>

                              <p>Edit</p>
                            </div>
                          </div>
                          <div className="mx-3">
                            <div
                              className="down"
                              onClick={() => {
                                // handleDownload("classic");
                                handlePDFDownload("classic");
                              }}
                            >
                              <div className="icon"></div>

                              <p>Download</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-6 mb-5 "> */}
                  <div className="col-lg-6 mb-5 default ">
                    <div className="row ">
                      <div className="col-6">
                        <h2>Functional</h2>
                      </div>
                      <div className="col-6">
                        <div className="float-end">
                          <div
                            className={
                              profileData?.membership?.id == 1
                                ? "d-none"
                                : "form-check"
                            }
                          >
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              Make Default
                            </label>
                            <input
                              disabled={profileData?.membership?.id == 1}
                              type="radio"
                              name="cv_type"
                              value="functional"
                              onChange={(e) => handleCV(e)}
                              checked={cvType == "functional"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="second">
                      <div>
                        <FunctionalTemplate
                          functionalRef={functionalRef}
                          userData={template["functional"]}
                          memberShip={profileData?.membership?.id}
                          cvUrl={cvTypes["functional"]}
                        />
                      </div>
                      <div
                        className={
                          profileData?.membership?.id == 1
                            ? "container download disable"
                            : "container download"
                        }
                      >
                        <div className="d-flex">
                          <div className="">
                            <button
                              className="prev bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                setPreviewCV("functional");
                                setPreviewResumeModal(true);
                              }}
                            >
                              <div className="icon"></div>
                              <p>Preview</p>
                            </button>
                          </div>
                          <div className="mx-5">
                            <button
                              className="edit bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => handleEdit("functional")}
                            >
                              <div className="icon"></div>

                              <p>Edit</p>
                            </button>
                          </div>
                          <div className="mx-3">
                            <button
                              className="down bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                // handleDownload("functional");
                                handlePDFDownload("functional");
                              }}
                            >
                              <div className="icon"></div>

                              <p>Download</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row header mt-30 resume-card-container"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="col-lg-6 mb-5 default">
                    <div className="row">
                      <div className="col-6">
                        <h2>Modern</h2>
                      </div>
                      <div className="col-6">
                        <div className="float-end">
                          <div
                            className={
                              profileData?.membership?.id == 1
                                ? "d-none"
                                : "form-check"
                            }
                          >
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              Make Default
                            </label>
                            <input
                              disabled={profileData?.membership?.id == 1}
                              type="radio"
                              name="cv_type"
                              value="modern"
                              onChange={(e) => handleCV(e)}
                              checked={cvType == "modern"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="third">
                      <div>
                        <ModernTemplate
                          modernRef={modernRef}
                          userData={template["modern"]}
                          memberShip={profileData?.membership?.id}
                          cvUrl={cvTypes["modern"]}
                        />
                      </div>

                      <div
                        className={
                          profileData?.membership?.id == 1
                            ? "container download disable"
                            : "container download"
                        }
                      >
                        <div className="d-flex">
                          <div className="">
                            <button
                              className="prev bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                setPreviewCV("modern");
                                setPreviewResumeModal(true);
                              }}
                            >
                              <div className="icon"></div>
                              <p>Preview</p>
                            </button>
                          </div>
                          <div className="mx-5">
                            <button
                              className="edit bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => handleEdit("modern")}
                            >
                              <div className="icon"></div>

                              <p>Edit</p>
                            </button>
                          </div>
                          <div className="mx-3">
                            <button
                              className="down bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                // handleDownload("modern");
                                handlePDFDownload("modern");
                              }}
                            >
                              <div className="icon"></div>

                              <p>Download</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-5 default">
                    <div className="row ">
                      <div className="col-6">
                        <h2>Combined</h2>
                      </div>
                      <div className="col-6">
                        <div className="float-end">
                          <div
                            className={
                              profileData?.membership?.id == 1
                                ? "d-none"
                                : "form-check"
                            }
                          >
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              Make Default
                            </label>
                            <input
                              disabled={profileData?.membership?.id == 1}
                              type="radio"
                              name="cv_type"
                              value="combined"
                              onChange={(e) => handleCV(e)}
                              checked={cvType == "combined"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fourth">
                      <div>
                        <CombinedTemplate
                          combinedRef={combinedRef}
                          userData={template["combined"]}
                          memberShip={profileData?.membership?.id}
                          cvUrl={cvTypes["combined"]}
                        />
                      </div>
                      <div
                        className={
                          profileData?.membership?.id == 1
                            ? "container download disable"
                            : "container download"
                        }
                      >
                        <div className="d-flex">
                          <div className="">
                            <button
                              className="prev bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                setPreviewCV("combined");
                                setPreviewResumeModal(true);
                              }}
                            >
                              <div className="icon"></div>
                              <p>Preview</p>
                            </button>
                          </div>
                          <div className="mx-5">
                            <button
                              className="edit bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => handleEdit("combined")}
                            >
                              <div className="icon"></div>

                              <p>Edit</p>
                            </button>
                          </div>
                          <div className="mx-3">
                            <button
                              className="down bg-transparent"
                              disabled={profileData?.membership?.id == 1}
                              onClick={() => {
                                // handleDownload("combined");
                                handlePDFDownload("combined");
                              }}
                            >
                              <div className="icon"></div>

                              <p>Download</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {uploadCvLoading ? (
                  <AppLoader />
                ) : (
                  <div className="">
                    {console.log(files, "ghghgh")}
                    <div className="edit-form">
                      <h5 className="text-dark">Upload Custom Resume</h5>
                      <form className="d-flex align-items-center">
                        <div
                          // style={{height:"500px"}}
                          className="mt-10"
                        >
                          <button
                            className="btn"
                            style={
                              files?.length
                                ? {
                                    border: "2px solid #0432A3",
                                    color: "#0432A3",
                                  }
                                : {}
                            }
                            onClick={(e) => handleBannerPicture(e)}
                            disabled={files?.length > 0 ? false : true}
                          >
                            Upload Resume
                          </button>
                        </div>
                        <div className="drag-box cv_drop_box mx-3">
                          {/* {resumes?.length > 0 ? (
                            resumes?.map((ele, i) => (
                              <div className="d-flex justify-content-center align-items-center pt-3">
                                <span>{ele?.resume_name}</span>
                                <span
                                  className="mx-3 fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {}}
                                >
                                  X
                                </span>
                              </div>
                            ))
                          ) : ( */}
                          <Dropzone
                            // handleBannerPicture={handleBannerPicture}
                            // bannerImage={bannerImage}
                            // deletePicture={handleDeleteResume}
                            setFiles={setFiles}
                          />
                          {/* )} */}
                        </div>
                      </form>
                    </div>
                    <div className="line mt-3"></div>
                    {console.log(profileData?.resumes)}
                    {profileData?.resumes?.length > 0 ? (
                      profileData?.resumes?.map((ele, i) => (
                        <div className="mt-3">
                          <span className="d-flex align-items-baseline">
                            <img src={tickIcon} width={15} height={15} />
                            <a href={ele?.resume_url} target="_blank">
                              <span className="text-secondary mx-3">
                                {ele?.resume_name}
                              </span>
                            </a>
                            <span>
                              <img
                                src={deleteIcon}
                                onClick={() => dispatch(deleteResume(ele?.id))}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </span>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {editResumeModal && (
            <EditResume
              modal={editResumeModal}
              toggle={() => seteditResumeModal(!editResumeModal)}
              // seteditResumeModal={seteditResumeModal}
              loader={isPageLoading}
              title="Edit Resume"
              editCV={editCV}
              pageNo={pageNo}
              setPageNo={setPageNo}
              user={template[editCV]}
              token={token}
              skillBackground={profileData?.cv_skill_color || defaultColor}
              interestBackground={
                profileData?.cv_interest_color || defaultColor
              }
              color={profileData?.cv_color || defaultColor}
              setPreviewCV={setPreviewCV}
              setPreviewResumeModal={setPreviewResumeModal}
              // handleColor={handleColor}
              // handleSkillBgColor={handleSkillBgColor}
              // handleInterestBgColor={handleInterestBgColor}
            />
          )}

          {previewResumeModal && (
            <Resume
              previewResumeModal={previewResumeModal}
              toggle={() => setPreviewResumeModal(!previewResumeModal)}
              // setPreviewResumeModal={setPreviewResumeModal}
              // user={profileData}
              // previewCV={isPageLoading}
              // skillBackground={skillBackground}
              // setSkillBackground={setSkillBackground}
              // interestBackground={interestBackground}
              // setinterestBackground={setinterestBackground}
              // setColor={setColor}
              // color={color}
              url={cvTypes[previewCV]}
              isLoading={isLoading}
            />
          )}
        </>
      )}
      <ToastContainer />
    </div>
  );
}

export default Cv;
