import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import poll from "../../../../assets/img/polling.svg";
import dmoj from "../../../../assets/img/emojee.svg";
import bx from "../../../../assets/img/bx_image.svg";
import bj from "../../../../assets/img/bi_camera-video.svg";
import paper from "../../../../assets/img/Paperclip.svg";
import party from "../../../../assets/img/party 1.svg";
import CreatePollModal from ".././poll/CreatePollModal";
import "./createPost.scss";
import {
  createPost,
  getPageDetails,
  updatePost,
} from "../../../../store/dashboard/dashboardActions";
import EmojiPicker from "emoji-picker-react";
import SelectOptionModal from "../CelebrationPostModals/SelectOption";
import SelectReceipent from "../CelebrationPostModals/selectReceipent";
import VisibilityModal from "../Visibility/VisibilityModal";
import PublishAsModal from "../PublishAsModal";
import ScheduleModal from "../ScheduleModal";
import pub from "../../../../assets/img/public.svg";
import axios from "axios";
import PollComponent from ".././poll/PollComponent";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import ShowImage from "../ShowImage";
import ShowCelebration from "../CelebrationPostModals/ShowCelebration";
import SelectCelebImage from "../CelebrationPostModals/SelectCelebImage";
import DiscardEventModal from "../../../mynetwork/Events/components/DiscardModal";
import "../../dashboard.scss";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../../store/loader/loaderActions";
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import { getHashTagList } from "../../../../store/master/masterActions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { getSuggestedGroups } from "../../../../store/groups/groupsActions";
import SelectGroup from "../Visibility/SelectGroup";
import AlertEditModal from "./alertEditModal";
import people from "../../../../assets/img/people.svg";
import union from "../../../../assets/img/Union.svg";
import profileadd from "../../../../assets/img/fluent_people.svg";
import watchIcon from "../../../../assets/img/Watch-icon.svg";
import { toast } from "react-toastify";
import NewsletterPreviewModal from "../../../mynetwork/Newsletter/components/newsletterPreview";
import GifModal from "../../../../core/components/common/GifModal";
import { getReadingTime } from "../../../../core/components/Utility/Utility";
import { Mention, MentionsInput } from "react-mentions";
import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defaultMentionStyle";
import { call } from "../../../../api/apiCall";

const CreatePostModal = (props) => {
  const {
    modal,
    toggle,
    title,
    userName,
    userId,
    createPostClick,
    discardModalStatus,
    closeAllModal,
    updateData,
    isEdit,
    saveDraft,
    isDataAvailable,
    setDataAvailable,
    setDraftDetails,
    toggleOff,
    dashboardData,
    isPagePosts,
    isGroupPosts,
    refGroupName,
    setCommentBoxStatus,
    type,
    isHashtagPost,
    keyword,
  } = props;
  const [createPollModal, setCreatePollModal] = useState(false);
  const [visibiltyModal, setVisibiltyModal] = useState(false);
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const [publishAsModal, setPublishAsModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [selectOptionModal, setSelectOptionModal] = useState(false);
  const [selectReceipent, setSelectReceipent] = useState(false);
  const [selectCelebImage, setSelectCelebImage] = useState(false);
  const [nPMemeber, setNPMember] = useState(true);
  const [showPostInfo, setShowPostInfo] = useState(false);
  const [image, setImage] = useState([]);
  const [pollData, setPollData] = useState({});
  const [isEditPoll, setEditPoll] = useState(false);
  const [profileImg, setProfileImg] = useState(dashboardData?.profile_img);
  const { getPageDetailData } = useSelector((state) => state.dashboard);
  const { joined_groups } = useSelector((state) => state.groups);
  const isPostNowPreview = useSelector(
    (state) => state?.postDataDetail?.isPostNowPreview
  );
  const { user } = useSelector((state) => state.auth);
  const [isData, setData] = useState({
    title: "",
    id: 0,
    images: [],
    image_text: "",
    selectedImg: "",
    data: [],
  });
  const [celebrationData, setCelebrationData] = useState({});
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isText, setIsText] = useState(false);
  const [fileName, setFileName] = useState("");
  const [sharedByType, setSharedByType] = useState("user");
  const [privacyId, setPrivacyId] = useState(
    isEdit > 0 ? updateData?.privacy_id : 1
  );
  const [visibileAs, setVisibileAs] = useState("Public");
  const [visibleIcon, setVisibleIcon] = useState(pub);
  const [groupName, setGroupName] = useState(
    updateData?.privacy_id === 3
      ? updateData.Group.name
      : isGroupPosts
      ? refGroupName
      : ""
  );
  const [groupId, setGroupId] = useState();
  const [publishUserId, setPublishUserId] = useState(
    updateData?.shared_by || userId
  );
  const [pageName, setPageName] = useState(
    updateData?.shared_by_type === "page" ? updateData?.Page.name : ""
  );
  const [isHovering, setIsHovering] = useState(false);
  const [scheduledData, setScheduledData] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [hashtagList, setHashtagList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [expand, setExpand] = useState(false);
  // const [tagDetails, setTagDetails] = useState(
  //   updateData?.Tags ? updateData?.Tags : []
  // );
  const [previewModal, setPreviewModal] = useState(false);
  const [tagDetails, setTagDetails] = useState([]);
  const [scheduleMessage, setScheduleMessage] = useState();
  const [visiblePersons, setVisiblePersons] = useState([]);
  const imgRef = useRef();
  const vidRef = useRef();
  const textAreaRef = useRef();
  const navigate = useNavigate();
  let token = window.localStorage.getItem("accessToken");
  const [postDetails, setPostDetails] = useState(
    !isEdit
      ? {
          type: "post", //poll,celebrationa
          description: "",
          feed_status: "published",
          feed_documents: [],
          tags: [],
          privacy_id: updateData?.privacy_id,
          shared_by: publishUserId,
          shared_by_type: updateData?.shared_by_type,
          scheduled_time: Date,
          hashtags: [],
        }
      : {
          // type: "post",
          id: 0,
          description: "",
          // feed_status: "published",
          tags: [],
          privacy_id: privacyId,
          shared_by: publishUserId,
          shared_by_type: sharedByType,
          scheduled_time: Date,
        }
  );
  const setVisibility = () => {
    if (privacyId === 1) {
      setVisibileAs("Public");
      setVisibleIcon(pub);
      setPostDetails({
        ...postDetails,
        privacy_id: 1,
      });
    }
    if (privacyId === 2) {
      setVisibileAs("Connections Only");
      setVisibleIcon(union);
      setPostDetails({
        ...postDetails,
        privacy_id: 2,
      });
      setSharedByType("user");
    }
    if (privacyId === 3 || isGroupPosts) {
      setVisibileAs(groupName);
      setVisibleIcon(people);
      setPostDetails({
        ...postDetails,
        privacy_id: 3,
      });
      setSharedByType("group");
    }
    if (privacyId === 4) {
      setVisibileAs("Specific Persons");
      setVisibleIcon(profileadd);
      let visible = [userId];
      for (let i = 0; i < visiblePersons?.length; i++) {
        visible.push(visiblePersons[i]);
      }
      setPostDetails({
        ...postDetails,
        privacy_id: 4,
        visibility_ids: visible,
      });
      setSharedByType("user");
    }
  };
  const handleMouseOver = () => {
    setIsHovering(!isHovering);
  };

  useEffect(() => {
    if (isHashtagPost) {
      postDetails.description = `#${keyword}`;
      setPostDetails({ ...postDetails });
    }
  }, [isHashtagPost, keyword]);

  useEffect(() => {
    let hashtags = postDetails?.description
      ?.replace(/\n/g, " \n")
      ?.split(" ")
      ?.filter((v) => v.startsWith("#") && v?.length > 1);
    setPostDetails({
      ...postDetails,
      hashtags: hashtags?.map((s) => s.slice(1)),
    });
  }, [postDetails?.description]);

  useEffect(() => {
    if (postDetails.feed_documents?.length === 0) {
      setIsImage(false);
      setIsText(false);
      setIsVideo(false);
    }
  }, [postDetails.feed_documents]);

  useEffect(() => {
    if (updateData && isEdit > 0) {
      postDetails.id = isEdit;
      postDetails.description = updateData.description;
      postDetails.tags = [];
      postDetails.privacy_id = privacyId;
      postDetails.shared_by = publishUserId;
      postDetails.shared_by_type = sharedByType;
      postDetails.scheduled_time = Date;
      setPostDetails({ ...postDetails });
    }

    if (updateData && isEdit > 0 && updateData?.Feed_Documents?.length > 0) {
      if (updateData?.Feed_Documents[0].document_type == "attachment") {
        setIsText(true);
        setIsVideo(false);
        setIsImage(false);
      } else if (updateData?.Feed_Documents[0]?.document_type == "video") {
        setIsText(false);
        setIsVideo(true);
        setIsImage(false);
      } else {
        setIsText(false);
        setIsVideo(false);
        setIsImage(true);
      }
      let img = [];
      for (let i = 0; i < updateData?.Feed_Documents?.length; i++) {
        img.push(updateData?.Feed_Documents[i]?.document_url);
      }
      setImage(img);
    }

    // if (
    //   updateData &&
    //   isEdit > 0 &&
    //   updateData?.Feed_Documents?.length > 0 &&
    //   updateData?.Tags?.length > 0
    // ) {
    //   let tags = [];
    //   for (let i = 0; i < updateData?.Tags?.length; i++) {
    //     // console.log("updateData?.Tags", updateData?.Tags[i]);
    //     tags.push(updateData?.Tags[i]);
    //   }
    //   setTagDetails(tags);
    //   // console.log("tagskkk",tags);
    // }

    if (createPostClick && createPostClick !== "") {
      if (createPostClick === "photo") {
        setTimeout(() => {
          try {
            imgRef.current.click();
          } catch (error) {
            console.log(error);
          }
        }, 0);
      } else if (createPostClick === "video") {
        setTimeout(() => {
          try {
            vidRef.current.click();
          } catch (error) {
            console.log(error);
          }
        }, 0);
      }
    }

    if (updateData && updateData.shared_by_type === "page" && isEdit > 0) {
      setProfileImg(updateData.Page.icon);
    }
  }, []);

  useEffect(() => {
    textAreaRef?.current?.refInput?.current?.focus();
    dispatch(getPageDetails(token));
    dispatch(getSuggestedGroups("my", { filter: "my" }));
  }, []);

  useEffect(() => {
    if (
      (postDetails?.description?.trim(" ") !== "" ||
        postDetails?.feed_documents?.length > 0 ||
        postDetails?.tags.length > 0) &&
      !isSubmitted &&
      isEdit === 0
    ) {
      setDraftDetails(postDetails);
      setDataAvailable(true);
    } else {
      setDataAvailable(false);
    }
  }, [isDataAvailable, postDetails, isEdit, isSubmitted, publishUserId]);

  const handleRequestOptions = (keyword) => {
    dispatch(getHashTagList({ search: keyword }))
      .then((res) => {
        setHashtagList(res?.data?.map((a) => a?.name));
      })
      .catch(() => {
        setHashtagList([]);
      });
  };

  useEffect(() => {
    setVisibility();
  }, [privacyId, groupName, visiblePersons]);
  useEffect(() => {
    if (groupName) {
      setProfileImg(dashboardData?.profile_img);
      // setUserName(groupName);
      setPageName("");
      setSharedByType("group");
      setPublishUserId(groupId);
    }
  }, [groupName]);
  const [showEmoji, setShowEmoji] = useState(false);

  const dispatch = useDispatch();
  const createMyPost = () => {
    const regex = /\((\d+)\)/g;
    let matches;
    const tags = [];

    while ((matches = regex.exec(postDetails.description)) !== null) {
      tags.push({ media: null, user_id: parseInt(matches[1], 10) });
    }

    console.log(tags);

    setIsSubmitted(true);
    setDataAvailable(false);
    try {
      if (celebrationData?.id) {
        postDetails["celebration_details"] = {
          celebration_type: celebrationData?.id,
          media: celebrationData.media,
          celebration_tags: celebrationData?.celebration_tags?.length
            ? celebrationData?.celebration_tags?.map((item) => item.user_id)
            : [],
        };
        postDetails.type = "celebration";
        postDetails.description += celebrationData?.title;
      }
      if (pollData?.question) {
        postDetails["poll_details"] = {
          options: pollData?.option,
          duration: pollData?.poll_duration,
          question: pollData?.question,
        };
        postDetails.type = "poll";
      }

      if (tagDetails.length > 0) {
        tagDetails?.map((item) => {
          postDetails.tags.push(item);
        });
      }
      if (scheduledData) {
        postDetails.scheduled_time = scheduledData;
        postDetails.feed_status = "scheduled";
      }
      if (isPagePosts) {
        //getting pageID as userId from pages
        postDetails.shared_by = userId;
        postDetails.shared_by_type = "page";
      } else if (isGroupPosts === true) {
        postDetails.shared_by = userId;
        postDetails.shared_by_type = "group";
      } else {
        postDetails.shared_by = publishUserId;
        postDetails.shared_by_type = sharedByType;
      }
      if (updateData && isEdit > 0) {
        if (isPostNowPreview) {
          dispatch(
            updatePost(
              { ...postDetails, feed_status: "published" },
              token,
              toggleOff
            )
          );
          toast.success("Posted Sucessfully!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } else {
          dispatch(updatePost(postDetails, token, toggleOff)).then((res) => {
            toast.success("Post has been updated Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });
        }
        setIsSubmitted(true);
        toggle();
      } else {
        if (scheduleMessage) {
          dispatch(createPost(postDetails, token, toggleOff));
          toast.success("Post has been Scheduled Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setIsSubmitted(true);
          setCommentBoxStatus([]);
        } else {
          dispatch(createPost(postDetails, token, toggleOff)).then((res) => {
            toast.success("Post has been created Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setIsSubmitted(true);
            setCommentBoxStatus([]);
          });
        }

        // setIsSubmitted(true);
        // setCommentBoxStatus([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isSubmitDisabled = () => {
    if (
      postDetails?.description?.trim(" ").length > 0 ||
      postDetails?.feed_documents?.length > 0 ||
      postDetails?.tags.length > 0 ||
      Object.keys(celebrationData).length > 0 ||
      postDetails?.poll != null
    ) {
      return true;
    } else return false;
  };

  const handleClose = async (img) => {
    setImage(image.filter((item) => item !== img));
    let temp = postDetails.feed_documents.filter(
      (item) => item.document_url !== img
    );
    postDetails.feed_documents = temp;
    setPostDetails({ ...postDetails });
    const headers = {
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    };
  };

  const onEmojiClick = (emojiData, event) => {
    postDetails.description += emojiData.emoji;
    setPostDetails({ ...postDetails });
    // setShowEmoji(false)
  };
  const handleChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let videoValid = /^(mp4|MP4)$/;
    let ImageValid = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    let TextValid = /^(pdf|ppt|pptx|txt|^text\/plain|doc|docx|xls|xlsx)$/;
    if (e.target.id === "chosefile1") {
      setIsImage(true);
      setIsVideo(false);
      setIsText(false);
      if (ImageValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (e.target.id === "chosefile2") {
      setIsVideo(true);
      setIsText(false);
      setIsImage(false);
      if (videoValid.test(fileExtension) == false) {
        validFile = false;
      }
    } else if (e.target.id === "chosefile3") {
      setIsText(true);
      setIsImage(false);
      setIsVideo(false);
      if (TextValid.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initPageLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopPageLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setImage(urlsArray);
          // setFileName(filesArray[0].originalname);
          for (let index = 0; index < filesArray.length; index++) {
            postDetails.feed_documents.push({
              document_type: type,
              document_url: urlsArray[index],
            });
          }
          setPostDetails({ ...postDetails });
        });
        //  e.target.files = ''
      } catch (err) {
        if (err.response.data.message === "File too large") {
          // alert("File is too large, please upload another file");
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopPageLoad());
          setIsImage(false);
          setIsText(false);
          setIsVideo(false);
          return;
        }
        console.log(err);
      }
    } else {
      // alert("Please choose valid file type");
      toast.warn("Please choose valid file type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setIsImage(false);
      setIsText(false);
      setIsVideo(false);
      return;
    }
  };

  const handleClickTag = () => {
    // textAreaRef?.current.refInput.current.focus();
    // if (celebrationData.title) {
    //   setCelebrationData({
    //     ...celebrationData,
    //     title: celebrationData.title + " #",
    //   });
    // } else
    if (postDetails?.description?.slice(-1) != "#")
      setPostDetails({
        ...postDetails,
        description: postDetails.description + " #",
      });
  };

  //  share on twitter
  const shareOnTwitter = (event) => {
    if (event.target.checked === true) {
      // window.open("https://twitter.com/share")
      alert("This feature will be implemented in the future milestone");
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     textAreaRef?.current.refInput.current.focus();
  //   }, 1000);
  // }, []);

  useEffect(() => {}, [selectedImage]);

  const mainArea = useMemo(() => {
    return (
      <ShowImage
        image={image}
        isImage={isImage}
        isVideo={isVideo}
        isText={isText}
        fileName={fileName}
        handleClose={handleClose}
        tagDetails={tagDetails}
        setTagDetails={setTagDetails}
        isEdit={isEdit}
      ></ShowImage>
    );
  }, [image]);

  const fetchHash = useCallback(
    debounce((query, callback) => {
      try {
        call(
          "get",
          `api/v1/feed/mention-hash`,
          { search: query, limit: 10, skip: 0 },
          null,
          token
        )
          .then((res) => {
            if (res.data.success && res.data.data.length > 0) {
              return res.data.data;
            } else {
              return [{ id: null, display: query }];
            }
          })
          .then(callback);
      } catch (error) {
        console.log(error);
      }
    }, 1000),
    []
  );

  const fetchUser = useCallback(
    debounce((query, callback) => {
      call(
        "get",
        `api/v1/user/mention-list`,
        { search: query, limit: 10, skip: 0 },
        null,
        token
      )
        .then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            return res.data.data;
          } else {
            return [];
          }
        })
        .then(callback);
    }, 1000),
    []
  );

  return (
    <div>
      <Modal
        zIndex={10}
        isOpen={modal}
        size={expand ? "xl" : "md"}
        style={{ maxWidth: expand ? "950px" : "600px", top: "19px" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="create-post">
          <div>
            <ModalHeader
              style={{ alignItems: "center !important" }}
              toggle={isPagePosts ? closeAllModal : toggle}
            >
              {title}
              <div
                className={expand ? "icon-collapse" : "icon-expand"}
                onClick={() => setExpand(!expand)}
              ></div>
            </ModalHeader>
            <ModalBody>
              <div className="createpost">
                <div className="row  mb-20 align-items-center">
                  <div className="col-lg-2 col-md-2 col-3 profileImageClass">
                    <div className="float-start">
                      <div className="pro-img">
                        {isPagePosts ? (
                          <img
                            src={
                              dashboardData ? dashboardData : placeholderUser
                            }
                            alt="profile img"
                            className="image-fit"
                          />
                        ) : (
                          <img
                            src={profileImg ? profileImg : placeholderUser}
                            alt="profile img"
                            className="image-fit"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-4 create-post-user"
                    onClick={() => {
                      isPagePosts ||
                      isGroupPosts ||
                      updateData?.shared_by_type === "page" ||
                      updateData?.shared_by_type === "group" ||
                      privacyId === 3 ||
                      getPageDetailData?.data?.Pages?.length <= 0
                        ? setPublishAsModal(false)
                        : setPublishAsModal(!publishAsModal);
                    }}
                  >
                    <div
                      className={
                        "name createPostNameSelect " +
                        (isPagePosts ||
                        isGroupPosts ||
                        updateData?.shared_by_type === "group" ||
                        updateData?.shared_by_type === "page" ||
                        privacyId === 3 ||
                        getPageDetailData?.data?.Pages?.length <= 0
                          ? "form-select no-dropdown"
                          : "form-select")
                      }
                    >
                      <span>{pageName ? pageName : userName}</span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-4 create-post-shared">
                    <div
                      className={
                        isPagePosts ||
                        isGroupPosts ||
                        updateData?.shared_by_type === "page"
                          ? "public form-select cursor-not-allowed"
                          : "public form-select"
                      }
                      onClick={() => {
                        isPagePosts ||
                          isGroupPosts ||
                          updateData?.shared_by_type === "page" ||
                          updateData?.shared_by_type === "page" ||
                          updateData?.privacy_id === 3 ||
                          // getPageDetailData?.data?.Pages?.length <= 0
                          // ? setVisibiltyModal(false)
                          // :
                          setVisibiltyModal(true);
                      }}
                    >
                      <span>
                        <img src={visibleIcon} alt="/" className="me-1" />
                        {visibileAs}
                      </span>
                    </div>
                  </div>
                  {!isGroupPosts &&
                    !isHashtagPost &&
                    user?.data?.membership?.name != "Starter" && (
                      <div className="col-lg-4 col-md-4 col-1 p-0 mt-2 create-post-loader">
                        <div className="float-end">
                          <div
                            className="dots"
                            onMouseEnter={handleMouseOver}
                            onMouseLeave={handleMouseOver}
                          >
                            <div>
                              <img
                                src={watchIcon}
                                alt="schedule"
                                onClick={() => setScheduleModal(!scheduleModal)}
                              />
                            </div>
                            {isHovering && (
                              <div
                                className="drop-down"
                                onClick={() => setScheduleModal(!scheduleModal)}
                              >
                                Schedule
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div></div>
                <div className="row">
                  <div className={expand ? "col-12 height-400px" : "col-12"}>
                    {Object.keys(celebrationData).length > 0 ? (
                      <ShowCelebration
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        handleClickTag={handleClickTag}
                        celebrationData={celebrationData}
                        handleRequestOptions={handleRequestOptions}
                        hashtagList={hashtagList}
                        textAreaRef={textAreaRef}
                        nextModal={(data) => {
                          setCelebrationData({
                            ...celebrationData,
                            title: data,
                          });
                        }}
                        image_text={isData.image_text}
                      />
                    ) : (
                      <>
                        {/* <TextInput
                            trigger={["#"]}
                            options={{ "#": hashtagList }}
                            ref={textAreaRef}
                            className="form-control border-none"
                            rows={
                              updateData?.type === "newsletter" ||
                                updateData?.type === "article" ||
                                updateData?.type === "event"
                                ? 3
                                : expand
                                  ? 15
                                  : 5
                            }
                            value={postDetails.description}
                            onRequestOptions={handleRequestOptions}
                            // maxLength={3000}
                            placeholder={
                              Object.keys(pollData).length > 0
                                ? "Say Something"
                                : "What do you want to talk about?"
                            }
                            onChange={(e, val) => {
                              if (!val) {
                                setPostDetails({ ...postDetails, description: e });
                              }
                            }}
                            onSelect={(val, obj) => {
                              setPostDetails({ ...postDetails, description: val });
                            }}
                          /> */}

                        <MentionsInput
                          placeholder="What do you want to talk about?"
                          style={defaultStyle}
                          value={postDetails.description}
                          onChange={(
                            e,
                            newValue,
                            newPlainTextValue,
                            mentions
                          ) => {
                            setPostDetails({
                              ...postDetails,
                              description: e.target.value,
                            });
                          }}
                        >
                          <Mention
                            style={defaultMentionStyle}
                            trigger={"#"}
                            markup="#[__display__]"
                            appendSpaceOnAdd={true}
                            displayTransform={(id, display) => {
                              return `#${display}`;
                            }}
                            data={fetchHash}
                            renderSuggestion={(
                              entry,
                              query,
                              highlightedDisplay,
                              index
                            ) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    columnGap: "50px",
                                  }}
                                >
                                  <p>{entry.display}</p>
                                </div>
                              );
                            }}
                          />
                          <Mention
                            style={defaultMentionStyle}
                            trigger={"@"}
                            markup="@[__display__](__id__)"
                            data={fetchUser}
                            appendSpaceOnAdd={true}
                            displayTransform={(id, display) => {
                              return `@${display}`;
                            }}
                            renderSuggestion={(
                              entry,
                              query,
                              highlightedDisplay,
                              index
                            ) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "50px",
                                  }}
                                >
                                  <img
                                    src={entry.profile_img}
                                    width={"40px"}
                                    height={"40px"}
                                    style={{ borderRadius: "20px" }}
                                  ></img>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>{entry.display}</p>
                                    <p
                                      className="headliner"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {entry?.profile?.profile_headline}
                                    </p>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </MentionsInput>
                      </>

                      // <div className="Editor">
                      //   <Autocomplete
                      //     editorState={editorState}
                      //     onChange={() => setEditorState(editorState)}
                      //     autocompletes={autocompletes}
                      //   >
                      //     <Editor />
                      //   </Autocomplete>
                      // </div>
                    )}
                    {updateData?.type === "newsletter" ||
                    updateData?.type === "article" ? (
                      <div className="share-article-post">
                        <div className="box">
                          <div className="row">
                            <div className="col-lg-12 p-0">
                              <div className="boxe-img">
                                <img
                                  src={updateData?.SharedDetails?.media}
                                  className="img-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row py-4">
                            <div className="col-sm-9">
                              <div className="text">
                                <h6>{updateData?.SharedDetails?.title}</h6>

                                <span>
                                  {getReadingTime(
                                    updateData?.SharedDetails?.content
                                  ) < 1
                                    ? "Less then 1 min"
                                    : getReadingTime(
                                        updateData?.SharedDetails?.content
                                      ) + " min"}{" "}
                                  to read
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : updateData?.type === "event" ? (
                      <div className="share-event-post">
                        <div className="box">
                          <div className="row">
                            <div className="col-lg-12 p-0">
                              <div className="boxe-img">
                                <img
                                  src={updateData?.SharedDetails?.media}
                                  className="img-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row py-4">
                            <div className="col-sm-6">
                              <div className="text">
                                <h6>{updateData?.SharedDetails?.title}</h6>
                                <p className="my-2">
                                  {moment
                                    .utc(updateData?.SharedDetails?.event_time)
                                    .format("dddd MMM DD")}
                                  ,{" "}
                                  {moment
                                    .utc(updateData?.SharedDetails?.event_time)
                                    .format("hh:mm A (UTC 5.30)")}
                                </p>
                                <span>
                                  Event created by
                                  {updateData?.SharedDetails?.User?.first_name +
                                    " " +
                                    updateData?.SharedDetails?.User?.last_name}
                                </span>
                                <button className="btn btn-blue mt-2">
                                  {updateData?.SharedDetails?.event_type ===
                                  "virtual"
                                    ? "Virtual"
                                    : "Physical"}
                                </button>
                              </div>
                            </div>
                            <div className="col-sm-6 mt-2">
                              <div className="float-sm-end">
                                <button
                                  className="btn btn-light"
                                  onClick={() => {
                                    navigate(
                                      `/eventdetail/${updateData?.SharedDetails?.id}`
                                    );
                                  }}
                                >
                                  View Event
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {Object.keys(pollData).length > 0 ||
                    (updateData &&
                      updateData?.Poll &&
                      Object.keys(updateData?.Poll).length > 0) ? (
                      <>
                        {updateData && updateData?.Poll && isEdit > 0 ? (
                          <PollComponent
                            data={updateData.Poll}
                            isEdit={isEdit}
                          />
                        ) : (
                          <PollComponent
                            data={pollData}
                            editPoll={() => {
                              setCreatePollModal(!createPollModal);
                              setEditPoll(true);
                            }}
                            deletePoll={() => {
                              setPollData({});
                            }}
                            isEdit={isEdit}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {(isText || isImage || isVideo) && mainArea}
                  </div>
                </div>
                <div className="shedule">
                  {scheduleMessage && (
                    <p className="pt-4">
                      This post is scheduled for the date{" "}
                      <span>
                        {moment(scheduleMessage.scheduleDate).format(
                          "MMM DD,YYYY"
                        )}
                      </span>{" "}
                      at{" "}
                      <span>
                        {moment(
                          scheduleMessage.scheduleTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}
                      </span>
                    </p>
                  )}
                </div>
                <div className="row pt-4">
                  <div className="col-12">
                    <div className="d-flex flex-wrap gap-2">
                      <div className="emoj">
                        <img
                          src={dmoj}
                          alt="emoji"
                          onClick={() => setShowEmoji(!showEmoji)}
                        />
                      </div>
                      {!isText &&
                        !isImage &&
                        !isVideo &&
                        !celebrationData?.id &&
                        !pollData?.question && (
                          <div className={isEdit ? "noemoj" : "emoj"}>
                            <img
                              src={bx}
                              alt="image_upload"
                              onClick={() => {
                                if (!isEdit) {
                                  try {
                                    setPostDetails({
                                      ...postDetails,
                                      feed_documents: [],
                                    });
                                    document
                                      .getElementById("chosefile1")
                                      .click();
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      {!isText &&
                        !isImage &&
                        !isVideo &&
                        !celebrationData?.id &&
                        !pollData?.question && (
                          <div className={isEdit ? "noemoj" : "emoj"}>
                            <img
                              src={bj}
                              alt="attachment"
                              onClick={() => {
                                if (!isEdit) {
                                  try {
                                    setPostDetails({
                                      ...postDetails,
                                      feed_documents: [],
                                    });
                                    document
                                      .getElementById("chosefile2")
                                      .click();
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      {!isText &&
                        !isImage &&
                        !isVideo &&
                        !celebrationData?.id &&
                        !pollData?.question && (
                          <div className={isEdit ? "noemoj" : "emoj"}>
                            <img
                              src={paper}
                              alt=""
                              onClick={() => {
                                if (!isEdit) {
                                  try {
                                    setPostDetails({
                                      ...postDetails,
                                      feed_documents: [],
                                    });
                                    document
                                      .getElementById("chosefile3")
                                      .click();
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }
                              }}
                            />
                          </div>
                        )}
                      {!isText &&
                        !isImage &&
                        !isVideo &&
                        !celebrationData?.id &&
                        !pollData?.question &&
                        !isGroupPosts && (
                          <div
                            className={
                              isEdit ? "noemoj" : "emoj position-relative"
                            }
                          >
                            <div
                              data-placement="top"
                              title=" Add Celebration Post"
                              role="tooltip"
                              className="position-relative"
                            >
                              <img
                                src={party}
                                alt="celebration"
                                onClick={() => {
                                  if (!isEdit) {
                                    setSelectOptionModal(!selectOptionModal);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      {!isText &&
                        !isImage &&
                        !isVideo &&
                        !celebrationData?.id &&
                        !pollData?.question && (
                          <div className={isEdit ? "noemoj" : "emoj"}>
                            <div
                              data-toggle="tooltip"
                              data-placement="top"
                              title=" Add Poll Post"
                              role="tooltip"
                              className="position-relative"
                            >
                              <img
                                src={poll}
                                alt="poll"
                                onClick={() => {
                                  if (!isEdit) {
                                    setCreatePollModal(!createPollModal);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      <div className="add btn-light" onClick={handleClickTag}>
                        Add #
                      </div>
                    </div>
                    {showEmoji && (
                      <div className="addemoji">
                        <EmojiPicker
                          height={"100%"}
                          width={"100%"}
                          onEmojiClick={onEmojiClick}
                          autoFocusSearch={false}
                          Theme="light" //Control light and dark theme(will be used in upcoming milestones)
                          // searchPlaceholder=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref={imgRef}
                accept="image/png, image/gif, image/jpeg"
                id="chosefile1"
                onChange={(e) => handleChange(e, "image")}
                onClick={(e) => (e.target.value = "")}
                multiple
              />
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                id="chosefile2"
                ref={vidRef}
                onChange={(e) => handleChange(e, "video")}
                onClick={(e) => (e.target.value = "")}
              />
              <input
                type="file"
                accept="pdf,ppt,pptx,txt,doc,docx,xls,xlsx"
                id="chosefile3"
                onChange={(e) => handleChange(e, "attachment")}
                onClick={(e) => (e.target.value = "")}
              />
            </ModalBody>
            <div className="row p-20" style={{ paddingTop: "0px" }}>
              <div className="col-12">
                <div
                  className={
                    isSubmitDisabled()
                      ? "btn btn-blue d-block "
                      : "btn btn-blue d-block disabledItem"
                  }
                  onClick={
                    isEdit && type !== "draft"
                      ? () => createMyPost()
                      : () => createMyPost()
                  }
                  disabled={true}
                >
                  {updateData && isEdit > 0 ? (
                    <>
                      {isPostNowPreview
                        ? "Post Now"
                        : Object.keys(pollData).length > 0
                        ? "Update Poll"
                        : "Update Post"}
                    </>
                  ) : (
                    <>
                      {Object.keys(pollData).length > 0
                        ? "Post Poll"
                        : scheduleMessage
                        ? "Schedule Post"
                        : "Create Post"}
                    </>
                  )}
                </div>
              </div>
              {/* <div className="preview-button">
                  {" "}
                  <span
                    className="preview"
                    onClick={() => setPreviewModal(!previewModal)}
                  >
                  
                    Preview
                  </span>
                </div> */}
            </div>
          </div>
        </div>
      </Modal>

      {/* {previewModal && (
          <NewsletterPreviewModal
            modal={previewModal}
            toggle={() => setPreviewModal(!previewModal)}
            title="Preview Newsletter"
            convertedContent={convertedContent}
            newsletterData={newsletterData}
            bannerImage={bannerImage}
            pageIcon={pageIcon}
            pageName={pageName}
          />
        )} */}

      {createPollModal && (
        <CreatePollModal
          modal={createPollModal}
          toggle={() => setCreatePollModal(!createPollModal)}
          isEditPoll={isEditPoll}
          pollData={pollData}
          nextModal={(data) => {
            setCreatePollModal(!createPollModal);
            setPollData(data);
          }}
          title="Create a Poll"
        />
      )}
      {visibiltyModal && (
        <VisibilityModal
          modal={visibiltyModal}
          toggle={() => setVisibiltyModal(!visibiltyModal)}
          setVisibiltyModal={setVisibiltyModal}
          privacyId={privacyId}
          setPrivacyId={setPrivacyId}
          title="Who can see your post?"
          joined_groups={joined_groups}
          setSelectGroupModal={setSelectGroupModal}
          setVisiblePersons={setVisiblePersons}
        />
      )}
      {publishAsModal && (
        <PublishAsModal
          modal={publishAsModal}
          toggle={() => setPublishAsModal(!publishAsModal)}
          title="Publish as"
          publishUserId={publishUserId}
          setPublishUserId={setPublishUserId}
          setPageName={setPageName}
          setProfileImg={setProfileImg}
          sharedByType={sharedByType}
          updateData={updateData}
          setSharedByType={setSharedByType}
          getPageDetailData={getPageDetailData}
        />
      )}
      {selectGroupModal && (
        <SelectGroup
          modal={selectGroupModal}
          toggle={() => setSelectGroupModal(!selectGroupModal)}
          setPrivacyId={setPrivacyId}
          title="Who can see your post?"
          setGroupName={setGroupName}
          groupId={groupId}
          setGroupId={setGroupId}
          joined_groups={joined_groups}
          setVisibiltyModal={setVisibiltyModal}
        />
      )}
      {scheduleModal && (
        <ScheduleModal
          modal={scheduleModal}
          toggle={() => setScheduleModal(!scheduleModal)}
          title="Schedule Post"
          scheduledData={scheduledData}
          setScheduledData={setScheduledData}
          setScheduleMessage={setScheduleMessage}
        />
      )}

      {selectOptionModal && (
        <SelectOptionModal
          modal={selectOptionModal}
          toggle={() => setSelectOptionModal(!selectOptionModal)}
          nextModal={(list) => {
            setSelectOptionModal(!selectOptionModal);
            setSelectCelebImage(!selectCelebImage);
            isData.id = list.id;
            isData.title = list?.sub_title;
            isData.images = list.media;
            isData.image_text = list.image_text;
            setData({ ...isData });
          }}
          title="Select an Option"
        />
      )}

      {selectCelebImage && (
        <SelectCelebImage
          modal={selectCelebImage}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          toggle={() => setSelectCelebImage(!selectCelebImage)}
          nextModal={(data) => {
            setSelectCelebImage(!selectCelebImage);
            isData.selectedImg = data;
            if (isData?.id == 1 || isData?.id == 2) {
              setSelectReceipent(!selectReceipent);
            } else {
              setCelebrationData({
                id: isData.id,
                celebration_tags: [],
                title: isData.title,
                // media: isData.selectedImg,
                media: selectedImage ? selectedImage : "",
                images: isData.images,
                //media:isData.image_text,
              });
            }
          }}
          backModal={() => {
            setSelectCelebImage(!selectCelebImage);
            setSelectOptionModal(!selectOptionModal);
          }}
          images={isData.images}
          title="New Celebrations"
          image_text={isData.image_text}
        />
      )}
      {selectReceipent && (
        <SelectReceipent
          modal={selectReceipent}
          toggle={() => setSelectReceipent(!selectReceipent)}
          nextModal={(data) => {
            setCelebrationData({
              id: isData.id,
              celebration_tags: data,
              title: isData.title,
              //media: isData.selectedImg,
              media: selectedImage ? selectedImage : "",
              images: isData.images,
              //media:isData.image_text,
            });
            setSelectReceipent(!selectReceipent);
          }}
          backModal={() => {
            setSelectReceipent(!selectReceipent);
            setSelectCelebImage(!selectCelebImage);
          }}
          title="Select Recipient"
        />
      )}
      {discardModalStatus && (
        <DiscardEventModal
          modal={discardModalStatus}
          toggle={closeAllModal}
          title="Discard Changes"
          saveDraft={saveDraft}
          close={closeAllModal}
        />
      )}

      {/* {alertEditModalStatus && (
        <AlertEditModal
          modal={alertEditModalStatus}
          toggle={() => setAlertEditModalStatus(!alertEditModalStatus)}
          title="Alert Message"
          createPost={() => createMyPost()}
        />
      )} */}
    </div>
  );
};

export default CreatePostModal;
