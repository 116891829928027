import React, { useEffect } from "react";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import downArrowIcon from "../../../../assets/img/down-arrow2.svg";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PaymentForShortlist from "../ShortlistedCandidates/PaymentForShortlist";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import sentNPMail from "../../../../assets/img/sendIconGrey.svg";
import scheduleMeetingIcon from "../../../../assets/img/scheduleMeetingIcon.svg";
import watch from "../../../../assets/img/watch.svg";
import starFilled from "../../../../assets/img/star-filled.svg";
import starUnfilled from "../../../../assets/img/star-unfilled.svg";
import exp_tb from "../../../../assets/img/exp_badge.svg";
import edu_tb from "../../../../assets/img/edu_badge.svg";
import cri_tb from "../../../../assets/img/criminal_badge.svg";
import { useDispatch, useSelector } from "react-redux";
import SendSmartInterview from "../../Recruiter/SmartInterview/Components/SendSmartInterview";
import SmartSuccessModal from "../../Recruiter/SmartInterview/SmartSuccessModal";
import { useNavigate } from "react-router-dom";
import { getJobsDetail } from "../../../../store/jobs/jobAction";
import NpMailModal from "../../../../core/components/modal/NpMailModal";
import { toast } from "react-toastify";
import moment from "moment";
import { postViewLog } from "../../../../store/dashboard/dashboardActions";

const MainComponent = (props) => {
  const {
    candidateData,
    shortlistedFor,
    setShortlistedFor,
    requestCandidateData,
    totalCount,
    currentCount,
    loadMoreData,
    isMoreShortlisted,
    tab,
    selectedJobId,
    selectedJob,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeCandidate, setActiveCandidate] = useState(null);
  const [isActiveCandidate, setIsActiveCandidate] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [smartInterviewModal, setSmartInterviewModal] = useState(false);
  const [smartInterviewSuccessModal, setSmartInterviewSuccessModal] =
    useState(false);
  const [npMailModal, setNpMailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const { isPageLoading } = useSelector((state) => state.loader);
  const { getAJob } = useSelector((state) => state.jobs);
  const [checkAll, setCheckAll] = useState(false);

  // console.log("shortlisedList", candidateData);
  const handleCheck = (id) => {
    setShortlistedFor((prevShortlistedFor) => [...prevShortlistedFor, id]);
  };

  const handleAllCheck = () => {
    const data =
      candidateData
        ?.filter((ele) => ele?.profile_visible === false)
        ?.map((ele) => ele?.id) || [];

    setShortlistedFor((prevShortlistedFor) => {
      const newShortlistedSet = new Set([...prevShortlistedFor, ...data]);
      return Array.from(newShortlistedSet);
    });

    setCheckAll(true);
  };
  const handleAllUncheck = () => {
    setShortlistedFor([]);
    setCheckAll(false);
  };
  const handleUnchecked = (id) => {
    setShortlistedFor(shortlistedFor.filter((el, i) => el !== id));
  };

  const handleSmartInterview = (shortlist) => {
    setSelectedUser(shortlist);
    setSmartInterviewModal(true);
  };

  const handleNpMail = (shortlist) => {
    setSelectedUser(shortlist);
    setNpMailModal(true);
  };

  useEffect(() => {
    dispatch(getJobsDetail(selectedJobId));
    setCheckAll(false);
    setShortlistedFor([]);
  }, [candidateData]);

  //truncate the name and show the ellipsis
  function truncateName(name, maxLength) {
    if (name && name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  }

  return (
    <div className="bg-white main-content">
      {isPageLoading && <AppLoader />}
      <div className="bg-white px-4 py-3 main-content">
        <InfiniteScroll
          dataLength={candidateData?.length ? candidateData?.length : 0}
          next={loadMoreData}
          hasMore={isMoreShortlisted}
          style={{ overflow: "visible" }}
        >
          <div
            className="shortlist bg-white mb-20"
            // style={{ minHeight: "110vh" }}
          >
            {candidateData?.length > 0 ? (
              <>
                {candidateData?.map((data, index) => {
                  const empCheck = data?.user?.experiences?.some(
                    (exp) => exp?.screening_status == "Verified"
                  );
                  const eduCheck = data?.user?.educations?.some(
                    (edu) => edu?.screening_status == "Verified"
                  );
                  const criCheck = data?.user?.screening_status == "Verified";
                  return (
                    <div key={index} className="mb-30">
                      <div className="d-flex justify-content-between">
                        <div className="user-post" style={{ width: "73%" }}>
                          <div className="prof-img">
                            <img
                              className="profile-pic"
                              src={
                                tab !== "shotlisted" &&
                                data?.user?.profile_img &&
                                data?.profile_visible
                                  ? data?.user?.profile_img
                                  : placeholderUser
                              }
                              alt="placeholder"
                              style={{
                                cursor:
                                  tab === "shotlisted" || !data?.profile_visible
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() =>
                                tab !== "shotlisted" &&
                                data?.profile_visible &&
                                navigate(`/profile/${data?.user?.id}`)
                              }
                            />
                            {tab === "viewSmartInterview" && (
                              <img
                                className="toplist-star"
                                src={
                                  getAJob?.toplist_id == data?.id
                                    ? starFilled
                                    : starUnfilled
                                }
                                alt="placeholder"
                              />
                            )}
                          </div>

                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h4 className="poster-name">
                                {tab !== "shotlisted" && data?.profile_visible
                                  ? truncateName(
                                      `${data?.user?.first_name} ${data?.user?.last_name}`,
                                      20
                                    )
                                  : "Anonymous"}
                              </h4>
                              {data?.user?.is_email_verified && (
                                <div className="varify"></div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "10px",
                                }}
                              >
                                {empCheck && (
                                  <div class="tooltip-container">
                                    <img
                                      src={exp_tb}
                                      alt="Tooltip Icon"
                                      class="tooltip-trigger"
                                    />
                                    <div class="custom-tooltip">
                                      <p className="fw-bold">
                                        Employment Check
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {eduCheck && (
                                  <div class="tooltip-container">
                                    <img
                                      src={edu_tb}
                                      alt="Tooltip Icon"
                                      class="tooltip-trigger"
                                    />
                                    <div class="custom-tooltip">
                                      <p className="fw-bold">Education Check</p>
                                    </div>
                                  </div>
                                )}
                                {criCheck && (
                                  <div class="tooltip-container">
                                    <img
                                      src={cri_tb}
                                      alt="Tooltip Icon"
                                      class="tooltip-trigger"
                                    />
                                    <div class="custom-tooltip">
                                      <p className="fw-bold">Criminal Check</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <span className="designation-name card-control-text-oneline">
                              {truncateName(
                                data?.user?.profile?.designation,
                                20
                              )}
                              <div>{data?.user?.profile?.profile_headline}</div>

                              {/* {data?.user?.profile?.designation}{" "}
                              {data?.user?.profile?.profile_headline} */}
                            </span>

                            <div
                              className="d-flex gap-1 mt-10"
                              onClick={() => {
                                setActiveCandidate(index);
                                setIsActiveCandidate((prevState) => !prevState);
                              }}
                            >
                              <p className="designation-name blue-color curserPointer">
                                View Details
                              </p>
                              <img
                                src={downArrowIcon}
                                alt="arrow-icon"
                                className="curserPointer"
                                // style={
                                //   activeCandidate == index
                                //     ? { transform: "rotate(180deg)" }
                                //     : { transform: "rotate(180deg)" }
                                // }
                              />
                              {data?.appliedJob ? (
                                <p className="designation-name applicant">
                                  Applicant
                                </p>
                              ) : (
                                <p className="designation-name search">
                                  Smart Search
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <div
                          className="btn btn-blue"
                        //   onClick={() => {
                        //     setShortlistModal(true);
                        //     setShortListUser(data?.id);
                        //   }}
                        >
                          <span>Shortlist</span>
                        </div> */}

                          {tab === "shotlisted" ? (
                            <>
                              {data?.profile_visible ? (
                                <span
                                  style={{
                                    color: "rgb(103, 106, 121)",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Requested
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  className="user-checkbox"
                                  name="user"
                                  id="check-box"
                                  onChange={(e) => {
                                    if (e.target.checked) handleCheck(data?.id);
                                    else handleUnchecked(data?.id);
                                  }}
                                  checked={shortlistedFor?.some(
                                    (ele) => ele === data?.id
                                  )}
                                />
                              )}
                            </>
                          ) : (
                            <div className="right-side">
                              {tab === "viewCandidate" ? (
                                !data?.interview_results?.length ? (
                                  <div
                                    className="interview-btn"
                                    onClick={() => {
                                      if (
                                        new Date() >
                                        new Date(selectedJob?.expiry_date)
                                      )
                                        toast.error("Job is expired");
                                      else handleSmartInterview(data);
                                    }}
                                  >
                                    {!data?.interview_set
                                      ? "Send Smart Interview"
                                      : "Resend Smart Interview"}
                                  </div>
                                ) : (
                                  <div className="mb-3">
                                    <div
                                      className="watch-interview"
                                      onClick={() => {
                                        if (
                                          data?.interview_results?.length &&
                                          moment(
                                            new Date(
                                              data?.interview_results[0]?.createdAt
                                            )
                                          )
                                            .add(14, "day")
                                            .toNow() > moment().toNow()
                                        )
                                          toast.error(
                                            "The Smart Interview you are trying to watch has expired now."
                                          );
                                        else {
                                          navigate(
                                            "/recuriter/watch-interview",
                                            {
                                              state: {
                                                shortlist_id: data?.id,
                                                job_id: selectedJobId,
                                                interview_result:
                                                  data?.interview_result,
                                              },
                                            }
                                          );
                                          dispatch(
                                            postViewLog({
                                              view_type: "interview",
                                              job_id: selectedJobId,
                                            })
                                          );
                                        }
                                      }}
                                      style={{ marginBottom: "0px" }}
                                    >
                                      {data?.Viewed ? (
                                        <p>Rewatch Smart Interview</p>
                                      ) : (
                                        <p>Watch Smart Interview</p>
                                      )}
                                      <img src={watch} alt="watch" />
                                    </div>
                                    {data?.interview_results?.length &&
                                    moment(
                                      new Date(
                                        data?.interview_results[0]?.createdAt
                                      )
                                    )
                                      .add(14, "day")
                                      .isAfter(moment()) ? (
                                      <p
                                        style={{
                                          textAlign: "right",
                                          marginRight: "30px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {Math.max(
                                          moment(
                                            new Date(
                                              data?.interview_results[0]?.createdAt
                                            )
                                          )
                                            .add(14, "day")
                                            .diff(moment(), "days"),
                                          0
                                        )}{" "}
                                        days remaining
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          textAlign: "right",
                                          marginRight: "30px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Expired
                                      </p>
                                    )}
                                  </div>
                                )
                              ) : tab === "viewSmartInterview" ? (
                                <div className="mb-3">
                                  <div
                                    className="watch-interview"
                                    onClick={() => {
                                      if (
                                        data?.interview_results?.length &&
                                        moment(
                                          new Date(
                                            data?.interview_results[0]?.createdAt
                                          )
                                        )
                                          .add(14, "day")
                                          .toNow() > moment().toNow()
                                      )
                                        toast.error(
                                          "The Smart Interview you are trying to watch has expired now."
                                        );
                                      else {
                                        navigate("/recuriter/watch-interview", {
                                          state: {
                                            shortlist_id: data?.id,
                                            job_id: selectedJobId,
                                            interview_result:
                                              data?.interview_result,
                                          },
                                        });

                                        dispatch(
                                          postViewLog({
                                            view_type: "interview",
                                            job_id: selectedJobId,
                                          })
                                        );
                                      }
                                    }}
                                    style={{ marginBottom: "0px" }}
                                  >
                                    {data?.Viewed ? (
                                      <p>Rewatch Smart Interview</p>
                                    ) : (
                                      <p>Watch Smart Interview</p>
                                    )}
                                    <img src={watch} alt="watch" />
                                  </div>
                                  {data?.interview_results?.length &&
                                  moment(
                                    new Date(
                                      data?.interview_results[0]?.createdAt
                                    )
                                  )
                                    .add(14, "day")
                                    .isAfter(moment()) ? (
                                    <p
                                      style={{
                                        textAlign: "right",
                                        marginRight: "30px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {Math.max(
                                        moment(
                                          new Date(
                                            data?.interview_results[0]?.createdAt
                                          )
                                        )
                                          .add(14, "day")
                                          .diff(moment(), "days"),
                                        0
                                      )}{" "}
                                      days remaining
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "right",
                                        marginRight: "30px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Expired
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                className="send"
                                onClick={() =>
                                  // alert(
                                  //   "This feature will be implemented in upcomming milestones"
                                  // )
                                  {
                                    if (
                                      new Date() >
                                      new Date(selectedJob?.expiry_date)
                                    )
                                      toast.error("Job is expired");
                                    else handleNpMail(data);
                                  }
                                }
                              >
                                <p>Send NPMail</p>
                                <img src={sentNPMail} alt="sentNPMail" />
                              </div>
                              <div
                                className="send"
                                onClick={() => {
                                  if (
                                    new Date() >
                                    new Date(selectedJob?.expiry_date)
                                  )
                                    toast.error("Job is expired");
                                  else navigate("/message");
                                }}
                              >
                                <p>Schedule a Meeting</p>
                                <img
                                  src={scheduleMeetingIcon}
                                  alt="sentNPMail"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {activeCandidate === index &&
                        isActiveCandidate == true && (
                          <>
                            <div className="line mt-20 mb-20"> </div>
                            <div className="row">
                              <div className="col-md-3 mt-2 mb-2">
                                Total Experience
                              </div>

                              <div className="col-md-9 mt-2 mb-2 d-flex">
                                <span className="job-details">
                                  {data?.user?.totalExperience
                                    ? data?.user?.totalExperience < 1 || ""
                                      ? "Fresher"
                                      : data?.user?.totalExperience == 1
                                      ? data?.user?.totalExperience + " Year"
                                      : data?.user?.totalExperience + " Years"
                                    : "Fresher"}
                                </span>
                              </div>
                              {data?.user?.experiences?.length > 0 && (
                                <>
                                  <div className="col-md-3 mb-2">
                                    Experience
                                  </div>
                                  <div
                                    className="col-md-9 mb-2  d-flex"
                                    style={{ flexWrap: "wrap", gap: "8px" }}
                                  >
                                    {data?.user?.experiences.map(
                                      (exp, expIndex) => (
                                        <div
                                          className="job-details"
                                          key={expIndex}
                                        >
                                          {exp.company_name}, {exp.title}
                                          {expIndex <
                                          data?.user?.experiences.length - 1
                                            ? " , "
                                            : " "}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {data?.user?.educations?.length > 0 && (
                                <>
                                  <div className="col-md-3 mb-2">Education</div>
                                  <div className="col-md-9 mb-2 d-flex">
                                    {data?.user?.educations.map(
                                      (exp, expIndex) => (
                                        <div key={expIndex}>
                                          <span className="job-details">
                                            {exp.degree}
                                            {expIndex <
                                            data?.user?.educations.length - 1
                                              ? " , "
                                              : ""}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {data?.user?.honors?.length > 0 && (
                                <>
                                  <div className="col-md-3 mb-2">
                                    Honour And Awards
                                  </div>
                                  <div
                                    className="col-md-9 mb-2  d-flex"
                                    style={{ flexWrap: "wrap", gap: "8px" }}
                                  >
                                    {data?.user?.honors?.map(
                                      (honor, honorIndex) => (
                                        <div key={honorIndex}>
                                          <div className="job-details">
                                            {honor?.name}{" "}
                                            {honorIndex <
                                              data?.user?.honors.length - 1 &&
                                              " , "}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {data?.user?.courses?.length > 0 && (
                                <>
                                  <div className="col-md-3 mb-2">Course</div>
                                  <div
                                    className="col-md-9 mb-2 d-flex"
                                    style={{ flexWrap: "wrap", gap: "8px" }}
                                  >
                                    {/* <div className="job-details"> */}
                                    {data?.user?.courses.map(
                                      (course, courseIndex) => (
                                        <div key={courseIndex}>
                                          <div className="job-details">
                                            {course.name}
                                            {courseIndex <
                                              data?.user?.courses.length - 1 &&
                                              " , "}
                                          </div>
                                        </div>
                                      )
                                    )}
                                    {/* </div> */}
                                    {/* {data?.user?.experiences.map(
                                    (exp, expIndex) => (
                                      <div
                                        className="job-details"
                                        key={expIndex}
                                      >
                                        {exp.company_name}, {exp.title}
                                        {expIndex <
                                        data?.user?.experiences.length - 1
                                          ? " , "
                                          : " "}
                                      </div>
                                    )
                                  )} */}
                                  </div>
                                </>
                              )}
                              {data?.user?.license_certifications?.length >
                                0 && (
                                <>
                                  <div className="col-md-3 mb-2">
                                    Certificates
                                  </div>
                                  <div className="col-md-9 mb-2 d-flex flex-wrap">
                                    {data?.user?.license_certifications?.map(
                                      (certi, certiIndex) => (
                                        <span
                                          className="me-2 job-details"
                                          style={{ fontWeight: "600" }}
                                        >
                                          {certi?.name}
                                          {certiIndex <
                                            data?.user?.license_certifications
                                              .length -
                                              1 && " , "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                              {/* <div className="col-md-2 mb-2">Interests</div>
                            <div className="col-md-10 mb-2 d-flex">
                            {data?.license_certifications?.map(
                                (certi, certiIndex) => (
                                  <span className="job-details">
                                    {certi?.name}
                                    {certiIndex <
                                      data?.license_certifications.length - 1 &&
                                      " , "}
                                  </span>
                                )
                              )}
                            </div> */}
                              {data?.user?.languages?.length > 0 && (
                                <>
                                  <div className="col-md-3 mb-2">Language</div>
                                  <div className="col-md-9 mb-2">
                                    <span className="job-details">
                                      {data?.user?.languages?.map(
                                        (language, languageIndex) => (
                                          <span key={languageIndex}>
                                            {language.name}
                                            {languageIndex <
                                              data?.user?.languages?.length -
                                                1 && ", "}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                              {data?.user?.skills?.length > 0 && (
                                <>
                                  <div className="col-md-3">Skills</div>
                                  <div className="col-md-9 mb-2">
                                    <div
                                      className="job-details d-flex flex-wrap"
                                      style={{ gap: "8px" }}
                                    >
                                      {data?.user?.skills.map(
                                        (skill, skillIndex) => (
                                          <div
                                            key={skillIndex}
                                            className="skill-span"
                                            style={{ fontWeight: 400 }}
                                          >
                                            {skill.skills}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="line mt-20 mb-20"> </div>
                          </>
                        )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div>No Candidates</div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      {tab === "shotlisted" && candidateData?.length > 0 && (
        <div
          className="d-flex justify-content-between align-item-center my-3 p-3"
          style={{ background: "#0432A3" }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="user-checkbox"
              name="user"
              id="check-box"
              style={{
                border: "1px solid #fff",
                height: "20px",
                width: "20px",
              }}
              onChange={(e) => {
                if (e.target.checked) handleAllCheck();
                else handleAllUncheck();
              }}
              checked={checkAll}
            />
            <p className="text-light mx-3">
              Select All : Selected {shortlistedFor.length}/
              {candidateData?.length}
            </p>
          </div>
          <div
            className="btn bg-white px-5 py-3"
            style={{ color: "#0432A3" }}
            onClick={() => {
              if (new Date(selectedJob?.expiry_date) < new Date())
                toast.error("This job is expired now");
              else if (shortlistedFor?.length > 50) {
                toast.error("You can select only 50 candidates");
              } else
                shortlistedFor.length > 0
                  ? setPaymentModal(true)
                  : toast.error(
                      "Please select at least one candidate or its already requested"
                    );
            }}
          >
            Request Data
          </div>
        </div>
      )}
      {paymentModal && (
        <PaymentForShortlist
          modal={paymentModal}
          toggle={() => setPaymentModal(!paymentModal)}
          requestCandidateData={requestCandidateData}
          setCheckAll={setCheckAll}
          handleAllUncheck={handleAllUncheck}
          shortlistedFor={shortlistedFor}
        />
      )}
      {smartInterviewModal && (
        <SendSmartInterview
          modal={smartInterviewModal}
          toggle={() => setSmartInterviewModal(!smartInterviewModal)}
          selectedUser={selectedUser}
          // requestCandidateData={requestCandidateData}
          setSmartInterviewSuccessModal={setSmartInterviewSuccessModal}
          isLoading={isPageLoading}
        />
      )}

      {npMailModal && (
        <NpMailModal
          modal={npMailModal}
          toggle={() => setNpMailModal(!npMailModal)}
          profileData={{
            id: selectedUser?.user?.id,
            first_name: selectedUser?.user?.first_name,
            last_name: selectedUser?.user?.last_name,
            profile_img: selectedUser?.user?.profile_img,
            profile_headline: selectedUser?.user?.profile?.profile_headline,
          }}
        />
      )}

      {smartInterviewSuccessModal && (
        <SmartSuccessModal
          modal={smartInterviewSuccessModal}
          toggle={() =>
            setSmartInterviewSuccessModal(!smartInterviewSuccessModal)
          }
          setSmartInterviewModal={setSmartInterviewModal}
          // requestCandidateData={requestCandidateData}
        />
      )}
    </div>
  );
};

export default MainComponent;
