import {
  GET_DATA,
  UPDATE_DATA,
  ADD_EDUCATION_DATA,
  ADD_COURSE_DATA,
  UPDATE_COURSE_DATA,
  UPDATE_EDUCATION_DATA,
  ADD_LANGUAGE_DATA,
  UPDATE_LANGUAGE_DATA,
  ADD_LICENSE_DATA,
  UPDATE_LICENSE_DATA,
  ADD_SKILLS_DATA,
  UPDATE_SKILLS_DATA,
  DELETE_DATA,
  ADD_EXPERIENCE_DATA,
  UPDATE_EXPERIENCE_DATA,
  EXP_DATA,
  EDU_DATA,
  GET_VIEW_PROFILE_COUNT,
  UPDATE_CONNECTION_STATUS,
  ADD_HONORS_DATA,
  UPDATE_HONORS_DATA,
  UPLOAD_INTRO_VIDEO,
  UPLOAD_RESUME_DATA,
  DELETE_INTRO_VIDEO,
  CREATE_PROFILE_FOLLOWER,
  CREATE_PROFILE_UNFOLLOWER,
  UPDATE_PROFILE_URL,
  VERIFY_EMAIL,
  CHANGE_EMAIL,
  PUBLIC_POSTS_LIST,
  UPDATE_CONNECTION_REMOVE,
  DELETE_MEDIA_DATA,
  DELETE_HON_DATA,
  DELETE_LAN_DATA,
  GET_RECENT_SEARCH,
  ADD_MULTIPLE_URL,
  DELETE_RESUME,
} from "../types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DATA:
      // const { profileData } = action.payload
      return {
        ...state,
        profileData: action.payload,
      };

    case UPDATE_DATA:
      return {
        ...state,
        profileData: { ...state.profileData, ...action.payload },
      };

    case UPLOAD_INTRO_VIDEO:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          intro_video: action.payload.intro_video,
        },
      };

    case DELETE_INTRO_VIDEO:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          intro_video: action.payload.intro_video,
        },
      };

    case UPLOAD_RESUME_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          resume_url: action.payload.resume_url,
        },
      };

    case ADD_EDUCATION_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          educations: [...state.profileData.educations, ...action.payload],
        },
      };

    case UPDATE_EDUCATION_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          educations: state.profileData.educations.map((x) =>
            x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_COURSE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          courses: [...state.profileData.courses, ...action.payload],
        },
      };

    case UPDATE_COURSE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          courses: state.profileData.courses.map((x) =>
            x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_LANGUAGE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          languages: [...state.profileData.languages, ...action.payload],
        },
      };

    case UPDATE_LANGUAGE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          languages: state.profileData.languages.map((x) =>
            x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_LICENSE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          license_certifications: [
            ...state.profileData.license_certifications,
            ...action.payload,
          ],
        },
      };

    case UPDATE_LICENSE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          license_certifications: state.profileData.license_certifications.map(
            (x) =>
              x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_HONORS_DATA:
      console.log(action.payload);
      return {
        ...state,
        profileData: {
          ...state.profileData,
          honors_and_awards: [
            ...state.profileData.honors_and_awards,
            ...action.payload,
          ],
        },
      };

    case UPDATE_HONORS_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          honors_and_awards: state.profileData.honors_and_awards.map((x) =>
            x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_SKILLS_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          exps_and_skills: [
            ...state.profileData.exps_and_skills,
            ...action.payload,
          ],
        },
      };

    case UPDATE_SKILLS_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          exps_and_skills: state.profileData.exps_and_skills.map((x) =>
            x.id === action.payload[0].id ? { ...x, ...action.payload[0] } : x
          ),
        },
      };

    case ADD_EXPERIENCE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          experiences: [...state.profileData.experiences, action.payload],
        },
      };

    case UPDATE_EXPERIENCE_DATA:
      let newExp = action.payload;

      // let experiences = [...state.profileData.experiences];
      // console.log(action.payload, "action.payload");
      // let updatedExpData = experiences.filter(
      //   (item) => item.id !== action.payload.id
      // );
      // console.log("experiences at reducer",experiences);
      // console.log("newExp",newExp);

      // updatedExpData.push(newExp);
      // console.log("updatedExpData",updatedExpData);
      return {
        ...state,
        profileData: {
          ...state.profileData,
          experiences: state?.profileData?.experiences?.map((exp) =>
            exp?.id == newExp?.id ? { ...exp, ...newExp } : exp
          ),
        },
      };

    case DELETE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          exps_and_skills: state.profileData.exps_and_skills.filter(
            (x) => x.id !== action.payload
          ),
        },
      };

    case DELETE_MEDIA_DATA:
      console.log("action.payload", action.payload);
      return {
        ...state,
        profileData: {
          ...state.profileData,
          experiences: state.profileData.experiences.medias.filter(
            (x) => x.id !== action.payload
          ),
        },
      };

    case EXP_DATA:
      let experiences1 = [...state.profileData.experiences];

      let updatedExpData1 = experiences1.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        profileData: { ...state.profileData, experiences: updatedExpData1 },
      };

    case EDU_DATA:
      let education1 = [...state.profileData.educations];

      let updatedEduData1 = education1.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        profileData: { ...state.profileData, educations: updatedEduData1 },
      };

    case GET_VIEW_PROFILE_COUNT:
      return {
        ...state,
        profileData: payload,
      };

    case UPDATE_CONNECTION_STATUS:
      return {
        ...state,
        profileData: { ...state.profileData, connection_status: "pending" },
      };

    case UPDATE_CONNECTION_REMOVE:
      return {
        ...state,
        profileData: { ...state.profileData, connection_status: null },
      };

    case CREATE_PROFILE_FOLLOWER:
      return {
        ...state,
        profileData: { ...state.profileData, follow_status: true },
      };

    case CREATE_PROFILE_UNFOLLOWER:
      return {
        ...state,
        profileData: { ...state.profileData, follow_status: false },
      };

    case UPDATE_PROFILE_URL:
      return {
        ...state,
        profileData: { ...state.profileData, profile_url: payload },
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          is_email_verified: true,
          profile_verified_percentage:
            state?.profileData?.isEduTb ||
            state?.profileData?.isExpTb ||
            state?.profileData?.screeing_status == "Verified"
              ? 100
              : 70,
        },
      };
    case CHANGE_EMAIL:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          email: action.payload.email,
          is_email_verified: false,
          profile_verified_percentage: 0,
        },
      };

    case PUBLIC_POSTS_LIST:
      return {
        ...state,
        public_post: action.payload,
      };

    case GET_RECENT_SEARCH:
      return {
        ...state,
        recent_search: action.payload,
      };

    case DELETE_HON_DATA:
      let hon = [...state.profileData.honors_and_awards];
      console.log(hon, "jthutght");

      let updatedHonData = hon.filter((item) => item.id !== action.payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          honors_and_awards: updatedHonData,
        },
      };

    case DELETE_LAN_DATA:
      let lan = [...state.profileData.languages];

      let updateLanData = lan.filter((item) => item.id !== action.payload);

      return {
        ...state,
        profileData: { ...state.profileData, languages: updateLanData },
      };

    case ADD_MULTIPLE_URL:
      return {
        ...state,
        profileData: {
          ...state?.profileData,
          resumes: [...action?.payload, ...state?.profileData?.resumes],
        },
      };

    case DELETE_RESUME:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          resumes: state.profileData.resumes.filter(
            (x) => x.id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
