import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { ToastContainer, toast } from "react-toastify";

const CreatorModeModal = (props) => {
  const { modal, toggle, title, data } = props;
  const [creatorMode, setCreatorMode] = useState(false);
  const [postTopics, setPostTopics] = useState([]);
  const [inputText, setInputText] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const token = localStorage.getItem("accessToken");

  const addPosts = (e) => {
    if (e.keyCode === 13) {
      setPostTopics((prevState) => [...prevState, inputText]);
      setInputText("");
    }
  };
  const handleCancel = (selectedItem) => {
    setPostTopics(postTopics?.filter((el) => el !== selectedItem));
  };

  // useEffect(() => {
  //   try {
  //     let mode = false;
  //     for (let index = 0; index < data.length; index++) {
  //       const element = data[index];
  //       if (element["key"] == "creator_mode") {
  //         let { value } = element.settings[0];
  //         if (value == 1) {
  //           mode = true;
  //         }
  //       }
  //     }

  //     setCreatorMode(mode);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(getUserSetting(6, token)).then((response) => {
      if (response?.data[0]?.settings[0].value === "0") {
        setCreatorMode(false);
      } else setCreatorMode(true);
      if (response?.data[0]?.settings[1].value) {
        setPostTopics(response?.data[0]?.settings[1].value.split(","));
      }
    });
  }, []);

  const updateMode = () => {
    try {
      if (postTopics) {
        updateTopics();
      }
      let value = 0;
      if (creatorMode === true) {
        value = 1;
      }
      let updateData = {
        setting: [
          {
            setting_master_id: 19,
            value: value + "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        toggle();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateTopics = () => {
    try {
      let updateData = {
        setting: [
          {
            setting_master_id: 20,
            value: postTopics + "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="lg"
        centered={true}
        style={{ maxWidth: "500px" }}
      >
        <div className="popup">
          <div className="setting-popup create-mode">
            <div className="d-flex justify-content-between">
              <ModalHeader id="account-modal">{title}</ModalHeader>

              <label className="switch">
                <input
                  role="switch"
                  type="checkbox"
                  checked={creatorMode}
                  onChange={(e) => setCreatorMode(e.target.checked)}
                  // disabled={user?.data?.followers > 0 ? false : true}
                />
                <span
                  className={
                    creatorMode
                      ? "slider creator_switch round"
                      : "slider_disabled round"
                  }
                ></span>
              </label>
            </div>
            <ModalBody>
              <p className="mb-3">
                Creator Mode lets you build a following on your profile.
                Followers get notified about your posts and interactions. It's
                different from 'Connect'—people can still send connection
                requests.
              </p>
              <h6 className="mb-3 text-black">
                Grow your audience with creator mode
              </h6>
              <ul className="mb-3">
                <li>Follow will be your primary action </li>
                <li>Get access to creator tools</li>
                <li>featured post will be on top of your profile</li>
              </ul>
              {creatorMode && (
                <div>
                  <h6 className="mb-3 text-black">
                    {" "}
                    Add topics you generally post
                  </h6>
                  <div className="type-box">
                    <div className="d-flex gap-2 flex-wrap">
                      {postTopics.length > 0 ? (
                        postTopics?.map((item, index) => (
                          <div className="round">
                            <p key={index}>{item}</p>
                            <div
                              className="close"
                              onClick={() => handleCancel(item)}
                            >
                              X
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      <input
                        value={inputText}
                        onKeyDownCapture={(e) => addPosts(e)}
                        onChange={(e) => setInputText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="text-end">
                <div className="btn btn-light mr-20" onClick={toggle}>
                  Close
                </div>
                <div className="btn btn-blue" onClick={updateMode}>
                  Update
                </div>
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CreatorModeModal;
