import React, { useEffect } from "react";
import PortalDashboard from "./PortalDashboard";
import { useDispatch } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
} from "../../../store/portal/portalAction";
import { useNavigate, useParams } from "react-router-dom";
import npLogo from "../../../assets/img/favicon.svg";

function PortalDashboardContainer() {
  const dispatch = useDispatch();
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  console.log(token);
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(getTalentPoolDetailsByToken());
    // const favicon = document.querySelector("link#favicon");
    // const title = document.querySelector("title#title");

    dispatch(getTalentPoolDetails(param))
      .then((res) => {
        // if (res?.data?.icon && favicon)
        //   favicon.setAttribute("href", res?.data?.icon);
        // if (res?.data?.company_name && title)
        //   title.innerHTML = `NobelPage | ${res?.data?.company_name}`;
      })
      .catch((err) =>
        err?.request?.status == 404 ? navigate("/error") : console.log(err)
      );
    if (token) dispatch(getTalentPoolDetailsByToken());
    // return () => {
    //   favicon.setAttribute("href", "/favicon.svg");
    //   title.innerHTML = `NobelPage`;
    // };
  }, []);
  return <PortalDashboard />;
}

export default PortalDashboardContainer;
