import React, { useEffect, useState } from "react";
import "./backgroundScreening.scss";
import AggrementContent from "./AggrementContent";
import BackgroundHeader from "./header";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/img/blackDownArrow.svg";
import moment from "moment";
import AlertModel from "./alertModel";
import { profileApi } from "../../api/profileAPI";
import Navbar from "../../pages/navbar/navbar";
import Footer from "./footer";
import notiIcon from "../../assets/img/notiIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { getScreeningRequest } from "../../store/backgroundScreening/backgroundScreeningAction";
const BackgroundReportScreen = () => {
  const navigate = useNavigate();
  const [showAggrement, setShowAggrement] = useState(false);
  const [alertModel, setAlertModel] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [skip, setSkip] = useState(0);
  // const [data, setData] = useState([]);
  // const backgroundList = async () => {
  //   const res = await profileApi.getBackgroundList(
  //     { limit: 100, skip: 0 },
  //     token
  //   );
  //   if (res?.success) setData(res?.data);
  // };

  // useEffect(() => {
  //   backgroundList();
  // }, []);

  const { screeningList } = useSelector((state) => state.backgroundScreen);
  console.log(screeningList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScreeningRequest({ limit: 100, skip: 0 }));
  }, []);

  const status = (status) => {
    if (status == "pending")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#FE980033", color: "#FE9800" }}
        >
          Pending
        </p>
      );
    else if (status == "Success")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#169B0033", color: "#169B00" }}
        >
          Success
        </p>
      );
    else if (status == "Completed")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#0432A31A", color: "#0432A3" }}
        >
          Completed
        </p>
      );
    else if (status == "Failed")
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#FE1E0033", color: "#FE1E00" }}
        >
          Failed
        </p>
      );
    else
      return (
        <p
          className="p-1 rounded"
          style={{ background: "#0432A31A", color: "#0432A3" }}
        >
          {status}
        </p>
      );
  };
  return (
    <>
      <Navbar isJobs={false} />

      <div className="background_service_screen">
        <BackgroundHeader />
        <div className="container mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-dark">
              Background Screening Services Agreement
            </h5>
            <div className="border rounded-circle p-1">
              <img
                src={ArrowIcon}
                width={25}
                onClick={() => setShowAggrement(!showAggrement)}
              />
            </div>
          </div>
          {showAggrement && <AggrementContent />}
          <hr />

          <div className="request-table">
            <table class="table">
              <thead className="">
                <tr>
                  <th scope="col">Order</th>
                  <th scope="col">Name</th>
                  <th scope="col">Flag</th>
                  <th scope="col">Status</th>
                  <th scope="col">Report Progress</th>
                  <th scope="col">Last Update</th>
                  <th scope="col">Order Number</th>
                </tr>
              </thead>

              {screeningList?.length > 0 && (
                <tbody>
                  {screeningList?.map((ele, i) => {
                    return (
                      <>
                        <tr
                          onClick={() =>
                            navigate("/report-info", {
                              state: ele?.id,
                            })
                          }
                        >
                          <td>{i + 1}</td>
                          <td style={{ cursor: "pointer" }}>
                            {ele?.user?.first_name} {ele?.user?.last_name}
                          </td>
                          <td>{ele?.falg || "-"}</td>
                          <td className="d-flex jsutify-content-center align-items-center">
                            {status(ele.backgroundStatus || "failed")}
                          </td>
                          <td>{ele?.report_progress || "-"}</td>
                          <td>{moment(ele?.updatedAt).format("DD/MM/yyyy")}</td>
                          <td>{ele?.orderNumber}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              )}
            </table>
            {screeningList?.length == 0 && (
              <p className="text-center mb-3">No data found</p>
            )}
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="btn btn-blue"
                onClick={() => {
                  console.log("abc");
                  setAlertModel(true);
                }}
              >
                Start Screening Process
              </button>
            </div>
          </div>

          <div
            className="mt-4"
            style={{
              background: "#EEF8FE",
              position: "relative",
              padding: "15px",
            }}
          >
            <div
              className="rounded-circle bg-white p-1"
              style={{
                position: "absolute",
                top: "-13px",
              }}
            >
              <img src={notiIcon} width={20} />
            </div>
            <p className="mt-1">
              NobelPage does not store your data. Please ensure a stable
              internet connection before starting the background screening
              process, as it must be completed in one session. Your data will be
              directly transferred to Global Background Screening (GBS) and
              removed from our temporary storage upon successful transmission.
            </p>
          </div>

          <Footer />
        </div>
        {alertModel && (
          <AlertModel
            modal={alertModel}
            toggle={() => setAlertModel(!alertModel)}
          />
        )}
      </div>
    </>
  );
};

export default BackgroundReportScreen;
