import React from "react";
import "./backgroundScreening.scss";
const AggrementContent = () => {
  return (
    <>
      <div className="background-screen mt-2">
        <div className="mt-3">
          <h6 className="mb-2 text-dark">Introduction:</h6>
          <p>
            Global Background Screening LLC, hereafter ("GBS") is esteemed for
            its rigorous background verification processes. NobelPage has
            exclusively partnered with GBS to offer our members privileged
            access to specialized verification services, carried out with the
            utmost security and attention to detail.
          </p>
        </div>
        <div className="mt-3">
          <h6 className="mb-2 text-dark">Service Provisions:</h6>
          <ul className="px-4">
            <li>
              Educational Verification: Acquire an educational badge following
              successful verification of academic records by GBS.
            </li>
            <li>
              Employment Screening: Attain an employment badge upon GBS's
              authentication of your professional history.
            </li>
            <li>
              {" "}
              Criminality Check (Worldscan): Receive a criminality badge
              subsequent to GBS's global criminal history review.
            </li>
          </ul>
          <p>
            The addition of these badges to your profile will attest to your
            credibility and enhance your visibility to potential employers
            within the NobelPage network.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">Pricing, Bundling, and Discounts:</h6>
          <p>
            Members of NobelPage benefit from specially negotiated rates for GBS
            services. Combining all three screening services shall result in
            additional membership tier-based discounts.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">Refund Policy Exceptions:</h6>
          <p>
            The verification and screening services are non-refundable. However,
            if GBS is unable to perform verification due to force majeure,
            sanctions, or other significant barriers, and the failure is deemed
            to be GBS's responsibility, a case-by-case evaluation will be
            conducted. A refund may be issued, less a proportional service fee.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">
            Documentation Submission and Authorization:
          </h6>
          <p>
            To initiate the verification and screening process, you are required
            to submit all necessary documents promptly and accurately. By
            proceeding, you hereby authorize GBS to conduct the screenings as
            mandated.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">Confirmation and Communication:</h6>
          <p>
            Upon completion of payment, you will receive a confirmation email,
            which will include a dedicated communication channel (nobelpage@
            globalbackgroundscreening.com) for inquiries and updates. You can
            monitor the status of your screening either through your NobelPage
            profile or by contacting the official channel provided.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">Service Duration:</h6>
          <p>
            The verification process shall take approximately 3-7 working days,
            contingent upon the timely and correct submission of all required
            documents and information.
          </p>
        </div>

        <div className="mt-3">
          <h6 className="mb-2 text-dark">Legal Consent and Acknowledgment:</h6>
          <p>By initiating the background screening process with GBS, you:</p>
          <ul className="px-4 mt-2">
            <li>
              Confirm the accuracy and completeness of your NobelPage profile
              and submitted documents.
            </li>
            <li>Consent to GBS's performance of the background screenings.</li>
            <li>
              Acknowledge the specific conditions under which refunds are
              evaluated and potentially issued.
            </li>
            <li>
              Agree to the stipulated processing time for the services
              requested.
            </li>
          </ul>
          <p>
            The addition of these badges to your profile will attest to your
            credibility and enhance your visibility to potential employers
            within the NobelPage network.
          </p>
        </div>
      </div>
    </>
  );
};

export default AggrementContent;
