import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../assets/img/uploadBlack.svg";
import "./Drag.scss";

function Dropzone({ setFiles }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      if (acceptedFiles.length > 0) {
        setFiles(acceptedFiles); // Set the single accepted file
      }
    },
    [setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: "application/pdf",
    onDrop,
  });

  return (
    <div className="text-center">
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="image-upload">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the file here</p>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <img src={drop} alt="drag-n-drop" height={20} width={20} />
              <span className="mx-2 fw-bold">Drop a file here...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dropzone;
