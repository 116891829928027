import React, { useEffect, useState } from "react";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import Sidebar from "../../../../core/components/layout/Sidebar/Sidebar";
import SidebarFooter from "../../../setting/SidebarFooter";
import { VideoSidebar } from "../../../../core/components/layout/Sidebar/VideoSidebar";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../../store/loader/loaderActions";
import {
  followPage,
  getAllPages,
  unFollowPage,
} from "../../../../store/pages/pagesAction";
import { useNavigate } from "react-router";
import {
  getSuggestedGroups,
  joinGroups,
  leaveGroup,
} from "../../../../store/groups/groupsActions";
import defaultGroupIcon from "../../../../assets/img/default_group_icon.svg";
import add from "../../../../assets/img/add-icon.svg";
import UnfollowIcon from "../../../../assets/img/UnfollowIcon.svg";
import unfollow from "../../../../assets/img/withd.png";
import nopreview from "../../../../assets/img/no-preview.png";

import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import {
  getSuggestedNewsletters,
  subscribeNewsletter,
  unsubscribeNewsletter,
} from "../../../../store/newsletter/newsletterActions";
import moment from "moment";
import {
  followHashtag,
  getHashtagData,
} from "../../../../store/hashtag/hashtagActions";
import InfiniteScroll from "react-infinite-scroll-component";

const Interest = (props) => {
  const {
    profileData,
    deleteIntroVideo,
    isAllowedToEdit,
    addIntroModal,
    setAddIntroModal,
  } = props;
  const { pageData } = useSelector((state) => state.pages);
  const { joined_groups } = useSelector((state) => state.groups);
  const { subscribed_newsletters } = useSelector((state) => state.newsletter);
  const { followedTagList } = useSelector((state) => state.hashtag);
  const [tabs, setTabs] = useState("Companies");
  const [isLoading, setIsLoading] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [currentCount, setCurrentCount] = useState(0);
  const [currentPageCount, setCurrentPageCount] = useState(1);

  useEffect(() => {
    console.log("currentPageCount == 1", currentPageCount <= 1);
    if (tabs === "Companies") {
      currentPageCount <= 1 ? setIsLoading(true) : setIsLoading(false);
      dispatch(
        getAllPages({
          filter: "following-company",
          page: currentPageCount,
        })
      )
        .then((res) => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        });
    } else if (tabs === "Schools") {
      currentPageCount <= 1 ? setIsLoading(true) : setIsLoading(false);
      dispatch(
        getAllPages({
          filter: "following-institute",
          page: currentPageCount,
        })
      )
        .then((res) => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        });
    } else if (tabs === "Groups") {
      let query = "joined";
      currentPageCount <= 1 ? setIsLoading(true) : setIsLoading(false);
      dispatch(
        getSuggestedGroups(
          query,
          { filter: "joined", page: currentPageCount },
          currentPageCount,
          true
        )
      )
        .then((res) => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        });
    } else if (tabs === "Newsletters") {
      currentCount === 0 ? setIsLoading(true) : setIsLoading(false);
      let query = "subscribed";
      dispatch(
        getSuggestedNewsletters(
          token,
          query,
          {
            type: "subscribed",
            skip: currentCount,
            limit: 10,
          },
          currentCount,
          true
        )
      )
        .then((res) => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("errr", error);
          dispatch(stopPageLoad());
          setIsLoading(false);
        });
    } else if (tabs === "Hashtags") {
      currentCount === 0 ? setIsLoading(true) : setIsLoading(false);
      let query = "followed";
      dispatch(getHashtagData(query, currentCount))
        .then((res) => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(stopPageLoad());
          setIsLoading(false);
        });
    }
  }, [tabs, , currentCount, currentPageCount]);
  const loadMoreData = () => {
    setCurrentCount(currentCount + 10);
  };
  const loadMoreHashtagData = () => {
    setCurrentCount(currentCount + 20);
  };
  const loadMorePageData = () => {
    setCurrentPageCount(currentPageCount + 1);
  };
  useEffect(() => {
    setCurrentCount(0);
    setCurrentPageCount(1);
  }, [tabs]);
  const handleFollow = (pageID, is_following) => {
    if (is_following) {
      dispatch(unFollowPage(pageID)).then((res) => {
        if (!res.success) {
          toast.success("Page unfollowed successfully!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    } else {
      dispatch(followPage(pageID)).then((res) => {
        if (res.success) {
          toast.success("Page followed successfully!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    }
  };
  const joinHandle = (id, type) => {
    dispatch(joinGroups(id, type)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };
  const leaveHandle = (id) => {
    setIsLeaving(true);
    dispatch(leaveGroup(id, true)).then((response) => {
      setIsLeaving(false);
      if (response.success) {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };
  const subscribeHandle = (id) => {
    dispatch(subscribeNewsletter(id, token)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };

  const UnsubscribeHandle = (id) => {
    dispatch(unsubscribeNewsletter(id, token)).then((res) => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
  };
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [leaveGroupID, setLeaveGroupID] = useState();

  const follow_hashtag = (hashtagId, hashtagName, type) => {
    dispatch(followHashtag(hashtagId, hashtagName, type, "followed_tags")).then(
      (res) => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }
    );
  };

  return (
    <div className="row">
      <div className="col-lg-9">
        <div className="recomendation mb-30">
          <div className="head_flex_rec">
            <div className="heading">
              <h6 className="text-dark">Interests</h6>
            </div>
          </div>
          <div className="d-flex mt-30 gap-3 recommend-tab">
            <div
              className={tabs === "Companies" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Companies");
                // setShowAllRecommend(true);
              }}
            >
              Companies
            </div>
            <div
              className={tabs === "Groups" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Groups");
                // setShowAllRecommend(true);
              }}
            >
              Groups
            </div>
            <div
              className={tabs === "Newsletters" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Newsletters");
                // setShowAllRecommend(true);
              }}
            >
              Newsletters
            </div>
            <div
              className={tabs === "Schools" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Schools");
                // setShowAllRecommend(true);
              }}
            >
              Schools
            </div>
            <div
              className={tabs === "Hashtags" ? "circle-blue" : "borders"}
              onClick={() => {
                setTabs("Hashtags");
                // setShowAllRecommend(true);
              }}
            >
              Hashtags
            </div>
          </div>
          {isLoading ? (
            <h6 className="ms-2 mt-4">Loading...</h6>
          ) : (
            <div>
              {tabs === "Companies" || tabs === "Schools" ? (
                <div>
                  {pageData?.data?.length > 0 && (
                    <InfiniteScroll
                      dataLength={pageData?.data?.length}
                      next={loadMorePageData}
                      hasMore={true}
                      style={{ overflow: "visible" }}
                    >
                      <>
                        {pageData?.data?.map((item, idx) => (
                          <div className="row mt-30" key={idx}>
                            <div className=" mb-3">
                              <div className="row" style={{ display: "flex" }}>
                                <div className="col-2 col-sm-1">
                                  <div className="profile-img_rec">
                                    <img
                                      src={
                                        item?.icon
                                          ? item?.icon
                                          : placeholderUser
                                      }
                                      alt=""
                                      className="image-fit"
                                      onClick={() =>
                                        navigate(`/pageadminview/${item?.id}`)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-10 col-sm-11">
                                  <div className="details ml-20 ">
                                    <div className="row">
                                      <h6
                                        className="card-control-text-oneline col-xs-6"
                                        onClick={() =>
                                          navigate(`/pageadminview/${item?.id}`)
                                        }
                                      >
                                        {item?.name}
                                      </h6>
                                      <p className="card-control-text-twoline">
                                        {item?.followers_count}{" "}
                                        {item?.followers_count > 1
                                          ? "Followers"
                                          : "Follower"}
                                      </p>
                                      <div
                                        className="btn btn-light text-blue btn-margin-bottom col-2 col-sm-3 col-md-3 col-lg-2 mt-3 button-unfollow"
                                        onClick={() => {
                                          handleFollow(
                                            item.id,
                                            item.is_following
                                          );
                                        }}
                                      >
                                        {item.is_following ? (
                                          <span>
                                            <img
                                              src={unfollow}
                                              alt=""
                                              className="followicon"
                                              style={{ marginRight: "6px" }}
                                            />
                                            Unfollow
                                          </span>
                                        ) : (
                                          <span>
                                            <img
                                              src={add}
                                              alt=""
                                              className="followicon"
                                              style={{ marginRight: "6px" }}
                                            />
                                            Follow
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    </InfiniteScroll>
                  )}
                </div>
              ) : tabs === "Groups" ? (
                <InfiniteScroll
                  dataLength={joined_groups?.length}
                  next={loadMorePageData}
                  hasMore={true}
                  style={{ overflow: "visible" }}
                >
                  <>
                    {joined_groups?.map((data, idx) => (
                      <div className="row mt-30" key={idx}>
                        <div className=" mb-3">
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-2 col-sm-1">
                              <div className="profile-img_rec">
                                <img
                                  src={
                                    data?.icon ? data.icon : defaultGroupIcon
                                  }
                                  alt=""
                                  className="image-fit"
                                  onClick={() =>
                                    navigate(`/groupdetails/${data?.id}`)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-10 col-sm-11">
                              <div className="details ml-20 ">
                                <div className="row">
                                  <h6
                                    className="card-control-text-oneline col-xs-6"
                                    onClick={() =>
                                      navigate(`/groupdetails/${data?.id}`)
                                    }
                                  >
                                    {data?.name}
                                  </h6>
                                  <p className="card-control-text-twoline">
                                    {data?.members?.length}{" "}
                                    {data?.members?.length > 1
                                      ? "Followers"
                                      : "Follower"}
                                  </p>
                                  <div className="col-2 mt-3">
                                    {data?.members_count ===
                                    data?.members_limit ? (
                                      <div
                                        className="btn btn-light disabled-btn-blue btn-margin-bottom"
                                        onClick={() =>
                                          toast.warn(
                                            "Can't join more members",
                                            {
                                              position:
                                                toast.POSITION.BOTTOM_LEFT,
                                            }
                                          )
                                        }
                                      >
                                        Join
                                      </div>
                                    ) : data?.is_joined === "confirm" ? (
                                      <div
                                        className="btn btn-light text-blue btn-margin-bottom"
                                        onClick={() => {
                                          setLeaveGroupID(data?.id);
                                          setConfirmDeleteModal(true);
                                        }}
                                      >
                                        {data?.id === leaveGroupID && isLeaving
                                          ? "Leaving..."
                                          : "Leave"}
                                      </div>
                                    ) : data?.is_joined === "pending" ||
                                      data?.is_joined === "recieved" ? (
                                      <div
                                        className="btn btn-light btn-margin-bottom"
                                        onClick={() => {
                                          alert(
                                            "Please wait for group admin response"
                                          );
                                        }}
                                      >
                                        Requested{" "}
                                      </div>
                                    ) : (
                                      <div
                                        className="btn btn-light btn-margin-bottom"
                                        onClick={() =>
                                          joinHandle(data.id, "joinTrue")
                                        }
                                      >
                                        Join
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                </InfiniteScroll>
              ) : tabs === "Newsletters" ? (
                <InfiniteScroll
                  dataLength={subscribed_newsletters?.length}
                  next={loadMoreData}
                  hasMore={true}
                  style={{ overflow: "visible" }}
                >
                  <>
                    {subscribed_newsletters?.map((item, idx) => (
                      <div className="row mt-30" key={idx}>
                        <div className=" mb-3">
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-2 col-sm-1">
                              {item?.media ? (
                                <div className="profile-img_rec">
                                  <img
                                    src={item?.media}
                                    alt=""
                                    className="image-fit"
                                    onClick={() =>
                                      navigate(`/pageadminview/${item?.id}`)
                                    }
                                  />
                                </div>
                              ) : (
                                <div
                                  className="profile-img_rec"
                                  style={{
                                    background:
                                      "linear-gradient(120.5deg, #5b2e92 0%, #29409c 50.49%, #0f91d2 100%)",
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="col-10 col-sm-11">
                              <div className="details ml-20 ">
                                <div className="row">
                                  <h6
                                    className="card-control-text-oneline col-xs-6"
                                    onClick={() =>
                                      navigate(`/newsletterdetail${item?.id}`)
                                    }
                                  >
                                    {item?.title}
                                  </h6>
                                  <p className="card-control-text-twoline">
                                    {moment(item?.createdAt)
                                      .startOf("minute")
                                      .fromNow()}
                                  </p>
                                  {item?.subscribed?.status ? (
                                    <div
                                      className="btn btn-light btn-blue-color col-2 mt-3"
                                      onClick={() => {
                                        UnsubscribeHandle(item?.id);
                                      }}
                                    >
                                      Unsubscribe
                                    </div>
                                  ) : (
                                    <div
                                      className="btn btn-light btn-blue-color col-2 mt-3"
                                      onClick={() => {
                                        subscribeHandle(item?.id);
                                      }}
                                    >
                                      Subscribe
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                </InfiniteScroll>
              ) : tabs === "Hashtags" ? (
                <InfiniteScroll
                  dataLength={followedTagList?.length}
                  next={loadMoreHashtagData}
                  hasMore={true}
                  style={{ overflow: "visible" }}
                >
                  <>
                    {followedTagList?.map((item, idx) => (
                      <div className="row mt-30" key={idx}>
                        <div className=" mb-3">
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-2 col-sm-1">
                              <div className="profile-img_rec">
                                <img
                                  src={
                                    item?.icon ? item?.icon : placeholderUser
                                  }
                                  alt=""
                                  className="image-fit"
                                  onClick={() =>
                                    navigate(
                                      `/hashtag/feed/?keyword=${item?.name}`
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-10 col-sm-11">
                              <div className="details ml-20 ">
                                <div className="row">
                                  <h6
                                    className="card-control-text-oneline col-xs-6"
                                    onClick={() =>
                                      navigate(
                                        `/hashtag/feed/?keyword=${item?.name}`
                                      )
                                    }
                                  >
                                    {item?.name}
                                  </h6>
                                  <p className="card-control-text-twoline">
                                    {item?.post_count}{" "}
                                    {item?.post_count > 1 ? "posts" : "post"}
                                  </p>
                                  {item?.follow_type === undefined ||
                                  item?.follow_type === true ? (
                                    <div
                                      className="btn btn-light text-blue col-2 mt-3"
                                      onClick={() =>
                                        follow_hashtag(
                                          item?.id,
                                          item?.name,
                                          "unfollow"
                                        )
                                      }
                                    >
                                      <img
                                        src={unfollow}
                                        style={{ marginRight: "6px" }}
                                      />
                                      Unfollow
                                    </div>
                                  ) : (
                                    <div
                                      className="btn btn-light text-blue  col-2 mt-3"
                                      onClick={() =>
                                        follow_hashtag(
                                          item?.id,
                                          item?.name,
                                          "follow"
                                        )
                                      }
                                    >
                                      <span className="mr-10">
                                        <img
                                          src={add}
                                          alt=""
                                          style={{ marginRight: "6px" }}
                                        />
                                      </span>
                                      Follow
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                </InfiniteScroll>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-3">
        <div className="mb-20">
          <VideoSidebar
            URLData={profileData?.intro_video}
            deleteIntroVideo={deleteIntroVideo}
            isAllowedToEdit={isAllowedToEdit}
            addIntroModal={addIntroModal}
            setAddIntroModal={setAddIntroModal}
          />
        </div>
        <div className="mb-20">
          <Sidebar />
        </div>
        <div className="sidebar-sticky mb-20">
          <SidebarFooter />
        </div>
      </div>
      {confirmDeleteModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
          title="Leave Confirmation"
          deleteConfirm={() => {
            leaveHandle(leaveGroupID);
          }}
          text="Are you want to sure leave this group?"
        />
      )}
    </div>
  );
};

export default Interest;
