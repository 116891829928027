import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTalentPoolDetails } from "../store/portal/portalAction";
import { useEffect } from "react";

const TPPublicRoute = ({ children }) => {
  const { param } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const favicon = document.querySelector("link#favicon");
    const title = document.querySelector("title#title");

    if (param) {
      dispatch(getTalentPoolDetails(param))
        .then((res) => {
          if (res?.data?.icon && favicon)
            favicon.setAttribute("href", res?.data?.icon);
          if (res?.data?.company_name && title)
            title.innerHTML = `NobelPage | ${res?.data?.company_name}`;
        })
        .catch((err) =>
          err?.request?.status == 404 ? navigate("/error") : console.log(err)
        );
    }
    return () => {
      favicon.setAttribute("href", "/favicon.svg");
      title.innerHTML = `NobelPage`;
    };
  }, []);
  return children;
};

export default TPPublicRoute;
