import React, { useEffect, useState } from "react";
import "./PortalAuth.scss";
import signupimage from "../../../assets/img/signup-portal.svg";
import logo from "../../../assets/img/logowithname.svg";
import companylogo from "../../../assets/img/company-name.svg";
import LoginModal from "./LoginModal";
import ForgotPasswordModal from "./ForgotPassModal";
import OtpEmail from "../Signup/OtpEmail";
import ChangePasswordModal from "./ChangePasswordModal";

const PortalWelcome = (props) => {
  const { navigate } = props;
  const [loginModal, setLoginModal] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  return (
    <div className="login-portal">
      <div className="col-4 left">
        <img src={signupimage} alt="signupimage" className="center" />
      </div>
      <div className="col-8 ">
        <div className="welcome">
          <p>Welcome 👋</p>
          <h3 className="form-heading">Login to your Recruiter Account</h3>
          <div className="sub-heading">
            <h4 className="my-4" onClick={() => setLoginModal(true)}>
              Login with{" "}
              <img
                className="nobalaccount"
                src={logo}
                alt="logo"
                // onClick={() => navigate(`/`)}
              />{" "}
            </h4>
          </div>
        </div>
        <div className="logo">
          <img src={companylogo} alt="company-logo" />
        </div>
      </div>

      {loginModal && (
        <LoginModal
          modal={loginModal}
          toggle={() => setLoginModal(!loginModal)}
          setForgotPasswordModal={setForgotPasswordModal}
          title="Log in"
        />
      )}
      {forgotPasswordModal && (
        <ForgotPasswordModal
          modal={forgotPasswordModal}
          toggle={() => setForgotPasswordModal(!forgotPasswordModal)}
          otpModal={otpModal}
          setOtpModal={setOtpModal}
          title="Forgot Password"
        />
      )}
      {otpModal && (
        <OtpEmail
          modal={otpModal}
          toggle={() => setOtpModal(false)}
          title="Verify Email"
          setOtpModal={setOtpModal}
          setChangePasswordModal={setChangePasswordModal}
        />
      )}
      {changePasswordModal && (
        <ChangePasswordModal
          modal={changePasswordModal}
          toggle={() => setChangePasswordModal(false)}
          title="Change Password"
          setChangePasswordModal={setChangePasswordModal}
        />
      )}
    </div>
  );
};

export default PortalWelcome;
