import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <ul className="d-flex" style={{ listStyleType: "disc" }}>
        <li>
          <Link
            to={`${process.env.REACT_APP_WEB_STAGE_URL}legal`}
            style={{ color: "#676A79" }}
          >
            {" "}
            Legals terms
          </Link>
        </li>
        <li className="mx-5">
          <Link
            to={`${process.env.REACT_APP_WEB_STAGE_URL}legal`}
            style={{ color: "#676A79" }}
          >
            Terms of use
          </Link>
        </li>
        <li>
          <Link
            to={`${process.env.REACT_APP_WEB_STAGE_URL}help`}
            style={{ color: "#676A79" }}
          >
            Help
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
