import { jobApi } from "../../api/jobs";
import { toast } from "react-toastify";
// import { initPageLoad, stopPageLoad } from "../loader/loaderActions";
import {
  CREATE_JOB_POST_SUCCESS,
  CREATE_JOB_QUESTIONARY,
  DELETE_QUESTIONARY_SET,
  GET_ALL_APPLICANTS_DETAILS_BYID,
  GET_ALL_JOBS,
  GET_ALL_JOBS_APPLICANTS_DETAILS,
  GET_ALL_JOBS_DETAILS,
  GET_ALL_JOBS_MESSAGES,
  GET_QUESTIONARY_LIST,
  LOAD_MORE_GET_QUESTIONARY_LIST,
  LOAD_MORE_SHORTLISTED_CANDIDATE_LIST,
  SEND_SMART_INTERVIEW,
  SHORTLISTED_CANDIDATE_LIST,
  SHORTLIST_CANDIDATE_FOR_JOBS,
  UPDATE_APPLICANTS_SUCCESS,
  UPDATE_APPLICANTS_FAIL,
  UPDATE_JOB_DETAIL_FAIL,
  UPDATE_JOB_DETAIL_SUCCESS,
  UPDATE_JOB_MESSAGE,
  SAVE_JOB,
  GET_QUESTIONS,
  SMART_APPLY_POST_FAIL,
  SMART_APPLY_POST,
  LOAD_MORE_GET_ALL_JOBS,
  APPLY_FOR_JOB,
  CREATE_JOB_ALERT,
  GET_JOB_ALERT,
  DELETE_JOB_ALERT,
  EDIT_JOB_ALERT,
  UPDATE_JOB_QUESTIONARY,
  GET_MY_JOBS,
  LOAD_MORE_GET_MY_JOBS,
  JOB_PREFERENCE,
  SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY,
  CLEAR_SEARCH_HISTORY,
  MORE_SEARCH_HISTORY,
  SHORTLISTED_VIEW_CANDIDATE_LIST,
  LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST,
  FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST,
  FAIL_SHORTLISTED_CANDIDATE_LIST,
  GET_FEATURED_JOBS,
  LOAD_MORE_FEATURED_JOBS,
  CLOSE_JOB_SUCCESS,
  UPDATE_JOB_DESCERPTION,
  GET_SAVED_JOBS,
  LOAD_MORE_SAVED_JOBS,
  GET_APPLIED_JOBS,
  LOAD_MORE_APPLIED_JOBS,
  GET_SENT_INTERVIEW_JOBS,
  LOAD_MORE_SENT_INTERVIEW_JOBS,
  GET_INPROGRESS_JOBS,
  LOAD_MORE_INPROGRESS_JOBS,
  GET_CLOSED_JOBS,
  LOAD_MORE_CLOSED_JOBS,
  UPDATE_JOB_FORM,
  GET_ALL_FEATURED_JOBS,
  LOAD_MORE__ALL_FEATURED_JOBS,
  ADD_JOB_FEEDBACK,
} from "../types";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../loader/loaderActions";

// export const createJobPostAction = (data) => (dispatch) => {
//   return jobApi.createJobPost(data).then(
//     (response) => {
//       dispatch({
//         type: CREATE_JOB_POST_SUCCESS,
//         payload: response.data,
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       toast.error(
//         error.response.data.message,

//         {
//           position: toast.POSITION.BOTTOM_LEFT,
//         }
//       );
//       return Promise.reject(error);
//     }
//   );
// };

export const createJobPostAction = (data) => (dispatch) => {
  return jobApi.createJobPost(data).then(
    (response) => {
      dispatch({
        type: CREATE_JOB_POST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);

      if (
        error.response.data.message ===
        '"smart_apply_questions" must contain at least 1 items'
      ) {
        toast.error("Smart apply questions must contain at least 1 item", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        // Handle other error cases or provide a generic error message
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }

      return Promise.reject(error);
    }
  );
};

export const getJobs = (params, currentCount, type) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getJobList(params).then(
    (response) => {
      if (type === "my") {
        if (currentCount === 0) {
          dispatch({
            type: GET_MY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_GET_MY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "featured") {
        if (currentCount === 0) {
          dispatch({
            type: GET_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "allfeatured") {
        if (currentCount === 0) {
          dispatch({
            type: GET_ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE__ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "saved") {
        if (currentCount === 0) {
          dispatch({
            type: GET_SAVED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_SAVED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }

      if (type === "applied") {
        if (currentCount === 0) {
          dispatch({
            type: GET_APPLIED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_APPLIED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "interview") {
        if (currentCount === 0) {
          dispatch({
            type: GET_SENT_INTERVIEW_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_SENT_INTERVIEW_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "inprogress") {
        if (currentCount === 0) {
          dispatch({
            type: GET_INPROGRESS_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_INPROGRESS_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "closed") {
        if (currentCount === 0) {
          dispatch({
            type: GET_CLOSED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_CLOSED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (currentCount === 0) {
        dispatch({
          type: GET_ALL_JOBS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      } else {
        dispatch({
          type: LOAD_MORE_GET_ALL_JOBS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const getPublicJobs = (params, currentCount, type) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getPublicJobList(params).then(
    (response) => {
      if (type === "my") {
        if (currentCount === 0) {
          dispatch({
            type: GET_MY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_GET_MY_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "featured") {
        if (currentCount === 0) {
          dispatch({
            type: GET_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "allfeatured") {
        if (currentCount === 0) {
          dispatch({
            type: GET_ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE__ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "saved") {
        if (currentCount === 0) {
          dispatch({
            type: GET_SAVED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_SAVED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }

      if (type === "applied") {
        if (currentCount === 0) {
          dispatch({
            type: GET_APPLIED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_APPLIED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "interview") {
        if (currentCount === 0) {
          dispatch({
            type: GET_SENT_INTERVIEW_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_SENT_INTERVIEW_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "inprogress") {
        if (currentCount === 0) {
          dispatch({
            type: GET_INPROGRESS_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_INPROGRESS_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (type === "closed") {
        if (currentCount === 0) {
          dispatch({
            type: GET_CLOSED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE_CLOSED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      if (currentCount === 0) {
        dispatch({
          type: GET_ALL_JOBS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      } else {
        dispatch({
          type: LOAD_MORE_GET_ALL_JOBS,
          payload: response.data,
        });
        dispatch(stopLoad());
        return Promise.resolve(response);
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const getAllPortalJobs = (params, currentCount, type) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getPortalJobList(params).then(
    (response) => {
      if (type === "allfeatured") {
        if (currentCount === 0) {
          dispatch({
            type: GET_ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        } else {
          dispatch({
            type: LOAD_MORE__ALL_FEATURED_JOBS,
            payload: response.data,
          });
          dispatch(stopLoad());
          return Promise.resolve(response);
        }
      }
      // if (currentCount === 0) {
      dispatch({
        type: GET_ALL_JOBS,
        payload: response.data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
      // } else {
      //   dispatch({
      //     type: LOAD_MORE_GET_ALL_JOBS,
      //     payload: response.data,
      //   });
      //   dispatch(stopLoad());
      //   return Promise.resolve(response);
      // }
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const applyForJob =
  (id, data = {}) =>
  (dispatch) => {
    return jobApi.applyJob(id, data).then(
      (response) => {
        dispatch({
          type: APPLY_FOR_JOB,
          payload: response.data,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getJobsDetail = (params) => (dispatch) => {
  return jobApi.getJobDetails(params).then(
    (response) => {
      dispatch({
        type: GET_ALL_JOBS_DETAILS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getDetailApplicantId = (params) => (dispatch) => {
  return jobApi.getApplicantsDetailsById(params).then(
    (response) => {
      dispatch({
        type: GET_ALL_APPLICANTS_DETAILS_BYID,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getJobsMessage = (params) => (dispatch) => {
  return jobApi.getJobMessage(params).then(
    (response) => {
      dispatch({
        type: GET_ALL_JOBS_MESSAGES,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getApplicantsDetails = (params) => (dispatch) => {
  return jobApi.getJobApplicatantsDetails(params).then(
    (response) => {
      dispatch({
        type: GET_ALL_JOBS_APPLICANTS_DETAILS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateJobData = (data, jobId, type) => (dispatch) => {
  return jobApi.getJobDetailUpdate(data, jobId).then(
    (response) => {
      if (type === "closejob") {
        dispatch({
          type: CLOSE_JOB_SUCCESS,
          payload: { data: data, jobId: jobId },
        });
        return Promise.resolve(response);
      }
      if (type === "update-desc") {
        dispatch({
          type: UPDATE_JOB_DESCERPTION,
          payload: { data: data, jobId: jobId },
        });
        return Promise.resolve(response);
      }
      dispatch({
        type: UPDATE_JOB_DETAIL_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_JOB_DETAIL_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// getApplicantsUpdate

export const saveJob = (id) => (dispatch) => {
  return jobApi.saveJobByID(id).then(
    (res) => {
      dispatch({
        type: SAVE_JOB,
        payload: id,
      });
      return Promise.resolve(res);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      return Promise.reject(error);
    }
  );
};

export const updateApplicant = (data, id) => (dispatch) => {
  return jobApi.getApplicantsUpdate(data, id).then(
    (response) => {
      dispatch({
        type: UPDATE_APPLICANTS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_APPLICANTS_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateJobsMessage = (data) => (dispatch) => {
  return jobApi.updateMessage(data).then(
    (response) => {
      dispatch({
        type: UPDATE_JOB_MESSAGE,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const shortListCandidateForJobs = (data) => (dispatch) => {
  return jobApi.shortlistCandidate(data).then(
    (response) => {
      dispatch({
        type: SHORTLIST_CANDIDATE_FOR_JOBS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getShotlistedList = (params, currentCount) => (dispatch) => {
  dispatch(initPageLoad());
  return jobApi.getShortlistedCandidate(params).then(
    (response) => {
      if (response.success) {
        if (params.type === "view") {
          if (currentCount === 0) {
            dispatch({
              type: SHORTLISTED_VIEW_CANDIDATE_LIST,
              payload: response,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(response);
          } else
            dispatch({
              type: LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST,
              payload: response,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(response);
        } else {
          if (currentCount === 0) {
            dispatch({
              type: SHORTLISTED_CANDIDATE_LIST,
              payload: response,
            });
            dispatch(stopPageLoad());
            return Promise.resolve(response);
          } else
            dispatch({
              type: LOAD_MORE_SHORTLISTED_CANDIDATE_LIST,
              payload: response,
            });
          dispatch(stopPageLoad());
          return Promise.resolve(response);
        }
      }
    },
    (error) => {
      console.log(error);
      dispatch(stopPageLoad());
      if (currentCount === 0) {
        dispatch({
          type: FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST,
        });

        dispatch({
          type: FAIL_SHORTLISTED_CANDIDATE_LIST,
        });
      }

      return Promise.reject(error);
    }
  );
};

export const createQuestionary = (data) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.createQuestionary(data).then(
    (response) => {
      dispatch({
        type: CREATE_JOB_QUESTIONARY,
        payload: response.data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const getQuestionaryList = (params, currentCount) => (dispatch) => {
  // dispatch(initPageLoad());
  return jobApi.getQuestionary(params).then(
    (response) => {
      // if (currentCount === 0) {
      dispatch({
        type: GET_QUESTIONARY_LIST,
        payload: response.data,
      });
      // dispatch(stopPageLoad());
      return Promise.resolve(response.data);
      // }
      //   else
      //     dispatch({
      //       type: LOAD_MORE_GET_QUESTIONARY_LIST,
      //       payload: response.data,
      //     });
      //   // dispatch(stopPageLoad());
      //   return Promise.resolve(response.data);
    },
    (error) => {
      console.log(error);
      // dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const deleteQuestionary = (setName) => (dispatch) => {
  return jobApi.deleteQuestionarySet(setName).then(
    (response) => {
      dispatch({
        type: DELETE_QUESTIONARY_SET,
        payload: setName,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};
export const updateQuestionary = (data, id) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.updateQuestionaire(data, id).then(
    (response) => {
      dispatch({
        type: UPDATE_JOB_QUESTIONARY,
        payload: { data: response.data, setId: id },
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch(stopPageLoad());
      return Promise.reject(error);
    }
  );
};

export const sendSmartInterview = (data) => (dispatch) => {
  // dispatch(initPageLoad());
  return jobApi.sentSmartInterview(data).then(
    (response) => {
      dispatch({
        type: SEND_SMART_INTERVIEW,
        payload: data,
      });
      // dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const rejectSmartInterview = (shortlist_id) => (dispatch) => {
  // dispatch(initPageLoad());
  return jobApi
    .updateShotlistById(shortlist_id, { interview_result: "rejected" })
    .then(
      (response) => {
        // dispatch(stopPageLoad());
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
};

// getQuestionsList
export const getQuestions = (params) => (dispatch) => {
  return jobApi.getQuestionsList(params).then(
    (response) => {
      dispatch({
        type: GET_QUESTIONS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const SmartApplyData = (data, id) => (dispatch) => {
  return jobApi.updateApplyJob(data, id).then(
    (response) => {
      dispatch({
        type: SMART_APPLY_POST,
        payload: response.data,
      });

      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: SMART_APPLY_POST_FAIL,
      });
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const CreateJobAlert = (data) => (dispatch) => {
  return jobApi.createAlertforJob(data).then(
    (response) => {
      dispatch({
        type: CREATE_JOB_ALERT,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return Promise.reject(error);
    }
  );
};

export const getJobAlert = () => (dispatch) => {
  dispatch(initLoad());
  return jobApi.getAlertsofJob().then(
    (response) => {
      dispatch({
        type: GET_JOB_ALERT,
        payload: response.data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return Promise.reject(error);
    }
  );
};
export const DeleteJobAlert = (alertId) => (dispatch) => {
  return jobApi.deleteAlertofJob(alertId).then(
    (response) => {
      dispatch({
        type: DELETE_JOB_ALERT,
        payload: alertId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return Promise.reject(error);
    }
  );
};

export const editJobAlert = (data, id) => (dispatch) => {
  return jobApi.editAlertofJob(data, id).then(
    (response) => {
      dispatch({
        type: EDIT_JOB_ALERT,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const salaryComparision = (data) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.salaryComparision(data).then(
    (response) => {
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const updateJobPreference = (data) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.updateJobPreference(data).then(
    (response) => {
      dispatch({
        type: JOB_PREFERENCE,
        payload: data,
      });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const jobPreference = () => (dispatch) => {
  dispatch(initLoad());
  return jobApi.jobPreference().then(
    (response) => {
      if (response?.success)
        dispatch({
          type: JOB_PREFERENCE,
          payload: response?.data,
        });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const createSearchHistory =
  (data, tp = false) =>
  (dispatch) => {
    return jobApi.createSearchHistory(data, tp).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getSearchHistory =
  (data, tp = false) =>
  (dispatch) => {
    dispatch(initLoad());
    return jobApi.getSearchHistory(data, tp).then(
      (response) => {
        if (response?.success)
          if (!data?.skip) {
            dispatch({
              type: SEARCH_HISTORY,
              payload: response?.data,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          } else {
            dispatch({
              type: MORE_SEARCH_HISTORY,
              payload: response?.data,
            });
            dispatch(stopLoad());
            return Promise.resolve(response);
          }

        dispatch(stopLoad());
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        dispatch(stopLoad());
        return Promise.reject(error);
      }
    );
  };

export const deleteSearchHistory = (id) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.deleteSearchHistory({ id }).then(
    (response) => {
      if (response?.success)
        dispatch({
          type: DELETE_SEARCH_HISTORY,
          payload: id,
        });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const clearSearchHistory = () => (dispatch) => {
  dispatch(initLoad());
  return jobApi.deleteSearchHistory({ type: "job" }).then(
    (response) => {
      if (response?.success)
        dispatch({
          type: CLEAR_SEARCH_HISTORY,
        });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const jobViewlog =
  (id, tp = false) =>
  (dispatch) => {
    return jobApi.jobViewlog(id, tp).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

export const jobApplicantViewlog = (id, job_id) => (dispatch) => {
  return jobApi.applicantJobViewlog(id, job_id).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const addJobFeedback = (answer, id) => (dispatch) => {
  dispatch(initLoad());
  return jobApi.addJobFeedback(answer, id).then(
    (response) => {
      if (response?.success)
        dispatch({
          type: ADD_JOB_FEEDBACK,
          payload: response?.data,
        });
      dispatch(stopLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      dispatch(stopLoad());
      return Promise.reject(error);
    }
  );
};

export const updateJobForm = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_JOB_FORM,
    payload: data,
  });
};
