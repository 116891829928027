import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import Autocomplete from "react-autocomplete";

const PostNewJobModal = (props) => {
  const { modal, toggle, formData, setFormData, setAddJobDetailModal } = props;
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [titleError, setTitleError] = useState("");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [locationOpen, setLocationOpen] = useState(false);
  const { portal, jobs } = useSelector((state) => state.talentPool);
  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });

  const [workspacetype, setWorkspaceType] = useState("On-site");
  // const [companyLocation, setCompanyLocation] = useState('');
  const [companyLocationError, setCompanyLocationError] = useState("");
  const [employmentType, setEmploymentType] = useState("Full-time");
  const [numberOfVacancies, setNumberOfVacancies] = useState("");
  const [numberOfVacanciesError, setNumberOfVacanciesError] = useState("");

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handleWorkspaceTypeChange = (e) => {
    setWorkspaceType(e.target.value);
  };

  const handleEmploymentType = (e) => {
    setEmploymentType(e.target.value);
  };

  const handleNumberOfVacancies = (e) => {
    const value = e.target.value;

    // Ensure the entered value is a number and between 1 and 999
    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setNumberOfVacancies(value);
      setNumberOfVacanciesError("");
    } else {
      setNumberOfVacancies("");
      setNumberOfVacanciesError(
        "Please enter a valid number between 1 and 999."
      );
    }
  };
  useEffect(() => {
    setTitle(formData?.jobTitle || "");
    setWorkspaceType(formData?.jobWorkspacetype || "On-site");
    setEmploymentType(formData?.jobEmploymentType || "Full-time");
    setSearchFieldValue(formData?.jobCompanyLocation || "");
    setNumberOfVacancies(formData?.jobNumberOfVacancies || "");
  }, []);

  const titleRegex = /^[a-zA-Z0-9\s]+$/;

  const handleValidation = () => {
    let isValid = true;
    let trimmedTitle;
    if (title) {
      trimmedTitle = title.trim();
    }
    // Validate title
    if (
      !trimmedTitle ||
      trimmedTitle.length < 3 ||
      !trimmedTitle.match(titleRegex)
    ) {
      setTitleError(
        "Title must not be empty, should be at least 3 characters long, and should not contain special characters or blank spaces."
      );
      isValid = false;
    } else {
      setTitleError("");
    }

    // Validate companyLocation
    if (!searchFieldValue) {
      setCompanyLocationError("Company location must not be empty.");
      isValid = false;
    } else if (
      locationsAPIData.length === 0 &&
      searchFieldValue &&
      locationOpen
    ) {
      setCompanyLocationError("Please select a value from the dropdown list.");
      isValid = false;
    } else {
      setCompanyLocationError("");
    }
    // Validate numberOfVacancies
    if (!numberOfVacancies) {
      setNumberOfVacanciesError("Please select the Number of Vacancies.");
      isValid = false;
    } else {
      setNumberOfVacanciesError("");
    }

    return isValid;
  };

  const handleGetStarted = () => {
    if (handleValidation()) {
      setFormData({
        jobTitle: title,
        jobWorkspacetype: workspacetype,
        jobEmploymentType: employmentType,
        jobCompanyLocation: searchFieldValue,
        jobNumberOfVacancies: numberOfVacancies,
      });
      setAddJobDetailModal(true);
      toggle();
    }
  };

  return (
    <Modal
      isOpen={modal}
      style={{ maxWidth: "464px", width: "100%" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="post-job-modal">
        <ModalHeader
          toggle={() => {
            setFormData({});
            toggle();
          }}
          style={{ alignItems: "baseline" }}
        >
          {"Find a great hire, fast"}
          <p
            className="mt-2 text-secondary"
            style={{ fontWeight: 400, fontSize: "15px" }}
          >
            This Job also created on Nobelpage
          </p>
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12">
            <form>
              <div>
                <div className="title mb-3">
                  <label>Title</label>
                  <Input
                    className="custom-cursor-style"
                    style={{ color: "gray" }}
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleError("");
                    }}
                    maxLength={50}
                    placeholder="Title"
                  />
                  {titleError && (
                    <div className="error-message" style={{ color: "red" }}>
                      {titleError}
                    </div>
                  )}
                </div>
                <div className="title mb-3">
                  <label>Workspace type</label>
                  <Input
                    type="select"
                    value={workspacetype}
                    onChange={handleWorkspaceTypeChange}
                  >
                    <option value="On-site">On-site</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="Remote">Remote</option>
                  </Input>
                </div>
                <div className="title  mb-3">
                  <label>Company location</label>
                  <div className="company-custom-dropdown mb-20">
                    <div className="autocomplete-wrapper">
                      <div className="search">
                        <Autocomplete
                          open={locationOpen}
                          value={searchFieldValue}
                          items={locationsAPIData}
                          getItemValue={(item) => item.name}
                          shouldItemRender={() => locationsAPIData}
                          renderMenu={(items, value) => (
                            <div className="dropdown">
                              {items.length === 0 ? (
                                <span style={{ color: "red" }}>
                                  No matches for {value}
                                </span>
                              ) : (
                                items
                              )}
                            </div>
                          )}
                          renderItem={(item, isHighlighted) => (
                            <div
                              className={`item ${
                                isHighlighted ? "selected-item" : ""
                              }`}
                            >
                              {item.name}
                            </div>
                          )}
                          onChange={(e, val) => {
                            if (val.length === 0) {
                              if (locationOpen) setLocationOpen(false);
                            } else {
                              if (!locationOpen) setLocationOpen(true);
                            }
                            setSearchFieldValue(e.target.value);
                            getLocations(e.target);
                          }}
                          onSelect={(value, obj) => {
                            setCompanyLocationError("");
                            setSearchFieldValue(value);
                            setPageDetails({
                              ...pageDetails,
                              location: obj.name,
                            });

                            setLocationOpen(false);
                          }}
                          wrapperStyle={{}}
                          renderInput={(params) => {
                            return (
                              <div class="fake-input">
                                <input
                                  {...params}
                                  type="text"
                                  placeholder="Location"
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                    {companyLocationError && (
                      <div className="error-message" style={{ color: "red" }}>
                        {companyLocationError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="title  mb-3">
                <label>Employment type</label>
                <Input
                  type="select"
                  b
                  onChange={handleEmploymentType}
                  value={employmentType}
                >
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Contract">Contract</option>
                  <option value="Temporary">Temporary</option>
                  <option value="Other">Other</option>
                  <option value="Volunteer">Volunteer</option>
                  <option value="Internship">Internship</option>
                </Input>
              </div>
              <div className="title  mb-3">
                <label>Number of Vacancies</label>
                <Input
                  placeholder="Number of vacancies"
                  type="number"
                  name="numberOfVacancies"
                  value={numberOfVacancies}
                  onChange={handleNumberOfVacancies}
                  min="1"
                  max="999"
                />
                {numberOfVacanciesError && (
                  <div className="error-message" style={{ color: "red" }}>
                    {numberOfVacanciesError}
                  </div>
                )}
              </div>

              <div className="startedbutton">
                <div
                  className="btn"
                  style={{
                    textAlign: "center",
                    background: portal?.theme,
                    color: "#fff",
                  }}
                  onClick={() => {
                    handleGetStarted();
                  }}
                >
                  Get started
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default PostNewJobModal;
