import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import tick from "../../assets/img/green-tick.svg";
import cross from "../../assets/img/cross.png";
import gold from "../../assets/img/gold.svg";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";

const MemberShipCards = (props) => {
  const {
    plan,
    index,
    membershipPlanName,
    detailPage,
    handleMore,
    isKnowMore,
    setIsKnowMore,
    isUserPlan,
  } = props;
  const [checkedFeature, setCheckedFeature] = useState();
  const [showMore, setShowMore] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === planPrices.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? planPrices.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const filteredData = plan?.feautures.filter((res) => res.checkmark == true);
    setCheckedFeature(filteredData);
  }, []);

  const planPrices = useMemo(() => [
    {
      id: 1,
      type: "monthly",
      name: "Month",
      price: plan?.monthlyPrize || 0,
    },
    {
      id: 2,
      type: "monthly",
      name: "Year",
      price: plan?.yearlyPrize || 0,
      totalPrice: plan?.yearlyTotalPrize || 0,
      discount: plan?.discount,
    },
  ]);

  return (
    <div
      key={index}
      className={
        "col-md-3 box" +
        // (membershipPlanName === plan?.name && detailPage ? " box-active" : "")
        (isUserPlan ? " box-active" : "")
      }
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          padding: "32px 32px 10px 32px",
          overflow: "auto",
          maxHeight: "875px",
        }}
      >
        <div className="flex">
          <h2>{plan?.name}</h2>
          {plan?.icon == "NP Gold" && <img src={gold} alt="" />}
        </div>
        <p className="">
          {!showMore && plan?.description?.length > 75
            ? plan?.description?.slice(0, 75) + "..."
            : plan?.description}
        </p>
        {plan?.description?.length > 75 && (
          <p style={{ marginBottom: "15px" }}>
            <span
              key={index}
              onClick={() => setShowMore(!showMore)}
              style={{ cursor: "pointer", color: "blue" }}
            >
              {showMore ? "Read Less" : "Read More"}
            </span>
          </p>
        )}

        {/* {showMore === plan?.name && isKnowMore ? ( */}
        <ul>
          {plan?.feautures?.map((feature, index) => (
            <li>
              <img
                src={feature?.checkmark === true ? tick : cross}
                alt="checktrue"
                className="img-check"
              />
              {feature?.Feauture?.name}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ position: "releative" }} className="pb-3">
        <div
          className={
            "line " + (plan?.name === "Starter" ? "mb-4 mt-rem" : "mb-3")
          }
        ></div>
        <div className="bottom">
          {!plan || plan?.id === 1 ? (
            <div className="price">
              <span>Free</span>
            </div>
          ) : (
            <Carousel
              className="membership-price"
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              ride={"carousel"}
              interval={3000}
              pause={""}
            >
              {/* <CarouselIndicators
                activeIndex={activeIndex}
                items={planPrices}
                onClickHandler={goToIndex}
                style={
                  planPrices[activeIndex]?.discount
                    ? { top: "50px" }
                    : { top: "20px" }
                }
              /> */}
              {planPrices?.map((item) => (
                <CarouselItem
                  className=""
                  tag="div"
                  key={item?.id}
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                >
                  <>
                    <div className="price">
                      <span>
                        <>
                          {item?.totalPrice && <del>${item?.totalPrice} </del>}$
                          {item?.price} / {item?.name}
                        </>
                      </span>
                    </div>
                    {item?.discount && (
                      <div className="sav my-2">
                        <span>You Saved {item?.discount}%</span>
                      </div>
                    )}
                  </>
                </CarouselItem>
              ))}
            </Carousel>
          )}

          <div
            id="more-btn-card"
            className={
              "more " +
              (plan?.name === "Starter"
                ? "mt-32"
                : // : activeIndex === 0

                  "mt-40")
              // : ""
            }
            //   style={{
            //     marginTop: "10px",
            //   }}
            onClick={() => handleMore(plan?.name, plan?.id)}
          >
            {isUserPlan ? (
              <>
                <span id="current-plan">Current plan</span>
                <span id="more-details">More Details</span>
              </>
            ) : (
              <>
                <span id="current-plan">More Details</span>
                <span id="more-details">More Details</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberShipCards;
