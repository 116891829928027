import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailList,
  getSessionList,
  getUserSetting,
} from "../../store/setting/SettingAction";
import {
  initSettingsLoad,
  stopSettingsLoad,
} from "../../store/loader/loaderActions";
import Navbar from "../../pages/navbar/navbar";
import AutoPlayVideoModal from "./components/AutoPlayVidoesModal";
import DarkModeModal from "./components/DarkModeModal";
import CloseAccountModal from "./components/CloseAccountModal";
import CreatorModeModal from "./components/CreatorModeModal";
import CustomProfileModal from "./components/CustomProfileModal";
import FeedModal from "./components/FeedModal";
import HibernateAccountModal from "./components/HibernateAccountModal";
import PrivateAccountModal from "./components/PrivateAccountModal";
import SocialMediaModal from "./components/SocialMediaModal";
import "./setting.scss";
import LanguageModal from "./components/LanguageModal";
import MergeAccountModal from "./components/MergeAccountModal";
import SidebarFooter from "./SidebarFooter";
import EditProfileModal from "../../routes/profile-pages/profile/components/EditProfileModal";
import AddContactInfoModal from "../../routes/profile-pages/profile/components/AddContactInfoModal";
import AddEducationModal from "../../routes/profile-pages/profile/components/AddEducationModal";
import AddExperModal from "../../routes/profile-pages/profile/components/AddExperModal";
import {
  getUserProfile,
  updateUserDetails,
} from "../../store/profile/profileActions";
import Security from "./security/Security";
import Visibility from "./visibility/Visibility";
import Communications from "./communication/Communications";
import Blocking from "./blocking/Blocking";
import Advertisement from "./advertisement/Advertisement";
import SearchSettings from "./components/SearchSettings";
import VerificationModal from "../profile-pages/profile/components/verification/VerificationModal";
import SettingMembership from "./membership/SettingMembership";
import ContentLanguageModal from "./components/ContentLanguageModal";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PeopleYouUnfolloed from "./components/PeopleYouUnfolloed";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import {
  updateNextPlan,
  updateCurrentPlan,
} from "../../store/auth/authActions";
import ProfilePhotoModal from "../profile-pages/profile/components/ProfilePhotoModal";
import BackgroundPhotoModal from "../profile-pages/profile/components/BackgroundPhotoModal";
import axios from "axios";
import RequestScreeningModel from "../../core/components/layout/Sidebar/RequestScreeningModel";
import { useLocation, useNavigate } from "react-router-dom";

export default function Setting() {
  let token = localStorage.getItem("accessToken");
  const [isActive, setActive] = useState(false);
  const [darkModeModal, setDarkModeModal] = useState(false);
  const [autoPlayVideoModal, setAutoPlayVideoModal] = useState(false);
  const [closeAccountModal, setCloseAccountModal] = useState(false);
  const [creatorModeModal, setCreatorModeModal] = useState(false);
  const [customProfileModal, setCustomProfileModal] = useState(false);
  const [feedModal, setFeedModal] = useState(false);
  const [peopleUnfollowModal, setPeopleUnfollowModal] = useState(false);
  const [hiberAccModal, setHiberAccModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [contentLanguageModal, setContentLanguageModal] = useState(false);
  const [privateAccModal, setPrivateAccModal] = useState(false);
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const [mergeAccountModal, setMergeAccountModal] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [addContactInfoModal, setAddContactInfoModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [editExperienceData, setEditExperienceData] = useState("");
  const [editEducationData, setEditEducationData] = useState("");
  const [addExpModal, setAddExpModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [results, setResults] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const { settingData } = useSelector((state) => state.setting);
  const { profileData } = useSelector((state) => state.profile);
  const { isSettingPageLoad } = useSelector((state) => state.loader);
  const { user } = useSelector((state) => state.auth);
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const dispatch = useDispatch();
  const [backgroundPhotoModal, setBackgroundPhotoModal] = useState(false);
  const [screeningModel, setScreeningModel] = useState(false);
  const getSettingData = () => {
    dispatch(initSettingsLoad());
    let id = 0;
    dispatch(getUserSetting(id, token))
      .then((res) => {
        console.log(res);
        dispatch(stopSettingsLoad());
      })
      .catch((error) => {
        dispatch(stopSettingsLoad());
      });
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "profile") {
          let formatProfileData = {
            profile_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        } else if (type === "background") {
          let formatProfileData = {
            background_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        }
      });
    } catch (err) {
      console.log(err);
    }
    // setProfilePhotoModal(false);
  };

  const handleRemovePicture = (type) => {
    if (type === "profile") {
      let formatProfileData = {
        profile_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    } else if (type === "background") {
      let formatProfileData = {
        background_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    }
  };

  const getSecondaryEmailList = () => {
    dispatch(getEmailList(token));
  };

  const getSessions = () => {
    dispatch(getSessionList(token));
  };
  useEffect(() => {
    getSettingData();
    getSecondaryEmailList();
    getSessions();
    let id = user?.data?.id;
    if (id) {
      dispatch(getUserProfile(id, token));
    }
  }, []);

  const map = {};
  profileData?.experiences?.forEach((obj) => {
    const { company_name, value } = obj;
    if (map[company_name]) {
      map[company_name].push(obj);
    } else {
      map[company_name] = [obj];
    }
  });

  const newExperienceData = Object.entries(map).map(([key, value]) => ({
    [key]: value,
  }));

  // search methods

  const AccountSearchList = [
    { id: "1", name: "Photo Name Location Industry" },
    { id: "2", name: "Employment" },
    { id: "3", name: "Education" },
    { id: "4", name: "Contact Info" },
    { id: "5", name: "Background checks and verfication" },
    { id: "6", name: "Custom Profile URL" },
    { id: "7", name: "Social Media" },
    { id: "8", name: "Creator Mode" },
    { id: "9", name: "Dark Mode" },
    { id: "10", name: "Language" },
    { id: "11", name: "Autoplay videos" },
    { id: "12", name: "Feed Preferences" },
    { id: "13", name: "People you unfollowed" },
    { id: "14", name: "People also viewed" },
    { id: "15", name: "Private Account" },
    { id: "16", name: "Merge Account" },
    { id: "17", name: "Close Account" },
    { id: "18", name: "Hiber Account" },
    { id: "19", name: "Verify/Update Email" },
  ];

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  const handleChangeMembershipStatus = () => {
    const data = {
      is_membership_active: !user?.data?.is_membership_active,
    };

    let message = "";
    if (data.is_membership_active) {
      message = "Membership Activated. Your membership will be auto-renewd";
    } else {
      message =
        "Membership deactiviated. Your membership will not be auto-renewed once it gets expired.";
    }

    dispatch(updateCurrentPlan(data, data, token))
      .then((res) => {
        if (res.success === true) {
          toast.success(message, { position: toast.POSITION.BOTTOM_LEFT });
          setIsDone(!isDone);
        }
      })
      .catch((error) => {
        toast.success("Something went wrong Please try again later", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setIsDone(!isDone);
      });
  };

  const handleCancelChange = () => {
    setIsDone(!isDone);
    const data = {
      next_membership_id: null,
      next_membership_duration: null,
    };
    const membershipData = {
      next_membership: null,
      next_membership_duration: null,
    };

    dispatch(updateNextPlan(data, membershipData, token))
      .then((res) => {
        if (res.success === true) {
          toast.success("Plan change cancelled.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setIsDone(!isDone);
        }
      })
      .catch((error) => {
        toast.success("Something went wrong Please try again later", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setIsDone(!isDone);
      });
  };

  useEffect(() => {
    if (selectedProfile.name === "Photo Name Location Industry") {
      setEditmodal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Employment") {
      setAddExpModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Education") {
      setEducationModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Contact Info") {
      setAddContactInfoModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Background checks and verfication") {
      setScreeningModel(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Custom Profile URL") {
      setCustomProfileModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Social Media") {
      setSocialMediaModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Creator Mode") {
      setCreatorModeModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Dark Mode") {
      setDarkModeModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Language") {
      setLanguageModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Autoplay videos") {
      setAutoPlayVideoModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Feed Preferences") {
      setFeedModal(!feedModal);
      setDefaultValue("");
    }
    if (selectedProfile.name === "People you unfollowed") {
      setPeopleUnfollowModal(!peopleUnfollowModal);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Private Account") {
      setPrivateAccModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Merge Account") {
      setMergeAccountModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Close Account") {
      setCloseAccountModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Hiber Account") {
      setHiberAccModal(true);
      setDefaultValue("");
    }
    if (selectedProfile.name === "Verify/Update Email") {
      setVerificationModal(true);
      setDefaultValue("");
    }
  }, [selectedProfile]);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("v-pills-home-tab");
  // useEffect(() => {
  //   const params = new URLSearchParams(location?.search);
  //   const tab = params.get("tab");
  //   if (tab) {
  //     setActiveTab(tab);
  //   }
  // }, [location]);
  return (
    <div>
      <div className="setting">
        <Navbar isJobs={false} />
        <div className="setting-page pb-3">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="main-tabs">
                  <aside>
                    <div className="heading">
                      <h1>Settings</h1>
                    </div>

                    <div
                      className="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-home-tab" ? "active" : ""
                        }`}
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-home-tab")
                        }
                      >
                        Account Preferences
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-profile-tab" ? "active" : ""
                        }`}
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-profile-tab")
                        }
                      >
                        Membership and Subscriptions
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-messages-tab" ? "active" : ""
                        }`}
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-messages-tab")
                        }
                      >
                        Security and Privacy
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-visibility-tab" ? "active" : ""
                        }`}
                        id="v-pills-visibility-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-visibility"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-visibility"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-visibility-tab")
                        }
                      >
                        Visibility
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-communications-tab"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-communications-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-communications"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-communications"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-communications-tab")
                        }
                      >
                        Communications
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-blocking-tab" ? "active" : ""
                        }`}
                        id="v-pills-blocking-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-blocking"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-blocking"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-blocking-tab")
                        }
                      >
                        Blocking
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "v-pills-advertisement-tab"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-advertisement-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-advertisement"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-advertisement"
                        aria-selected="false"
                        onClick={() =>
                          navigate("/setting?tab=v-pills-advertisement-tab")
                        }
                      >
                        Advertisement
                      </button>
                    </div>
                    <div className="setting-bottom">
                      <SidebarFooter />
                    </div>
                  </aside>
                  <div className="vertical-tabs h-100">
                    {isSettingPageLoad ? (
                      <div className="loader-content">
                        {" "}
                        <AppLoader />{" "}
                      </div>
                    ) : (
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-home-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                          tabIndex="0"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="heading">
                                <h1>Account Preferences</h1>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="search">
                                {/* <Input placeholder="Search setting" /> */}
                                <SearchSettings
                                  results={results}
                                  value={selectedProfile?.name}
                                  renderItem={(item) => <p>{item.name}</p>}
                                  onChange={handleChange}
                                  defaultValue={defaultValue}
                                  setDefaultValue={setDefaultValue}
                                  onSelect={(item) => setSelectedProfile(item)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="content">
                            <div className="profile-information">
                              <h4>Profile information</h4>
                              <ul>
                                <li
                                  className={isActive ? "active" : null}
                                  onClick={() => {
                                    setEditmodal(!editmodal);
                                  }}
                                >
                                  Photo, Name, location, industry{" "}
                                </li>
                                <li
                                  className={isActive ? "active" : null}
                                  onClick={() => setAddExpModal(!addExpModal)}
                                >
                                  Employment
                                </li>
                                <li
                                  className={isActive ? "active" : null}
                                  onClick={() =>
                                    setEducationModal(!educationModal)
                                  }
                                >
                                  Education
                                </li>
                                <li
                                  className={isActive ? "active" : null}
                                  onClick={() =>
                                    setAddContactInfoModal(!addContactInfoModal)
                                  }
                                >
                                  Contact info
                                </li>
                                <li
                                  className={isActive ? "active" : null}
                                  onClick={() =>
                                    setVerificationModal(!verificationModal)
                                  }
                                >
                                  Verify/Update Email
                                </li>
                              </ul>
                            </div>
                            <div className="line"></div>
                            <div className="profile-information">
                              <h4>Profile Settings</h4>
                              <ul>
                                <li
                                  onClick={() =>
                                    setScreeningModel(!screeningModel)
                                  }
                                >
                                  Background checks and verifications{" "}
                                </li>
                                <li
                                  onClick={() =>
                                    setCustomProfileModal(!customProfileModal)
                                  }
                                >
                                  Custom profile URL
                                </li>
                                <li
                                  onClick={() =>
                                    setSocialMediaModal(!socialMediaModal)
                                  }
                                >
                                  Social media
                                </li>
                                <li
                                  onClick={() =>
                                    setCreatorModeModal(!creatorModeModal)
                                  }
                                >
                                  Creator mode
                                </li>
                              </ul>
                            </div>
                            <div className="line"></div>
                            <div className="profile-information">
                              <h4>Display Mode</h4>
                              <ul>
                                <li
                                  onClick={() => {
                                    setDarkModeModal(!darkModeModal);
                                  }}
                                >
                                  Dark mode
                                </li>
                              </ul>
                            </div>
                            <div className="line"></div>
                            <div className="profile-information">
                              <h4>Site Preferences</h4>
                              <ul>
                                <li
                                  onClick={() =>
                                    setLanguageModal(!languageModal)
                                  }
                                >
                                  Language{" "}
                                </li>
                                <li
                                  onClick={() =>
                                    setContentLanguageModal(
                                      !contentLanguageModal
                                    )
                                  }
                                >
                                  Content Language{" "}
                                </li>
                                <li
                                  onClick={() =>
                                    setAutoPlayVideoModal(!autoPlayVideoModal)
                                  }
                                >
                                  Autoplay videos
                                </li>
                                <li onClick={() => setFeedModal(!feedModal)}>
                                  Feed preferences
                                </li>
                                <li
                                  onClick={() =>
                                    setPeopleUnfollowModal(!peopleUnfollowModal)
                                  }
                                >
                                  People you unfollowed
                                </li>
                              </ul>
                            </div>
                            <div className="line"></div>
                            <div className="profile-information">
                              <h4>Account Management</h4>
                              <ul>
                                <li
                                  onClick={() =>
                                    setPrivateAccModal(!privateAccModal)
                                  }
                                >
                                  Private account{" "}
                                </li>
                                <li
                                  onClick={() =>
                                    setMergeAccountModal(!mergeAccountModal)
                                  }
                                >
                                  Merge accounts
                                </li>
                                <li
                                  onClick={() =>
                                    setHiberAccModal(!hiberAccModal)
                                  }
                                >
                                  Hibernate account
                                </li>
                                <li
                                  onClick={() =>
                                    setCloseAccountModal(!closeAccountModal)
                                  }
                                >
                                  Close account
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-profile-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                          tabIndex="0"
                        >
                          <SettingMembership
                            isActive={isActive}
                            profileData={user.data}
                            handleChangeMembershipStatus={
                              handleChangeMembershipStatus
                            }
                            handleCancelChange={handleCancelChange}
                            isDone={isDone}
                            // goToProfile={goToProfile}
                          />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-messages-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-messages"
                          role="tabpanel"
                          aria-labelledby="v-pills-messages-tab"
                          tabIndex="0"
                        >
                          <Security />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-visibility-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-visibility"
                          role="tabpanel"
                          aria-labelledby="v-pills-visibility-tab"
                          tabIndex="0"
                        >
                          <Visibility />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-blocking-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-communications"
                          role="tabpanel"
                          aria-labelledby="v-pills-communications-tab"
                          tabIndex="0"
                        >
                          <Communications />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-blocking"
                          role="tabpanel"
                          aria-labelledby="v-pills-blocking-tab"
                          tabIndex="0"
                        >
                          <Blocking />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "v-pills-advertisement-tab"
                              ? "show active"
                              : ""
                          }`}
                          id="v-pills-advertisement"
                          role="tabpanel"
                          aria-labelledby="v-pills-advertisement-tab"
                          tabIndex="0"
                        >
                          <Advertisement />
                          {/* This feature will be implemented in the future milestone */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {darkModeModal && (
        <DarkModeModal
          modal={darkModeModal}
          toggle={() => setDarkModeModal(!darkModeModal)}
          title="Dark Mode"
          data={settingData?.data}
        />
      )}
      {autoPlayVideoModal && (
        <AutoPlayVideoModal
          modal={autoPlayVideoModal}
          toggle={() => setAutoPlayVideoModal(!autoPlayVideoModal)}
          title="Auto Play Videos"
          data={settingData?.data}
        />
      )}
      {closeAccountModal && (
        <CloseAccountModal
          modal={closeAccountModal}
          toggle={() => setCloseAccountModal(!closeAccountModal)}
          title="Close Account"
          data={settingData?.data[9]}
          setCloseAccountModal={setCloseAccountModal}
        />
      )}
      {creatorModeModal && (
        <CreatorModeModal
          modal={creatorModeModal}
          toggle={() => setCreatorModeModal(!creatorModeModal)}
          title="Enable creator mode"
          data={settingData?.data}
        />
      )}
      {customProfileModal && (
        <CustomProfileModal
          modal={customProfileModal}
          toggle={() => setCustomProfileModal(!customProfileModal)}
          title="Custom Profile"
          data={settingData?.data[3]}
          profileURL={profileData?.profile_url}
        />
      )}
      {feedModal && (
        <FeedModal
          modal={feedModal}
          toggle={() => setFeedModal(!feedModal)}
          title="Feed Preferences"
          data={settingData?.data[1]}
        />
      )}
      {peopleUnfollowModal && (
        <PeopleYouUnfolloed
          modal={peopleUnfollowModal}
          toggle={() => setPeopleUnfollowModal(!peopleUnfollowModal)}
        />
      )}
      {hiberAccModal && (
        <HibernateAccountModal
          modal={hiberAccModal}
          toggle={() => setHiberAccModal(!hiberAccModal)}
          title="Hibernate Account"
          data={settingData?.data[8]}
        />
      )}
      {languageModal && (
        <LanguageModal
          modal={languageModal}
          toggle={() => setLanguageModal(!languageModal)}
          title="Language"
          data={settingData?.data[0]}
        />
      )}
      {contentLanguageModal && (
        <ContentLanguageModal
          modal={contentLanguageModal}
          toggle={() => setContentLanguageModal(!contentLanguageModal)}
          title="Content Language"
        />
      )}
      {privateAccModal && (
        <PrivateAccountModal
          modal={privateAccModal}
          toggle={() => setPrivateAccModal(!privateAccModal)}
          title="Private Account"
          data={settingData?.data}
        />
      )}
      {socialMediaModal && (
        <SocialMediaModal
          modal={socialMediaModal}
          toggle={() => setSocialMediaModal(!socialMediaModal)}
          title="Social Link"
          data={settingData?.data[2]}
          getSettingData={getSettingData}
        />
      )}
      {mergeAccountModal && (
        <MergeAccountModal
          modal={mergeAccountModal}
          toggle={() => setMergeAccountModal(!mergeAccountModal)}
          title="Merge Account"
        />
      )}
      {editmodal && (
        <EditProfileModal
          firstName={profileData?.first_name}
          modal={editmodal}
          lastName={profileData?.last_name}
          profileHeadline={profileData?.profile_headline}
          designation={profileData?.designation}
          toggle={() => setEditmodal(!editmodal)}
          addContactInfoModal={addContactInfoModal}
          setAddContactInfoModal={setAddContactInfoModal}
          addExpModal={addExpModal}
          setAddExpModal={setAddExpModal}
          educationModal={educationModal}
          setEducationModal={setEducationModal}
          educationData={profileData?.educations}
          setEditEducationData={setEditEducationData}
          experienceData={newExperienceData}
          setEditExperienceData={setEditExperienceData}
          highestEducation={profileData?.highestEducation}
          highestExperience={profileData?.highestExperience}
          profilePhotoModal={profilePhotoModal}
          setProfilePhotoModal={setProfilePhotoModal}
          backgroundPhotoModal={backgroundPhotoModal}
          setBackgroundPhotoModal={setBackgroundPhotoModal}
          backgroundImage={profileData?.background_img}
          profileImage={profileData?.profile_img}
        />
      )}
      {addContactInfoModal && (
        <AddContactInfoModal
          modal={addContactInfoModal}
          toggle={() => {
            setAddContactInfoModal(!addContactInfoModal);
          }}
          title="Edit Contact Info"
          country={profileData?.country}
          state={profileData?.state}
          city={profileData?.city}
          pincode={profileData?.pin_code}
          phone={profileData?.phone}
          address={profileData?.address}
          dob={profileData?.dob}
          nationality={profileData?.nationality}
          email={profileData?.email}
          location={profileData?.location}
          profileUrl={profileData?.profile_url}
          website={profileData?.website}
          setCustomProfileModal={setCustomProfileModal}
        />
      )}
      {educationModal && (
        <AddEducationModal
          modal={educationModal}
          toggle={() => setEducationModal(!educationModal)}
          title="Add Education"
          setEditEducationData={setEditEducationData}
          editEducationData={editEducationData}
        />
      )}

      {addExpModal && (
        <AddExperModal
          modal={addExpModal}
          toggle={() => setAddExpModal(!addExpModal)}
          editExperienceData={editExperienceData}
          setEditExperienceData={setEditExperienceData}
        />
      )}
      {verificationModal && (
        <VerificationModal
          modal={verificationModal}
          toggle={() => setVerificationModal(!verificationModal)}
          email={profileData?.email}
          isVerfied={profileData?.is_email_verified}
        />
      )}

      {profilePhotoModal && (
        <ProfilePhotoModal
          modal={profilePhotoModal}
          toggle={() => setProfilePhotoModal(!profilePhotoModal)}
          title="Profile Photo"
          editProfileImage={profileData?.profile_img}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
          // setBannerModal={setBannerModal}
        />
      )}

      {backgroundPhotoModal && (
        <BackgroundPhotoModal
          modal={backgroundPhotoModal}
          toggle={() => setBackgroundPhotoModal(!backgroundPhotoModal)}
          title="Background Photo"
          editBackgroundImage={profileData?.background_img}
          handleBannerPicture={handleBannerPicture}
          // setBannerModal={setBannerModal}
          handleRemovePicture={handleRemovePicture}
        />
      )}

      {screeningModel && (
        <RequestScreeningModel
          model={screeningModel}
          toggle={() => setScreeningModel(!screeningModel)}
        />
      )}
      <ToastContainer />
    </div>
  );
}
