import moment from "moment/moment";
import React, { useRef, useState } from "react";
import placeHolder from "../../../assets/img/page-placeholder.svg";
import { useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import { Button } from "reactstrap";
import Slider from "react-slick";
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export let classicRef;
const ClassicTemplate = ({ userData, classicRef, cvUrl }) => {
  classicRef = useRef();
  const [error, setError] = useState(null);
  // const { profileData: userData } = useSelector((state) => state.profile);
  console.log(cvUrl);
  const hrStyle = {
    background: userData?.cv_color || "#0432A3",
  };
  const headColor = {
    color: "#000",
  };
  const skillBackColor = {
    background: userData?.cv_skillColor || "#0432A3",
  };
  // const interestBackColor = {
  //   background: userData?.cv_interestColor || "#0432A3",
  // };
  const sliderRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadError(error) {
    setError(error.message);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
  }
  return (
    <>
      {/* <iframe
        src={cvUrl}
        title="W3Schools Free Online Web Tutorials"
      ></iframe> */}

      <div className="d-flex justify-content-center align-items-center preview_model">
        <Document
          file={cvUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        />
        {/* {error && <p>{error}</p>} */}
        <div style={{ position: "relative", width: "100%" }}>
          {numPages && (
            <div className="d-flex justify-content-center align-items-center">
              {/* <Button
                onClick={previous}
                disabled={pageNumber <= 1}
                className="bg-transparent border-0"
              >
                <img src={RightArrowIcon} width={25} />
              </Button> */}
              <div
                style={{
                  width: "100%",
                  maxWidth: "calc(450px + 150px)",
                  height: "100%",
                  margin: "0 15px",
                }}
              >
                <Slider
                  ref={sliderRef}
                  {...settings}
                  afterChange={(index) => setPageNumber(index + 1)}
                >
                  {[...Array(numPages)].map((_, index) => (
                    <div key={index} style={{ border: "1px solid #ddd" }}>
                      <div
                        style={{
                          padding: "0 5px",
                          width: "100%",
                          height: "100%",
                          marginInline: "auto",
                        }}
                      >
                        <Document
                          file={cvUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={onDocumentLoadError}
                          className={"pdfFullWidth"}
                        >
                          <Page
                            pageNumber={index + 1}
                            width={540}
                            // height={400}
                          />
                        </Document>
                      </div>
                    </div>
                  ))}
                </Slider>
                <p className="text-center text-white">
                  {pageNumber} / {numPages}
                </p>
              </div>
              {/* <Button
                onClick={next}
                disabled={pageNumber >= numPages}
                className="bg-transparent  border-0"
              >
                <img src={LeftArrowIcon} width={25} />
              </Button> */}
            </div>
          )}
        </div>
      </div>

      {/* <div className="first" style={{ height: "550px", overflow: "auto" }}>
        <div className="resume-1" ref={classicRef}>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <div className="profile-img">
                  <img
                    src={
                      userData?.profile_img ||
                      "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                    }
                    className="image-fit"
                  />
                </div>

                <div className="skills">
                  <div className="heading">
                    <h3>Skills</h3>
                  </div>
                  <ul>
                    {userData?.exps_and_skills?.length > 0 ? (
                      userData?.exps_and_skills?.map((ele) => {
                        return (
                          <li className="text-white" style={skillBackColor}>
                            {ele?.skills ? ele?.skills : ele}
                          </li>
                        );
                      })
                    ) : (
                      <>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>After effect</li>
                        <li>microsoft office</li>
                        <li>Adobe illustrator</li>
                        <li>Autocad</li>
                        <li>3D Character blending</li>
                        <li>Sketching</li>
                      </>
                    )}
                  </ul>
                </div>

                <div className="honor">
                  <div className="heading">
                    <h3 style={headColor}>Honour Awards</h3>
                  </div>

                  {userData?.honors_and_awards?.length > 0 ? (
                    userData?.honors_and_awards?.map((ele) => {
                      return (
                        <div className="text">
                          <h4 style={{ lineHeight: "20px !important" }}>
                            {ele?.name} of the year {ele?.year}
                          </h4>
                          <p>
                            {ele?.Industry?.length > 0
                              ? ele?.Industry[0]?.name
                              : ele?.industry}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="text">
                        <h4>Top scorer of the year 2022</h4>
                        <p>Lorem Ipsum is simply dummy</p>
                      </div>
                      <div className="text">
                        <h4>Top scorer of the year 2022</h4>
                        <p>Lorem Ipsum is simply dummy</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="honor">
                  <div className="heading">
                    <h3 style={headColor}> Languages</h3>
                  </div>
                  {userData?.languages?.length > 0 ? (
                    userData?.languages?.map((ele) => {
                      return (
                        <div className="text">
                          <h4>{ele?.name}</h4>
                          <p>{ele?.proficiency}</p>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="text">
                        <h4>English</h4>
                        <p>Native</p>
                      </div>
                      <div className="text">
                        <h4>Arabic</h4>
                        <p>Elementary</p>
                      </div>
                    </>
                  )}
                </div>

                
              </div>
              <div className="col-8 right">
                <div className="hr-line" style={hrStyle}></div>
                <div className="name">
                  <h2>
                    {userData?.first_name || "John"}{" "}
                    {userData?.last_name || "Smith"}
                  </h2>
                  <h3 style={headColor}>
                    {userData?.profile_headline || "Designation"}
                  </h3>
                  <p>
                    {userData?.about ||
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                  </p>

                  <div className="grid">
                    <div className="grid-cell-1">
                      <i className="message-icon"></i>
                      {userData?.email || "John@gmail.com"}
                    </div>
                    <div className="grid-cell-1">
                      <i className="mobile-icon"></i>
                      {userData?.phone || "+97 55555 55555"}
                    </div>
                    <div className="grid-cell-1">
                      <i className="loction-icon"></i>
                      {userData?.address || "Ajman, UAE"}
                    </div>
                    <div className="grid-cell-1">
                      <i className="nobel-icon"></i>
                      {userData?.profile_url || "nobel.com/john"}
                    </div>
                    <div className="grid-cell-1">
                      <i className="linkdin-icon"></i>
                      {userData?.linkedin_url || "linkedin.com/john"}
                    </div>
                  </div>
                </div>
                <div className="work">
                  <h2 style={headColor}>Work Experience</h2>
                  {userData?.experiences?.length > 0 ? (
                    userData?.experiences?.map((ele) => {
                      return (
                        <>
                          <h3>{ele?.company_name}</h3>
                          <h4>{ele?.title}</h4>
                          <div className="row description">
                            <div className="col-6">
                              <p>
                                {moment(ele?.start_date)
                                  ?.utc()
                                  ?.format("MMM YYYY")}
                                {" to"}{" "}
                                {ele?.is_current
                                  ? "Present"
                                  : ele?.end_date
                                  ? moment(ele?.end_date)
                                      ?.utc()
                                      ?.format("MMM YYYY")
                                  : "No Date"}
                              </p>
                            </div>
                            <div className="col-6">
                              <div className="float-end">
                                <p>{ele?.location}</p>
                              </div>
                            </div>
                          </div>
                          <h5>Achievements / Tasks</h5>
                          <ul className="mb-2">
                            <li>{ele?.description}</li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h3>Webmate Solutions</h3>
                      <h4>UI Designer</h4>
                      <div className="row description">
                        <div className="col-6">
                          <p>2019 to Present</p>
                        </div>
                        <div className="col-6">
                          <div className="float-end">
                            <p>California, CA, USA</p>
                          </div>
                        </div>
                      </div>
                      <h5>Achievements / Tasks</h5>
                      <ul className="mb-5">
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </li>
                        <li>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </li>
                      </ul>
                      <h3>Project Manager</h3>
                      <h4>UI Designer</h4>
                      <div className="row description">
                        <div className="col-6">
                          <p>2019 to Present</p>
                        </div>
                        <div className="col-6">
                          <div className="float-end">
                            <p>California, CA, USA</p>
                          </div>
                        </div>
                      </div>
                      <h5>Achievements / Tasks</h5>
                      <ul className="mb-3">
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </li>
                        <li>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </li>
                        <li>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book.
                        </li>
                      </ul>
                    </>
                  )}

                  <h2 style={headColor}>Education</h2>

                  {userData?.educations?.length > 0 ? (
                    userData?.educations?.map((ele) => {
                      return (
                        <>
                          <h3>{ele?.degree}</h3>
                          <h4>{ele?.school_name}</h4>
                          <div className="row description">
                            <div className="col-6">
                              <p>
                                {moment(ele?.start_date)
                                  ?.utc()
                                  ?.format("MMM YYYY")}
                                {" to"}{" "}
                                {ele?.end_date
                                  ? moment(ele?.end_date)
                                      ?.utc()
                                      ?.format("MMM YYYY")
                                  : "No Date"}
                              </p>
                            </div>
                            <div className="col-6">
                              <div className="float-end"></div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h3>MBA</h3>
                      <h4>University of cambridge</h4>
                      <div className="row description">
                        <div className="col-6">
                          <p>2019 to 2020</p>
                        </div>
                        <div className="col-6">
                          <div className="float-end">
                            <p>California, CA, USA</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ClassicTemplate;
