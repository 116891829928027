import moment from "moment";
import React, { useRef, useState } from "react";
import placeHolder from "../../../assets/img/page-placeholder.svg";
import { useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import { Button } from "reactstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export let functionalRef;
const FunctionalTemplate = ({ userData, memberShip, functionalRef, cvUrl }) => {
  functionalRef = useRef();
  // const { profileData: userData } = useSelector((state) => state.profile);
  // const { profileData } = useSelector((state) => state.profile);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [previewResumeModal, setPreviewResumeModal] = useState(false);
  // const [totalPdfPages, setTotalPdfPages] = useState(false);
  // const [pdfUrl, setPdfUrl] = useState([]);
  // const handlePreview = async (value) => {
  //   dispatch(initPageLoad());
  //   if (
  //     profileData?.membership?.id > 1 ||
  //     (profileData?.membership?.id === 1 && value === "classic")
  //   ) {
  //     const templateRef =
  //       value === "classic"
  //         ? classicRef.current
  //         : value === "functional"
  //         ? functionalRef.current
  //         : value === "combined"
  //         ? combinedRef.current
  //         : modernRef.current;

  //     const pixelRatio = 3;
  //     const canvas = await html2canvas(templateRef, { scale: pixelRatio });
  //     const pageHeight = 295;
  //     const imgWidth = 210;

  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const urls = [];
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     // const doc = new jsPDF("p", "mm");
  //     const doc = new jsPDF({
  //       orientation: "p",
  //       unit: "mm",
  //       format: "a4",
  //       hotfixes: ["px_scaling"],
  //       // Add viewer preferences to hide controls and sidebar
  //       putOnlyUsedFonts: true,
  //       viewerPreferences: {
  //         HideMenubar: true,
  //         HideToolbar: true,
  //         HideWindowUI: true,
  //       },
  //     });

  //     while (heightLeft > 0) {
  //       doc.addImage(
  //         canvas,
  //         "PNG",
  //         0,
  //         position,
  //         imgWidth,
  //         imgHeight,
  //         "",
  //         "FAST"
  //       );
  //       position -= pageHeight;
  //       heightLeft -= pageHeight;
  //       if (heightLeft > 0) doc.addPage();

  //       // urls.push(pdfDataUrl);
  //     }
  //     const pdfBlob = doc.output("blob");
  //     const pdfDataUrl = URL.createObjectURL(pdfBlob);
  //     const totalPages = doc.internal.getNumberOfPages();

  //     dispatch(stopPageLoad());
  //     setPdfUrl(pdfDataUrl);
  //     setPreviewResumeModal(true);
  //     setTotalPdfPages(totalPages);
  //   } else {
  //     navigate("/membership");
  //   }
  // };
  console.log(userData, memberShip);
  const [error, setError] = useState(null);
  // const { profileData: userData } = useSelector((state) => state.profile);
  console.log(cvUrl);
  const hrStyle = {
    background: userData?.cv_color || "#0432A3",
  };
  const headColor = {
    color: "#000",
  };
  const skillBackColor = {
    background: userData?.cv_skillColor || "#0432A3",
  };
  // const interestBackColor = {
  //   background: userData?.cv_interestColor || "#0432A3",
  // };
  const sliderRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadError(error) {
    setError(error.message);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null);
  }
  return (
    <>
      {memberShip > 1 ? (
        <div className="d-flex justify-content-center align-items-center preview_model">
          <Document
            file={cvUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
          />
          {/* {error && <p>{error}</p>} */}
          <div style={{ position: "relative", width: "100%" }}>
            {numPages && (
              <div className="d-flex justify-content-center align-items-center">
                {/* <Button
                onClick={previous}
                disabled={pageNumber <= 1}
                className="bg-transparent border-0"
              >
                <img src={RightArrowIcon} width={25} />
              </Button> */}
                <div
                  style={{
                    width: "100%",
                    maxWidth: "calc(450px + 150px)",
                    height: "100%",
                    margin: "0 15px",
                  }}
                >
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    afterChange={(index) => setPageNumber(index + 1)}
                  >
                    {[...Array(numPages)].map((_, index) => (
                      <div key={index} style={{ border: "1px solid #ddd" }}>
                        <div
                          style={{
                            padding: "0 5px",
                            width: "100%",
                            height: "100%",
                            marginInline: "auto",
                          }}
                        >
                          <Document
                            file={cvUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentLoadError}
                            className={"pdfFullWidth"}
                          >
                            <Page
                              pageNumber={index + 1}
                              width={540}
                              // height={400}
                            />
                          </Document>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <p className="text-center text-white">
                    {pageNumber} / {numPages}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={"second hidden"}>
          <div
            ref={functionalRef}
            className={"resume-2 hidden"}
            style={{ height: "781px" }}
          >
            <div className={"lock"}>
              <div className="d-block">
                <Link to="/membership">
                  <div className="lock-img"></div>
                  <p>Unlock More Features</p>
                </Link>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center preview_model">
              <Document
                file={cvUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              />
              <div style={{ position: "relative", width: "100%" }}>
                {numPages && (
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "calc(450px + 150px)",
                        height: "100%",
                        margin: "0 15px",
                      }}
                    >
                      <Slider
                        ref={sliderRef}
                        {...settings}
                        afterChange={(index) => setPageNumber(index + 1)}
                      >
                        {[...Array(numPages)].map((_, index) => (
                          <div key={index} style={{ border: "1px solid #ddd" }}>
                            <div
                              style={{
                                padding: "0 5px",
                                width: "100%",
                                height: "100%",
                                marginInline: "auto",
                              }}
                            >
                              <Document
                                file={cvUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={onDocumentLoadError}
                                className={"pdfFullWidth"}
                              >
                                <Page
                                  pageNumber={index + 1}
                                  width={600}
                                  // height={400}
                                />
                              </Document>
                            </div>
                          </div>
                        ))}
                      </Slider>
                      <p className="text-center text-white">
                        {pageNumber} / {numPages}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div
        className={memberShip > 1 ? "second show" : "second hidden"}
        style={{ height: "550px" }}
      >
        <div
          ref={functionalRef}
          className={memberShip > 1 ? "resume-2 show" : "resume-2 hidden"}
          style={{ height: memberShip == 1 && "550px" }}
        >
          <div className={memberShip > 1 ? "d-none" : "lock"}>
            <div className="d-block">
              <Link to="/membership">
                <div className="lock-img"></div>
                <p>Unlock More Features</p>
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-3">
                <div className="profile-img">
                  <img
                    src={
                      userData?.profile_img ||
                      "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png"
                    }
                    className="image-fit"
                  />
                </div>
              </div>
              <div className="col-9 ">
                <div className="blue w-100" style={bg_color}>
                  <h2>
                    {userData?.first_name || "John"}{" "}
                    {userData?.last_name || "Smith"}
                  </h2>
                  <h3>{userData?.profile_headline || "Designation"}</h3>
                  <div className="line"></div>
                  <p>
                    {userData?.about ||
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12 list">
                <ul>
                  <li>
                    <i className="message-icon"></i>
                    {userData?.email || "John@gmail.com"}
                  </li>
                  <li>
                    <i className="mobile-icon"></i>
                    {userData?.phone || "+97 55555 55555"}
                  </li>
                  <li>
                    <i className="loction-icon"></i>
                    {userData?.address || "Ajman, UAE"}
                  </li>
                  <li>
                    <i className="nobel-icon"></i>
                    {userData?.profile_url || "nobel.com/john"}
                  </li>
                  <li>
                    <i className="linkdin-icon"></i>
                    {userData?.linkedin_url || "linkedin.com/john"}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12 ">
                <div className="skills">
                  <div className="heading">
                    <h3 style={headColor}>Skills</h3>
                    <div className="line" style={bg_color}></div>
                  </div>
                  <ul>
                    {userData?.exps_and_skills?.length > 0 ? (
                      userData?.exps_and_skills?.map((ele) => {
                        return (
                          <li style={skillBackColor}>
                            {ele?.skills ? ele?.skills : ele}
                          </li>
                        );
                      })
                    ) : (
                      <>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>After effect</li>
                        <li>microsoft office</li>
                        <li>Adobe illustrator</li>
                        <li>Autocad</li>
                        <li>3D Character blending</li>
                        <li>Sketching</li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12 ">
                <div className="skills">
                  <div className="heading">
                    <h3 style={headColor}>Work Experience</h3>
                    <div className="line" style={bg_color}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-12 ">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="right">
                        <div className="work">
                          {userData?.experiences?.length > 0 ? (
                            userData?.experiences?.map((ele) => {
                              return (
                                <>
                                  <h3>
                                    <div className="text-blue">
                                      {ele?.title}
                                    </div>
                                  </h3>
                                  <h3>{ele?.company_name}</h3>
                                  <div className="row description">
                                    <div className="col-6">
                                      <p>
                                        {moment(ele?.start_date)
                                          ?.utc()
                                          ?.format("MMM YYYY")}
                                        {" to"}{" "}
                                        {ele?.is_current
                                          ? "Present"
                                          : ele?.end_date
                                          ? moment(ele?.end_date)
                                              ?.utc()
                                              ?.format("MMM YYYY")
                                          : "No Date"}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <div className="float-end">
                                        <p>{ele?.location}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <h5>Achievements / Tasks</h5>
                                  <ul className="mb-3">
                                    <li>{ele?.description}</li>
                                  </ul>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <h3>
                                <div className="text-blue">
                                  Marketing Manager
                                </div>
                              </h3>
                              <h3>Webmate Solutions</h3>
                              <div className="row description">
                                <div className="col-6">
                                  <p>2019 to Present</p>
                                </div>
                                <div className="col-6">
                                  <div className="float-end">
                                    <p>California, CA, USA</p>
                                  </div>
                                </div>
                              </div>
                              <h5>Achievements / Tasks</h5>
                              <ul className="mb-3">
                                <li>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book.
                                </li>
                                <li>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </li>
                                <li>
                                  Lorem Ipsum has been the industry's standard
                                  dummy text ever since the 1500s, when an
                                  unknown printer took a galley of type and
                                  scrambled it to make a type specimen book.
                                </li>
                              </ul>
                              <h3>
                                <div className="text-blue">
                                  Marketing Manager
                                </div>
                              </h3>
                              <h3>Webmate Solutions</h3>
                              <div className="row description">
                                <div className="col-6">
                                  <p>2019 to Present</p>
                                </div>
                                <div className="col-6">
                                  <div className="float-end">
                                    <p>California, CA, USA</p>
                                  </div>
                                </div>
                              </div>
                              <h5>Achievements / Tasks</h5>
                              <ul className="">
                                <li>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book.
                                </li>
                                <li>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </li>
                                <li>
                                  Lorem Ipsum has been the industry's standard
                                  dummy text ever since the 1500s, when an
                                  unknown printer took a galley of type and
                                  scrambled it to make a type specimen book.
                                </li>
                              </ul>
                            </>
                          )}

                          <div className="row ">
                            <div className="col-12 ">
                              <div className="skills">
                                <div className="heading">
                                  <h3 style={headColor}>Education</h3>
                                  <div className="line" style={bg_color}></div>
                                </div>
                                {userData?.educations?.length > 0 ? (
                                  userData?.educations?.map((ele) => {
                                    return (
                                      <>
                                        <h3>
                                          <div className="text-blue">
                                            {ele?.degree}
                                          </div>
                                        </h3>
                                        <h3>{ele?.school_name}</h3>
                                        <div className="row description">
                                          <div className="col-6">
                                            <p>
                                              {moment(ele?.start_date)
                                                ?.utc()
                                                ?.format("MMM YYYY")}
                                              {" to"}{" "}
                                              {ele?.end_date
                                                ? moment(ele?.end_date)
                                                    ?.utc()
                                                    ?.format("MMM YYYY")
                                                : "No Date"}
                                            </p>
                                          </div>
                                          <div className="col-6">
                                            <div className="float-end"></div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <h3>
                                      <div className="text-blue">MBA</div>
                                    </h3>
                                    <h3>University of cambridge</h3>
                                    <div className="row description">
                                      <div className="col-6">
                                        <p>2019 to Present</p>
                                      </div>
                                      <div className="col-6">
                                        <div className="float-end">
                                          <p>California, CA, USA</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className=" btn btn-blue"
          style={{
            height: "23px",
            width: "58px",
            fontSize: "10px",
            padding: "5px 10px 20px",
            borderRadius: "25px",
            float: "right",
            marginRight: "2px",
            marginBottom: "4px",
          }}
          onClick={() => handlePreview("functional")}
        >
          Preview
        </button>
      </div> */}
    </>
  );
};

export default FunctionalTemplate;
