import React from "react";
import elipse from "../../../assets/img/benifit-elipise.svg";
import arrow from "../../../assets/img/Right_arrow-black.svg";

const BenifitCard = () => {
  return (
    <div className="benifit-card">
      <img src={elipse} alt="elipse" className="card-image" />
      <h2>Benefits of NobelPage</h2>
      <p style={{ width: "102%" }}>
        Discover limitless opportunities with A NobelPage Membership! Access
        exclusive content, connect with industry leaders, and unlock your full
        potential.
      </p>
      <div
        className="btn"
        onClick={() =>
          window.open(`${process.env.REACT_APP_WEB_STAGE_URL}signup`)
        }
      >
        Join Now
        <img src={arrow} className="ms-4 text-dark" alt="right-arrow" />
      </div>
    </div>
  );
};

export default BenifitCard;
