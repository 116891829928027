import { Navigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
} from "../store/portal/portalAction";
import { useEffect } from "react";

const TPPrivateRoute = ({ children }) => {
  const location = useLocation();
  const { talentPoolDataByToken, talentPoolData } = useSelector(
    (state) => state.portal
  );
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  const dispatch = useDispatch();
  useEffect(() => {
    const favicon = document.querySelector("link#favicon");
    const title = document.querySelector("title#title");

    dispatch(getTalentPoolDetailsByToken());
    if (param) {
      dispatch(getTalentPoolDetails(param)).then((res) => {
        if (res?.data?.icon && favicon)
          favicon.setAttribute("href", res?.data?.icon);
        if (res?.data?.company_name && title)
          title.innerHTML = `NobelPage | ${res?.data?.company_name}`;
      });
    }
    return () => {
      favicon.setAttribute("href", "/favicon.svg");
      title.innerHTML = `NobelPage`;
    };
  }, []);
  if (!token || talentPoolData?.id != talentPoolDataByToken?.id) {
    return (
      <Navigate to={`/tp/portal/${param}/login`} state={{ from: location }} />
    );
  }
  return children;
};

export default TPPrivateRoute;
