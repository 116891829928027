import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { useEffect } from "react";

const FeedModal = (props) => {
  const { modal, toggle, title, data } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [responseMsg, setResponseMsg] = useState("");
  const [pages, setPages] = useState(false);
  const [pagesValue, setPagesValue] = useState(data.settings[1].value);
  const [hashtags, setHashtags] = useState(false);
  const [hashtagsValue, setHashtagsValue] = useState(data.settings[2].value);
  const [events, setEvents] = useState(false);
  const [eventsValue, setEventsValue] = useState(data.settings[3].value);
  const [articles, setArticles] = useState(false);
  const [articlesValue, setArticlesValue] = useState(data.settings[4].value);
  const [groups, setGroups] = useState(false);
  const [groupsValue, setGroupsValue] = useState(data.settings[5].value);
  const [newsletters, setNewsletters] = useState(false);
  const [newslettersValue, setNewslettersValue] = useState(
    data.settings[6].value
  );
  const [connections, setConnections] = useState(false);
  const [connectionsValue, setConnectionsValue] = useState(
    data.settings[7].value
  );
  const [peopleFollow, setPeopleFollow] = useState(false);
  const [peopleFollowValue, setPeopleFollowValue] = useState(
    data.settings[0].value
  );
  let token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (peopleFollowValue === "1") {
      setPeopleFollow(true);
    }
    if (pagesValue === "1") {
      setPages(true);
    }
    if (hashtagsValue === "1") {
      setHashtags(true);
    }
    if (eventsValue === "1") {
      setEvents(true);
    }
    if (articlesValue === "1") {
      setArticles(true);
    }
    if (groupsValue === "1") {
      setGroups(true);
    }
    if (newsletters === "1") {
      setNewsletters(true);
    }
    if (newslettersValue === "1") {
      setNewsletters(true);
    }
    if (connectionsValue === "1") {
      setConnections(true);
    }
  }, []);

  const handleFollow = (event) => {
    setPeopleFollow(event.target.checked);
    if (event.target.checked === true) {
      setPeopleFollowValue("1");
    }
    if (event.target.checked === false) {
      setPeopleFollowValue("0");
    }
  };

  const handlePages = (event) => {
    setPages(event.target.checked);
    if (event.target.checked == true) {
      setPagesValue("1");
    }
    if (event.target.checked == false) {
      setPagesValue("0");
    }
  };
  const handleHashtags = (event) => {
    setHashtags(event.target.checked);
    if (event.target.checked == true) {
      setHashtagsValue("1");
    }
    if (event.target.checked == false) {
      setHashtagsValue("0");
    }
  };
  const handleEvents = (event) => {
    setEvents(event.target.checked);
    if (event.target.checked == true) {
      setEventsValue("1");
    }
    if (event.target.checked == false) {
      setEventsValue("0");
    }
  };
  const handleArticles = (event) => {
    setArticles(event.target.checked);
    if (event.target.checked == true) {
      setArticlesValue("1");
    }
    if (event.target.checked == false) {
      setArticlesValue("0");
    }
  };
  const handleGroups = (event) => {
    setGroups(event.target.checked);
    if (event.target.checked == true) {
      setGroupsValue("1");
    }
    if (event.target.checked == false) {
      setGroupsValue("0");
    }
  };
  const handleNewsletters = (event) => {
    setNewsletters(event.target.checked);
    if (event.target.checked == true) {
      setNewslettersValue("1");
    }
    if (event.target.checked == false) {
      setNewslettersValue("0");
    }
  };
  const handleConnections = (event) => {
    setConnections(event.target.checked);
    if (event.target.checked == true) {
      setConnectionsValue("1");
    }
    if (event.target.checked == false) {
      setConnectionsValue("0");
    }
  };

  const updateMode = async () => {
    try {
      let updateData = {
        setting: [
          {
            setting_master_id: 2,
            value: peopleFollowValue,
          },
          {
            setting_master_id: 3,
            value: pagesValue,
          },
          {
            setting_master_id: 4,
            value: hashtagsValue,
          },
          {
            setting_master_id: 5,
            value: eventsValue,
          },
          {
            setting_master_id: 6,
            value: articlesValue,
          },
          {
            setting_master_id: 7,
            value: groupsValue,
          },
          {
            setting_master_id: 8,
            value: newslettersValue,
          },
          {
            setting_master_id: 9,
            value: connectionsValue,
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        setResponseMsg(res.message);
        setTimeout(() => {
          setResponseMsg("");
          toggle();
        }, 3000);
        // alert(
        //   "This feature will be implemented in the future milestone"
        // );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                <p>Choose your preferred feed content preferences.</p>
                <ul className="mt-3">
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleFollow(e)}
                      checked={peopleFollow}
                    />{" "}
                    People I Follow
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handlePages(e)}
                      checked={pages}
                    />{" "}
                    Pages
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleHashtags(e)}
                      checked={hashtags}
                    />{" "}
                    Hashtags
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleEvents(e)}
                      checked={events}
                    />{" "}
                    Events
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleArticles(e)}
                      checked={articles}
                    />{" "}
                    Articles
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleGroups(e)}
                      checked={groups}
                    />{" "}
                    Groups
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleNewsletters(e)}
                      checked={newsletters}
                    />{" "}
                    Newsletters
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      onChange={(e) => handleConnections(e)}
                      checked={connections}
                    />{" "}
                    Connections
                  </li>
                </ul>
                <div className="text-end">
                  <div className="btn btn-blue" onClick={updateMode}>
                    Save
                  </div>
                </div>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FeedModal;
