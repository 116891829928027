import React, { useEffect, useState } from "react";
import LeftSidebar from "./LeftSidebar";
import noti from "../../../assets/img/notification-orange.svg";
import "./TalentPoolDashboard.scss";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers } from "../../../store/getAllUsers/allUsersAction";
import HomeTab from "./HomeTab";
import SettingTab from "./SettingTab";
import PostNewJobModal from "./PostNewJobModal";
import AddJobDetailModal from "./AddJobDetailModal";
import AddJobDetail2Modal from "./AddJobDetail2Modal";
import JobPostSuccessful from "../../Jobs/PostJob/components/SucessfulModal";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import {
  getTalentPool,
  getmembers,
} from "../../../store/talentPool/talentPoolAction";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import { ToastContainer, toast } from "react-toastify";
import MemberTab from "./memberTab";
import { portalApi } from "../../../api/portalApi";

const TalentPoolDashboard = () => {
  const [tab, setTab] = useState("home");
  const [memberList, setMemberList] = useState([]);
  const [memberInput, setMemberInput] = useState("");
  const [postJobModal, setPostJobModal] = useState(false);
  const [postJobFormData, setPostJobFormData] = useState({});
  const [addJobDetailModal, setAddJobDetailModal] = useState(false);
  const [addSecondJobDetailModal, setAddSecondJobDetailModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = "#000";
  const { isLoading } = useSelector((state) => state.loader);
  const { portal } = useSelector((state) => state.talentPool);
  const params = useParams();
  const [getJobs, setGetJobs] = useState(false);
  const [createdJobData, setCreatedJobData] = useState();
  useEffect(() => {
    if (memberInput?.val?.trim()) getMemberList();
  }, [memberInput]);

  const getMemberList = async () => {
    try {
      const res = await portalApi.getMemebersList(portal?.id, {
        search: memberInput?.val,
      });
      if (res?.success) {
        setMemberList(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(getTalentPool(params?.id))
      .then((res) => {
        if (!res.success) {
          navigate("/dashboard");
          toast.error("Permission Denied", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        setGetJobs(true);
      })
      .catch((err) => {
        navigate("/dashboard");
        toast.error("Permission Denied", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <div className="talent-dashboard">
      <div className="col-lg-3">
        <LeftSidebar tab={tab} setTab={setTab} color={portal?.theme || theme} />
      </div>
      <div className="col-lg-9">
        {isLoading && <Loader color={portal?.theme || theme} />}
        <div
          className="pe-5 pb-5 pt-5"
          style={{ backgroundColor: "#f8faff", paddingLeft: "20px" }}
        >
          <div className="row mb-4">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className="row justify-content-between align-items-cener">
                <div className="col-lg-8 col-md-6 col-sm-12 px-sm-2 px-0">
                  <h3
                    className="text-dark tp__name"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "30px",
                      maxWidth: "350px",
                    }}
                  >
                    Hello,{" "}
                    {portal?.User?.first_name
                      ? portal?.User?.first_name
                      : "User"}
                  </h3>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 px-sm-2 px-0 tp-serch">
                  <div className="noti justify-content-end mt-2">
                    <div className="notification">
                      <img
                        src={noti}
                        alt="noti"
                        width={20}
                        onClick={() => navigate("/notifications")}
                      />
                    </div>
                    <div className="search autocomplete-wrapper">
                      <Autocomplete
                        open={open}
                        inputProps={{ placeholder: "Search candidate" }}
                        value={memberInput?.val || ""}
                        items={memberList}
                        getItemValue={(item) => item?.user?.first_name || ""}
                        renderMenu={(items) => (
                          <div className="dropdown">
                            {items?.length === 0 ? "No match found" : items}
                          </div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item mt-2 ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                            style={{ display: "flex", zIndex: "2!important" }}
                          >
                            <img
                              src={
                                item?.user?.profile_img
                                  ? item?.user?.profile_img
                                  : placeholderUser
                              }
                              alt="dropdown_pic"
                              className="image-fit mr-10"
                              style={{ height: "36px", width: "36px" }}
                            />
                            <div>
                              <div className="name-heading-drop">
                                {item?.user?.first_name || "Unknown"}{" "}
                                {item?.user?.last_name || ""}
                              </div>
                              <div className="sub-heading-drop">
                                {item?.user?.profile?.profile_headline || ""}
                              </div>
                            </div>
                          </div>
                        )}
                        onChange={(event, val) => {
                          setOpen(!!val);
                          setMemberInput({ val: val || "" });
                        }}
                        onSelect={(val, obj) => {
                          setMemberInput({ val });
                          navigate(
                            `/tp/${params?.id}/member/details/${obj?.user_id}`
                          );
                          setOpen(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tab === "home" && (
            <HomeTab setPostJobModal={setPostJobModal} getJob={getJobs} />
          )}
          {tab === "setting" && (
            <SettingTab setPostJobModal={setPostJobModal} />
          )}
          {tab === "member" && <MemberTab />}
        </div>
        <ToastContainer />
      </div>
      {postJobModal && (
        <PostNewJobModal
          modal={postJobModal}
          toggle={() => setPostJobModal(!postJobModal)}
          setAddJobDetailModal={setAddJobDetailModal}
          formData={postJobFormData}
          setFormData={setPostJobFormData}
        />
      )}
      {addJobDetailModal && (
        <AddJobDetailModal
          modal={addJobDetailModal}
          toggle={() => setAddJobDetailModal(!addJobDetailModal)}
          close={() => {
            setPostJobFormData({});
            setAddJobDetailModal(!addJobDetailModal);
          }}
          title="Job Details"
          formData={postJobFormData}
          setPostJobModal={setPostJobModal}
          // setAddSecondJobDetailModal={setAddSecondJobDetailModal}
          setSuccessfulModal={setSuccessfulModal}
          tab={tab}
          setGetJobs={setGetJobs}
          setCreatedJobData={setCreatedJobData}
        />
      )}
      {/* {addSecondJobDetailModal && (
        <AddJobDetail2Modal
          modal={addSecondJobDetailModal}
          toggle={() => setAddSecondJobDetailModal(!addSecondJobDetailModal)}
          title="Job Details 2"
          setAddJobDetailModal={setAddJobDetailModal}
          setSuccessfulModal={setSuccessfulModal}
        />
      )} */}
      {successfulModal && (
        <JobPostSuccessful
          modal={successfulModal}
          toggle={() => setSuccessfulModal(!successfulModal)}
          talentPool={true}
          createdJobData={createdJobData}
        />
      )}
    </div>
  );
};

export default TalentPoolDashboard;
