import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import alertIcon from "../../assets/img/Warning.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearData } from "../../store/backgroundScreening/backgroundScreeningAction";
const AlertModel = ({ modal, toggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        size="md"
        isOpen={modal}
        style={{ maxWidth: "550px" }}
        centered
        className="gradient-background"
      >
        <ModalHeader
          className="dicardModalHeader text-white d-flex justify-content-start  align-items-center"
          toggle={toggle}
          style={{
            background:
              "linear-gradient(120.5deg, #5B2E92 0%, #29409C 50.49%, #0F91D2 100%)",
          }}
        >
          <img src={alertIcon} width={25} className="mx-2" /> Alert
        </ModalHeader>
        <ModalBody>
          <div className="event-create-popup">
            <p>
              Ensure your profile is accurate before starting the screening
              process. Variations may lead to rejection. Changes post-screening
              could result in profile badge loss. Related profile data will be
              locked, changes require a request with double authentication.
            </p>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn btn-blue px-4"
                onClick={() => {
                  dispatch(clearData());
                  navigate("/background-create-order", {
                    state: { edit: false },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AlertModel;
