import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { uploadVideo } from "../../../../store/profile/profileActions";
import { useDispatch } from "react-redux";
import WebcamVideo from "./recordVideo/recordVideo";
import ApiLoader from "../../../../core/components/Loaders/apiLoader/apiLoader";
import { toast } from "react-toastify";
import waterMark from "../../../../assets/img/introvideowatermark.png";

export default function AddIntroVideo(props) {
  const { modal, toggle } = props;

  const dispatch = useDispatch();

  const [introVideo, setIntroVideo] = useState();
  const [urlData, setUrlData] = useState();
  const [tab, setTab] = useState("");
  const [errorVideo, setErrorVideo] = useState(null);

  const onChangeVideo = async (e) => {
    setErrorVideo("uploading");
    console.log(e.target.files);
    if (e.target.files[0]) {
      setIntroVideo(URL.createObjectURL(e.target.files[0]));
    }

    let tempimage = [];
    let type = "video";
    Array.from(e.target.files).map((file) => {
      return tempimage.push({ type, file });
    });
    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/compress-upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (var i = 0; i < tempimage.length; i++) {
        formData.append("image", tempimage[i].file);
      }

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        setUrlData(res?.data?.urlsArray[0]);
        // setCroppedImage(res?.data?.urlsArray[0]);
        setErrorVideo(true);
      });
    } catch (err) {
      console.log(err);
      setErrorVideo(err.response.data.message);
    }
  };

  const handleSubmit = () => {
    let token = localStorage.getItem("accessToken");
    let data = {
      intro_video: urlData,
    };
    dispatch(uploadVideo(data, token)).then((response) => {
      toast.success("Upload saved successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      toggle();
    });
  };

  // const handleSubmitRecord = (videoURL) => {
  //   let token = localStorage.getItem("accessToken");
  //   let data = {
  //     intro_video: videoURL,
  //   };
  //   dispatch(uploadVideo(data, token)).then((response) => {
  //     toast.success("Upload saved successfully", {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //     toggle();
  //   });
  // };

  return (
    <div>
      <Modal
        isOpen={modal}
        size="xl"
        toggle={toggle}
        style={{ maxWidth: "1100px" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="gradient-background"
      >
        <div className="popup">
          <div className="add-skill">
            <ModalHeader toggle={toggle}>
              <h5>Introduction Video</h5>
            </ModalHeader>
            <ModalBody style={{ padding: "0px 30px 0px 30px" }}>
              <form>
                <div className="title">
                  <label className="py-3">
                    Upload or record a brief introduction video using your
                    device camera. Keep it under one minute for optimal
                    engagement.
                  </label>
                </div>
                <div
                  // style={{height:"500px"}}
                  className="mt-10"
                >
                  <input
                    type="file"
                    id="intro_video"
                    name="intro_video"
                    accept="video/mp4"
                    style={{ display: "none" }}
                    onChange={onChangeVideo}
                    //   onClick={onInputClick}
                  />
                  {introVideo ? (
                    <div>
                      {errorVideo === "uploading" ? (
                        <>
                          <p className="text-center mt-10 mb-10">
                            <ApiLoader />
                          </p>
                        </>
                      ) : errorVideo === true ? (
                        <div style={{ position: "relative" }}>
                          <video
                            controls
                            width="100%"
                            controlsList="nodownload"
                          >
                            <source src={introVideo} />
                          </video>

                          <img
                            style={{
                              width: "82px",
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                            src={waterMark}
                            alt="NobelPage logo"
                          />
                        </div>
                      ) : (
                        <p className="text-center red-color">{errorVideo}!</p>
                      )}
                    </div>
                  ) : tab === "recording" ? (
                    <div style={{ position: "relative" }}>
                      <WebcamVideo setUrlData={setUrlData} toggle={toggle} />
                      <img
                        style={{
                          width: "82px",
                          position: "absolute",
                          top: "10px",
                          right: "20px",
                        }}
                        src={waterMark}
                        alt="NobelPage logo"
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "30px",
                      }}
                      className="video-buttons-row"
                    >
                      <label
                        style={{
                          display: "block",
                          cursor: "pointer",
                          fontSize: "15px",
                          background: "#e5e5e5",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                        htmlFor="intro_video"
                      >
                        <div>Upload Video</div>
                      </label>
                      <label
                        style={{
                          display: "block",
                          cursor: "pointer",
                          fontSize: "15px",
                          background: "#e5e5e5",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        <div onClick={() => setTab("recording")}>
                          Record Video
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                <div className="add-media mb-20">
                  {errorVideo === true ? (
                    <div
                      className="btn btn-blue"
                      style={{ marginTop: "30px" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </div>
                  ) : errorVideo === "uploading" ? (
                    <></>
                  ) : introVideo ? (
                    <div
                      className="btn btn-blue"
                      style={{ marginTop: "30px" }}
                      onClick={() => {
                        setIntroVideo(null);
                        setErrorVideo(null);
                      }}
                    >
                      Try Again!
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
}
