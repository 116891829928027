import React, { useEffect, useState } from "react";
import moment from "moment";
import ShowCelebration from "../CelebrationPostModals/ShowCelebration";
import ShowDasboardImage from "../ShowDasboardImage";
import CommentsContainer from "../comments/Comments.container";
import like from "../../../../assets/img/like_1.svg";
import likeGrey from "../../../../assets/img/like_grey.svg";
import hand from "../../../../assets/img/clapping 3.svg";
import heart from "../../../../assets/img/ant-design_heart-filled.svg";
import hat from "../../../../assets/img/hat 1.svg";
import bulb from "../../../../assets/img/idea_1.svg";
import emoj from "../../../../assets/img/smile_1.svg";
import surp from "../../../../assets/img/facebook-reactions_1.svg";
import comment from "../../../../assets/img/comment.svg";
import save from "../../../../assets/img/save.svg";
import unsave from "../../../../assets/img/unsave.svg";
import speaker from "../../../../assets/img/speaker.svg";
import cross_circle from "../../../../assets/img/cross_circle.svg";
import edit from "../../../../assets/img/edit-icon.svg";
import slash from "../../../../assets/img/blockIcon.svg";
import deleteIcon from "../../../../assets/img/delete.svg";
import share from "../../../../assets/img/share.svg";
import link from "../../../../assets/img/link.svg";
import eyeOff from "../../../../assets/img/eyeOff.svg";
import eye from "../../../../assets/img/eye.svg";
import repost from "../../../../assets/img/repost.svg";
import sendIcon from "../../../../assets/img/sendIconGrey.svg";
import add from "../../../../assets/img/add-icon.svg";
import union from "../../../../assets/img/Union-blue.svg";
import remove from "../../../../assets/img/remove-user-16.png";
import unfollow from "../../../../assets/img/unfollow.png";
import hashtagIcon from "../../../../assets/img/hastag.svg";
import message from "../../../../assets/img/message-icon.svg";
import postNow1 from "../../../../assets/img/postNow.svg";
import "./postOnDashboard.scss";
// import tag from "../../assets/img/tag.svg";
// import create from "../../assets/img/create.png";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewPollComp from "../poll/viewPollComponent";
import DropdownApp from "../../../../core/components/Dropdownv2/CustomDropdown";

import UnfollowModal from "../../../../core/components/modal/UnfollowModal";
import RemoveModal from "../../../../core/components/modal/RemoveModal";
import {
  GetFormattedText,
  GetHashtagText,
  GetTime,
  abbreviateNumber,
  autoDetect,
  createLink,
  getReadingTime,
  handleTranslate,
} from "../../../../core/components/Utility/Utility";
import { Spinner } from "reactstrap";
import routeNames from "../../../routeNames";
import GifModal from "../../../../core/components/common/GifModal";
import { useDispatch, useSelector } from "react-redux";
import { postViewLog } from "../../../../store/dashboard/dashboardActions";

const PostOnDashboard = (props) => {
  const {
    item,
    index,
    userId,
    user,
    handleReaction,
    isPagePosts,
    userName,
    setPostID,
    isViewReaction,
    setViewReaction,
    privacy_type,
    dropDown,
    setDropDown,
    editPost,
    save_post,
    removeSavePost,
    dropRef,
    dropRefShare,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    hideUserPost,
    commentBoxStatus,
    setCommentBoxStatus,
    dropDownShare,
    setDropDownShare,
    type,
    repostPost,
    likeResult,
    sendRequest,
    peopleFollowRequest,
    peopleUnFollowRequest,
    peopleRemoveConnection,
    handlePageFollow,
    handlePageUnFollow,
    postNow,
    commentStatus,
    setCommentStatus,
    handleCommentStatus,
    closeModal,
    setCloseModal,
    setDeleteId,
    postViewCounter,
    openShareModal,
    setOpenShareModal,
    setShareData,
    getPermissions,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    roleShareModal,
    setRoleShareModal,
    setRolePageId,
    selectedUserId,
    isSinglePost,
  } = props;
  const { profileData } = useSelector((state) => state.profile);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteRemoveModal, setDeleteRemoveModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [unfollowDetail, setUnfollowDetail] = useState(null);
  const [isTranslated, setIsTranslated] = useState();
  const [translatedText, setTraslatedText] = useState();
  const [detectedLanguage, setDetectedLanguage] = useState();

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }
  useEffect(() => {
    autoDetect(item?.description, setDetectedLanguage);
  }, []);

  const navigate = useNavigate();

  // console.log(
  //   item?.User?.badge == "hiring" && item?.User?.membership?.name !== "Starter"
  // );
  const dispatch = useDispatch();

  return (
    <div
      className={`post-main-box  ${
        type === "myPost" ||
        type === "savedPost" ||
        type === "scheduledPost" ||
        type === "draftPost"
          ? "p-0"
          : "p-20"
      }`}
      key={index}
    >
      <div style={{ position: "relative" }}>
        <div
          className="post-head mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {item?.other_shared ? (
            //otherShared is a mock key for now(will be added from backend later)
            <div className="post">
              <div className="img">
                <img
                  src={
                    item?.User?.profile_img
                      ? item?.User?.profile_img
                      : placeholderUser
                  }
                  alt=""
                />
              </div>
              <span className="userNameTextWrap">
                {item?.User?.first_name + " " + item?.User?.last_name}
              </span>
              <span>
                {item?.User?.is_email_verified && (
                  <div className="varify">
                    <div className="checkMark"></div>
                  </div>
                )}
                {item?.User?.badge == "hiring" &&
                  item?.User?.membership?.name !== "Starter" && (
                    <div className="hiring">
                      <span>Hiring</span>
                    </div>
                  )}
                {item?.User?.badge == "seeking" && (
                  <div className="seeking">
                    <span>Seeking</span>
                  </div>
                )}
              </span>
              {item?.User?.is_email_verified && <div className="varify"></div>}
              <div className="share">
                <span>Shared this post</span>
              </div>
            </div>
          ) : item?.Repost ? (
            <div className="post">
              <div className="img">
                {item?.Repost?.Page ? (
                  <img
                    src={
                      item?.Repost?.Page?.icon
                        ? item?.Repost?.Page?.icon
                        : placeholderUser
                    }
                    onClick={() =>
                      navigate(`/pageadminview/${item?.Repost?.Page?.id}`)
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      item?.Repost?.User?.profile_img
                        ? item?.Repost?.User?.profile_img
                        : placeholderUser
                    }
                    onClick={() =>
                      navigate(`/profile/${item?.Repost?.user_id}`)
                    }
                    alt=""
                  />
                )}
              </div>

              {item?.Repost?.Page ? (
                <div
                  className="d-flex align-items-center text-wrap-nowrap info-title"
                  onClick={() =>
                    navigate(`/pageadminview/${item?.Repost?.Page?.id}`)
                  }
                >
                  <h5 className="name-bold">{item?.Repost?.Page?.name} </h5>
                  <span>reposted this post</span>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center text-wrap-nowrap info-title"
                  onClick={() => navigate(`/profile/${item?.Repost?.user_id}`)}
                >
                  <h5 className="name-bold">
                    {item?.Repost?.User?.first_name +
                      " " +
                      item?.Repost?.User?.last_name}{" "}
                  </h5>
                  <span>reposted this post</span>
                </div>
              )}
              {item?.Repost?.User?.is_email_verified && (
                <div className="varify"></div>
              )}
            </div>
          ) : item?.Reaction_Activity ? (
            <div className="post">
              <div className="img">
                <img
                  src={
                    item?.Reaction_Activity?.User?.profile_img
                      ? item?.Reaction_Activity?.User?.profile_img
                      : placeholderUser
                  }
                  onClick={() =>
                    navigate(`/profile/${item?.Reaction_Activity?.user_id}`)
                  }
                  alt=""
                />
              </div>

              <div
                className="d-flex align-items-center text-wrap-nowrap info-title"
                onClick={() =>
                  navigate(`/profile/${item?.Reaction_Activity?.user_id}`)
                }
              >
                <h5 className="name-bold">
                  {type === "activity"
                    ? userName
                    : item?.Reaction_Activity?.User?.first_name +
                      " " +
                      item?.Reaction_Activity.User?.last_name}{" "}
                </h5>
                <span>
                  {`${
                    item?.Reaction_Activity?.type === "Insightful" ||
                    item?.Reaction_Activity?.type === "Curious" ||
                    item?.Reaction_Activity?.type === "Respect"
                      ? item?.Reaction_Activity?.type.toLowerCase()
                      : `${item?.Reaction_Activity?.type.toLowerCase()}d`
                  } this post`}
                  {/* {item?.Reaction_Activity?.type?.toLowerCase()}d this post */}
                </span>
              </div>
            </div>
          ) : item?.Hashtag_Activity ? (
            <div className="post">
              <div className="hash"></div>
              <div
                className="d-flex align-items-center text-wrap-nowrap info-title"
                onClick={() =>
                  navigate(
                    `/hashtag/feed/?keyword=${item?.Hashtag_Activity?.name}`
                  )
                }
              >
                <h5 className="name-bold">{item?.Hashtag_Activity?.name} </h5>
                <span>following</span>
              </div>
            </div>
          ) : (
            ""
          )}
          {item?.Repost || item?.Reaction_Activity || item?.Hashtag_Activity ? (
            <div
              ref={dropRef}
              className="dots clickDots "
              onClick={() => {
                if (dropDown === index) {
                  setDropDown(-1);
                } else {
                  setDropDown(index);
                }
              }}
            >
              {/* <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span> */}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="post">
          <div className="drop">
            {dropDown === index && (
              <div className="box">
                {type === "myPost" ? (
                  <ul id="dropdownBox" ref={dropRef}>
                    {(userId === item?.user_id || userId === item?.shared_by) &&
                    !item?.Celebration ? (
                      <li className="listing" onClick={() => editPost(item)}>
                        <img src={edit} alt="" />
                        Edit
                      </li>
                    ) : (
                      ""
                    )}
                    {userId === item?.user_id || userId === item?.shared_by ? (
                      <li
                        className="listing"
                        onClick={() => {
                          setCloseModal(!closeModal);
                          setDeleteId(item?.id);
                        }}
                      >
                        <img src={deleteIcon} alt="" />
                        Delete
                      </li>
                    ) : (
                      ""
                    )}
                    {!item?.Saved_Post && item?.Saved_Post === false ? (
                      <>
                        <li
                          //className="listing "
                          style={{ gap: "11px" }}
                          onClick={() => {
                            save_post(item?.id, index);
                          }}
                        >
                          <img
                            src={save}
                            alt="save-btn"
                            className="me-1"
                            // style={{ paddingLeft: "2px" }}
                            style={{ width: "13px", height: "13px" }}
                          />{" "}
                          Save
                        </li>
                      </>
                    ) : (
                      <li
                        className="listing"
                        style={{ gap: "19px" }}
                        onClick={() => {
                          removeSavePost(item?.id);
                        }}
                      >
                        <img src={unsave} alt="" /> {"Unsave"}
                      </li>
                    )}

                    {
                      <li
                        className="listing"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.origin}/postdetails/${item.id}`
                          );
                          toast.success("Copied URL !", {
                            position: toast.POSITION.BOTTOM_LEFT,
                          });
                        }}
                      >
                        <img
                          src={link}
                          alt=""
                          style={{ width: "13px", height: "13px" }}
                        />{" "}
                        Copy Link
                      </li>
                    }
                    {userId === item?.user_id ? (
                      <li
                        className="listing"
                        onClick={() => {
                          navigate(routeNames.CREATECAMPAIGN, {
                            state: {
                              isFeatured: false,
                              selectedPostId: item?.id,
                            },
                          });
                        }}
                      >
                        <img src={speaker} alt="" />
                        Promote this Post
                      </li>
                    ) : null}
                  </ul>
                ) : type === "scheduledPost" ? (
                  <ul id="dropdownBox" ref={dropRef}>
                    {(userId === item?.user_id || userId === item?.shared_by) &&
                    !item?.Celebration ? (
                      <li
                        className="listing"
                        onClick={
                          () => editPost(item, true)

                          //postNow(item?.id, "schedulePost")
                        }
                      >
                        <img src={postNow1} alt="" />
                        Post Now
                      </li>
                    ) : (
                      ""
                    )}
                    {(userId === item?.user_id || userId === item?.shared_by) &&
                    !item?.Celebration ? (
                      <li className="listing" onClick={() => editPost(item)}>
                        <img src={edit} alt="" />
                        Edit
                      </li>
                    ) : (
                      ""
                    )}
                    {userId === item?.user_id || userId === item?.shared_by ? (
                      <li
                        className="listing"
                        onClick={() => {
                          setCloseModal(!closeModal);
                          setDeleteId(item?.id);
                        }}
                      >
                        {" "}
                        <img src={deleteIcon} alt="" />
                        Delete
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : type === "draftPost" ? (
                  <ul id="dropdownBox" ref={dropRef}>
                    {/* {(userId === item?.user_id || userId === item?.shared_by) &&
                    !item?.Celebration ? (
                      <li
                        className="listing"
                        onClick={() => postNow(item?.id, "draftPost")}
                      >
                        <img src={postNow1} alt="" />
                        Post Now
                      </li>
                    ) : (
                      ""
                    )} */}
                    {(userId === item?.user_id || userId === item?.shared_by) &&
                    !item?.Celebration ? (
                      <li className="listing" onClick={() => editPost(item)}>
                        <img src={edit} alt="" />
                        Edit
                      </li>
                    ) : (
                      ""
                    )}
                    {userId === item?.user_id || userId === item?.shared_by ? (
                      <li
                        className="listing"
                        onClick={() => {
                          setCloseModal(!closeModal);
                          setDeleteId(item?.id);
                        }}
                      >
                        {" "}
                        <img src={deleteIcon} alt="" />
                        Delete
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  <ul id="dropdownBox" ref={dropRef}>
                    {(item?.shared_by_type === "page" &&
                      getPermissions?.manage_feed) ||
                    (item?.shared_by_type === "user" &&
                      (userId === item?.user_id ||
                        userId === item?.shared_by) &&
                      !item?.Celebration) ||
                    (type === "groupPost" &&
                      (userId === item?.user_id ||
                        userId === item?.shared_by)) ? (
                      <li className="listing" onClick={() => editPost(item)}>
                        <img
                          src={edit}
                          alt=""
                          style={{ width: "13px", height: "13px" }}
                        />{" "}
                        Edit
                      </li>
                    ) : (
                      ""
                    )}
                    {(item?.shared_by_type === "page" &&
                      getPermissions?.manage_feed) ||
                    (item?.shared_by_type === "user" &&
                      (userId === item?.user_id ||
                        userId === item?.Repost?.user_id ||
                        userId === item?.shared_by)) ||
                    (type === "groupPost" &&
                      (userId === item?.user_id ||
                        userId === item?.shared_by)) ? (
                      <li
                        className="listing"
                        onClick={() => {
                          setCloseModal(!closeModal);
                          setDeleteId(
                            item?.Repost?.id ? item?.Repost?.id : item?.id
                          );
                        }}
                      >
                        <img
                          src={deleteIcon}
                          style={{ width: "13px", height: "13px" }}
                          alt=""
                        />{" "}
                        {item?.Repost?.user_id ? "Delete Repost" : "Delete"}
                      </li>
                    ) : (
                      ""
                    )}
                    {!isPagePosts ? (
                      <>
                        {!item?.Saved_Post && item?.Saved_Post === false ? (
                          <li
                            //className="listing "
                            style={{ gap: "11px" }}
                            onClick={() => {
                              save_post(item?.id, index);
                            }}
                          >
                            <img
                              src={save}
                              alt="save-btn"
                              className="me-1"
                              // style={{ paddingLeft: "2px" }}
                              style={{ width: "13px", height: "13px" }}
                            />{" "}
                            Save
                          </li>
                        ) : (
                          <li
                            className="listing"
                            style={{ gap: "19px" }}
                            onClick={() => {
                              removeSavePost(item?.id);
                            }}
                          >
                            <img src={unsave} alt="" /> {"Unsave"}
                          </li>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {item?.shared_by_type != "page" &&
                    item?.User?.follow_status ? (
                      <li
                        className="listing card-control-text-oneline"
                        onClick={() => {
                          setDeleteModal(!deleteModal);
                          // peopleUnFollowRequest(
                          //   item?.user_id,
                          //   "home",
                          //   item?.id
                          // );
                        }}
                      >
                        <img
                          src={unfollow}
                          alt=""
                          style={{ width: "13px", height: "13px" }}
                        />
                        {" " +
                          `Unfollow ${truncate(item?.User?.first_name, 10)}` +
                          " " +
                          `${truncate(item?.User?.last_name, 10)}`}
                      </li>
                    ) : (
                      // item?.shared_by_type === "page" &&
                      //   item?.Page?.is_following ? (
                      //   <li
                      //     className="listing card-control-text-oneline"
                      //     onClick={() => {
                      //       setUnfollowDetail({
                      //         pageId: item?.Page?.id,
                      //         pageName: "home",
                      //         id: item?.id,
                      //       });
                      //       setDeleteModal(!deleteModal);
                      //     }}
                      //   >
                      //     <img src={unfollow} alt="" style={{ width: "13px" }} />
                      //     {" " + `Unfollow ${truncate(item?.Page?.name, 13)}`}
                      //   </li>
                      // ) :
                      ""
                    )}
                    {item?.shared_by_type != "page" &&
                    item?.User?.connection_status === "confirm" ? (
                      <li
                        className="listing card-control-text-oneline"
                        // onClick={() => {
                        //   peopleRemoveConnection(
                        //     item?.user_id,
                        //     "home",
                        //     item?.id
                        //   );

                        // }}
                        onClick={() => {
                          setRemoveModal(!removeModal);
                          setDeleteRemoveModal({
                            pageId: item?.user_id,
                            pageName: "home",
                            id: item?.id,
                          });
                        }}
                      >
                        <img
                          src={remove}
                          alt=""
                          style={{ width: "13px", height: "13px" }}
                        />
                        {`Remove Connection with ${truncate(
                          item?.User?.first_name,
                          5
                        )}` +
                          " " +
                          `${truncate(item?.User?.last_name, 5)}`}
                      </li>
                    ) : (
                      ""
                    )}
                    {!isSinglePost && (
                      <li
                        className="listing"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.origin}/postdetails/${item.id}`
                          );
                          toast.success("Copied URL !", {
                            position: toast.POSITION.BOTTOM_LEFT,
                          });
                        }}
                      >
                        <img
                          src={link}
                          alt=""
                          style={{ width: "13px", height: "13px" }}
                        />{" "}
                        Copy Link
                      </li>
                    )}
                    {userId === item?.user_id ? (
                      <li
                        className="listing"
                        onClick={() => {
                          navigate(routeNames.CREATECAMPAIGN, {
                            state: {
                              isFeatured: false,
                              selectedPostId: item?.id,
                            },
                          });
                        }}
                      >
                        <img src={speaker} alt="" />
                        Promote this Post
                      </li>
                    ) : null}
                    {(userId === item?.user_id || userId === item?.shared_by) &&
                    !isPagePosts &&
                    !type === "groupPost" ? (
                      <>
                        <>
                          {item?.comment_status ? (
                            <li
                              className="listing"
                              //style={{ gap: "12px" }}
                              onClick={() =>
                                handleCommentStatus(item.id, "commentTrue")
                              }
                            >
                              <img
                                src={eyeOff}
                                alt=""
                                style={{ height: "13px", width: "13px" }}
                              />
                              Turn off comments
                            </li>
                          ) : (
                            <li
                              className="listing"
                              //   style={{ gap: "12px" }}
                              onClick={() =>
                                handleCommentStatus(item.id, "commentFalse")
                              }
                            >
                              <img
                                src={eye}
                                alt=""
                                style={{ height: "16px" }}
                              />
                              Turn on comments
                            </li>
                          )}
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                    {!isPagePosts ? (
                      userId === item?.user_id || userId === item?.shared_by ? (
                        <></>
                      ) : (
                        <li
                          className="listing"
                          onClick={() => {
                            hideUserPost(item?.id);
                          }}
                        >
                          <img
                            src={cross_circle}
                            alt=""
                            style={{ height: "13px", width: "13px" }}
                          />
                          Hide User's Post
                        </li>
                      )
                    ) : (
                      <></>
                    )}
                    {!isPagePosts ? (
                      userId === item?.user_id || userId === item?.shared_by ? (
                        <></>
                      ) : (
                        <li
                          onClick={() => {
                            setReportModal(!reportModal);
                            setPostID(item?.id);
                            setReportType("post");
                            setReportCommentId(0);
                          }}
                          className="listing"
                        >
                          <img
                            src={slash}
                            alt=""
                            style={{ height: "13px", width: "13px" }}
                          />{" "}
                          Report this Post
                        </li>
                      )
                    ) : (
                      <></>
                    )}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        {item?.other_shared ||
        item?.Repost ||
        item?.Reaction_Activity ||
        item?.Hashtag_Activity ? (
          <div className="line mb-20"></div>
        ) : (
          ""
        )}
        <div className="main-post ">
          <div className="main-Post-div ">
            <div
              className="prof-img mx-2"
              onClick={() => navigate(`/profile/${item?.user_id}`)}
            >
              <img
                src={
                  item?.Page?.icon
                    ? item?.Page?.icon
                    : item?.User?.profile_img
                    ? item?.User?.profile_img
                    : placeholderUser
                }
                alt=""
                className="img-fluid"
                style={{ width: "40px" }}
              />
            </div>
            <div className="details">
              {item?.Page ? (
                <>
                  <h4
                    className="userNameTextWrap"
                    onClick={() => navigate(`/pageadminview/${item?.Page?.id}`)}
                  >
                    {item?.Page?.name}
                  </h4>
                  <p>{item?.Page?.industry}</p>
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <h4
                      className="userNameTextWrap d-flex"
                      onClick={() => navigate(`/profile/${item?.user_id}`)}
                    >
                      {item?.User?.first_name + " " + item?.User?.last_name}
                    </h4>
                    {item?.User?.is_email_verified && (
                      <div className="varify mx-2">
                        <div className="checkMark"></div>
                      </div>
                    )}
                    {item?.User?.badge == "hiring" &&
                      item?.User?.membership?.name !== "Starter" && (
                        <div className="hiring  mx-2">
                          <span>Hiring</span>
                        </div>
                      )}
                    {item?.User?.badge == "seeking" && (
                      <div className="seeking mx-2">
                        <span>Seeking</span>
                      </div>
                    )}
                  </div>
                  {item?.User?.profile?.profile_headline ? (
                    <p className=" card-control-text-twoline maxWid">
                      {item?.User?.profile?.profile_headline}
                    </p>
                  ) : (
                    ""
                  )}
                </>
              )}

              {type === "scheduledPost" ? (
                <p>
                  Scheduled on {moment(item?.scheduled_time).format("MMMM")}{" "}
                  {moment(item?.scheduled_time).format("DD")} at{" "}
                  {moment(item?.scheduled_time).format("hh:mm A")}
                </p>
              ) : (
                <p>
                  {item?.type == "campaign" ? (
                    <span>Promoted</span>
                  ) : (
                    <span>{GetTime(item?.updatedAt)}</span>
                  )}
                  <span className="ml-5">&#x2022;</span>
                  <span>
                    <>{privacy_type(item?.privacy_id)}</>
                  </span>
                  {type === "draftPost" ||
                  item?.createdAt === item?.updatedAt ? (
                    <></>
                  ) : (
                    <>
                      <span className="ml-5">&#x2022;</span>
                      <span>&nbsp;Edited</span>
                    </>
                  )}
                </p>
              )}
            </div>
            {/* <div className="varify">
                          <div className="checkMark"></div>
                        </div>
                        <div className="hiring">
                          <span>Hiring</span>
                        </div> */}
          </div>
          {item?.Repost || item?.Reaction_Activity || item?.Hashtag_Activity ? (
            <div>
              {item?.Page?.name ? (
                <div>
                  {item?.Page?.is_following ? (
                    ""
                  ) : (
                    // <div
                    //   className="btn btn-light btn-blue-color"
                    //   style={{ cursor: "defult", padding: "6px 20px" }}
                    //   onClick={() => {
                    //     handlePageUnFollow(item?.Page?.id, "home", item?.id);
                    //   }}
                    // >
                    //   <span>
                    //     {"   "}
                    //     Following
                    //   </span>
                    // </div>
                    <div
                      className="btn btn-light btn-blue-color"
                      style={{ padding: "6px 20px" }}
                      onClick={() => {
                        handlePageFollow(item?.Page?.id, "home", item?.id);
                      }}
                    >
                      <span>
                        <img src={add} alt="" className="followicon" />
                        {"   "}
                        Follow
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {user?.data?.id === item?.user_id ? (
                    <></>
                  ) : (
                    <div className="connect">
                      {item?.User?.creator_mode ? (
                        <div>
                          {item?.User?.follow_status ? (
                            ""
                          ) : (
                            // <div
                            //   className="btn btn-light btn-blue-color"
                            //   style={{ cursor: "pointer", padding: "6px 20px" }}
                            //   onClick={() => {
                            //     peopleUnFollowRequest(
                            //       item?.user_id,
                            //       "home",
                            //       item?.id
                            //     );
                            //   }}
                            // >
                            //   <span>
                            //     {"   "}
                            //     Following
                            //   </span>
                            // </div>
                            <div
                              className="btn btn-light btn-blue-color"
                              style={{ padding: "6px 20px" }}
                              onClick={() => {
                                peopleFollowRequest(
                                  item.user_id,
                                  "home",
                                  item.id
                                );
                              }}
                            >
                              <span>
                                <img
                                  src={add}
                                  alt=""
                                  className="followicon"
                                  style={{ marginRight: "3px" }}
                                />
                                {"   "}
                                Follow
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {item?.User?.connection_status ? (
                            <div>
                              {item.User.connection_status === "confirm" ? (
                                <></>
                              ) : item?.User.connection_status === "pending" ? (
                                <div
                                  className="btn btn-light btn-blue-color d-flex gap-1"
                                  style={{ padding: "6px 20px" }}
                                >
                                  <span>
                                    <img
                                      src={union}
                                      alt=""
                                      className="followicon"
                                    />{" "}
                                    Pending
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  <div
                                    className="btn btn-light btn-blue-color d-flex gap-1"
                                    style={{ padding: "6px 20px" }}
                                    onClick={() =>
                                      sendRequest(
                                        item?.shared_by || item?.user_id,
                                        "home",
                                        item?.id
                                      )
                                    }
                                  >
                                    <span>
                                      <img
                                        src={union}
                                        alt=""
                                        className="followicon"
                                      />{" "}
                                      Connect
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className="btn btn-light btn-blue-color d-flex gap-1"
                              style={{ padding: "6px 20px" }}
                              onClick={() =>
                                sendRequest(
                                  item?.shared_by || item?.user_id,
                                  "home",
                                  item?.id
                                )
                              }
                            >
                              <span>
                                <img
                                  src={union}
                                  alt=""
                                  className="followicon"
                                />{" "}
                                Connect
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div
              ref={dropRef}
              className="dots clickDots "
              onClick={() => {
                if (dropDown === index) {
                  setDropDown(-1);
                } else {
                  setDropDown(index);
                }
              }}
            >
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
              <span className="clickDots">&#x2022;</span>
            </div>
          )}
        </div>
        <div
          id="descriptionOverFlow"
          className="text form-control border-none"
          style={{ padding: "0px" }}
        >
          <span className="descriptionOverFlowSpan despan">
            {item?.description ? GetFormattedText(item?.description) : ""}
            {item?.type === "article" || item?.type === "newsletter" ? (
              <div className="share-article-post">
                <div className="box mt-20">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <div className="boxe-img">
                        {item?.SharedDetails?.media && (
                          <img
                            src={item?.SharedDetails?.media}
                            className="img-fit"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-sm-9">
                      <div className="text">
                        {item?.type === "article" ? (
                          <h6
                            onClick={() =>
                              navigate(
                                `/articledetail/${item?.SharedDetails?.id}`
                              )
                            }
                          >
                            {item?.SharedDetails?.title}
                          </h6>
                        ) : (
                          <h6
                            onClick={() =>
                              navigate(
                                `/newsletterdetail/${item?.SharedDetails?.id}`
                              )
                            }
                          >
                            {item?.SharedDetails?.title}
                          </h6>
                        )}
                        {console.log(
                          getReadingTime(item?.SharedDetails?.content)
                        )}
                        <span>
                          {getReadingTime(item?.SharedDetails?.content) < 1
                            ? "Less then 1 min"
                            : getReadingTime(item?.SharedDetails?.content) +
                              " min"}{" "}
                          to read
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {item?.type === "event" ? (
              <div className="share-event-post">
                <div className="box mt-20">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <div className="boxe-img">
                        <img
                          src={
                            item?.SharedDetails?.media
                              ? item?.SharedDetails?.media
                              : null
                          }
                          className="img-fit"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-sm-8">
                      <div className="text">
                        <h6>{item?.SharedDetails?.title}</h6>
                        <p className="my-2">
                          {moment
                            .utc(item?.SharedDetails?.event_time)
                            .format("dddd MMM DD")}
                          ,{" "}
                          {moment
                            .utc(item?.SharedDetails?.event_time)
                            .format("hh:mm A (UTC 5.30)")}
                        </p>
                        <span>
                          Event created by{" "}
                          {item?.SharedDetails?.User?.first_name}{" "}
                          {item?.SharedDetails?.User?.last_name}
                        </span>
                        <button className="btn btn-blue mt-2">
                          {item?.SharedDetails?.event_type === "virtual"
                            ? "Virtual"
                            : "Physical"}
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-4 mt-2">
                      <div className="float-sm-end me-2">
                        <button
                          className="btn btn-light me-0"
                          onClick={() =>
                            navigate(`/eventdetail/${item?.SharedDetails?.id}`)
                          }
                        >
                          View Event
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {item?.type === "shared_post" ? (
              <div className="shareRepost">
                <div className="main-Post-div ">
                  <div
                    className="prof-img mx-2"
                    onClick={() =>
                      navigate(`/profile/${item?.SharedDetails?.user_id}`)
                    }
                  >
                    <img
                      src={
                        item?.SharedDetails?.Page?.icon
                          ? item?.SharedDetails?.Page?.icon
                          : item?.SharedDetails?.User?.profile_img
                          ? item?.SharedDetails?.User?.profile_img
                          : placeholderUser
                      }
                      alt=""
                      className="img-fluid"
                      style={{ width: "40px" }}
                    />
                  </div>
                  <div className="details">
                    {item?.SharedDetails?.Page ? (
                      <>
                        <h4
                          className="userNameTextWrap"
                          onClick={() =>
                            navigate(
                              `/pageadminview/${item?.SharedDetails?.Page?.id}`
                            )
                          }
                        >
                          {item?.SharedDetails?.Page?.name}
                        </h4>
                        <p>{item?.SharedDetails?.Page?.industry}</p>
                      </>
                    ) : (
                      <>
                        <h4
                          className="userNameTextWrap d-flex"
                          onClick={() =>
                            navigate(`/profile/${item?.SharedDetails?.user_id}`)
                          }
                        >
                          {item?.SharedDetails?.User?.first_name +
                            " " +
                            item?.SharedDetails?.User?.last_name}

                          {item?.User?.is_email_verified && (
                            <div className="varify mx-2">
                              <div className="checkMark"></div>
                            </div>
                          )}
                          {item?.User?.badge == "hiring" &&
                            item?.User?.membership?.name !== "Starter" && (
                              <div className="hiring  mx-2">
                                <span>Hiring</span>
                              </div>
                            )}
                          {item?.User?.badge == "seeking" && (
                            <div className="seeking mx-2">
                              <span>Seeking</span>
                            </div>
                          )}
                        </h4>
                        {item?.SharedDetails?.User?.profile
                          ?.profile_headline ? (
                          <p className=" card-control-text-twoline maxWid">
                            {
                              item?.SharedDetails?.User?.profile
                                ?.profile_headline
                            }
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    <p>
                      <span>{GetTime(item?.SharedDetails?.updatedAt)}</span>
                      <span className="ml-5">&#x2022;</span>
                      <span>
                        <>{privacy_type(item?.SharedDetails?.privacy_id)}</>
                      </span>
                    </p>
                  </div>
                  {/* <div className="varify">
                                  <div className="checkMark"></div>
                                </div>
                                <div className="hiring">
                                  <span>Hiring</span>
                                </div> */}
                </div>
                <div
                  id="descriptionOverFlow"
                  className="text form-control border-none"
                  style={{ padding: "0px" }}
                >
                  <span className="descriptionOverFlowSpan">
                    {item?.SharedDetails?.description
                      ? GetHashtagText(item?.SharedDetails?.description)
                      : ""}
                    {item?.SharedDetails?.type === "article" ||
                    item?.SharedDetails?.type === "newsletter" ? (
                      <div className="share-article-post">
                        <div className="box mt-20">
                          <div className="row">
                            <div className="col-lg-12 p-0">
                              <div className="boxe-img">
                                {item?.SharedDetails?.SharedDetails?.media && (
                                  <img
                                    src={
                                      item?.SharedDetails?.SharedDetails?.media
                                    }
                                    className="img-fit"
                                    alt=""
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row py-4">
                            <div className="col-sm-9">
                              <div className="text">
                                {item?.SharedDetails?.type === "article" ? (
                                  <h6
                                    onClick={() =>
                                      navigate(
                                        `/articledetail/${item?.SharedDetails?.SharedDetails?.id}`
                                      )
                                    }
                                  >
                                    {item?.SharedDetails?.SharedDetails?.title}
                                  </h6>
                                ) : (
                                  <h6
                                    onClick={() =>
                                      navigate(
                                        `/newsletterdetail/${item?.SharedDetails?.SharedDetails?.id}`
                                      )
                                    }
                                  >
                                    {item?.SharedDetails?.SharedDetails?.title}
                                  </h6>
                                )}
                                <span>
                                  {getReadingTime(
                                    item?.SharedDetails?.SharedDetails?.content
                                  ) < 1
                                    ? "Less then 1 min"
                                    : getReadingTime(
                                        item?.SharedDetails?.SharedDetails
                                          ?.content
                                      ) + " min"}{" "}
                                  to read
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.SharedDetails?.type === "event" ? (
                      <div className="share-event-post">
                        <div className="box mt-20">
                          <div className="row">
                            <div className="col-lg-12 p-0">
                              <div className="boxe-img">
                                <img
                                  src={
                                    item?.SharedDetails?.SharedDetails?.media
                                  }
                                  className="img-fit"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row py-4">
                            <div className="col-sm-8">
                              <div className="text">
                                <h6>
                                  {item?.SharedDetails?.SharedDetails?.title}
                                </h6>
                                <p className="my-2">
                                  {moment
                                    .utc(
                                      item?.SharedDetails?.SharedDetails
                                        ?.event_time
                                    )
                                    .format("dddd MMM DD")}
                                  ,{" "}
                                  {moment
                                    .utc(
                                      item?.SharedDetails?.SharedDetails
                                        ?.event_time
                                    )
                                    .format("hh:mm A (UTC 5.30)")}
                                </p>
                                <span>
                                  Event created by{" "}
                                  {
                                    item?.SharedDetails?.SharedDetails?.User
                                      ?.first_name
                                  }{" "}
                                  {
                                    item?.SharedDetails?.SharedDetails?.User
                                      ?.last_name
                                  }
                                </span>
                                <button className="btn btn-blue mt-2">
                                  {item?.SharedDetails?.SharedDetails
                                    ?.event_type === "virtual"
                                    ? "Virtual"
                                    : "Physical"}
                                </button>
                              </div>
                            </div>
                            <div className="col-sm-4 mt-2">
                              <div className="float-sm-end me-2">
                                <button
                                  className="btn btn-light me-0"
                                  onClick={() =>
                                    navigate(
                                      `/eventdetail/${item?.SharedDetails?.SharedDetails?.id}`
                                    )
                                  }
                                >
                                  View Event
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.SharedDetails?.Celebration
                      ? createLink(
                          item?.SharedDetails?.Celebration?.celebration_tag
                        )
                      : ""}

                    {item?.SharedDetails?.Feed_Documents && (
                      <div className="post-img row">
                        {
                          <div className="main-view">
                            <ShowDasboardImage
                              item={item?.SharedDetails}
                              userId={userId}
                              index={index}
                              userName={userName}
                              privacy_type={privacy_type}
                              commentBoxStatus={commentBoxStatus}
                              setCommentBoxStatus={setCommentBoxStatus}
                              dropRefShare={dropRefShare}
                              dropDownShare={dropDownShare}
                              setDropDownShare={setDropDownShare}
                              podType={type}
                              repostPost={repostPost}
                              getHashtagText={GetHashtagText}
                              handleReaction={handleReaction}
                              likeResult={likeResult}
                              sendRequest={sendRequest}
                              peopleFollowRequest={peopleFollowRequest}
                              peopleUnFollowRequest={peopleUnFollowRequest}
                              handlePageFollow={handlePageFollow}
                              handlePageUnFollow={handlePageUnFollow}
                              GetTime={GetTime}
                              postViewCounter={postViewCounter}
                              postNow={postNow}
                              commentStatus={commentStatus}
                              setCommentStatus={setCommentStatus}
                              handleCommentStatus={handleCommentStatus}
                              closeModal={closeModal}
                              setCloseModal={setCloseModal}
                              setDeleteId={setDeleteId}
                              setShareData={setShareData}
                              dropDownRepost={dropDownRepost}
                              setDropDownRepost={setDropDownRepost}
                              dropRefRepost={dropRefRepost}
                            />
                            {item?.SharedDetails?.view_count > 0 && (
                              <div
                                className={
                                  item?.SharedDetails?.Feed_Documents[0]
                                    ?.document_type === "video"
                                    ? "image-video-view-count"
                                    : "image-view-count"
                                }
                              >
                                <img
                                  width={"20px"}
                                  className="me-1"
                                  src={eye}
                                  alt="view_icon"
                                />
                                {item?.SharedDetails?.view_count}
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    )}

                    {item?.SharedDetails?.Poll ? (
                      <ViewPollComp
                        pollData={item?.SharedDetails?.Poll}
                        postId={item?.SharedDetails?.id}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {item?.Celebration
              ? createLink(item?.Celebration?.celebration_tag)
              : ""}
          </span>
          {detectedLanguage !== user?.data?.content_language && (
            <div className="translate">
              <span
                onClick={() =>
                  handleTranslate(
                    item?.description,
                    item?.id,
                    setIsTranslated,
                    setTraslatedText,
                    user?.data?.content_language
                  )
                }
              >
                translate
              </span>
            </div>
          )}
          {isTranslated === item?.id && (
            <span className="descriptionOverFlowSpan despan">
              {translatedText || (
                <Spinner
                  style={{
                    margin: "0.5rem",
                    height: "1.5rem",
                    width: "1.5rem",
                    border: "var(--bs-spinner-border-width) solid  #0432a3",
                    borderRightColor: "transparent",
                  }}
                >
                  Loading...
                </Spinner>
              )}
            </span>
          )}
        </div>

        {item?.Celebration ? (
          <ShowCelebration
            celebration_tag={item.celebration_tag}
            celebrationData={item?.Celebration}
            description={item?.description}
            home="true"
            image_text={item?.Celebration?.Celebrate_Type?.image_text}
          />
        ) : (
          ""
        )}

        {item?.Poll ? (
          <ViewPollComp pollData={item?.Poll} postId={item?.id} />
        ) : (
          ""
        )}
        {item?.Feed_Documents && (
          <div className="post-img row">
            {
              <div className="main-view">
                <ShowDasboardImage
                  item={item}
                  index={index}
                  userName={userName}
                  privacy_type={privacy_type}
                  commentBoxStatus={commentBoxStatus}
                  setCommentBoxStatus={setCommentBoxStatus}
                  dropRefShare={dropRefShare}
                  dropDownShare={dropDownShare}
                  setDropDownShare={setDropDownShare}
                  podType={type}
                  repostPost={repostPost}
                  getHashtagText={GetHashtagText}
                  handleReaction={handleReaction}
                  likeResult={likeResult}
                  sendRequest={sendRequest}
                  peopleFollowRequest={peopleFollowRequest}
                  peopleUnFollowRequest={peopleUnFollowRequest}
                  handlePageFollow={handlePageFollow}
                  handlePageUnFollow={handlePageUnFollow}
                  GetTime={GetTime}
                  postViewCounter={postViewCounter}
                  dropDownRepost={dropDownRepost}
                  setDropDownRepost={setDropDownRepost}
                  dropRefRepost={dropRefRepost}
                />
                {item.view_count > 0 && (
                  <div
                    className={
                      item?.Feed_Documents[0]?.document_type === "video"
                        ? "image-video-view-count"
                        : "image-view-count"
                    }
                  >
                    <img
                      width={"20px"}
                      className="me-1"
                      src={eye}
                      alt="view_icon"
                    />
                    {item.view_count}
                  </div>
                )}
              </div>
            }
          </div>
        )}
        {console.log(item)}
        {item?.type == "campaign" && (
          <>
            <div className="">
              <div className="p-2 post-campaign-details">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <h5 className="m-0 card-control-text-oneline">
                      {item?.campaignDetails?.name?.length > 100
                        ? item?.campaignDetails?.name?.slice(0, 100) + "..."
                        : item?.campaignDetails?.name}
                    </h5>
                    <p
                      className="text-secondary card-control-text-oneline"
                      style={{ fontSize: "10px" }}
                    >
                      {item?.campaignDetails?.action_link?.length > 100
                        ? item?.campaignDetails?.action_link?.slice(0, 100) +
                          "..."
                        : item?.campaignDetails?.action_link}
                    </p>
                  </div>
                  {item?.campaignDetails?.action_link && (
                    <button
                      className="btn p-2"
                      style={{ border: "1px solid #0432A3", color: "#0432A3" }}
                      onClick={() =>
                        dispatch(
                          postViewLog({
                            view_type: "campaign",
                            campaign_id: item?.campaignDetails?.id,
                          })
                        )
                      }
                    >
                      <a
                        style={{ color: "#0432A3" }}
                        href={item?.campaignDetails?.action_link_label}
                      >
                        {item?.campaignDetails?.action_link_label || "Label"}
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {type === "scheduledPost" || type === "draftPost" ? (
          <></>
        ) : (
          <div className="flex mt-3 align-items-center post-info-row">
            <div className="d-flex align-items-center post-info-items">
              {/* <DropdownApp /> */}
              <div
                className={
                  "curserPointer d-flex align-items-center" +
                  (type === "myPost" ||
                  type === "savedPost" ||
                  type === "scheduledPost" ||
                  type === "draftPost" ||
                  isPagePosts
                    ? " mr-3"
                    : " mr-10")
                }
                onClick={() => {
                  setRoleShareModal(!roleShareModal);
                  setPostID(item?.id);
                  setRolePageId(
                    item?.as_a_page
                      ? item?.as_a_page?.id
                      : isPagePosts && selectedUserId
                      ? item?.Page?.id
                      : user?.data?.id
                  );
                }}
              >
                {item?.as_a_page ? (
                  <img
                    src={
                      item?.as_a_page?.icon
                        ? item?.as_a_page?.icon
                        : placeholderUser
                    }
                    className="user-circular-img"
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      isPagePosts && selectedUserId
                        ? item?.Page?.icon
                          ? item?.Page?.icon
                          : pagePlaceholder
                        : user?.data?.profile_img
                        ? user?.data?.profile_img
                        : placeholderUser
                    }
                    className="user-circular-img"
                    alt=""
                  />
                )}
                {<div className={"caret" + (isPagePosts ? " ml-5" : "")}></div>}
              </div>
              <div className="like_main_comment_post">
                <div className="likes_comment_post">
                  <img
                    src={like}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Like");
                    }}
                  />

                  <img
                    src={heart}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Love");
                    }}
                  />
                  <img
                    src={hat}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Respect");
                    }}
                  />

                  <img
                    src={hand}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Celebrate");
                    }}
                  />

                  <img
                    src={bulb}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Insightful");
                    }}
                  />

                  <img
                    src={emoj}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Curious");
                    }}
                  />

                  <img
                    src={surp}
                    alt=""
                    onClick={() => {
                      handleReaction(item, "Surprise");
                    }}
                  />
                </div>
                <div
                  className={
                    "like" +
                    (type === "myPost" ||
                    type === "savedPost" ||
                    type === "scheduledPost" ||
                    type === "draftPost" ||
                    type === "groupPost" ||
                    isPagePosts
                      ? " mr-3"
                      : " mr-10")
                  }
                >
                  {item?.as_a_page ? (
                    item.reaction.byUsers?.find(
                      ({ page_id }) => page_id === item?.as_a_page?.id
                    ) ? (
                      item?.reaction?.byUsers.map((reactionDel) =>
                        // console.log(item?.as_a_page?.id, reactionDel?.page_id)
                        item?.as_a_page?.id === reactionDel?.page_id ? (
                          reactionDel?.type === "Like" ? (
                            <span
                              onClick={() =>
                                handleReaction(item, "Like", "deleteReaction")
                              }
                              style={{ color: "#0432A3" }}
                            >
                              <img
                                src={like}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Like
                            </span>
                          ) : reactionDel?.type === "Celebrate" ? (
                            <span
                              onClick={() =>
                                handleReaction(
                                  item,
                                  "Celebrate",
                                  "deleteReaction"
                                )
                              }
                              style={{ color: "#198754" }}
                            >
                              <img
                                src={hand}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Celebrate
                            </span>
                          ) : reactionDel?.type === "Respect" ? (
                            <span
                              onClick={() =>
                                handleReaction(
                                  item,
                                  "Respect",
                                  "deleteReaction"
                                )
                              }
                              style={{ color: "#ffc107" }}
                            >
                              <img
                                src={hat}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Respect
                            </span>
                          ) : reactionDel?.type === "Curious" ? (
                            <span
                              onClick={() =>
                                handleReaction(
                                  item,
                                  "Curious",
                                  "deleteReaction"
                                )
                              }
                              style={{ color: "#ffc107" }}
                            >
                              <img
                                src={emoj}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Curious
                            </span>
                          ) : reactionDel?.type === "Love" ? (
                            <span
                              onClick={() =>
                                handleReaction(item, "Love", "deleteReaction")
                              }
                              style={{ color: "#dc3545" }}
                            >
                              <img
                                src={heart}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Love
                            </span>
                          ) : reactionDel?.type === "Insightful" ? (
                            <span
                              onClick={() =>
                                handleReaction(
                                  item,
                                  "Insightful",
                                  "deleteReaction"
                                )
                              }
                              style={{ color: "#3F2DAF" }}
                            >
                              <img
                                src={bulb}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Insightful
                            </span>
                          ) : reactionDel?.type === "Surprise" ? (
                            <span
                              onClick={() =>
                                handleReaction(
                                  item,
                                  "Surprise",
                                  "deleteReaction"
                                )
                              }
                              style={{ color: "#ffc107" }}
                            >
                              <img
                                src={surp}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              Surprise
                            </span>
                          ) : item?.reaction?.byUsers === null ? (
                            <span>
                              <img
                                src={likeGrey}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />{" "}
                              <span>Like</span>
                            </span>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )
                      )
                    ) : (
                      <span>
                        <img src={likeGrey} alt="" /> <span>Like</span>
                      </span>
                    )
                  ) : item?.reaction?.byUser?.type === "Like" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Like", "deleteReaction")
                      }
                      style={{ color: "#0432A3" }}
                    >
                      <img
                        src={like}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Like
                    </span>
                  ) : item?.reaction?.byUser?.type === "Celebrate" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Celebrate", "deleteReaction")
                      }
                      style={{ color: "#198754" }}
                    >
                      <img
                        src={hand}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Celebrate
                    </span>
                  ) : item?.reaction?.byUser?.type === "Respect" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Respect", "deleteReaction")
                      }
                      style={{ color: "#DF6B00" }}
                    >
                      <img
                        src={hat}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Respect
                    </span>
                  ) : item?.reaction?.byUser?.type === "Curious" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Curious", "deleteReaction")
                      }
                      style={{ color: "#ffc107" }}
                    >
                      <img
                        src={emoj}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Curious
                    </span>
                  ) : item?.reaction?.byUser?.type === "Love" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Love", "deleteReaction")
                      }
                      style={{ color: "#dc3545" }}
                    >
                      <img
                        src={heart}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Love
                    </span>
                  ) : item?.reaction?.byUser?.type === "Insightful" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Insightful", "deleteReaction")
                      }
                      style={{ color: "#3F2DAF" }}
                    >
                      <img
                        src={bulb}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Insightful
                    </span>
                  ) : item?.reaction?.byUser?.type === "Surprise" ? (
                    <span
                      onClick={() =>
                        handleReaction(item, "Surprise", "deleteReaction")
                      }
                      style={{ color: "#ffc107" }}
                    >
                      <img
                        src={surp}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />{" "}
                      Surprise
                    </span>
                  ) : item?.reaction?.byUser === null ? (
                    <span>
                      <img
                        src={likeGrey}
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />{" "}
                      <span
                      // style={{ fontSize: "14px" }}
                      >
                        Like
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="others viewReactionsType viewReactionsTypeTop"
                onClick={() => {
                  setViewReaction(!isViewReaction);
                  setPostID(item?.id);
                }}
              >
                <div className="curserPointer">
                  {item?.reaction?.count > 0 &&
                    // (
                    //   item?.reaction?.byUser?.type === "Like" ? (
                    //     <img src={like} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Celebrate" ? (
                    //     <img src={hand} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Respect" ? (
                    //     <img src={hat} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Love" ? (
                    //     <img src={heart} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Curious" ? (
                    //     <img src={emoj} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Insightful" ? (
                    //     <img src={bulb} alt="" />
                    //   ) : item?.reaction?.byUser?.type === "Surprise" ? (
                    //     <img src={surp} alt="" />
                    //   ) : (
                    //     ""
                    //   )
                    // ) :

                    likeResult(
                      item?.reaction?.byUser?.type,
                      item?.reaction?.byUsers,
                      item?.reaction?.type
                    )
                      // item?.reaction?.type
                      ?.map((item, idx) => {
                        console.log("array3", item);
                        return idx < 3 ? (
                          <>
                            {item?.type === "Like" ? (
                              <img src={like} alt="" />
                            ) : item?.type === "Celebrate" ? (
                              <img src={hand} alt="" />
                            ) : item?.type === "Respect" ? (
                              <img src={hat} alt="" />
                            ) : item?.type === "Love" ? (
                              <img src={heart} alt="" />
                            ) : item?.type === "Curious" ? (
                              <img src={emoj} alt="" />
                            ) : item?.type === "Insightful" ? (
                              <img src={bulb} alt="" />
                            ) : item?.type === "Surprise" ? (
                              <img src={surp} alt="" />
                            ) : (
                              ""
                            )}{" "}
                          </>
                        ) : (
                          ""
                        );
                      })}

                  <span className="txt">
                    {item?.reaction?.count > 0 ? (
                      `(${abbreviateNumber(item?.reaction.count)})`
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ gap: "6px" }}>
              {item?.comment_status ? (
                <div
                  className="comment curserPointer"
                  onClick={() => {
                    setCommentBoxStatus((prevState) => {
                      const ind = prevState?.findIndex((i) => i?.id == index);
                      if (ind == -1)
                        return [...prevState, { id: index, status: true }];
                      else
                        return prevState?.map((item) =>
                          item?.id == index
                            ? { ...item, status: !item?.status }
                            : item
                        );
                    });
                  }}
                >
                  <img src={comment} alt="" />
                  <span>
                    {item?.comment_count > 1 ? "Comments " : " Comment "}
                    {item?.comment_count > 0 &&
                      `(${abbreviateNumber(item?.comment_count)})`}
                  </span>
                </div>
              ) : (
                <></>
              )}

              {item?.type !== "campaign" && (
                <div
                  className="shared"
                  ref={dropRefRepost}
                  onClick={() => {
                    if (dropDownRepost === index) {
                      setDropDownRepost(-1);
                    } else {
                      setDropDownRepost(index);
                    }
                  }}
                >
                  <img src={repost} alt="" />
                  <span style={{ marginLeft: "4px" }}>Repost </span>

                  <span className="txt">
                    {item?.shared_count > 0 ? (
                      `(${abbreviateNumber(item?.shared_count)})`
                    ) : (
                      <></>
                    )}
                  </span>
                  <div className="drop">
                    {dropDownRepost === index && (
                      <div className="box box-repost">
                        <ul ref={dropRefRepost}>
                          {item?.shared_count > 0 ? (
                            <li className="shareListing" disabled>
                              <img
                                src={repost}
                                alt=""
                                style={{ height: "34px", width: "20px" }}
                              />
                              Repost
                            </li>
                          ) : (
                            <li
                              className="shareListing"
                              onClick={() =>
                                repostPost(
                                  item?.id,
                                  item?.as_a_page ? "asPage" : "",
                                  item?.as_a_page ? item?.as_a_page?.id : ""
                                )
                              }
                            >
                              <img
                                src={repost}
                                alt=""
                                style={{ height: "34px", width: "20px" }}
                              />
                              Repost
                            </li>
                          )}
                          <li
                            className="shareListing"
                            onClick={() => {
                              setOpenShareModal(!openShareModal);
                              setShareData(item);
                            }}
                            style={{ gap: "14px" }}
                          >
                            <img
                              src={edit}
                              alt=""
                              style={{
                                height: "28px",
                                width: "15px",
                                marginLeft: "2px",
                              }}
                            />{" "}
                            Repost with own thoughts
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div
                className="shared"
                ref={dropRefShare}
                onClick={() => {
                  if (dropDownShare === index) {
                    setDropDownShare(-1);
                  } else {
                    setDropDownShare(index);
                  }
                }}
              >
                <img src={share} alt="" />
                <span>{item?.shared_count > 1 ? " Shares " : " Share "}</span>
                <div className="drop">
                  {dropDownShare === index && (
                    <div
                      className="box box-share"
                      // style={{ bottom: "0 !important" }}
                    >
                      <ul ref={dropRefShare}>
                        <li
                          className="shareListing"
                          onClick={() => {
                            navigate("/message");
                          }}
                        >
                          <img src={sendIcon} alt="" /> Send this post
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {type === "scheduledPost" || type === "draftPost" ? (
        <></>
      ) : commentBoxStatus?.find((x) => x.id === index)?.status === true ? (
        <CommentsContainer
          postId={item}
          type={type}
          dropDown={dropDown}
          setDropDown={setDropDown}
          item={item}
          userId={userId}
          setPostID={setPostID}
          reportModal={reportModal}
          setReportModal={setReportModal}
          reportType={reportType}
          setReportType={setReportType}
          reportCommentId={reportCommentId}
          setReportCommentId={setReportCommentId}
          isPagePosts={isPagePosts}
          selectedUserId={selectedUserId}
        />
      ) : (
        ""
      )}

      {deleteModal && (
        <UnfollowModal
          modal={UnfollowModal}
          toggle={() => setDeleteModal(!deleteModal)}
          title="Unfollow Confirmation"
          deleteConfirm={() =>
            unfollowDetail &&
            handlePageUnFollow(
              unfollowDetail?.pageId,
              unfollowDetail?.pageName,
              unfollowDetail?.id
            )
          }
          text="Are you sure you want to unfollow this?"
        />
      )}
      {removeModal && (
        <RemoveModal
          modal={RemoveModal}
          title="Remove Confirmation"
          deleteConfirm={() =>
            peopleRemoveConnection(
              deleteRemoveModal?.pageId,
              deleteRemoveModal?.pageName,
              deleteRemoveModal?.id
            )
          }
          toggle={() => setRemoveModal(!removeModal)}
          text="Are you sure you want to remove this?"
        />
      )}
    </div>
  );
};

export default PostOnDashboard;
