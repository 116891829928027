import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FileLargerView from "./FileLargerView";
import ImageCarousel from "./ImageCarousel";
import icon from "../../../assets/img/play-icon.svg";
import whiteEye from "../../../assets/img/WhiteEye.svg";
import DocView from "../../../core/components/DocView/DocView";

const ShowDasboardImage = (props) => {
  const { Feed_Documents } = props.item;
  const {
    item,
    index,
    userName,
    privacy_type,
    commentBoxStatus,
    setCommentBoxStatus,
    dropRefShare,
    dropDownShare,
    setDropDownShare,
    podType,
    repostPost,
    getHashtagText,
    handleReaction,
    likeResult,
    sendRequest,
    peopleFollowRequest,
    peopleUnFollowRequest,
    handlePageFollow,
    handlePageUnFollow,
    getTime,
    postViewCounter,
    postNow,
    commentStatus,
    setCommentStatus,
    handleCommentStatus,
    closeModal,
    setCloseModal,
    setDeleteId,
    openShareModal,
    setOpenShareModal,
    setShareData,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    source,
  } = props;
  const { isWebView } = useSelector((state) => state.auth);
  const { settingData } = useSelector((state) => state.setting);
  const [mainImage, setMainImage] = useState("");
  const [largerView, setLargerView] = useState(false);
  const [type, setType] = useState("");
  const [imgWidth, setImgWidth] = useState("278px");
  const videoRef = useRef(null);
  let params = new URL(document.location).searchParams;
  let mode = params.get("mode");

  const handleImageClick = (type) => {
    if (isWebView) {
      postViewCounter(item?.id);
      navigatePostPageView(item);
    } else {
      postViewCounter(item?.id);
      setLargerView(!largerView);
      setType(type);
    }
  };

  const handlePlayIcon = (id) => {
    const element = document.getElementById(`post-videos${id}`);
    if (element) {
      element?.play();
      element.controls = true;
    }
    const elementIcon = document.getElementById(`playBox${id}`);
    if (elementIcon) {
      elementIcon.style.display = "none";
    }
  };

  useEffect(() => {
    let image1 = Feed_Documents.length === 0 ? "" : Feed_Documents[0];
    setMainImage(image1);
  }, [Feed_Documents]);

  useEffect(() => {
    if (
      mainImage?.document_type === "video" &&
      mainImage.document_url &&
      videoRef.current
    ) {
      const autoPlay = settingData?.data?.find((s) => s.id == 8)?.settings
        ?.length
        ? settingData?.data?.find((s) => s.id == 8)?.settings[0]
        : {};
      if (autoPlay?.value == "1") {
        videoRef.current.muted = true;
        videoRef.current
          .play()
          .then((res) => {
            // console.log("Video is loaded", res);
            // videoRef.current.muted = false;
          })
          .catch((error) => {
            console.log("Autoplay prevented by the browser", error);
          });
      }
    }
  }, [videoRef?.current, mainImage]);

  const showImageData = (data, id, imgWidth, showNumber) => {
    return (
      <>
        <div
          key={id}
          style={{
            width: imgWidth,
            height: "300px",
            position: "relative",
            paddingLeft: "3px",
            paddingBottom: "3px",
          }}
          className="box-img curserPointer basic-div"
        >
          {data?.document_type === "image" && (
            <img
              id={id}
              src={data.document_url}
              alt="thumbnail"
              width="100%"
              height="100%"
              className="main-img"
              onClick={
                source === "publicDirectory"
                  ? () => null
                  : () => handleImageClick("image")
              }
            />
          )}
          {data?.document_type === "video" && (
            <>
              <video
                ref={videoRef}
                id={`post-videos${item?.id}`}
                controls={isWebView ? false : true}
                controlsList="nodownload"
                width="100%"
                height="100%"
                className="main-img"
                onClick={(e) => {
                  e.preventDefault();
                  if (source === "publicDirectory") {
                  } else handleImageClick("video");
                }}
              >
                <source src={`${data.document_url}#t=0.01`} />
              </video>
              {isWebView && (
                <div
                  id={`playBox${item?.id}`}
                  className="play-box"
                  onClick={() => handlePlayIcon(item?.id)}
                >
                  <img className="play-icon" src={icon} alt="" />
                </div>
              )}
            </>
          )}
          {data?.document_type === "attachment" && (
            <div className="fs-6">
              <DocView docURL={data?.document_url} />
            </div>
          )}
          {Feed_Documents.length > 5 && showNumber ? (
            <div className="center" onClick={() => handleImageClick("image")}>
              +{Feed_Documents.length - 5}
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const navigatePostPageView = (item) => {
    if (isWebView) {
      console.log(
        `webviewHandler-postWebview-${JSON.stringify({ id: item?.id })}`
      );
    }
  };

  const showTwoImage = () => {
    return (
      <div className="d-flex">
        {Feed_Documents.map((item, index) =>
          showImageData(item, `image${index}`, "50%")
        )}
      </div>
    );
  };

  const showThreeImage = () => {
    return (
      <div>
        {/* {showImageData(Feed_Documents[0], `image0`, "100%")} */}
        {/* <div className="d-flex">
          {Feed_Documents.map(
            (item, index) =>
              index != 0 && showImageData(item, `image${index}`, "50%")
          )}
        </div> */}
        <div className="d-flex">
          {showImageData(Feed_Documents[0], `image${0}`, "50%")}
          {showImageData(Feed_Documents[1], `image${1}`, "50%")}
        </div>
        <div className="d-flex">
          {showImageData(Feed_Documents[2], `image${2}`, "100%")}
          {/* {showImageData(Feed_Documents[3], `image${3}`, "50%")} */}
        </div>
      </div>
    );
  };
  const showFourImage = () => {
    return (
      <>
        <div className="d-flex">
          {showImageData(Feed_Documents[0], `image${0}`, "50%")}
          {showImageData(Feed_Documents[1], `image${1}`, "50%")}
        </div>
        <div className="d-flex">
          {showImageData(Feed_Documents[2], `image${2}`, "50%")}
          {showImageData(Feed_Documents[3], `image${3}`, "50%")}
        </div>
      </>
    );
  };
  const showFiveImage = () => {
    const filtertedImages = Feed_Documents.filter((item, index) => {
      return index != 0 && index != 1;
    });

    return (
      <>
        <div className="d-flex">
          {showImageData(Feed_Documents[0], `image${0}`, "50%")}
          {showImageData(Feed_Documents[1], `image${1}`, "50%")}
        </div>
        <ImageCarousel
          images={filtertedImages}
          changeImage={handleImageClick}
          fromDashboard={true}
        />
        {/* {showImageData(Feed_Documents[2], `image${2}`, "33%")}
          {showImageData(Feed_Documents[3], `image${3}`, "33%")}
          {showImageData(Feed_Documents[4], `image${4}`, "33%", true)} */}
      </>
    );
  };

  return (
    <div className="row showcarousalimg position-relative">
      <div
      // className={
      //   "ps-1" +
      //   (Feed_Documents.length === 1
      //     ? " px-0"
      //     : " imagestwo col-lg-6")
      // }
      >
        {Feed_Documents.length === 1 &&
          showImageData(mainImage, "Image0", "100%")}
        {Feed_Documents.length === 2 && showTwoImage()}
        {Feed_Documents.length === 3 && showThreeImage()}
        {Feed_Documents.length === 4 && showFourImage()}
        {Feed_Documents.length >= 5 && showFiveImage()}

        {/* {Feed_Documents.length === 2 ?
        // { showImageData(mainImage, "Image0","278px")
        //   showImageData(Feed_Documents[1], "Image1")}
        // }
        //    {Feed_Documents.length === 3 &&
        //   showImageData(Feed_Documents[2], "Image2")} */}
      </div>
      {/* {Feed_Documents.length >= 3 && (
        <ImageCarousel
          images={Feed_Documents}
          changeImage={changeImage}
          fromDashboard={true}
        />
      )} */}
      {item.view_count > 0 && (
        <div className="ViewCount">
          <img width={"15px"} alt="" className="me-1" src={whiteEye} />
          {item.view_count ? item.view_count : 188}
        </div>
      )}
      {largerView && (
        <FileLargerView
          type={type}
          deatils={item}
          index={index}
          modal={largerView}
          toggle={() => setLargerView(!largerView)}
          userName={userName}
          privacy_type={privacy_type}
          commentBoxStatus={commentBoxStatus}
          setCommentBoxStatus={setCommentBoxStatus}
          dropRefShare={dropRefShare}
          dropDownShare={dropDownShare}
          podType={podType}
          setDropDownShare={setDropDownShare}
          repostPost={repostPost}
          getHashtagText={getHashtagText}
          handleReaction={handleReaction}
          likeResult={likeResult}
          sendRequest={sendRequest}
          peopleFollowRequest={peopleFollowRequest}
          peopleUnFollowRequest={peopleUnFollowRequest}
          handlePageFollow={handlePageFollow}
          handlePageUnFollow={handlePageUnFollow}
          getTime={getTime}
          postNow={postNow}
          commentStatus={commentStatus}
          setCommentStatus={setCommentStatus}
          handleCommentStatus={handleCommentStatus}
          closeModal={closeModal}
          setCloseModal={setCloseModal}
          setDeleteId={setDeleteId}
          postViewCounter={postViewCounter}
          dropDownRepost={dropDownRepost}
          setDropDownRepost={setDropDownRepost}
          dropRefRepost={dropRefRepost}
        />
      )}
    </div>
  );
};

export default ShowDasboardImage;
