import React, { useEffect, useState, useRef } from "react";
import SidebarFooter from "../../../setting/SidebarFooter";
import Refer from "../../../../core/components/layout/Sidebar/Refer";
import Sidebar from "../../../../core/components/layout/Sidebar/Sidebar";
import "../profile.scss";
import icon from "../../../../assets/img/play-icon.svg";
import { allActivityAction } from "../../../../store/activity/activityActions";
import { useDispatch, useSelector } from "react-redux";
import PostOnDashboard from "../../../dashboard/components/postComponent/PostOnDashboard";
import { getActivityMedia } from "../../../../store/activity/activityActions";
import { LinkSidebar } from "../../../../core/components/layout/Sidebar/LinkSidebar";
import { VideoSidebar } from "../../../../core/components/layout/Sidebar/VideoSidebar";
import { useNavigate, useParams } from "react-router-dom";
import VideoViewerModal from "../../../../core/components/VideoViewer/videoViewer";
import { reactionsData } from "../../../../store/reactionPost/reactionAction";
import { ToastContainer, toast } from "react-toastify";
import PeopleYouMayKnow from "../../../../core/components/layout/Sidebar/PeopleYouMayKnow";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import ReportModal from "../../../dashboard/components/reportTypeModal";
import RoleChangeModal from "../../../dashboard/components/RoleChangeModal";
import { sendConnectionRequest } from "../../../../store/connections/connectionsActions";
import { getSuggestedPeopleList } from "../../../../store/people/peopleActions";
import CreatePostModal from "../../../dashboard/components/createPost/CreatePostModal";
import like from "../../../../assets/img/like.png";
import comment from "../../../../assets/img/message.png";
import play from "../../../../assets/img/play.png";

import moment from "moment";
import {
  deletePost,
  getPostDetails,
  updatePost,
  savePost,
  savePostDelete,
  repost_post,
  hide_user_post,
  getPageDetails,
} from "../../../../store/dashboard/dashboardActions";
import ViewReactionModal from "../../../dashboard/components/viewReactionModal";

export default function Activites(props) {
  const {
    profileData,
    deleteIntroVideo,
    isAllowedToEdit,
    sendRequest,
    suggested_people,
    addIntroModal,
    setAddIntroModal,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allActivity } = useSelector((state) => state.activity);
  const { getPageDetailData } = useSelector((state) => state.dashboard);
  const { postData } = useSelector((state) => state.dashboard);
  const { allPosts } = useSelector((state) => state.activity);
  const { isWebView, user } = useSelector((state) => state.auth);
  const { allArticles } = useSelector((state) => state.activity);
  const { allMedia } = useSelector((state) => state.activity);
  const [currentCount, setCurrentCount] = useState(0);
  const [videoModal, setVideoModal] = useState(false);
  const [URLData, setURLData] = useState("");
  const [dropDown, setDropDown] = useState(-1);
  const [isReaction, setIsReaction] = useState(-1);
  const [isViewReaction, setViewReaction] = useState(false);
  const [postId, setPostID] = useState(0);
  const [dropDownShare, setDropDownShare] = useState(-1);
  const [commentBoxStatus, setCommentBoxStatus] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [dropDownRepost, setDropDownRepost] = useState(-1);
  const [deleteId, setDeleteId] = useState("");
  const [shareData, setShareData] = useState("");
  const [reportModal, setReportModal] = useState(false);
  const [reportType, setReportType] = useState();
  const [reportCommentId, setReportCommentId] = useState();
  const [roleShareModal, setRoleShareModal] = useState(false);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [isDataAvailable, setDataAvailable] = useState(false);
  const [discardModalStatus, setDiscardModalStatus] = useState(false);
  const [createPostClick, setCreatePostClick] = useState("");
  const [draftDetails, setDraftDetails] = useState();
  const [updateData, setUpdateData] = useState();
  const [isEdit, setIsEdit] = useState(0);

  const [tabs, setTabs] = useState("Activity");
  const params = useParams();

  // const toggle = () => setModal(!modal);
  const toggleOff = () => setCreatePostModal(false);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    if (tabs === "Activity") {
      dispatch(
        allActivityAction(token, {
          user_id: JSON.parse(params.id),
          skip: currentCount,
          limit: 10,
        })
      );
    } else if (tabs === "Posts") {
      dispatch(
        allActivityAction(
          token,
          {
            type: "post",
            user_id: JSON.parse(params.id),
            skip: currentCount,
            limit: 10,
          },
          "posts"
        )
      );
    } else if (tabs === "Articles") {
      dispatch(
        allActivityAction(
          token,
          {
            type: "article",
            user_id: JSON.parse(params.id),
            skip: currentCount,
            limit: 10,
          },
          "articles"
        )
      );
    } else if (tabs === "Media") {
      dispatch(getActivityMedia(token, { user_id: JSON.parse(params.id) }));
    }
    dispatch(getPageDetails(token));
  }, [tabs, params.id, currentCount]);

  useEffect(() => {
    setCurrentCount(0);
    setCommentBoxStatus([]);
  }, [tabs]);

  const handleNext = () => {
    setCurrentCount(currentCount + 10);
  };

  const dropRef = useRef();
  const dropRefShare = useRef(null);
  const dropRefRepost = useRef(null);

  const closeAllModal = () => {
    setDiscardModalStatus(!discardModalStatus);
    // toggle();
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);
    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [dropDown, dropDownShare, dropDownRepost]);

  const clickOutsideAdmin = (e) => {
    if (e.toElement && e?.toElement.className === "shareListing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "listing") {
      return;
    }
    if (e.toElement && e?.toElement.className === "clickDots") {
      return;
    }
    if (dropRef?.current?.contains(e.target) && dropDown > -1) {
      return;
    }
    if (dropRefRepost?.current?.contains(e.target) && dropDownRepost > -1) {
      return;
    }
    setDropDown(-1);
    setDropDownShare(-1);
    setDropDownRepost(-1);
  };
  // function reactionName(id) {
  //   dispatch(GetAllUsers({ id: id })).then((res) => {
  //     return `${res.data[0].first_name} ${res.data[0].last_name} and`;
  //   });
  // }

  function privacy_type(privacy_id) {
    if (privacy_id === 1) return " Public";
    if (privacy_id === 2) return " Connection";
    return " Group";
  }

  const delete_post = (_id) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(deletePost(_id, token)).then((response) => {
      toast.success("Deleted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setDropDown(-1);
    });
  };
  const removeSavePost = (id, i) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePostDelete(id, token)).then((response) => {
      toast.success("Post Removed", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  // const saveDraft = () => {
  //   let token = window.localStorage.getItem("accessToken");
  //   draftDetails.feed_status = "draft";
  //   dispatch(createPost(draftDetails, token));
  //   setCreatePostModal(false);
  //   closeAllModal();
  // };

  const editPost = (postData) => {
    if (postData.Celebration) {
      console.log("Cannot edit celebration post and feed docs");
    } else {
      setIsEdit(postData?.id);
      setCreatePostModal(!createPostModal);
      setCreatePostClick("");
      setUpdateData(postData);
      setDropDown(-1);
    }
  };

  // function handleReaction(user, reacted) {
  //   let data = {
  //     post_id: user.id,
  //     type: reacted,
  //   };
  //   dispatch(reactionsData(data));
  //   setIsReaction(-1);
  // }

  function handleReaction(item, reacted, type) {
    let data = {
      post_id: item.id,
      type: reacted,
      // id: byUserId !== 0 ? byUserId : item?.reaction?.byUser?.id,
    };
    if (item?.as_a_page) {
      Object.assign(data, { page_id: item?.as_a_page?.id });
    }
    dispatch(reactionsData(data, type)).then((res) => {
      // setByUserId(res.data[0].id);
    });
    setIsReaction(!isReaction);
    // dispatch({type: LIKED_DASHBOARD_POST, payload: data});
  }

  // useEffect(() => {
  //   getFeedApiCall();
  // }, []);

  // const getFeedApiCall = () => {

  // }

  // const handleNext = () => {
  //   console.log("calling/", currentCount)
  //   setCurrentCount(currentCount + 10);
  // let token = window.localStorage.getItem("accessToken");
  // dispatch(
  //   getPostDetails(token, {
  //     user_id: user?.data?.id,
  //     feed_status: pageState,
  //     skip: currentCount,
  //     limit: 10,
  //   })
  // );
  // };

  const hideUserPost = (id) => {
    dispatch(hide_user_post(id)).then((reponse) => {
      toast.success("Post Hide Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
    setDropDown(-1);
  };

  const createLink = (content) =>
    content?.map((str, index) => {
      return (
        <a
          href={`/profile/${str.user_id}`}
          className="text-blue-500"
          key={index}
        >
          {str?.user?.first_name} {str?.user?.last_name}{" "}
        </a>
      );
    });

  const postNow = (_id, type) => {
    let token = window.localStorage.getItem("accessToken");
    let data = { feed_status: "published", id: _id };
    dispatch(updatePost(data, token, type)).then((reponse) => {
      toast.success("Posted Sucessfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setDropDown(-1);
    });
  };

  const save_post = (id, i) => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(savePost(id, token));
  };

  const getHashtagText = (content) =>
    content
      ?.replace(/\n#/g, " \n#")
      .replace(/\n/g, " \n")
      ?.split(" ")
      ?.map((str, index) => {
        if (str.startsWith("#") || str.startsWith("\n#")) {
          let keyword = str.startsWith("#")
            ? str.substring(1)
            : str.substring(2);
          if (isWebView) {
            return (
              <span
                // onClick={() => webViewPostHashtag({ keyword })}
                className="text-blue-500"
                key={index}
              >
                {str}{" "}
              </span>
            );
          } else {
            return (
              <a
                href={`/hashtag/feed/?keyword=${keyword}`}
                className="text-blue-500"
                key={index}
              >
                {str}{" "}
              </a>
            );
          }
        }
        return str + " ";
      });

  const repostPost = (id, type) => {
    let repost_data = {
      type: "repost",
      shared_id: id,
      privacy_id: 1,
    };
    dispatch(repost_post(repost_data, type)).then((res) => {
      // setDropDownShare(-1);
      toast.success("Reposted successfully!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const likeResult = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
      let array3 = arr2;
      return array3;
    } else {
      let arr4 = [{ type: arr1 }];
      let array3 = arr4?.concat(
        arr2?.filter((bo) =>
          arr4?.every((ao) => ao?.type !== bo?.type && bo?.page_id !== null)
        )
      );
      return array3;
    }
  };

  const handleLikes = (item, index) => {
    if (isReaction === index) {
      setIsReaction(-1);
    } else {
      if (item?.reaction?.byUser?.type) {
        handleReaction(item, item?.reaction?.byUser?.type, "deleteReaction");
      } else {
        setIsReaction(index);
      }
    }
  };

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  return (
    <div className="social-media mb-5">
      <div className="row">
        <div className="col-lg-3">
          <Refer />
          <div className="sidebar-sticky">
            <div className="mt-20">
              <SidebarFooter />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="social">
            <div className="box-cover">
              <div className="row align-items-center p-3">
                <div className="col-lg-12">
                  <div className="d-flex gap-3 tab-flex-wrap">
                    <div
                      className={
                        tabs === "Activity"
                          ? "btn-active text-white"
                          : "round btn-light"
                      }
                      onClick={() => setTabs("Activity")}
                    >
                      All activity
                    </div>
                    <div
                      className={
                        tabs === "Posts"
                          ? "btn-active text-white"
                          : "round btn-light"
                      }
                      onClick={() => setTabs("Posts")}
                    >
                      Posts
                    </div>
                    <div
                      className={
                        tabs === "Articles"
                          ? "btn-active text-white"
                          : "round btn-light"
                      }
                      onClick={() => setTabs("Articles")}
                    >
                      Articles
                    </div>
                    <div
                      className={
                        tabs === "Media"
                          ? "btn-active text-white"
                          : "round btn-light"
                      }
                      onClick={() => setTabs("Media")}
                    >
                      Media
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {tabs === "Activity" ? (
              !postData?.isActivityHidden ? (
                postData?.data?.map((item, index) => (
                  <div>
                    <PostOnDashboard
                      user={user}
                      item={item}
                      index={index}
                      dropDown={dropDown}
                      userId={user?.data?.id}
                      editPost={editPost}
                      delete_post={delete_post}
                      dropRef={dropRef}
                      dropRefShare={dropRefShare}
                      getHashtagText={getHashtagText}
                      createLink={createLink}
                      privacy_type={privacy_type}
                      save_post={save_post}
                      removeSavePost={removeSavePost}
                      setDropDown={setDropDown}
                      handleReaction={handleReaction}
                      isViewReaction={isViewReaction}
                      setViewReaction={setViewReaction}
                      setPostID={setPostID}
                      userName={
                        user?.data?.first_name + " " + user?.data?.last_name
                      }
                      type="activity"
                      reportModal={reportModal}
                      setReportModal={setReportModal}
                      hideUserPost={hideUserPost}
                      reportType={reportType}
                      setReportType={setReportType}
                      reportCommentId={reportCommentId}
                      setReportCommentId={setReportCommentId}
                      commentBoxStatus={commentBoxStatus}
                      setCommentBoxStatus={setCommentBoxStatus}
                      dropDownShare={dropDownShare}
                      setDropDownShare={setDropDownShare}
                      repostPost={repostPost}
                      likeResult={likeResult}
                      getTime={getTime}
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                      setDeleteId={setDeleteId}
                      // postViewCounter={postViewCounter}
                      openShareModal={openShareModal}
                      setOpenShareModal={setOpenShareModal}
                      setShareData={setShareData}
                      dropDownRepost={dropDownRepost}
                      setDropDownRepost={setDropDownRepost}
                      dropRefRepost={dropRefRepost}
                      roleShareModal={roleShareModal}
                      setRoleShareModal={setRoleShareModal}
                      getPageDetailData={getPageDetailData}
                    />
                  </div>
                ))
              ) : (
                <div className="post-main-box">
                  You are not permitted to view user's activity
                </div>
              )
            ) : tabs === "Media" ? (
              <div className="viewPost">
                <div className="main-box">
                  <div className="container">
                    <div className="row pt-4">
                      {allMedia?.map(
                        (data, index) =>
                          (data?.document_type === "image" ||
                            data?.document_type === "video") && (
                            <div
                              className="col-lg-6"
                              style={{ padding: "0px" }}
                            >
                              <div
                                className="activity-media"
                                style={{ height: "250px" }}
                              >
                                {data?.document_type === "image" ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "flex-end",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      className="likes-comment"
                                      style={{
                                        position: "absolute",
                                        // left: "35%",
                                        bottom: "10%",
                                      }}
                                    >
                                      <div className="likes">
                                        <i className="message"></i>
                                        <img
                                          src={like}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {data?.reaction_count}
                                      </div>
                                      <div className="comments">
                                        <i className="comment"></i>
                                        <img
                                          src={comment}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {data?.comment_count}
                                      </div>
                                    </div>
                                    <img
                                      style={{
                                        height: "250px",
                                        width: "100%",
                                        borderStyle: "solid",
                                        borderWidth: "0.2px",
                                        borderColor: "#f5f2f2",
                                        cursor: "pointer",
                                      }}
                                      src={data?.document_url}
                                      alt=""
                                      onClick={() =>
                                        navigate(
                                          `/postdetails/${data?.post_id}`
                                        )
                                      }
                                    />
                                  </div>
                                ) : data?.document_type === "video" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      style={{
                                        position: "absolute",
                                        zIndex: 1,
                                      }}
                                      src={play}
                                      alt=""
                                      onClick={() => {
                                        setVideoModal(!videoModal);
                                        setURLData(data?.document_url);
                                      }}
                                    />
                                    <video
                                      style={{
                                        height: "250px",
                                        width: "100%",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                      }}
                                      preload="metadata"
                                      height="250px"
                                      onClick={() =>
                                        navigate(
                                          `/postdetails/${data?.post_id}`
                                        )
                                      }
                                    >
                                      <source src={data?.document_url} />
                                    </video>
                                    <div
                                      className="likes-comment"
                                      style={{
                                        position: "absolute",
                                        // left: "35%",
                                        bottom: "10%",
                                      }}
                                    >
                                      <div className="likes">
                                        <i className="message"></i>
                                        <img
                                          src={like}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {data?.reaction_count}
                                      </div>
                                      <div className="comments">
                                        <i className="comment"></i>
                                        <img
                                          src={comment}
                                          style={{ marginRight: "3px" }}
                                        />
                                        {data?.comment_count}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {/* {data?.document_type === "video" && (
                                  <div className="play">
                                    <div
                                      className="icon"
                                      onClick={() => {
                                        console.log("dfdf");
                                        setVideoModal(!videoModal);
                                        setURLData(data?.document_url);
                                      }}
                                    ></div>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          )
                      )}

                      {/* <div className="col-lg-6" style={{padding:"0px"}}>
                                    <div className="activity-media" style={{height:"250px"}}>
                                            <img style={{height:"250px", maxWidth:"100%"}}src="https://techmonitor.ai/wp-content/uploads/sites/4/2016/06/what-is-URL.jpg" alt=""/>
                                            </div>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : tabs === "Posts" ? (
              !postData?.isActivityHidden ? (
                postData?.data?.map((item, index) => (
                  <div>
                    <PostOnDashboard
                      user={user}
                      item={item}
                      index={index}
                      dropDown={dropDown}
                      userId={user?.data?.id}
                      editPost={editPost}
                      delete_post={delete_post}
                      dropRef={dropRef}
                      dropRefShare={dropRefShare}
                      getHashtagText={getHashtagText}
                      createLink={createLink}
                      privacy_type={privacy_type}
                      save_post={save_post}
                      removeSavePost={removeSavePost}
                      setDropDown={setDropDown}
                      handleReaction={handleReaction}
                      isViewReaction={isViewReaction}
                      setViewReaction={setViewReaction}
                      setPostID={setPostID}
                      userName={
                        user?.data?.first_name + " " + user?.data?.last_name
                      }
                      type="activity"
                      reportModal={reportModal}
                      setReportModal={setReportModal}
                      hideUserPost={hideUserPost}
                      reportType={reportType}
                      setReportType={setReportType}
                      reportCommentId={reportCommentId}
                      setReportCommentId={setReportCommentId}
                      commentBoxStatus={commentBoxStatus}
                      setCommentBoxStatus={setCommentBoxStatus}
                      dropDownShare={dropDownShare}
                      setDropDownShare={setDropDownShare}
                      repostPost={repostPost}
                      likeResult={likeResult}
                      getTime={getTime}
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                      setDeleteId={setDeleteId}
                      // postViewCounter={postViewCounter}
                      openShareModal={openShareModal}
                      setOpenShareModal={setOpenShareModal}
                      setShareData={setShareData}
                      dropDownRepost={dropDownRepost}
                      setDropDownRepost={setDropDownRepost}
                      dropRefRepost={dropRefRepost}
                      roleShareModal={roleShareModal}
                      setRoleShareModal={setRoleShareModal}
                      getPageDetailData={getPageDetailData}
                    />
                  </div>
                ))
              ) : (
                <div className="post-main-box">
                  You are not permitted to view user's posts
                </div>
              )
            ) : tabs === "Articles" ? (
              !postData?.isActivityHidden ? (
                postData?.data?.map((item, index) => (
                  <div>
                    <PostOnDashboard
                      user={user}
                      item={item}
                      index={index}
                      dropDown={dropDown}
                      userId={user?.data?.id}
                      editPost={editPost}
                      delete_post={delete_post}
                      dropRef={dropRef}
                      dropRefShare={dropRefShare}
                      getHashtagText={getHashtagText}
                      createLink={createLink}
                      privacy_type={privacy_type}
                      save_post={save_post}
                      removeSavePost={removeSavePost}
                      setDropDown={setDropDown}
                      handleReaction={handleReaction}
                      isViewReaction={isViewReaction}
                      setViewReaction={setViewReaction}
                      setPostID={setPostID}
                      userName={
                        user?.data?.first_name + " " + user?.data?.last_name
                      }
                      type="activity"
                      reportModal={reportModal}
                      setReportModal={setReportModal}
                      hideUserPost={hideUserPost}
                      reportType={reportType}
                      setReportType={setReportType}
                      reportCommentId={reportCommentId}
                      setReportCommentId={setReportCommentId}
                      commentBoxStatus={commentBoxStatus}
                      setCommentBoxStatus={setCommentBoxStatus}
                      dropDownShare={dropDownShare}
                      setDropDownShare={setDropDownShare}
                      repostPost={repostPost}
                      likeResult={likeResult}
                      getTime={getTime}
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                      setDeleteId={setDeleteId}
                      // postViewCounter={postViewCounter}
                      openShareModal={openShareModal}
                      setOpenShareModal={setOpenShareModal}
                      setShareData={setShareData}
                      dropDownRepost={dropDownRepost}
                      setDropDownRepost={setDropDownRepost}
                      dropRefRepost={dropRefRepost}
                      roleShareModal={roleShareModal}
                      setRoleShareModal={setRoleShareModal}
                      getPageDetailData={getPageDetailData}
                    />
                  </div>
                ))
              ) : (
                <div className="post-main-box">
                  You are not permitted to view user's articles
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-lg-3">
          <div className="mb-20">
            <VideoSidebar
              URLData={profileData?.intro_video}
              deleteIntroVideo={deleteIntroVideo}
              isAllowedToEdit={isAllowedToEdit}
              addIntroModal={addIntroModal}
              setAddIntroModal={setAddIntroModal}
            />
          </div>
          <Sidebar />
          <div className="sidebar-sticky">
            <PeopleYouMayKnow
              suggested_people={suggested_people}
              sendRequest={sendRequest}
            />
          </div>
        </div>
      </div>
      {videoModal && (
        <VideoViewerModal
          modal={videoModal}
          toggle={() => setVideoModal(!videoModal)}
          URLData={URLData}
        />
      )}
      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            delete_post(deleteId);
          }}
          text="Are you want to sure to delete this?"
        />
      )}

      {createPostModal && (
        <CreatePostModal
          modal={createPostModal}
          toggle={() => {
            if (isDataAvailable) {
              setDiscardModalStatus(true);
            } else {
              setCreatePostModal(!createPostModal);
            }
          }}
          toggleOff={() => {
            setCreatePostModal(!createPostModal);
          }}
          title="Create Post"
          userName={user?.data?.first_name + " " + user?.data?.last_name}
          userId={user?.data?.id}
          createPostClick={createPostClick}
          discardModalStatus={discardModalStatus}
          setDiscardModalStatus={setDiscardModalStatus}
          closeAllModal={closeAllModal}
          updateData={updateData}
          isEdit={isEdit}
          isDataAvailable={isDataAvailable}
          setDataAvailable={setDataAvailable}
          draftDetails={draftDetails}
          setDraftDetails={setDraftDetails}
          dashboardData={user?.data}
          setCommentBoxStatus={setCommentBoxStatus}
        />
      )}

      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
          reportType={reportType}
          reportCommentId={reportCommentId}
        />
      )}
      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}
      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
          reportType={reportType} //"post"/"comment"
          reportCommentId={reportCommentId} //null in case of post
        />
      )}
      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={postId}
          updateData={postData} //to be chnage later after edit post
          getPageDetailData={getPageDetailData}
        />
      )}
      {isViewReaction && (
        <ViewReactionModal
          modal={isViewReaction}
          toggle={() => setViewReaction(!isViewReaction)}
          title="View Reactions"
          userName={user?.data?.first_name + " " + user?.data?.last_name}
          postId={postId}
        />
      )}
    </div>
  );
}
