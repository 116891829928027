import React, { useEffect, useRef, useState } from "react";
import globalBackground from "../../../assets/img/globalBackgroundImage.svg";
import FormInput from "../../../core/components/Inputs/FormInput";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import infoIcon from "../../../assets/img/infoIcon.png";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { call } from "../../../api/apiCall";
import { Input } from "reactstrap";
import moment from "moment/moment";
import { updateAuthorization } from "../../../store/backgroundScreening/backgroundScreeningAction";
import { Link, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
const AuthorizationForm = ({ user, data }) => {
  const [countryList, setCountryList] = useState([]);
  const dispatch = useDispatch();
  const [sign, setSign] = useState(data?.authorization?.signature);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    setSign(data?.authorization?.sign);
  }, [data]);

  const handleClear = (e) => {
    e.preventDefault();
    sign?.clear();
    setFormData({
      ...formData,
      signature: "",
    });
    setSign("");
    setError({ ...error, signature: "Signature is required" });
  };
  const handleSave = (e) => {
    e.preventDefault();
    const url = sign?.getTrimmedCanvas()?.toDataURL("image/png");
    console.log(url);
    setFormData({
      ...formData,
      signature: url,
    });
    if (url) setError({ ...error, signature: "" });
    else setError({ ...error, signature: "Signature is required" });
  };
  const [error, setError] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    country: "",
    citizenship: "",
    address: "",
    signature: "",
    date: "",
    // obtain_error: "",
  });

  const [formData, setFormData] = useState({
    first_name: data?.authorization?.first_name
      ? data?.authorization?.first_name
      : user?.first_name,
    middle_name: data?.authorization?.middle_name
      ? data?.authorization?.middle_name
      : data?.basicInfo?.info?.middle_name,
    last_name: data?.authorization?.last_name
      ? data?.authorization?.last_name
      : user?.last_name,
    dob: data?.authorization?.dob ? data?.authorization?.dob : user?.dob,
    country: data?.authorization?.country
      ? data?.authorization?.country
      : user?.country?.name,
    citizenship: data?.authorization?.nationality
      ? data?.authorization?.nationality
      : user?.nationality?.name,
    address: data?.authorization?.address
      ? data?.authorization?.address
      : user?.address,
    obtain_request: data?.authorization?.obtain_request || false,
    signature: data?.authorization?.signature || "",
    date: data?.authorization?.date || new Date(),
    authorization_form_pdf: data?.authorization?.authorization_form_pdf || "",
  });

  useEffect(() => {
    setFormData({
      first_name: data?.authorization?.first_name
        ? data?.authorization?.first_name
        : data?.basicInfo?.info?.first_name,
      middle_name: data?.authorization?.middle_name
        ? data?.authorization?.middle_name
        : data?.basicInfo?.info?.middle_name,
      last_name: data?.authorization?.last_name
        ? data?.authorization?.last_name
        : data?.basicInfo?.info?.last_name,
      dob: data?.authorization?.dob
        ? data?.authorization?.dob
        : data?.basicInfo?.dob,
      country: data?.authorization?.country
        ? data?.authorization?.country
        : data?.basicInfo?.country,
      citizenship: data?.authorization?.nationality
        ? data?.authorization?.nationality
        : data?.basicInfo?.nationality,
      address: data?.authorization?.address
        ? data?.authorization?.address
        : data?.basicInfo?.address,
      obtain_request: data?.authorization?.obtain_request || false,
      signature: data?.authorization?.signature || "",
      date: data?.authorization?.date || new Date(),
      authorization_form_pdf: data?.authorization_form_pdf || "",
    });
  }, [data]);

  const checkValidation = (type) => {
    let isFormValid = true;
    let nameRegex = /^[^\d\s]+$/;
    if (type === "first_name" || type == "all") {
      if (!formData?.first_name.trim()) {
        setError({ ...error, first_name: "First name is required" });
        isFormValid = false;
        return;
      } else if (!nameRegex.test(formData?.first_name.trim())) {
        setError({ ...error, first_name: "Please enter valid name" });
        isFormValid = false;
        return;
      } else setError({ ...error, first_name: "" });
    }

    if (type === "last_name" || type == "all") {
      if (!formData?.last_name.trim()) {
        setError({ ...error, last_name: "Last name is required" });
        isFormValid = false;
        return;
      } else if (!nameRegex.test(formData?.last_name.trim())) {
        setError({ ...error, first_name: "Please enter valid name" });
        isFormValid = false;
        return;
      } else setError({ ...error, last_name: "" });
    }
    if (formData?.middle_name) {
      if (type == "middle_name" || type == "all") {
        if (!formData?.middle_name.trim()) {
          setError({
            ...error,
            middle_name: "Middle name is required",
          });
          isFormValid = false;
          return;
        } else if (!nameRegex.test(formData?.middle_name.trim())) {
          setError({
            ...error,
            middle_name: "Spaces and numeric are not allowed",
          });
          isFormValid = false;
          return;
        } else {
          setError({
            ...error,
            middle_name: "",
          });
        }
      }
    }

    if (type === "dob" || type == "all") {
      if (!formData?.dob) {
        setError({ ...error, dob: "DOB is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, dob: "" });
    }

    if (type === "country" || type == "all") {
      if (!formData?.country) {
        setError({ ...error, country: "Country is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, country: "" });
    }

    if (type === "citizenship" || type == "all") {
      if (!formData?.citizenship) {
        setError({ ...error, citizenship: "Citizenship is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, citizenship: "" });
    }

    if (type === "address" || type == "all") {
      if (!formData?.address) {
        setError({ ...error, address: "Address is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, address: "" });
    }

    if (type === "signature" || type == "all") {
      if (!formData?.signature) {
        setError({ ...error, signature: "Signature is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, signature: "" });
    }

    if (type === "date" || type == "all") {
      if (!formData?.date) {
        setError({ ...error, date: "Date is required" });
        isFormValid = false;
        return;
      } else setError({ ...error, date: "" });
    }

    // if (type === "obtain_error" || type == "all") {
    //   if (!formData?.obtain_request) {
    //     setError({
    //       ...error,
    //       obtain_request:
    //         "you have to confirm the obtain candidate authorization",
    //     });
    //     isFormValid = false;
    //     return;
    //   } else setError({ ...error, obtain_request: "" });
    // }

    return isFormValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      handlePdf();
    }
  };

  const sigCanvas = useRef(null);

  useEffect(() => {
    if (sigCanvas.current && data?.authorization?.signature) {
      sigCanvas.current.fromDataURL(data?.authorization?.signature);
    }
  }, [data?.authorization?.signature]);
  console.log(formData);

  const formRef = useRef();

  // const handlePdf = async () => {
  //   const pixelRatio = 3;
  //   const canvas = await html2canvas(formRef?.current, { scale: pixelRatio });
  //   const imgWidth = 210;
  //   const pageHeight = 395;

  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   const pdf = new jsPDF({
  //     orientation: "p",
  //     unit: "mm",
  //     format: "a4",
  //     hotfixes: ["px_scaling"],
  //   });

  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   while (heightLeft > 0) {
  //     pdf.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     position -= pageHeight;
  //     if (heightLeft > 0) {
  //       pdf.addPage();
  //     }
  //   }

  //   const blob = pdf.output("blob");
  //   const url = URL.createObjectURL(blob);
  //   console.log(url);
  //   const anchor = document.createElement("a");
  //   anchor.href = url;
  //   anchor.download = "document.pdf";
  //   anchor.click();

  //   URL.revokeObjectURL(url);
  // };
  const [loading, setLoading] = useState(false);
  const handlePdf = async () => {
    setLoading(true);

    try {
      const pixelRatio = 2; // Adjust pixel ratio to balance between quality and size
      const canvas = await html2canvas(formRef.current, { scale: pixelRatio });
      const imgWidth = 210;
      const pageHeight = 395;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("image/jpeg", 0.5); // Use JPEG and reduce quality

      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        hotfixes: ["px_scaling"],
        compress: true, // Enable compression
      });

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Generate Blob from PDF
      const pdfBlob = pdf.output("blob");

      // Convert Blob to File
      const pdfFile = new File([pdfBlob], "document.pdf", {
        type: "application/pdf",
      });

      // Create FormData and append the PDF file
      const FormDataPDF = new FormData();
      FormDataPDF.append("image", pdfFile);

      // Upload the PDF file using axios
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const headers = {
        "Accept-Language": "en",
        "Content-Type": "multipart/form-data",
      };

      const response = await axios.post(UPLOAD_URL, FormDataPDF, { headers });

      // Log response data for debugging
      console.log("Upload response:", response.data);

      // Handle success response
      if (response?.data?.success) {
        dispatch(
          updateAuthorization({
            ...formData,
            authorization_form_pdf: response.data.urlsArray[0], // Assuming this is where you store the URL
          })
        );
        setFormData({
          ...formData,
          authorization_form_pdf: response.data.urlsArray[0],
        });

        setLoading(false);
        navigate("/order-preview", {
          state: {
            data: { ...data, authorization: formData },
          },
        });
      }
    } catch (error) {
      console.error("Error during PDF generation or upload:", error);
      setLoading(false); // Ensure loading state is reset on error
    }
  };

  return (
    <div className="px-5 py-3 package_form" ref={formRef}>
      <div className="d-flex justify-content-center align-items-center">
        <img src={globalBackground} alt="" width={200} height={150} />
      </div>
      <h3 className="text-decoration-underline text-center text-dark">
        DATA PRIVACY NOTICE & AUTHORIZATION
      </h3>
      <div className="mt-3 p-3">
        <p className="mb-4">
          The undersigned acknowledges and consents to Global Background
          Screening, LLC, located at 531 Roselane St NW Suite 400-177, Marietta,
          GA30060, phone number +1-855-561-5890 ('Reporting Agency'), as
          NobelPage's selected and authorized partner, to conduct thorough
          screening services. Such services will encompass the verification of
          employment history, educational background, and checks against global
          watchlists. The results of these verifications, as conducted by the
          Reporting Agency, will be provided to NobelPage. Subsequently,
          NobelPage will issue verification badges on the user's profile,
          reflecting the accuracy and integrity of the information provided, in
          accordance with the results received from the Reporting Agency. The
          undersigned hereby grants full consent for the Reporting Agency to
          obtain, verify, and communicate the necessary information to NobelPage
          for the assignment of truth badges on their profile.
        </p>
        <p className="mb-4">
          Information you have or will supply, may be disclosed to third parties
          including agents or vendors of the Reporting Agency, courts,
          institutions, schools, or universities (public or private),
          information service bureaus, consumer reporting agencies, employers,
          employees, to verify and investigate your background.
        </p>
        <p className="mb-4">
          In accordance with the host nation's laws and the laws applicable to
          you depending on your location regarding the release of information,
          you understand that information may be transmitted from any country to
          the above listed parties located in any country, including countries
          outside the EU that have a different level of data protection or
          inadequate data protection laws as defined by the European Commission
          than your country of residence.
        </p>
        <p className="mb-4">
          For access to the personal data collected or transferred, to your
          report, or for any other inquiries or complaints you may contact
          Company or Reporting Agency. Reporting Agency’s privacy policy is
          available at
          <span
            style={{
              cursor: "pointer",
            }}
            className="mx-1"
            onClick={() => {
              let fullURL =
                "https://www.globalbackgroundscreening.com/privacy-policy";
              fullURL.startsWith("http://") || window.open(fullURL, "_blank");
            }}
          >
            https://www.globalbackgroundscreening.com/privacy-policy
          </span>
          and view our data security
          <span
            style={{
              cursor: "pointer",
            }}
            className="mx-1"
            onClick={() => {
              let fullURL =
                "https://www.globalbackgroundscreening.com/security";
              fullURL.startsWith("http://") || window.open(fullURL, "_blank");
            }}
          >
            https://www.globalbackgroundscreening.com/security
          </span>
          . Global Background Screening, LLC. affords individuals the
          opportunity to choose whether their personal information will be
          disclosed to a third party as described above. A consumer can withdraw
          consent by contacting Company, Reporting Agency inwriting or by
          emailing info@getgbs.com.If choosing to withdraw your consent for your
          background check, if still in progress, will not be completed. This
          may affect Company’s decision related to the purpose in which the
          background check was requested.
        </p>
        <div className="mt-3">
          <h5 className="text-dark">NobelPage’s Verification Badge:</h5>
          <p className="mt-4">
            The background checks carried out by Global Background Screening,
            LLC may reveal information about the undersigned's criminal history,
            including any sanctions, terrorist watchlist inclusions, as well as
            education and employment history, based on the selected verification
            criteria. These verifications are conducted with the undersigned's
            consent. NobelPage receives only the summarized outcomes for the
            purpose of awarding verification badges. The complete reports are
            sent directly to the undersigned by the Reporting Agency. However,
            these full reports can be shared with NobelPage in situations
            requiring dispute resolution, but only with the clear and explicit
            consent of the undersigned.
          </p>
        </div>
      </div>

      <h3 className="text-decoration-underline text-center text-dark mt-3">
        ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND CHECK
      </h3>
      <div className="mt-3 p-3">
        <p className="">
          I, the undersigned, hereby freely authorize the collection, processing
          and use of my personal data for the obtaining of background reports,
          “consumer reports” or “investigative consumer reports,” as defined in
          the Fair Credit Reporting Act (FCRA) for verification process. To this
          end, I hereby authorize, without reservation, all third parties who
          are asked to provide information concerning me, including, but not
          limited to state or federal agency, institution, school, or university
          (public or private), information service bureau, employer, to furnish
          any and all background information requested by{" "}
          <strong className="text-dark">GLOBAL BACKGROUND SCREENING LLC</strong>
          , 531 Roselane St NW Suite 400-177 Marietta, GA 30060, 855-561-5890,{" "}
          <u
            style={{
              cursor: "pointer",
              color: "rgba(4, 50, 163, 1)",
              fontWeight: 600,
            }}
            className="mx-1"
            onClick={() => {
              let fullURL = "https://www.globalbackgroundscreening.com";
              fullURL.startsWith("http://") || window.open(fullURL, "_blank");
            }}
          >
            WWW.GLOBALBACKGROUNDSCREENING.COM
          </u>{" "}
          and/or Company itself. In accordance with the host nation's laws and
          the laws applicable to me depending on my location regarding the
          release of information, I authorize the release and transmittal of
          information from any country to the above listed parties, their
          clients, and/or their agents or vendors located in any country,
          including countries outside the European Union that may have a
          different level of data protection or inadequate data protection laws
          as defined by the European Commission. I agree that a facsimile
          (“fax”), electronic or photographic copy of this Authorization shall
          be as valid as the original. I certify that all information provided
          below or on my Nobel Page Profile is correct to the best of my
          knowledge.
        </p>
      </div>
      <div className="mt-3">
        <form>
          <div className="row">
            <div className="col-4 authorization_input">
              <FormInput
                label={"First Name"}
                value={formData?.first_name}
                onBlur={() => checkValidation("first_name")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    first_name: e.target.value,
                  })
                }
                disabled={formData?.first_name}
                type="text"
                error={error?.first_name}
              />
            </div>
            {console.log(formData)}
            {formData?.middle_name && (
              <div className="col-4 authorization_input">
                <FormInput
                  label={"Middle Name"}
                  value={formData?.middle_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      middle_name: e.target.value,
                    })
                  }
                  disabled={formData?.middle_name}
                  onBlur={() => checkValidation("middle_name")}
                  type="text"
                  error={error?.middle_name}
                />
              </div>
            )}
            <div className="col-4 authorization_input">
              <FormInput
                label={"Last Name"}
                value={formData?.last_name}
                onBlur={() => checkValidation("last_name")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    last_name: e.target.value,
                  })
                }
                error={error?.last_name}
                type="text"
                disabled={formData?.last_name}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="name_type" className="form-label">
                DOB (MM/DD/YYYY)
              </label>
              <DatePickerCustom
                value={formData?.dob ? new Date(formData?.dob) : null}
                disabled={formData?.dob}
                onChange={(date) => {
                  date
                    ? setFormData({
                        ...formData,
                        dob: moment(date).format("YYYY-MM-DD"),
                      })
                    : setFormData({
                        ...formData,
                        dob: null,
                      });
                }}
                max={new Date()}
                placeholder=""
                onBlur={() => checkValidation("dob")}
              />
              {error?.dob && <p className="text-danger">{error?.dob}</p>}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                Country of Birth
              </label>
              <Input
                type="select"
                value={formData?.country}
                onClick={() => checkValidation("country")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  })
                }
                disabled={formData?.country}
                className="bg-transparent"
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.country && (
                <p className="text-danger">{error?.country}</p>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                Citizenship
              </label>
              <Input
                type="select"
                value={formData?.citizenship}
                onClick={() => checkValidation("citizenship")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    citizenship: e.target.value,
                  })
                }
                className="bg-transparent"
                disabled={formData?.citizenship}
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.citizenship && (
                <p className="text-danger">{error?.citizenship}</p>
              )}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end authorization_input">
              <FormInput
                required={true}
                label={"Current Full Address (Include City/Zip & Country) "}
                onBlur={() => checkValidation("address")}
                value={formData?.address}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  })
                }
                disabled={formData?.address}
                type="text"
                error={error?.address}
              />
            </div>
          </div>

          <div className="mt-3 d-flex align-items-start">
            <Input
              type="checkbox"
              checked={formData?.obtain_request}
              defaultChecked={true}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  obtain_request: !formData?.obtain_request,
                });
              }}
              className="me-2"
            />{" "}
            <span>I have obtained candidate authorization.</span>{" "}
            <span
              style={{
                cursor: "pointer",
                color: "rgba(4, 50, 163, 1)",
              }}
            >
              {" "}
              Know your rights <img src={infoIcon} width={15} />
            </span>
          </div>

          <div className="row mt-3 d-flex justify-content-between align-items-center">
            <div className="col-4">
              <label htmlFor="signature" className="form-label">
                Signature
              </label>
              <div
                className="border border-1"
                style={{ height: "100px", overflow: "hidden" }}
              >
                <SignatureCanvas
                  ref={(ref) => {
                    setSign(ref);
                    sigCanvas.current = ref;
                  }}
                />
              </div>
              {error?.signature && (
                <p className="text-danger">{error?.signature}</p>
              )}
              <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                  className="btn btn-blue p-2"
                  onClick={(e) => handleClear(e)}
                >
                  Clear
                </button>
                <button
                  className="btn btn-blue mx-2 p-2"
                  onClick={(e) => handleSave(e)}
                  disabled={!sign}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="col-3">
              <label htmlFor="signature" className="form-label">
                Date
              </label>
              <DatePickerCustom
                value={formData?.date ? new Date(formData?.date) : null}
                max={new Date()}
                placeholder=""
                onBlur={() => checkValidation("date")}
                onChange={(d) => {
                  d
                    ? setFormData({
                        ...formData,
                        date: moment(d).format("YYYY-MM-DD"),
                      })
                    : setFormData({
                        ...formData,
                        date: null,
                      });
                }}
              />
              {error?.date && <p className="text-danger">{error?.date}</p>}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <button
              disabled={loading || !formData?.obtain_request}
              className="btn btn-blue"
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? "Loading..." : "Review your request"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthorizationForm;
