import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import cale from "../../assets/img/calendar.svg";
import photo from "../../assets/img/photo.svg";
import video from "../../assets/img/video.svg";
import article from "../../assets/img/article.svg";
import news from "../../assets/img/news.svg";
import eve from "../../assets/img/events.svg";
import add from "../../assets/img/add-icon.svg";
import users from "../../assets/img/users.svg";
import Navbar from "../../pages/navbar/navbar";
import Chat from "../../pages/chat/chat";
import { Link } from "react-router-dom";
import CreatePostModal from "./components/createPost/CreatePostModal";
import ViewReactionModal from "./components/viewReactionModal";
import CreateArticleModal from "../mynetwork/Articles/components/createArticle";
import CreateEventModal from "../mynetwork/Events/components/createEvent";
import CreateNewsletterModal from "../mynetwork/Newsletter/components/CreateNewsletter";
import Advertisement from "./Advertisement";
import InfiniteScroll from "react-infinite-scroll-component";
import DiscardEventModal from "../mynetwork/Events/components/DiscardModal";
import Ad from "../../core/components/layout/Sidebar/Ad";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import defaultIcon from "../../assets/img/default_profile_sqr img.svg";
import Refer from "../../core/components/layout/Sidebar/Refer";
import SidebarFooter from "../setting/SidebarFooter";
import PostOnDashboard from "./components/postComponent/PostOnDashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportModal from "./components/reportTypeModal";
import DeleteModal from "../../core/components/modal/DeleteModal";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import ShareModal from "../../core/components/sharePost/sharePost";
import nopreview from "../../assets/img/no-preview.png";
import defaulticon from "../../assets/img/noIcon.png";
import RoleChangeModal from "./components/RoleChangeModal";
import PeopleYouMayKnow from "../../core/components/layout/Sidebar/PeopleYouMayKnow";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import { abbreviateNumber } from "../../core/components/Utility/Utility";
import noPost from "../../assets/img/no-post.png";
import ChangePageModel from "./components/ChoosePageModel";
export default function Dashboard(props) {
  const {
    postData,
    suggested_articles,
    suggested_groups,
    suggested_events,
    user,
    dashboardData,
    userName,
    createPostModal,
    setCreatePostModal,
    newsletterModal,
    setNewsletterModal,
    userId,
    articleModal,
    setArticleModal,
    eventModal,
    setEventModal,
    handleReaction,
    postId,
    setPostID,
    isViewReaction,
    setViewReaction,
    createPostClick,
    setCreatePostClick,
    handleNext,
    currentCount,
    privacy_type,
    dropDown,
    setDropDown,
    discardModalStatus,
    setDiscardModalStatus,
    closeAllModal,
    delete_post,
    editPost,
    updateData,
    setUpdateData,
    isEdit,
    setIsEdit,
    saveDraft,
    isDataAvailable,
    setDataAvailable,
    draftDetails,
    setDraftDetails,
    save_post,
    removeSavePost,
    dropRef,
    dropRefShare,
    viewConnection,
    navigate,
    reportModal,
    setReportModal,
    reportType,
    setReportType,
    reportCommentId,
    setReportCommentId,
    followedTagList,
    suggested_people,
    sendRequest,
    hideUserPost,
    commentBoxStatus,
    setCommentBoxStatus,
    dropDownShare,
    setDropDownShare,
    repostPost,
    likeResult,
    peopleFollowRequest,
    peopleUnFollowRequest,
    peopleRemoveConnection,
    handlePageFollow,
    handlePageUnFollow,
    commentStatus,
    setCommentStatus,
    handleCommentStatus,
    closeModal,
    setCloseModal,
    deleteId,
    setDeleteId,
    isPageLoading,
    postViewCounter,
    openShareModal,
    setOpenShareModal,
    shareData,
    setShareData,
    pageData,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    roleShareModal,
    setRoleShareModal,
    getPageDetailData,
    rolePageId,
    setRolePageId,
    isPostLoading,
  } = props;

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector(".sidebar");
      const sidebarRect = sidebar.getBoundingClientRect();
      // console.log(sidebarRect.top + sidebarRect.height < window.innerHeight);
      if (sidebarRect.top + sidebarRect.height < window.innerHeight) {
        setIsSticky(true);
        sidebar.style.top = `${sidebarRect.top}px`;
        // console.log(sidebar.style.top);
      } else setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {(isPageLoading || isPostLoading) && (
        <AppLoader styleProps={{ position: "fixed" }} />
      )}
      <div className="dashboard bg-grey">
        <Navbar isJobs={false} />

        <div className="container mt-4">
          <div className="row gy-3">
            <div className="col-lg-3">
              <div
                className={isSticky ? "sidebar sidebar-sticky-left" : "sidebar"}
              >
                <div className="left border-radius-5">
                  <div className="profile">
                    {user?.data?.background_img ? (
                      <img
                        src={user?.data?.background_img}
                        alt="background img"
                        className="back-image-fluid"
                        style={{ position: "absolute" }}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="img">
                      <Link to={"/profile/" + user?.data?.id}>
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : defaultIcon
                          }
                          alt="profile img"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="starter">
                    {user?.data?.membership?.name || ""}
                  </div>
                  <div className="px-3">
                    <div className="profile-details">
                      <Link to={"/profile/" + user?.data?.id}>
                        {/* { console.log("user", user)} */}
                        <h6 className="userNameTextWrap">
                          {user?.data?.first_name} {user?.data?.last_name}
                        </h6>
                      </Link>

                      <p className="card-control-text-twoline">
                        {user?.data?.profile_headline
                          ? user?.data?.profile_headline
                          : ""}
                      </p>
                    </div>
                    <div className="margin">
                      <div className="follower" onClick={viewConnection}>
                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.connections
                              ? abbreviateNumber(user?.data?.connections)
                              : 0}
                          </h6>
                          <p>
                            {user?.data?.connections > 1
                              ? "Connections"
                              : "Connection"}
                          </p>
                        </div>
                        <div className="h-border "></div>
                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.followers
                              ? abbreviateNumber(user?.data?.followers)
                              : 0}
                          </h6>
                          <p>
                            {user?.data?.followers > 1
                              ? "Followers"
                              : "Follower"}
                          </p>
                        </div>
                        <div className="h-border"></div>

                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.followings
                              ? abbreviateNumber(user?.data?.followings)
                              : 0}
                          </h6>
                          <p>
                            {/* {user?.data?.followings > 1
                              ? "Followings" */}
                            {/* : "Following"} */}
                            Following
                          </p>
                        </div>
                      </div>
                      <div className="line mb-20"></div>
                    </div>
                    <div className="update mt-3">
                      <Link to={"/profile/" + user?.data?.id}>
                        <span>View My Profile</span>
                      </Link>
                    </div>
                    {pageData?.data?.length > 0 && (
                      <div className="pages my-4">
                        <h4 className="mb-3">
                          {pageData?.data?.length > 1 ? "My Pages" : "My Page"}
                        </h4>
                        <div className="line"></div>
                        <div className="mt-3">
                          {pageData?.data?.map((item, index) => {
                            if (index < 3) {
                              return (
                                <div key={index} className="box">
                                  <Link to={`/pageadminview/${item?.id}`}>
                                    <div className="img">
                                      {item?.background_img ? (
                                        <img
                                          src={item?.background_img}
                                          alt=""
                                        />
                                      ) : (
                                        <img alt="" />
                                      )}
                                      {/* <Link to={`/pageadminview/${item?.id}`}> */}
                                      <img
                                        src={
                                          item?.icon
                                            ? item?.icon
                                            : pagePlaceholder
                                        }
                                        alt=""
                                        className="page-icon"
                                      />
                                    </div>
                                  </Link>
                                  <div className="text">
                                    <Link to={`/pageadminview/${item?.id}`}>
                                      <h6
                                        className="card-control-text-twoline"
                                        style={{
                                          wordBreak: "break-word",
                                          color: "#0432a3",
                                        }}
                                      >
                                        {item.name}
                                      </h6>
                                    </Link>
                                    <span>
                                      {item?.followers_count}{" "}
                                      {item?.followers_count > 1
                                        ? "followers"
                                        : "follower"}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          <div className="mt-3">
                            <Link to={"/pages"}>
                              <span>See all</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="article my-4">
                      <h4 className="mb-3">Articles for you</h4>
                      <div className="line"></div>
                      <div className="mt-3">
                        {suggested_articles?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <div key={index} className="box">
                                <Link to={`/articledetail/${item?.id}`}>
                                  <div className="img">
                                    {item?.media ? (
                                      <img src={item?.media} alt="" />
                                    ) : (
                                      <img src={null} alt="" />
                                    )}
                                  </div>
                                </Link>
                                <div className="text">
                                  <Link to={`/articledetail/${item?.id}`}>
                                    <h6
                                      className="card-control-text-twoline"
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {item.title}
                                    </h6>
                                  </Link>
                                  <span>{item?.view_count || 0} viewers</span>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div className="mt-3">
                          <Link to={"/articles"}>
                            <span>See all</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="groups my-4">
                      <h4 className="mb-3">Groups</h4>
                      <div className="line mb-3"></div>
                      <ul>
                        {suggested_groups?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <Link to={`/groupdetails/${item?.id}`}>
                                <li
                                  className="card-control-text-oneline mb-2"
                                  style={{ wordBreak: "break-word" }}
                                  key={index}
                                >
                                  {item.name}
                                </li>
                              </Link>
                            );
                          }
                        })}
                      </ul>
                      <Link to={"/groups"}>
                        <span className="ms-0">See all</span>
                      </Link>
                    </div>
                    <div className="events mb-3">
                      <h4 className="mb-3">Events</h4>
                      <div className="line mb-3"></div>
                      <ul className="mb-2">
                        {suggested_events?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <Link to={`/eventdetail/${item?.id}`}>
                                <li
                                  className="card-control-text-oneline"
                                  style={{ wordBreak: "break-word" }}
                                  key={index}
                                >
                                  <div className="icon">
                                    <img src={cale} alt="" />
                                  </div>{" "}
                                  {item.title}
                                </li>
                              </Link>
                            );
                          }
                        })}
                      </ul>
                      <Link to={"/event"}>
                        <span className="ms-0">See all</span>
                      </Link>
                    </div>
                    {followedTagList?.length > 0 ? (
                      <div className="follow mt-4 mb-10 pb-2">
                        <h4 className="mb-3">Followed Hashtags</h4>
                        <div className="line"></div>
                        <div className="tag_parent">
                          {followedTagList?.map((item, index) => {
                            if (index < 6) {
                              return (
                                <div>
                                  <p className="tags_text">#{item?.name}</p>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <Link to={"/hashtag"}>
                          <span>See all</span>
                        </Link>
                      </div>
                    ) : (
                      <div className="mb-10 pb-2"></div>
                    )}
                  </div>
                </div>
                <Link to={"/network"} style={{ backgroundColor: "white" }}>
                  <div className="left px-3 py-3 mb-10 d-flex justify-content-center border-radius-5 bg-white">
                    <div className="discover">
                      <h4>Discover more</h4>
                    </div>
                  </div>
                </Link>
                <div>
                  <div>
                    <SidebarFooter />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="center">
                <div className="msg">
                  <div className="msg-box-create">
                    <div className="type">
                      <div className="profile">
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : placeholderUser
                          }
                          alt="profile img"
                          className="img-fluid"
                        />
                      </div>
                      <textarea
                        style={{ marginTop: "8px" }}
                        id="w3review"
                        rows="5"
                        className="curserPointer"
                        onKeyDown={() => {
                          setCreatePostModal(!createPostModal);
                          setIsEdit(0);
                          setCreatePostClick("");
                        }}
                        placeholder="Share your thoughts..."
                        onClick={() => {
                          setUpdateData({});
                          setCreatePostModal(!createPostModal);
                          setIsEdit(0);
                          setCreatePostClick("");
                        }}
                      ></textarea>
                    </div>
                    <div className="social mt-2">
                      <div
                        className="box"
                        onClick={() => {
                          setCreatePostModal(!createPostModal);
                          setCreatePostClick("photo");
                          setIsEdit(0);
                        }}
                      >
                        <img src={photo} alt="" />
                        <p>Photo</p>
                      </div>
                      <div
                        className="box"
                        onClick={() => {
                          setCreatePostModal(!createPostModal);
                          setCreatePostClick("video");
                          setIsEdit(0);
                        }}
                      >
                        <img src={video} alt="" />
                        <p>Video</p>
                      </div>
                      <div
                        className={
                          user?.data?.membership?.name == "Starter"
                            ? "box opacity-50"
                            : "box"
                        }
                        onClick={() => {
                          if (user?.data?.membership?.name != "Starter")
                            setArticleModal(!articleModal);
                        }}
                      >
                        <img src={article} alt="" />
                        <p>Article</p>
                      </div>

                      <>
                        <div
                          className={
                            user?.data?.membership?.name == "Starter"
                              ? "box opacity-50"
                              : "box"
                          }
                          onClick={() => {
                            if (user?.data?.membership?.name != "Starter")
                              setEventModal(!eventModal);
                          }}
                        >
                          <img src={eve} alt="" />
                          <p>Event</p>
                        </div>
                        {console.log(user?.data, "user?data")}
                        <div
                          className={
                            user?.data?.membership?.name == "Starter"
                              ? "box opacity-50"
                              : "box"
                          }
                          onClick={() => {
                            if (
                              user?.data?.membership?.name != "Starter" &&
                              getPageDetailData?.data?.Pages?.length > 0
                            ) {
                              setNewsletterModal(!newsletterModal);
                            } else {
                              toast.error(
                                "You need to create a page for creating a newsletter",
                                { position: "bottom-left" }
                              );
                            }
                          }}
                        >
                          <img src={news} alt="" />
                          <p>Newsletter</p>
                        </div>
                      </>
                    </div>
                  </div>
                  {postData?.data?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={currentCount}
                      next={handleNext}
                      hasMore={true}
                      style={{ overflow: "hidden" }}
                    >
                      {postData?.data?.map((item, index) => (
                        <PostOnDashboard
                          item={item}
                          index={index}
                          dropDown={dropDown}
                          userId={userId}
                          user={user}
                          editPost={editPost}
                          delete_post={delete_post}
                          dropRef={dropRef}
                          dropRefShare={dropRefShare}
                          privacy_type={privacy_type}
                          save_post={save_post}
                          removeSavePost={removeSavePost}
                          setDropDown={setDropDown}
                          handleReaction={handleReaction}
                          isViewReaction={isViewReaction}
                          setViewReaction={setViewReaction}
                          setPostID={setPostID}
                          userName={userName}
                          reportModal={reportModal}
                          setReportModal={setReportModal}
                          reportType={reportType}
                          setReportType={setReportType}
                          reportCommentId={reportCommentId}
                          setReportCommentId={setReportCommentId}
                          hideUserPost={hideUserPost}
                          commentBoxStatus={commentBoxStatus}
                          setCommentBoxStatus={setCommentBoxStatus}
                          dropDownShare={dropDownShare}
                          setDropDownShare={setDropDownShare}
                          repostPost={repostPost}
                          likeResult={likeResult}
                          sendRequest={sendRequest}
                          peopleFollowRequest={peopleFollowRequest}
                          peopleUnFollowRequest={peopleUnFollowRequest}
                          peopleRemoveConnection={peopleRemoveConnection}
                          handlePageFollow={handlePageFollow}
                          handlePageUnFollow={handlePageUnFollow}
                          commentStatus={commentStatus}
                          setCommentStatus={setCommentStatus}
                          handleCommentStatus={handleCommentStatus}
                          closeModal={closeModal}
                          setCloseModal={setCloseModal}
                          setDeleteId={setDeleteId}
                          postViewCounter={postViewCounter}
                          openShareModal={openShareModal}
                          setOpenShareModal={setOpenShareModal}
                          setShareData={setShareData}
                          dropDownRepost={dropDownRepost}
                          setDropDownRepost={setDropDownRepost}
                          dropRefRepost={dropRefRepost}
                          roleShareModal={roleShareModal}
                          setRoleShareModal={setRoleShareModal}
                          rolePageId={rolePageId}
                          setRolePageId={setRolePageId}
                        />
                      ))}
                    </InfiniteScroll>
                  ) : !isPostLoading ? (
                    <div className={`post-main-box p-20 text-center`}>
                      <div className="noPostShowBox">
                        <div className="msgBox">
                          <h6>
                            Start your first post and engage with your network!
                          </h6>
                        </div>
                        <div className="imgBox">
                          <img
                            src={noPost}
                            alt="noPost"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="middle">
                <Advertisement />
                <div style={{ marginTop: "26px" }}>
                  <Ad />
                </div>
                <div style={{ marginTop: "26px" }}>
                  <Refer />
                </div>
              </div>
              <div className="middle sidebar-sticky">
                <PeopleYouMayKnow
                  suggested_people={suggested_people}
                  sendRequest={sendRequest}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>

        <Chat />

        {createPostModal && (
          <CreatePostModal
            modal={createPostModal}
            toggle={() => {
              if (isDataAvailable) {
                setDiscardModalStatus(true);
              } else {
                setCreatePostModal(!createPostModal);
              }
            }}
            toggleOff={() => {
              setCreatePostModal(!createPostModal);
            }}
            title="Create Post"
            userName={userName}
            userId={userId}
            createPostClick={createPostClick}
            discardModalStatus={discardModalStatus}
            setDiscardModalStatus={setDiscardModalStatus}
            closeAllModal={closeAllModal}
            updateData={updateData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isDataAvailable={isDataAvailable}
            setDataAvailable={setDataAvailable}
            draftDetails={draftDetails}
            setDraftDetails={setDraftDetails}
            dashboardData={dashboardData}
            setCommentBoxStatus={setCommentBoxStatus}
          />
        )}
        {isViewReaction && (
          <ViewReactionModal
            modal={isViewReaction}
            toggle={() => setViewReaction(!isViewReaction)}
            title="Reactions"
            postId={postId}
            type="post"
          />
        )}

        {articleModal && (
          <CreateArticleModal
            modal={articleModal}
            toggle={() => setArticleModal(!articleModal)}
            title="Create Article"
            source="home"
          />
        )}

        {eventModal && (
          <CreateEventModal
            modal={eventModal}
            toggle={() => setEventModal(!eventModal)}
            title="Create Event"
            source="home"
            // successfulStatus={successfulStatus}
          />
        )}

        {/* {newsletterModal && (
          <CreateNewsletterModal
            modal={newsletterModal}
            toggle={() => setNewsletterModal(!newsletterModal)}
            title="Create Newsletter"
            source="home"
          />
        )} */}

        {discardModalStatus && (
          <DiscardEventModal
            modal={discardModalStatus}
            close={closeAllModal}
            toggle={() => setDiscardModalStatus(!discardModalStatus)}
            title="Discard Changes."
            saveDraft={saveDraft}
            isDash={true}
          />
        )}

        {reportModal && (
          <ReportModal
            modal={reportModal}
            toggle={() => setReportModal(!reportModal)}
            post_id={postId}
            reportType={reportType} //"post"/"comment"
            reportCommentId={reportCommentId} //null in case of post
          />
        )}

        {closeModal && (
          <DeleteModal
            modal={DeleteModal}
            toggle={() => setCloseModal(!closeModal)}
            title="Delete Confirmation"
            deleteConfirm={() => {
              delete_post(deleteId);
            }}
            text="Are you want to sure to delete this?"
          />
        )}
        {openShareModal && (
          <ShareModal
            modal={openShareModal}
            toggle={() => setOpenShareModal(!openShareModal)}
            title="Share"
            shareData={shareData}
            type="shared_post"
            source="dashboard"
            toggleSuccessful={() => setOpenShareModal(!openShareModal)}
          />
        )}

        {roleShareModal && (
          <RoleChangeModal
            modal={roleShareModal}
            toggle={() => setRoleShareModal(!roleShareModal)}
            title="Post, like, comment and share "
            postId={postId}
            getPageDetailData={getPageDetailData}
            rolePageId={rolePageId}
          />
        )}

        {newsletterModal && (
          <ChangePageModel
            modal={newsletterModal}
            toggle={() => setNewsletterModal(!newsletterModal)}
            title="Choose an option"
            getPageDetailData={getPageDetailData}
          />
        )}
      </div>
    </>
  );
}
