import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import drop from "../../../../assets/img/gallery-add.png";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import "./DragNDrop.scss";

function DropzoneMobile({ open, bannerImage, deletePicture, className }) {
  const [files, setFiles] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {});
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          alt=""
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <div className={`text-center`}>
        {bannerImage !== "" ? (
          <div className={`image-upload`}>
            <img
              src={bannerImage}
              className="image_drag_drop"
              alt="articleBanner"
            />
            <div className="del_button" onClick={() => deletePicture()}>
              <img src={deleteIcon} alt="del_icon" />
              <div>Remove</div>
            </div>
          </div>
        ) : (
          <div>
            <div className={`image-upload ${className}`}>
              <div className="desktop-dropzone">
                <img src={drop} alt="drag-n-drop" />
                <div
                  className="pt-2"
                  onClick={() =>
                    window.addEventListener("uploadImageMobile", () => {})
                  }
                >
                  Drag and drop or{" "}
                  <span className="blue-txt">
                    <u>browse</u>
                  </span>{" "}
                  your files
                </div>
                <div className="pt-2">
                  We recommend uploading an image of at least 1920x1080 pixels.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DropzoneMobile;
