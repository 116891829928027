import React, { useState } from "react";
import edit from "../../../../assets/img/edit.svg";
import placeholderUser from "../../../../assets/img/placeholder_user.jpg";
import ImageModal from "../../../../core/components/modal/ImageModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../../../store/profile/profileActions";
import { FormGroup, Input, Label } from "reactstrap";
import Dropdown from "../../../../core/components/DropDown/Dropdown";
import { toast } from "react-toastify";

const EditProfileModal = (props) => {
  const {
    firstName,
    modal,
    toggle,
    lastName,
    setAddContactInfoModal,
    addContactInfoModal,
    highestEducation,
    highestExperience,
    profileHeadline,
    educationModal,
    setEducationModal,
    educationData,
    addExpModal,
    setAddExpModal,
    experienceData,
    backgroundPhotoModal,
    setBackgroundPhotoModal,
    profilePhotoModal,
    setProfilePhotoModal,
    profileImage,
    backgroundImage,
    id,
  } = props;
  const [imageModal, setImageModal] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorProfileHeadline, setErrorProfileHeadline] = useState("");
  const [errorEducation, setErrorEducation] = useState("");
  const [errorExperience, setErrorExperience] = useState("");
  const { isWebView } = useSelector((state) => state.auth);

  const imageToggle = () => setImageModal(!imageModal);

  const [pageData, setPageData] = useState({
    first_name: firstName,
    last_name: lastName,
    profile_headline: profileHeadline,
    experiences: [],
    educationCheck: highestEducation ? true : false,
    educationName: highestEducation ? highestEducation?.id : "",
    experienceName: highestExperience ? highestExperience?.id : "",
    experienceCheck: highestExperience ? true : false,
  });

  const dispatch = useDispatch();

  const checkSignUpValidation = () => {
    let stringName = /^(?![\s])[A-Za-z\s]+$/;
    let stringNameLast = /^(?![\s])[A-Za-z\s]+$/;
    let stringHeadline = /^[A-Za-z\d$-@.!%*#?&\s]+$/;

    let formIsValid = true;
    if (!pageData.first_name) {
      setErrorFirstName("Enter your first name.");
      formIsValid = false;
    } else if (!stringName.test(pageData.first_name)) {
      setErrorFirstName("First name is invalid.");
      formIsValid = false;
    } else if (pageData.first_name.length > 50) {
      setErrorFirstName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorFirstName("");
    }
    if (!pageData.last_name) {
      setErrorLastName("Enter your last name.");
      formIsValid = false;
    } else if (!stringNameLast.test(pageData.last_name)) {
      setErrorLastName("Last name is invalid.");
      formIsValid = false;
    } else if (pageData.last_name.length > 50) {
      setErrorLastName("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorLastName("");
    }

    if (!pageData.profile_headline) {
      setErrorProfileHeadline("The field should not be empty.");
      formIsValid = false;
    } else if (!stringHeadline.test(pageData.profile_headline)) {
      setErrorProfileHeadline("Profile headline is invalid.");
      formIsValid = false;
    } else if (pageData?.profile_headline?.length > 50) {
      setErrorProfileHeadline("Max 50 characters allowed.");
      formIsValid = false;
    } else {
      setErrorProfileHeadline("");
    }

    if (pageData.educationCheck) {
      if (pageData.educationName === "") {
        setErrorEducation("Please select current education.");
        formIsValid = false;
      }
    } else {
      setErrorEducation("");
    }

    if (pageData.experienceCheck) {
      if (pageData.experienceName === "") {
        setErrorExperience("Please select current experience.");
        formIsValid = false;
      }
    } else {
      setErrorExperience("");
    }

    return formIsValid;
  };

  const handleSubmit = async (e) => {
    if (checkSignUpValidation()) {
      let formatProfileData = {
        first_name: pageData.first_name,
        last_name: pageData.last_name,
        profile_headline: pageData.profile_headline,
        education_id:
          pageData.educationCheck === true ? pageData.educationName : null,
        experience_id:
          pageData.experienceCheck === true ? pageData.experienceName : null,
      };
      var token = localStorage.getItem("accessToken");
      dispatch(updateUserDetails(formatProfileData, token))
        .then((res) => {
          toast.success("Profile updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          toggle();
        })
        .catch((e) => {
          console.log(e);
          alert("Error" + e.response.data.message);
        });
    }
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setPageData((prevState) => ({
      ...prevState,
      [input]: value,
    }));

    if (input === "first_name") {
      if (value.length === 0) {
        setErrorFirstName("Enter your first name.");
      } else if (!/^(?![\s])[A-Za-z\s]+$/.test(value)) {
        setErrorFirstName("First name is invalid.");
      } else if (value.length > 50) {
        setErrorFirstName("Max 50 characters allowed.");
      } else {
        setErrorFirstName("");
      }
    } else if (input === "last_name") {
      if (value.length === 0) {
        setErrorLastName("Enter your last name.");
      } else if (!/^(?![\s])[A-Za-z\s]+$/.test(value)) {
        setErrorLastName("Last name is invalid.");
      } else if (value.length > 50) {
        setErrorLastName("Max 50 characters allowed.");
      } else {
        setErrorLastName("");
      }
    } else if (input === "profile_headline") {
      if (value.length === 0) {
        setErrorProfileHeadline("The field should not be empty.");
      } else if (!/^[A-Za-z\d$-@.!%*#?&\s]+$/.test(value)) {
        setErrorProfileHeadline("Profile headline is invalid.");
      } else if (value.length > 50) {
        setErrorProfileHeadline("Max 50 characters allowed.");
      } else {
        setErrorProfileHeadline("");
      }
    }
  };

  const handleEdit = (type, data) => {
    if (isWebView) {
      if (type === "BackGround") {
        console.log(
          `webViewDataHandler-editProfileBgimage-${JSON.stringify(data)}`
        );
      } else {
        console.log(
          `webViewDataHandler-editProfileimage-${JSON.stringify(data)}`
        );
      }
    } else if (type === "Profile") {
      setProfilePhotoModal(!profilePhotoModal);
    } else if (type === "BackGround") {
      setBackgroundPhotoModal(!backgroundPhotoModal);
    }
  };

  const DropdownOptionWebview = (data) => {
    let dropdown = [];
    data?.map((item) => {
      Object.values(item).map((item2) => {
        dropdown.push({
          value: `${item2[0]?.title} at ${item2[0]?.company_name}`,
          id: item2[0]?.id,
        });
      });
    });
    return dropdown;
  };

  const SelectedTitle = (id, data) => {
    let dropdown = [];
    data?.map((item) => {
      Object.values(item).map((item2) => {
        dropdown.push({
          value: `${item2[0]?.title} at ${item2[0]?.company_name}`,
          id: item2[0]?.id,
        });
      });
    });
    return dropdown.find((item) => item.id === id)?.value;
  };

  const SelectedTitleEducation = (id, data) => {
    let dropdown = [];
    data?.map((item) => {
      dropdown.push({
        value: item?.school_name,
        id: item?.id,
      });
    });
    return dropdown.find((item) => item.id === id)?.value;
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="contact-info"
        size="lg"
        style={{ maxWidth: "640px" }}
        centered
      >
        <ModalHeader
          toggle={toggle}
          id="account-modal"
          className="border-0"
        ></ModalHeader>
        <ModalBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
          <div className="intro edit-profile">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-3 popup">
                  <form>
                    <div className="cover mt-20">
                      <div className="banner ">
                        {backgroundImage ? (
                          <img
                            className="image-fit"
                            src={backgroundImage}
                            alt=""
                            style={{
                              position: "absolute",
                              borderRadius: "5px",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        <div className="user-image">
                          <div className="profile-img">
                            <div className="img">
                              <img
                                className="image-fit"
                                src={
                                  profileImage ? profileImage : placeholderUser
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className="edit"
                              onClick={() => handleEdit("Profile", { id: id })}
                            ></div>
                          </div>
                        </div>

                        <div className="img-circle">
                          <div className="edit">
                            <img
                              src={edit}
                              alt=""
                              height={"25px"}
                              width={"25px"}
                              onClick={() =>
                                handleEdit("BackGround", { id: id })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="title mb-3">
                        <label className="text-dark">First Name</label>
                        <Input
                          type="text"
                          placeholder="Enter First Name"
                          value={pageData.first_name}
                          onChange={handleInputData("first_name")}
                        />
                        {errorFirstName && (
                          <label className="label-input100 text-danger">
                            {errorFirstName}
                          </label>
                        )}
                      </div>
                      <div className="title mb-3">
                        <label className="text-dark">Last Name</label>
                        <Input
                          type="text"
                          placeholder="Enter Last Name"
                          value={pageData.last_name}
                          onChange={handleInputData("last_name")}
                        />
                        {errorLastName && (
                          <label className="label-input100 text-danger">
                            {errorLastName}
                          </label>
                        )}
                      </div>
                      <div className="title">
                        <label className="text-dark">Headline</label>
                        <Input
                          type="text"
                          placeholder="Co-founder of"
                          value={pageData.profile_headline}
                          onChange={handleInputData("profile_headline")}
                        />
                        {errorProfileHeadline && (
                          <label className="text-danger">
                            {errorProfileHeadline}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="exper mb-3 mt-3">
                      <div>
                        <FormGroup>
                          <Label for="experiencedropdown">
                            Current position
                          </Label>

                          {false ? (
                            <Dropdown
                              label="Select"
                              data={DropdownOptionWebview(experienceData)}
                              dynamicPlaceHolder={SelectedTitle(
                                pageData.experienceName,
                                experienceData
                              )}
                              onClick={() => setErrorExperience("")}
                              handleSelect={(value) =>
                                setPageData({
                                  ...pageData,
                                  experienceName: value?.id,
                                })
                              }
                            />
                          ) : (
                            <Input
                              id="experiencedropdown"
                              name="select"
                              type="select"
                              onClick={() => setErrorExperience("")}
                              value={pageData.experienceName}
                              onChange={(e) =>
                                setPageData({
                                  ...pageData,
                                  experienceName: e.target.value,
                                })
                              }
                            >
                              <option value="">Please Select</option>
                              {experienceData?.map((item, index) => {
                                return Object.values(item)[0].map(
                                  (item3, index) => {
                                    return (
                                      <option key={index} value={item3.id}>
                                        {item3.title} at {item3.company_name}
                                      </option>
                                    );
                                  }
                                );
                              })}
                            </Input>
                          )}
                        </FormGroup>
                      </div>
                      {errorExperience && (
                        <label className="label-input100 text-danger">
                          {errorExperience}
                        </label>
                      )}
                      <span
                        onClick={() => setAddExpModal(!addExpModal)}
                        className="addButton"
                      >
                        + Add new position
                      </span>
                      <div className="check">
                        <input
                          type="checkbox"
                          id="experienceCheck"
                          name="experienceCheck"
                          checked={pageData?.experienceCheck}
                          onClick={(e) =>
                            setPageData({
                              ...pageData,
                              experienceCheck: e.target.checked,
                            })
                          }
                        />
                        <label htmlFor="educationCheck">
                          Show current position in my intro
                        </label>
                      </div>
                    </div>
                    <div className="educa exper mb-3">
                      <div>
                        <FormGroup>
                          <Label for="educationdropdown">Education</Label>
                          {false ? (
                            <Dropdown
                              label="Select"
                              data={educationData.map((item) => {
                                return {
                                  value: item?.school_name,
                                  id: item?.id,
                                };
                              })}
                              dynamicPlaceHolder={SelectedTitleEducation(
                                pageData.educationName,
                                educationData
                              )}
                              onClick={() => setErrorEducation("")}
                              handleSelect={(value) =>
                                setPageData({
                                  ...pageData,
                                  educationName: value?.id,
                                })
                              }
                            />
                          ) : (
                            <Input
                              id="educationdropdown"
                              onClick={() => setErrorEducation("")}
                              name="select"
                              type="select"
                              // value={JSON.stringify(pageData.educationName)}
                              value={pageData.educationName}
                              onChange={(e) =>
                                setPageData({
                                  ...pageData,
                                  // educationName: (JSON.parse(e.target.value))
                                  educationName: e.target.value,
                                })
                              }
                            >
                              <option value="">Please Select</option>
                              {educationData?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    // value={JSON.stringify(item)}
                                    value={item.id}
                                  >
                                    {item.school_name}
                                  </option>
                                );
                              })}
                            </Input>
                          )}
                        </FormGroup>
                      </div>
                      {errorEducation && (
                        <label className="label-input100 text-danger">
                          {errorEducation}
                        </label>
                      )}
                      <span
                        className="addButton"
                        onClick={() => setEducationModal(!educationModal)}
                      >
                        + Add new education
                      </span>
                      <div className="check">
                        <input
                          type="checkbox"
                          id="educationCheck"
                          name="educationCheck"
                          checked={pageData?.educationCheck}
                          onClick={(e) =>
                            setPageData({
                              ...pageData,
                              educationCheck: e.target.checked,
                            })
                          }
                        />
                        <label htmlFor="educationCheck">
                          Show education in my intro
                        </label>
                      </div>
                    </div>
                    <div className="contact mb-3">
                      <label>Contact information</label>
                      <p className="text-secondary">
                        Add or edit your profile URL, email, and more
                      </p>
                      <span
                        onClick={() =>
                          setAddContactInfoModal(!addContactInfoModal)
                        }
                      >
                        Edit contact info
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <ImageModal
              modal={imageModal}
              toggle={imageToggle}
              title="Background Cover"
            />
          </div>
        </ModalBody>
        <div className="savebutton">
          <div
            className={isWebView ? "btnWebView btn-blue" : "btn btn-blue"}
            style={{ textAlign: "center" }}
            onClick={(e) => handleSubmit(e)}
          >
            Save
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditProfileModal;
