import React, { useCallback, useEffect, useState } from "react";
import "./HiringModal.scss";
import verifiedSmall from "../../assets/img/verifiedSmall.png";
import placeholderUser from "../../assets/img/placeholder_user.jpg";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import confidential from "../../assets/img/confidentialIcon.svg";
import orgLogoSmall from "../../assets/img/orgLogoSmall.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobs,
  getJobsMessage,
  updateJobsMessage,
} from "../../store/jobs/jobAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateUserDetails } from "../../store/profile/profileActions";
import { ToastContainer, toast } from "react-toastify";
import { GetTime } from "../../core/components/Utility/Utility";
import EditIcon from "../../assets/img/EditIconFigma.svg";
const HiringModals = (props) => {
  const { modal, toggle, getAllJobs, loadMoreData, totalCount } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");
  const { getJobsMessageData, getMyJobs } = useSelector((state) => state.jobs);
  const [createMessage, setCreateMessage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentMyJobCount, setCurrentMyJobCount] = useState(0);
  const [totalMyJobCount, setTotalMyJobCount] = useState(0);
  const [isMoreMyJob, setIsMoreMyJob] = useState();
  const [editRejectionMode, setEditRejectionMode] = useState(false);

  const [editedText, setEditedText] = useState("");
  const [editedRejectedText, setEditedRejectedText] = useState("");
  const [shortlistMsgError, setShortlistMsgError] = useState("");
  const [rejectionMsgError, setRejectionMsgError] = useState("");
  const [closedMsgError, setClosedMsgError] = useState("");

  const [closeRejectionMode, setCloseRejectionMode] = useState(false);

  const [closedText, setClosedText] = useState("");
  const loadMoreMyJob = () => {
    setTimeout(() => {
      setCurrentMyJobCount(currentMyJobCount + 10);
    }, 1000);
  };
  useEffect(() => {
    if (getMyJobs?.length < totalMyJobCount) setIsMoreMyJob(true);
    else setIsMoreMyJob(false);
  }, [currentMyJobCount, totalMyJobCount]);

  useEffect(() => {
    dispatch(
      getJobs(
        { limit: 10, skip: currentMyJobCount, filter: "my" },
        currentMyJobCount,
        "my"
      )
    ).then((res) => {
      setTotalMyJobCount(res.totalItem);
    });
  }, [currentMyJobCount]);

  useEffect(() => {
    dispatch(getJobsMessage());
  }, [createMessage]);
  useEffect(() => {
    setEditedText(getJobsMessageData?.job_shortlist_message);
    setEditedRejectedText(getJobsMessageData?.job_reject_message);
    setClosedText(getJobsMessageData?.job_close_message);
  }, [getJobsMessageData]);
  const handleEditClick = () => {
    setEditMode(true);
    setEditedText(getJobsMessageData?.job_shortlist_message?.trim() || "");
  };

  const handleEditSecondClick = () => {
    setEditRejectionMode(true);
    setEditedRejectedText(getJobsMessageData?.job_reject_message?.trim() || "");
  };
  const handleCloseSeondClick = () => {
    setCloseRejectionMode(true);
    setClosedText(getJobsMessageData?.job_close_message?.trim() || "");
  };

  const handleSaveClick = () => {
    console.log("editedText?.trim()?.length", editedText?.trim()?.length);
    if (editedText?.trim()?.length == 0) {
      setShortlistMsgError("Please enter the message");
      return;
    } else {
      const data = {
        job_shortlist_message: editedText?.trim(),
      };
      dispatch(updateJobsMessage(data)).then((res) => {
        if (res.success) {
          toast.success("Job Message updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    }
    setEditMode(false);
  };
  const handleSaveSecondClick = () => {
    if (editedRejectedText?.trim()?.length == 0) {
      setRejectionMsgError("Please enter the message");
      return;
    } else {
      const data = {
        job_reject_message: editedRejectedText?.trim(),
      };
      dispatch(updateJobsMessage(data)).then((res) => {
        if (res.success) {
          toast.success("Job Message updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    }
    setEditRejectionMode(false);
  };

  const handleSaveThirdClick = () => {
    if (closedText?.trim()?.length == 0) {
      setClosedMsgError("Please enter the message");
      return;
    } else {
      const data = {
        job_close_message: closedText?.trim(),
      };
      dispatch(updateJobsMessage(data)).then((res) => {
        if (res.success) {
          toast.success("Job Message updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    }
    setCloseRejectionMode(false);
  };

  const handleRejectClick = () => {
    setEditedText("");
    const data = {
      job_shortlist_message: "",
    };
    dispatch(updateJobsMessage(data)).then((res) => {
      if (res.success) {
        toast.success("Job Message updated successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
    setEditMode(false);
  };
  const handleCloseClick = () => {
    setClosedText("");
    const data = {
      job_close_message: "",
    };
    dispatch(updateJobsMessage(data)).then((res) => {
      if (res.success) {
        toast.success("Job Message updated successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
    setCloseRejectionMode(false);
  };

  const handleRejectSecondClick = () => {
    setEditedRejectedText("");
    const data = {
      job_reject_message: "",
    };
    dispatch(updateJobsMessage(data)).then((res) => {
      if (res.success) {
        toast.success("Job Message updated successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
    setEditRejectionMode(false);
  };

  return (
    <>
      {/* Jobs I Have Posted */}
      <Modal
        size="xl"
        isOpen={modal}
        // style={{ maxWidth: "600px" }}
        className={createMessage ? "" : "gradient-background modal-size-sm"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {!createMessage ? (
          <ModalHeader className="dicardModalHeader py-3" toggle={toggle}>
            <h3 className="d-flex gap-3 align-items-center">
              <>
                <img
                  src={
                    user?.data?.profile_img
                      ? user?.data?.profile_img
                      : placeholderUser
                  }
                  alt="img"
                  className="circle_profile"
                />{" "}
                <span
                  className="name card-control-text-oneline"
                  style={{ color: "rgba(255, 255, 255, 1)", fontSize: "15px" }}
                >
                  {user?.data?.first_name} {user?.data?.last_name}
                </span>{" "}
                {user?.data?.is_email_verified && (
                  <img
                    src={verifiedSmall}
                    alt="img "
                    style={{ height: "15px", width: "15px" }}
                  />
                )}
              </>
            </h3>
          </ModalHeader>
        ) : (
          <ModalHeader
            className="dicardModalHeader"
            toggle={() => setCreateMessage(!createMessage)}
            style={{ border: "none" }}
          >
            <h3 className="text-dark">Job Messages</h3>
            <p
              style={{
                width: "60%",
                marginTop: "5px",
                fontSize: "15px",
                fontWeight: "100",
              }}
            >
              Manage automatic messages to respond to applicants in the event of
              a job being closed or the candidate being shortlisted and/or
              rejected.
            </p>
          </ModalHeader>
        )}
        <ModalBody style={{ padding: "20px" }}>
          {/* <div className='hiring-pop-up-default jobs-posted-modal'> */}

          {!createMessage && (
            <div className="jobs-posted-modal">
              <div className="hiring-pop-up-default-inner">
                <div className="jobs-posted-head">
                  <span className="left">Jobs I Have Posted</span>
                  <span
                    className="right"
                    onClick={() => {
                      navigate("/jobs", { state: { isPost: true } });
                    }}
                  >
                    + Post a New Job
                  </span>
                </div>
                <div id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={getMyJobs?.length ? getMyJobs?.length : 0}
                    next={loadMoreMyJob}
                    hasMore={isMoreMyJob}
                    style={{ overflow: "visible" }}
                    scrollableTarget="scrollableDiv"
                  >
                    <div className="job-posted-outer">
                      {getMyJobs?.map((data, index) => (
                        <div className="job-posted-list" key={index}>
                          <div className="left-list">
                            <img
                              src={
                                data?.company_visible
                                  ? data?.company?.icon
                                    ? data?.company?.icon
                                    : pagePlaceholder
                                  : confidential
                              }
                              alt="logo"
                              className="circle_profile"
                            />
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(
                                  updateUserDetails({ badge: "hiring" }, token)
                                );
                                navigate(`/jobdetails/${data.id}`);
                              }}
                            >
                              <h4>{data?.title}</h4>
                              <p
                                className="org-name d-flex card-control-text-oneline"
                                style={{ fontSize: "13px" }}
                              >
                                {data?.company_visible
                                  ? data?.company_name
                                  : "Confidential"}{" "}
                                <p className="location">{data?.job_location}</p>
                              </p>
                            </div>
                          </div>
                          <div className="right-list">
                            {GetTime(data.createdAt)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
                <div className="bottom-text">
                  <h4>Create Job Messages</h4>
                  <p>
                    Manage automatic messages to respond to applicants in the
                    event of a job being closed or the candidate being
                    shortlisted and/or rejected.
                  </p>
                  <div
                    className="message"
                    onClick={() => {
                      setCreateMessage(true);
                    }}
                  >
                    + Manage message
                  </div>
                </div>
              </div>
            </div>
          )}

          {createMessage && (
            <div className="jobs-messages-modal">
              <div className="hiring-pop-up-default-inner">
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
                                    libero et velit interdum, ac aliquet odio mattis.</p> */}
                <form>
                  <div className="title-head mt-0">
                    <h4>Job Application Shortlisted Message</h4>
                    {!editMode && (
                      <span className="edit" onClick={() => handleEditClick()}>
                        {editedText ? <img src={EditIcon} width={20} /> : "Add"}
                      </span>
                    )}
                    {/* <span className='edit'>Edit</span> */}
                  </div>
                  {editMode ? (
                    <textarea
                      value={editedText || ""}
                      onChange={(e) => {
                        setEditedText(e.target.value);
                        setShortlistMsgError("");
                      }}
                    />
                  ) : (
                    <p>{editedText}</p>
                  )}
                  {editMode && (
                    <>
                      {shortlistMsgError && (
                        <label
                          className="text-danger"
                          style={{ fontWeight: "400", fontSize: "15px" }}
                        >
                          {shortlistMsgError}
                        </label>
                      )}
                      <div class="blue-btn-outer">
                        <span
                          className="white-btn px-3"
                          style={{
                            background: "rgba(4, 50, 163, 0.05)",
                            color: "rgba(4, 50, 163, 1)",
                            border: "rgba(4, 50, 163, 0.05) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleRejectClick}
                        >
                          Remove
                        </span>
                        <span
                          className="blue-btn px-4"
                          style={{
                            background: "rgba(4, 50, 163, 1)",
                            color: "white",
                            border: "rgba(4, 50, 163, 1) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSaveClick()}
                        >
                          Save
                        </span>
                      </div>
                    </>
                  )}
                  <div className="title-head">
                    <h4>Job Application Rejection Message</h4>
                    {!editRejectionMode && (
                      <span
                        className="edit"
                        onClick={() => handleEditSecondClick()}
                      >
                        {editedRejectedText ? (
                          <img src={EditIcon} width={20} />
                        ) : (
                          "Add"
                        )}
                      </span>
                    )}
                    {/* <span className='edit'>Edit</span> */}
                  </div>
                  {editRejectionMode ? (
                    <textarea
                      value={editedRejectedText}
                      onChange={(e) => {
                        setEditedRejectedText(e.target.value);
                        setRejectionMsgError("");
                      }}
                    />
                  ) : (
                    <p>{editedRejectedText}</p>
                  )}
                  {editRejectionMode && (
                    <>
                      {rejectionMsgError && (
                        <label
                          className="text-danger"
                          style={{ fontWeight: "400", fontSize: "15px" }}
                        >
                          {rejectionMsgError}
                        </label>
                      )}
                      <div class="blue-btn-outer">
                        <span
                          className="white-btn px-3"
                          style={{
                            background: "rgba(4, 50, 163, 0.05)",
                            color: "rgba(4, 50, 163, 1)",
                            border: "rgba(4, 50, 163, 0.05) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleRejectSecondClick}
                        >
                          Remove
                        </span>
                        <span
                          className="blue-btn px-4"
                          style={{
                            background: "rgba(4, 50, 163, 1)",
                            color: "white",
                            border: "rgba(4, 50, 163, 1) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleSaveSecondClick}
                        >
                          Save
                        </span>
                      </div>
                    </>
                  )}
                  <div className="title-head">
                    <h4>Job Ad Closed Message</h4>
                    {!closeRejectionMode && (
                      <span
                        className="edit"
                        onClick={() => handleCloseSeondClick()}
                      >
                        {closedText ? <img src={EditIcon} width={20} /> : "Add"}
                      </span>
                    )}
                  </div>

                  {closeRejectionMode ? (
                    <textarea
                      value={closedText}
                      onChange={(e) => {
                        setClosedText(e.target.value);
                        setClosedMsgError("");
                      }}
                    />
                  ) : (
                    <p>{closedText}</p>
                  )}
                  {closeRejectionMode && (
                    <>
                      {closedMsgError && (
                        <label
                          className="text-danger"
                          style={{ fontWeight: "400", fontSize: "15px" }}
                        >
                          {closedMsgError}
                        </label>
                      )}
                      <div class="blue-btn-outer">
                        <span
                          className="white-btn px-3"
                          style={{
                            background: "rgba(4, 50, 163, 0.05)",
                            color: "rgba(4, 50, 163, 1)",
                            border: "rgba(4, 50, 163, 0.05) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleCloseClick}
                        >
                          Remove
                        </span>
                        <span
                          className="blue-btn px-4"
                          style={{
                            background: "rgba(4, 50, 163, 1)",
                            color: "white",
                            border: "rgba(4, 50, 163, 1) 1px solid",
                            padding: "8px",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          onClick={handleSaveThirdClick}
                        >
                          Save
                        </span>
                      </div>
                    </>
                  )}
                </form>
              </div>
              <ToastContainer />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
export default HiringModals;
