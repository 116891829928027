import {
  ADD_EMPLOYMENT,
  ADD_OTHER_INFO,
  ADD_EDUCATION,
  ADD_CRIMINAL,
  UPDATE_BASIC_INFO,
  UPDATE_EMPLOYMENT,
  UPDATE_EDUCATION,
  UPDATE_CRIMINAL,
  UPDATE_AUTHORIZATION,
  REMOVE_OTHER_INFO,
  REMOVE_PERTICULAR_EDUCATION,
  REMOVE_PERTICULAR_EMPLOYMENT,
  REMOVE_ALL_EMPLOYMENT,
  REMOVE_ALL_EDUCATION,
  REMOVE_CRIMINAL,
  CLEAR_DATA,
  GET_SCREENING_LIST_SUCCESS,
} from "../types";

export const initialState = {
  screeningList: [],
  basicInfo: {
    info: {
      first_name: "",
      middle_name: "",
      last_name: "",
      name_type: "",
    },
    otherInfo: {},
    ssn: "",
    gender: "",
    dob: null,
    country: "",
    nationality: "",
    additional_nationality: "",
    applicable_nationality: false,
    residence: "",
    state: "",
    applicable_state: false,
    city: "",
    zip: "",
    address: "",
    applicant_phone: "",
    applicant_email: "",
    document: [],
    country_id: "",
    state_id: "",
    city_id: "",
    documents_name: [],
  },

  employment: [],
  education: [],

  criminal: {},
  authorization: {
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: null,
    country: "",
    citizenship: "",
    address: "",
    obtain_request: false,
    signature: "",
    date: null,
    authorization_form_pdf: "",
  },
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OTHER_INFO:
      return {
        ...state,
        basicInfo: {
          ...state?.basicInfo,
          otherInfo: {
            first_name: "",
            middle_name: "",
            last_name: "",
            name_type: "",
          },
        },
      };

    case REMOVE_OTHER_INFO:
      return {
        ...state,
        basicInfo: {
          ...state?.basicInfo,
          otherInfo: {},
        },
      };

    case ADD_EMPLOYMENT:
      return {
        ...state,
        employment: [...state.employment, action.payload],
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [...state.education, action.payload],
      };

    case ADD_CRIMINAL:
      return {
        ...state,
        criminal: {
          residence: "",
          nationality: "",
        },
      };

    case UPDATE_BASIC_INFO:
      return {
        ...state,
        basicInfo: { ...state?.basicInfo, ...action?.payload },
      };

    case UPDATE_EMPLOYMENT:
      const updatedEmployment = [...state?.employment];
      updatedEmployment[action?.payload?.index] = action?.payload?.data;
      console.log(action.payload.data, "redredr");
      return {
        ...state,
        employment: updatedEmployment,
      };

    case UPDATE_EDUCATION:
      const updatedEducation = [...state?.education];
      updatedEducation[action?.payload?.index] = action?.payload?.data;
      return {
        ...state,
        education: updatedEducation,
      };

    case UPDATE_CRIMINAL:
      return {
        ...state,
        criminal: { ...state?.criminal, ...action?.payload },
      };

    case UPDATE_AUTHORIZATION:
      return {
        ...state,
        authorization: { ...state?.authorization, ...action?.payload },
      };

    case REMOVE_PERTICULAR_EMPLOYMENT:
      const removeEmployment = state?.employment?.filter(
        (ele) => ele?.id !== action.payload.id
      );
      return {
        ...state,
        employment: removeEmployment,
      };

    case REMOVE_PERTICULAR_EDUCATION:
      const removeEducation = state?.education?.filter(
        (ele) => ele?.id !== action.payload.id
      );
      return {
        ...state,
        education: removeEducation,
      };

    case REMOVE_ALL_EMPLOYMENT:
      return {
        ...state,
        employment: [],
      };
    case REMOVE_ALL_EDUCATION:
      return {
        ...state,
        education: [],
      };

    case REMOVE_CRIMINAL:
      return {
        ...state,
        criminal: {},
      };

    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case GET_SCREENING_LIST_SUCCESS:
      return {
        ...state,
        screeningList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default blog;
