import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAds,
  postViewLog,
} from "../../../../store/dashboard/dashboardActions";
import "./Ad.scss";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import adIcon from "../../../../assets/img/adIcon.svg";
import reportIcon from "../../../../assets/img/slash.svg";
import WhyAdModel from "../../modal/WhyAdmodel";
import ReportModal from "../../../../routes/dashboard/components/reportTypeModal";

export default function Ad() {
  const dispatch = useDispatch();
  // const [ad, setAd] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showAdModel, setShowAdModel] = useState(false);
  const [activeAd, setActiveAd] = useState("");
  const [reportModel, setReportModal] = useState(false);
  const { ads } = useSelector((state) => state.dashboard);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === ads.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? ads.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    dispatch(getAds())
      .then((res) => {
        // const myData = res.data.map((ele, index) => {
        //   console.log(ele);
        //   return {
        //     altText: ele?.campaignDetails?.name,
        //     caption: ele?.campaignDetails?.action_link_label,
        //     key: index + 1,
        //     url: ele?.campaignDetails?.action_link,
        //     src: ele?.Feed_Documents[0]?.document_url,
        //     id: ele?.campaignDetails?.id,
        //     feedback: ele?.Feedback,
        //   };
        // });
        // setAd(myData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const postViewCounter = (id) => {
    const payload = {
      view_type: "campaign",
      campaign_id: id,
    };
    dispatch(postViewLog(payload));
  };

  return (
    <div>
      {ads?.length > 0 && (
        <div className="advertisement-side">
          <div
            className="bg-white"
            style={{ borderRadius: "5px", position: "relative" }}
          >
            <div className="row p-1 px-4">
              <div className="col-6">
                <h6>Ad</h6>
              </div>
              <div className="col-6">
                <div
                  style={{ cursor: "pointer" }}
                  className="float-end"
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                >
                  <span>&bull;</span>
                  <span>&bull;</span>
                  <span>&bull;</span>
                </div>
              </div>
            </div>
            {toggleMenu && (
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "30px",
                  right: "5px",
                  background: "#fff",
                  zIndex: 999,
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "10px",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowAdModel(true);
                    setActiveAd(activeIndex);
                    setToggleMenu(false);
                  }}
                >
                  <img src={adIcon} width={15} height={20} className="me-2" />{" "}
                  Why am I seeing this ad?
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setReportModal(true);
                    setActiveAd(activeIndex);
                    setToggleMenu(false);
                  }}
                >
                  <img
                    src={reportIcon}
                    width={17}
                    height={20}
                    className="me-2"
                  />{" "}
                  Report this ad
                </span>
              </div>
            )}
            <div>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride={!showAdModel ? "" : "carousel"}
                interval={!showAdModel ? 0 : 3000}
              >
                <CarouselIndicators
                  items={ads}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {ads.map((item) => (
                  <CarouselItem
                    className="custom-tag"
                    tag="div"
                    key={item.key}
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    <div
                      style={{
                        background: `url(${item?.Feed_Documents[0]?.document_url}) no-repeat`,
                        backgroundSize: "cover",
                        height: "232px",
                      }}
                    >
                      <div className="ad-black-shade">
                        <div className="ad-content">
                          <p className="ad-desc">
                            {item?.campaignDetails?.name}
                          </p>
                          <p
                            className="ad-link"
                            onClick={() => {
                              window.open(item?.campaignDetails?.action_link);
                              postViewCounter(item?.campaignDetails?.id);
                            }}
                          >
                            {item?.campaignDetails?.action_link_label}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
                {/* <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              /> */}
              </Carousel>
            </div>
            {showAdModel && (
              <WhyAdModel
                modal={showAdModel}
                toggle={() => setShowAdModel(!showAdModel)}
                activeAd={ads[activeAd]}
              />
            )}
            {console.log(ads[activeAd])}
            {reportModel && (
              <ReportModal
                modal={reportModel}
                toggle={() => setReportModal(!reportModel)}
                reportType={"campaign"}
                reportTypeId={ads[activeAd]?.campaignDetails?.id}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
