import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getUnfollowedUserList } from "../../../store/people/peopleActions";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { Link, useNavigate } from "react-router-dom";

const PeopleYouUnfolloed = (props) => {
  const { modal, toggle } = props;
  const dispatch = useDispatch();
  const { unfollowed_users } = useSelector((state) => state.people);
  useEffect(() => {
    dispatch(getUnfollowedUserList()).then((res) => console.log("res", res));
  }, []);

  console.log("unfollowed_users", unfollowed_users);

  const navigate = useNavigate();

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              People You Unfollowed
            </ModalHeader>
            <ModalBody>
              <div style={{ minHeight: "250px", marginTop: "10px" }}>
                {unfollowed_users?.rows?.length > 0 ? (
                  unfollowed_users?.rows?.map((item, index) => (
                    <div
                      className="d-flex gap-3 mb-3 align-items-center"
                      key={index}
                      onClick={() =>
                        navigate(`/profile/${item?.following?.id}`)
                      }
                    >
                      <img
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                        src={item?.following?.profile_img || placeholderUser}
                        alt="prof"
                      />

                      <h5>
                        {item?.following?.first_name}{" "}
                        {item?.following?.last_name}
                      </h5>
                    </div>
                  ))
                ) : (
                  <h5 className="text-center">No Data Found</h5>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PeopleYouUnfolloed;
