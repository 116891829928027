// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useSelector } from "react-redux";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBifW1dIzc_pbZ3Ydq_adlX3QyzQR2CQiI",
  authDomain: "noble-page.firebaseapp.com",
  projectId: "noble-page",
  storageBucket: "noble-page.firebasestorage.app",
  messagingSenderId: "599545149960",
  appId: "1:599545149960:web:84afc1ff770a2d935cefbd",
  measurementId: "G-FVP1P4B5BB",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Messaging service
export const messaging = getMessaging(firebaseApp);

export const getTokenFirebase = async () => {
  return getToken(messaging, {
    vapidKey:
      "BPxt9aMLzBICv-e28zrtu7IpI3lceRGz9IWGGOfrlr07VNVEO4hxV6fK6gubEbpaNsaZYHYrzeqofFk8gBbR2b8",
  })
    .then((currentToken) => {
      console.log("currentToken", currentToken);

      if (currentToken) {
        localStorage.setItem("firebase_token", currentToken);
        return currentToken;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log("firebase error", err);
      throw err;
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
