import React from "react";
import adBarImage from "../../../../../assets/img/adbar.png";

const AdBarPreview = ({ name, label, selectedFeed, campaignDetail }) => {
  console.log(campaignDetail);
  return (
    <div className="adbar-preview ">
      <h5> Ad Bar Preview</h5>
      <div className="advertisement-side m-3">
        <div className="bg-white" style={{ borderRadius: "5px" }}>
          <div className="row p-1 px-4">
            <div className="col-6">
              <h6>Ad</h6>
            </div>
            {/* <div className="col-6">
              <div
                className="float-end"
                onClick={() => {
                  alert(
                    "This feature will be implemented in the future milestone"
                  );
                }}
              >
                <span>&bull;</span>
                <span>&bull;</span>
                <span>&bull;</span>
              </div>
            </div> */}
          </div>

          <div
            className="ad-image"
            style={{
              backgroundImage:
                campaignDetail?.type === "page"
                  ? selectedFeed?.icon
                    ? `url(${selectedFeed?.icon})`
                    : `url(${adBarImage})`
                  : selectedFeed?.Feed_Documents?.length > 0
                  ? `url(${selectedFeed?.Feed_Documents[0]?.document_url})`
                  : `url(${adBarImage})`,
              backgroundSize: "cover",
            }}
          >
            <div className="bottoms">
              <h4 className="card-control-text-twoline">
                {name?.length > 25 ? name?.slice(0, 25) + "..." : name}
              </h4>
              <button>{label}</button>
              <div
                className="dots"
                style={{ background: "none", height: "33px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdBarPreview;
