import React from "react";
import "../network.scss";
import Layout from "../Layout/Layout";
import moment from "moment";
import CreateNewsletterModal from "./components/CreateNewsletter";
import NewsletterSuccessfulModal from "./components/newsletterSuccessfulModal";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import nopreview from "../../../assets/img/no-preview.png";
import { Link } from "react-router-dom";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import Loader from "../../../core/components/Loaders/ComponentLoader";
import ChangePageModel from "../../dashboard/components/ChoosePageModel";

export default function Newsletter(props) {
  const {
    isPageLoading,
    createModal,
    setCreateModal,
    filterSearchData,
    searchKey,
    setSearchKey,
    updatedNewsletter,
    tabs,
    setTabs,
    subscribeHandle,
    readMore,
    newSubscribedNewsletter,
    newMyNewsletter,
    newDrafts,
    createMarkup,
    successfulStatus,
    popupShareData,
    successModal,
    setSuccessModal,
    UnsubscribeHandle,
    navigate,
    currentCount,
    loadMoreData,
    totalCount,
    user,
    getPageDetailData,
  } = props;

  const handleKeyDown = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      filterSearchData(searchKey);
      console.log(searchKey);
    }
  };

  return (
    <>
      {/* {isPageLoading && <AppLoader />} */}
      <Layout>
        <div className="col-lg-9">
          <div className="main-center mt-4">
            <div className="search">
              <input
                type="text"
                placeholder="Search Newsletter"
                // onChange={(e) => setSearchKey(e.target.value)}
                // onChange={(e) => setSearchKey(e?.target?.value ?? '')}
                onChange={(e) => setSearchKey(e?.target?.value)}
                onKeyDown={handleKeyDown}
              />
              <div
                className="search-icon"
                onClick={() => filterSearchData(searchKey)}
              ></div>
            </div>
            {isPageLoading && (
              <div className="d-flex justify-content-center align-items-center">
                <AppLoader styleProps={{ position: "fixed", left: "60%" }} />
              </div>
            )}
            <div className="wrapper mt-20">
              <div className="container p-3">
                <div className="overflow">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        onClick={() => setTabs("suggested_newsletter")}
                        href="#suggested_newsletter"
                      >
                        Suggested Newsletters
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        onClick={() => setTabs("subscribed_newsletter")}
                        href="#subscribed_newsletter"
                      >
                        Subscribed Newsletters
                      </a>
                    </li>
                    {user?.data?.membership?.name == "Starter" ? (
                      <></>
                    ) : (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          onClick={() => setTabs("my_newsletter")}
                          href="#my_newsletter"
                        >
                          My Newsletters
                        </a>
                      </li>
                    )}
                    {user?.data?.membership?.name == "Starter" ? (
                      <></>
                    ) : (
                      <li className="nav-item">
                        <div
                          className="end"
                          onClick={() => {
                            if (getPageDetailData?.data?.Pages?.length > 0) {
                              setCreateModal(!createModal);
                            } else {
                              toast.error(
                                "You need to create a page for creating a newsletter",
                                { position: "bottom-left" }
                              );
                            }
                          }}
                        >
                          + Create Newsletter
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="tab-content mt-20">
                  <div
                    id="suggested_newsletter"
                    className="container tab-pane active"
                  >
                    <InfiniteScroll
                      // dataLength={totalCount}
                      dataLength={
                        updatedNewsletter?.length
                          ? updatedNewsletter?.length
                          : 0
                      }
                      next={loadMoreData}
                      hasMore={
                        totalCount === 0
                          ? true
                          : totalCount <= updatedNewsletter?.length
                          ? false
                          : true
                      }
                      //  hasMore={updatedNewsletter?.length<totalCount}

                      loader={
                        updatedNewsletter ? (
                          <b>
                            {/* <p style={{ textAlign: "center" }}>Loading...</p> */}
                            <p style={{ textAlign: "center" }}></p>
                          </b>
                        ) : (
                          <b>
                            <p style={{ textAlign: "center" }}>
                              Nothing found!
                            </p>
                          </b>
                        )
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      <div className="row">
                        {updatedNewsletter.map((data) => {
                          return (
                            <div
                              className="col-lg-6 col-md-6 mt-2"
                              key={data.id}
                            >
                              <div className="card overflow-hidden card-height-article">
                                {/* <Link to={`/profile/${data?.user?.user_id}`}> */}
                                <Link to={`/newsletterdetail/${data.id}`}>
                                  <div className="img card-image-square">
                                    {data?.media ? (
                                      <img
                                        src={data?.media || nopreview}
                                        className="card-image-square"
                                        alt="newsletter-card"
                                      />
                                    ) : (
                                      <img alt="" />
                                    )}
                                  </div>
                                </Link>

                                {/* </Link> */}
                                <div className="card-header">
                                  <div className="d-flex align-items-center gap-3">
                                    {/* <div
                                      className="profile-img"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          data?.user?.profile_img
                                            ? data?.user?.profile_img
                                            : placeholderUser
                                        }
                                        alt=""
                                        className="image-fit"
                                        style={{ width: "36px" }}
                                      />
                                    </div> */}

                                    <div className="profile-img">
                                      <Link
                                        to={`/profile/${data?.user?.user_id}`}
                                      >
                                        <img
                                          src={
                                            data?.user?.profile_img ||
                                            placeholderUser
                                          }
                                          alt=""
                                          className="image-fit"
                                          style={{ width: "36px" }}
                                        />
                                      </Link>
                                    </div>
                                    <span
                                      className="name card-control-text-oneline"
                                      onClick={() =>
                                        navigate(
                                          `/profile/${data?.user?.user_id}`
                                        )
                                      }
                                    >
                                      {data?.user?.first_name}{" "}
                                      {data?.user?.last_name}
                                    </span>
                                  </div>
                                  <div className="date">
                                    <div className="d-flex">
                                      <span className="calender-icon"></span>
                                      <div className="days ml-5">
                                        {" "}
                                        <span>
                                          {moment(data?.createdAt).format(
                                            "ddd, MMM DD, YYYY"
                                          )}{" "}
                                          at{" "}
                                          {moment(data?.createdAt).format(
                                            "hh:mm A z"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    {/* {data?.subscribed?.status ? (
                                    <div className="subscribe">Subscribed</div>
                                  ) : (
                                    <div
                                      className="subscribe"
                                      onClick={() => {
                                        subscribeHandle(data.id);
                                      }}
                                    >
                                      Subscribe
                                    </div>
                                  )} */}
                                    {data?.subscribed?.status ? (
                                      <div
                                        className="subscribe"
                                        onClick={() => {
                                          UnsubscribeHandle(data?.id);
                                        }}
                                      >
                                        Unsubscribe
                                      </div>
                                    ) : (
                                      <div
                                        className="subscribe"
                                        onClick={() => {
                                          subscribeHandle(data?.id);
                                        }}
                                      >
                                        Subscribe
                                      </div>
                                    )}
                                  </div>
                                  <h3 className="card-control-text-twoline">
                                    {data.title}
                                  </h3>
                                </div>
                                <div className="card-body">
                                  <p>
                                    {data?.content && (
                                      <div
                                        className="card-control-text-twoline"
                                        dangerouslySetInnerHTML={createMarkup(
                                          data?.content
                                        )}
                                      ></div>
                                    )}
                                  </p>
                                  <div
                                    className="read "
                                    onClick={() => {
                                      readMore(data.id);
                                    }}
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",

                                      cursor: "pointer",
                                    }}
                                  >
                                    Read newsletter
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div
                    id="subscribed_newsletter"
                    className="container tab-pane fade"
                  >
                    <InfiniteScroll
                      dataLength={totalCount}
                      next={loadMoreData}
                      // hasMore={
                      //   totalCount === 0
                      //     ? true
                      //     : totalCount >= currentCount
                      //     ? true
                      //     : false
                      // }

                      hasMore={newSubscribedNewsletter?.length < totalCount}

                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row">
                        {newSubscribedNewsletter?.length !== 0 ? (
                          newSubscribedNewsletter.map((data) => {
                            return (
                              <div
                                className="col-lg-6 col-md-6 mt-2"
                                key={data?.id}
                              >
                                <div className="card overflow-hidden card-height-article">
                                  {/* <Link to={`/profile/${data?.user?.user_id}`}> */}
                                  <div className="img card-image-square">
                                    {data?.media ? (
                                      <img
                                        src={data?.media || nopreview}
                                        className="card-image-square"
                                        alt="newsletter-card"
                                      />
                                    ) : (
                                      <img alt="" />
                                    )}
                                  </div>
                                  {/* </Link> */}

                                  <div className="card-header">
                                    <div className="d-flex align-items-center gap-3">
                                      <div
                                        className="profile-img"
                                        onClick={() =>
                                          navigate(
                                            `/profile/${data?.user?.user_id}`
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            data?.user?.profile_img
                                              ? data?.user?.profile_img
                                              : placeholderUser
                                          }
                                          alt=""
                                          className="image-fit"
                                          style={{ width: "36px" }}
                                        />
                                      </div>
                                      <span
                                        className="name card-control-text-oneline"
                                        onClick={() =>
                                          navigate(
                                            `/profile/${data?.user?.user_id}`
                                          )
                                        }
                                      >
                                        {data?.user?.first_name}{" "}
                                        {data?.user?.last_name}
                                      </span>
                                    </div>
                                    <div className="date">
                                      <div className="d-flex">
                                        <span className="calender-icon"></span>
                                        <div className="days ml-5">
                                          {" "}
                                          <span>
                                            {moment(data?.createdAt).format(
                                              "ddd, MMM DD, YYYY"
                                            )}{" "}
                                            at{" "}
                                            {moment(data?.createdAt).format(
                                              "hh:mm A z"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      {data?.subscribed?.status ? (
                                        <div
                                          className="subscribe"
                                          onClick={() => {
                                            UnsubscribeHandle(data?.id);
                                          }}
                                        >
                                          Unsubscribe
                                        </div>
                                      ) : (
                                        <div
                                          className="subscribe"
                                          onClick={() => {
                                            subscribeHandle(data?.id);
                                          }}
                                        >
                                          Subscribe
                                        </div>
                                      )}
                                    </div>
                                    <h3 className="card-control-text-twoline">
                                      {data?.title}
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    <p>
                                      {data?.content && (
                                        <div
                                          className="card-control-text-twoline"
                                          dangerouslySetInnerHTML={createMarkup(
                                            data?.content
                                          )}
                                        ></div>
                                      )}
                                    </p>
                                    <div
                                      className="read"
                                      onClick={() => {
                                        readMore(data?.id);
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "10px",

                                        cursor: "pointer",
                                      }}
                                    >
                                      Read newsletter
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="row ">
                            <p
                              style={{ textAlign: "center" }}
                              className="my-30"
                            >
                              <b>Not any newsletter subscribed</b>
                            </p>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                  <div id="my_newsletter" className="container tab-pane fade">
                    <InfiniteScroll
                      dataLength={totalCount}
                      next={loadMoreData}
                      // hasMore={
                      //   totalCount === 0
                      //     ? true
                      //     : totalCount <= currentCount
                      //     ? false
                      //     : true
                      // }

                      hasMore={newMyNewsletter?.length < totalCount}

                      // loader={<h4>Loading...</h4>}
                      // endMessage={
                      //   <p style={{ textAlign: "center" }}>
                      //     <b>Yay! You have seen it all</b>
                      //   </p>
                      // }
                    >
                      <div className="row mb-6">
                        <div className="col-12">
                          <div
                            className={
                              tabs === "my_newsletter"
                                ? "btn blue-btn"
                                : "btn border-btn"
                            }
                            onClick={() => setTabs("my_newsletter")}
                          >
                            My Published Newsletters
                          </div>
                          <div
                            className={
                              tabs === "my_drafts"
                                ? "btn blue-btn"
                                : "btn border-btn"
                            }
                            onClick={() => setTabs("my_drafts")}
                          >
                            Draft
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {tabs === "my_newsletter" ? (
                          newMyNewsletter?.length !== 0 ? (
                            newMyNewsletter.map((data) => {
                              return (
                                <div
                                  className="col-lg-6 col-md-6 mt-20"
                                  key={data.id}
                                >
                                  <div className="card overflow-hidden card-height-article">
                                    {/* <Link
                                      to={`/profile/${data?.user?.user_id}`}
                                    > */}
                                    <div className="img card-image-square">
                                      {data?.media ? (
                                        <img
                                          src={
                                            data?.media
                                              ? data?.media
                                              : nopreview
                                          }
                                          className="card-image-square"
                                          alt="newsletter-card"
                                        />
                                      ) : (
                                        <img alt="" />
                                      )}
                                    </div>
                                    {/* </Link> */}
                                    <div className="card-header">
                                      <div className="d-flex align-items-center gap-3">
                                        <div
                                          className="profile-img"
                                          onClick={() =>
                                            navigate(
                                              `/profile/${data?.user?.user_id}`
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              data?.user?.profile_img
                                                ? data?.user?.profile_img
                                                : placeholderUser
                                            }
                                            alt=""
                                            className="image-fit"
                                            style={{ width: "36px" }}
                                          />
                                        </div>
                                        <span
                                          className="name card-control-text-oneline"
                                          onClick={() =>
                                            navigate(
                                              `/profile/${data?.user?.user_id}`
                                            )
                                          }
                                        >
                                          {data?.user?.first_name}{" "}
                                          {data?.user?.last_name}
                                        </span>
                                      </div>
                                      <div className="date">
                                        <div className="d-flex">
                                          <span className="calender-icon"></span>
                                          <div className="days ml-5">
                                            {" "}
                                            <span>
                                              {moment(data?.createdAt).format(
                                                "ddd, MMM DD, YYYY"
                                              )}{" "}
                                              at{" "}
                                              {moment(data?.createdAt).format(
                                                "hh:mm A z"
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        {data?.subscribed?.status ? (
                                          <div
                                            className="subscribe"
                                            onClick={() => {
                                              UnsubscribeHandle(data?.id);
                                            }}
                                          >
                                            Unsubscribe
                                          </div>
                                        ) : (
                                          <div
                                            className="subscribe"
                                            onClick={() => {
                                              subscribeHandle(data?.id);
                                            }}
                                          >
                                            Subscribe
                                          </div>
                                        )}
                                      </div>
                                      <h3 className="card-control-text-twoline">
                                        {data?.title}
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      <p>
                                        {data?.content && (
                                          <div
                                            className="card-control-text-twoline"
                                            dangerouslySetInnerHTML={createMarkup(
                                              data?.content
                                            )}
                                          ></div>
                                        )}
                                      </p>
                                      <div
                                        className="read"
                                        onClick={() => {
                                          readMore(data?.id);
                                        }}
                                        style={{
                                          position: "absolute",
                                          bottom: "10px",

                                          cursor: "pointer",
                                        }}
                                      >
                                        Read newsletter
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="row ">
                              <p
                                style={{ textAlign: "center" }}
                                className="my-30"
                              >
                                {!isPageLoading && <b>No newsletter found</b>}
                              </p>
                            </div>
                          )
                        ) : newDrafts?.length !== 0 ? (
                          newDrafts.map((data) => {
                            return (
                              <div
                                className="col-lg-6 col-md-6 mt-20"
                                key={data.id}
                              >
                                <div className="card overflow-hidden card-height-article">
                                  <div className="img card-image-square">
                                    {data?.media ? (
                                      <img
                                        src={
                                          data?.media ? data?.media : nopreview
                                        }
                                        className="card-image-square"
                                        alt="newsletter-card"
                                      />
                                    ) : (
                                      <img alt="" />
                                    )}
                                  </div>
                                  <div className="card-header">
                                    <div className="d-flex align-items-center gap-3">
                                      <div
                                        className="profile-img"
                                        onClick={() =>
                                          navigate(
                                            `/profile/${data?.user?.user_id}`
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            data?.user?.profile_img
                                              ? data?.user?.profile_img
                                              : placeholderUser
                                          }
                                          alt=""
                                          className="image-fit"
                                          style={{ width: "36px" }}
                                        />
                                      </div>
                                      <span
                                        className="name card-control-text-oneline"
                                        onClick={() =>
                                          navigate(
                                            `/profile/${data?.user?.user_id}`
                                          )
                                        }
                                      >
                                        {data?.user?.first_name}{" "}
                                        {data?.user?.last_name}
                                      </span>
                                    </div>
                                    <div className="date">
                                      <div className="d-flex">
                                        <span className="calender-icon"></span>
                                        <div className="days ml-5">
                                          {" "}
                                          <span>
                                            {moment(data?.createdAt).format(
                                              "ddd, MMM DD, YYYY"
                                            )}{" "}
                                            at{" "}
                                            {moment(data?.createdAt).format(
                                              "hh:mm A z"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      {/* {data?.subscribed?.status ? (
                                    <div className="subscribe">Subscribed</div>
                                  ) : (
                                    <div
                                      className="subscribe"
                                      onClick={() => {
                                        subscribeHandle(data?.id);
                                      }}
                                    >
                                      Subscribe
                                    </div>
                                  )} */}
                                    </div>
                                    <h3 className="card-control-text-twoline">
                                      {data?.title}
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    <p>
                                      {data?.content && (
                                        <div
                                          className="card-control-text-twoline"
                                          dangerouslySetInnerHTML={createMarkup(
                                            data?.content
                                          )}
                                        ></div>
                                      )}
                                    </p>
                                    <div
                                      className="read"
                                      onClick={() => {
                                        readMore(data?.id);
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "10px",

                                        cursor: "pointer",
                                      }}
                                    >
                                      <b> Read newsletter</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="row ">
                            <p
                              style={{ textAlign: "center" }}
                              className="my-30"
                            >
                              {!isPageLoading && <b>No newsletter found</b>}
                            </p>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        {createModal && (
          <ChangePageModel
            modal={createModal}
            toggle={() => setCreateModal(!createModal)}
            title="Choose an option"
            getPageDetailData={getPageDetailData}
          />
        )}
        {successModal && (
          <NewsletterSuccessfulModal
            modal={successModal}
            toggle={() => setSuccessModal(!successModal)}
            title=""
            shareData={popupShareData}
          />
        )}
      </Layout>
    </>
  );
}
