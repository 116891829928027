import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import SearchSettings from "../components/SearchSettings";

const Advertisement = (props) => {
  const AccountSearchList = [
    { id: "1", name: "Profile data" },
    { id: "2", name: "Connections" },
    { id: "3", name: "Location" },
    { id: "4", name: "Demograpics" },
    { id: "5", name: "Companies you follow" },
    { id: "6", name: "Groups" },
    { id: "7", name: "Events" },
    { id: "8", name: "Education" },
    { id: "9", name: "Job information" },
    { id: "10", name: "Employer" },
    { id: "11", name: "Ads beyond NobelPage" },
    { id: "12", name: "Interaction with other businesses" },
    { id: "13", name: "Ad related actions" },
    {
      id: "14",
      name: "Sharing profile data to recruiters other than NobelPage",
    },
    {
      id: "15",
      name: "Sharing profile data to 3rd party company for background check",
    },
  ];
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [results, setResults] = useState([]);
  const { isActive } = props;
  const { settingData } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [modalOpen7, setModalOpen7] = useState(false);
  const [modalOpen8, setModalOpen8] = useState(false);
  const [modalOpen9, setModalOpen9] = useState(false);
  const [modalOpen10, setModalOpen10] = useState(false);
  const [modalOpen11, setModalOpen11] = useState(false);
  const [modalOpen12, setModalOpen12] = useState(false);
  const [profileSettings, setProfileSettings] = useState([]);
  const [educationSetting, setEducationSetting] = useState([]);
  const [connectionSetting, setConnectionSetting] = useState([]);
  const [locationSetting, setLocationSetting] = useState([]);
  const [demoSetting, setDemoSetting] = useState([]);
  const [companySetting, setCompanySetting] = useState([]);
  const [groupSetting, setGroupSetting] = useState([]);
  const [jobSetting, setJobSetting] = useState([]);
  const [employerSetting, setEmployerSetting] = useState([]);
  const [adSetting, setAdSetting] = useState([]);
  const [interactionSetting, setInteractionSetting] = useState([]);
  const [relatedSetting, setRelatedSetting] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { target } = e;
    if (!target.value.trim()) return setResults([]);

    const filteredValue = AccountSearchList.filter((AccountSearchList) =>
      AccountSearchList.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  useEffect(() => {
    if (selectedProfile.id === "1") {
      setModalOpen1(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "2") {
      setModalOpen2(true);
      setDefaultValue("");
    }
    if (selectedProfile.id === "3") {
      setModalOpen3(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "4") {
      setModalOpen4(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "5") {
      setModalOpen5(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "6") {
      setModalOpen6(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "7") {
      navigate("/membership", { state: { isAddOns: true } });
      setDefaultValue("");
    }

    if (selectedProfile.id === "8") {
      setModalOpen7(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "9") {
      setModalOpen8(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "10") {
      setModalOpen9(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "11") {
      setModalOpen10(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "12") {
      setModalOpen11(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "13") {
      setModalOpen12(true);
      setDefaultValue("");
    }

    if (selectedProfile.id === "14") {
      navigate("/membership", { state: { isAddOns: true } });
      setDefaultValue("");
    }

    if (selectedProfile.id === "15") {
      navigate("/membership", { state: { isAddOns: true } });
      setDefaultValue("");
    }
  }, [selectedProfile]);

  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };

  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const toggleModal6 = () => {
    setModalOpen6(!modalOpen6);
  };

  const toggleModal7 = () => {
    setModalOpen7(!modalOpen7);
  };

  const toggleModal8 = () => {
    setModalOpen8(!modalOpen8);
  };

  const toggleModal9 = () => {
    setModalOpen9(!modalOpen9);
  };

  const toggleModal10 = () => {
    setModalOpen10(!modalOpen10);
  };

  const toggleModal11 = () => {
    setModalOpen11(!modalOpen11);
  };

  const toggleModal12 = () => {
    setModalOpen12(!modalOpen12);
  };

  const handleProfileChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = profileSettings.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setProfileSettings(updatedArray);
  };

  const handleProfileClose = () => {
    setModalOpen1(false);
    setProfileSettings(settingData?.data[33]?.settings);
  };

  const handleProfileSave = () => {
    let updatedItems = [];

    for (let i = 0; i < profileSettings?.length; i++) {
      let myObj = {
        setting_master_id: profileSettings[i].id,
        value: profileSettings[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen1(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Profile Data Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleConnectionChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = connectionSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setConnectionSetting(updatedArray);
  };

  const handleConnectionClose = () => {
    setModalOpen2(false);
    setConnectionSetting(settingData?.data[34]?.settings);
  };

  const handleConnectionSave = () => {
    let updatedItems = [];

    for (let i = 0; i < connectionSetting?.length; i++) {
      let myObj = {
        setting_master_id: connectionSetting[i].id,
        value: connectionSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen2(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Connection Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleLocationChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = locationSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setLocationSetting(updatedArray);
  };

  const handleLocationClose = () => {
    setModalOpen3(false);
    setLocationSetting(settingData?.data[35]?.settings);
  };

  const handleLocationSave = () => {
    let updatedItems = [];

    for (let i = 0; i < locationSetting?.length; i++) {
      let myObj = {
        setting_master_id: locationSetting[i].id,
        value: locationSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen3(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Location Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleDemoChange = (value, target) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = demoSetting.map((obj, i) => {
      if (target == i) {
        return { ...obj, value: targetValue };
      } else {
        return { ...obj };
      }
    });
    setDemoSetting(updatedArray);
  };

  const handleDemoClose = () => {
    setModalOpen4(false);
    setDemoSetting(settingData?.data[36]?.settings);
  };

  const handleDemoSave = () => {
    let updatedItems = [];

    for (let i = 0; i < demoSetting?.length; i++) {
      let myObj = {
        setting_master_id: demoSetting[i].id,
        value: demoSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen4(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Demographic Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleCompanyChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = companySetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setCompanySetting(updatedArray);
  };

  const handleCompanyClose = () => {
    setModalOpen5(false);
    setCompanySetting(settingData?.data[37]?.settings);
  };

  const handleCompanySave = () => {
    let updatedItems = [];

    for (let i = 0; i < companySetting?.length; i++) {
      let myObj = {
        setting_master_id: companySetting[i].id,
        value: companySetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen5(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Companies you follow Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleGroupChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = groupSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setGroupSetting(updatedArray);
  };

  const handleGroupClose = () => {
    setModalOpen6(false);
    setGroupSetting(settingData?.data[38]?.settings);
  };

  const handleGroupSave = () => {
    let updatedItems = [];

    for (let i = 0; i < groupSetting?.length; i++) {
      let myObj = {
        setting_master_id: groupSetting[i].id,
        value: groupSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen6(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Groups Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleEducationChange = (targetCheck, value) => {
    let targetArr = educationSetting[0]?.value.split(",");

    if (targetCheck) {
      targetArr.push(value);
    } else {
      targetArr = targetArr.filter((item) => item !== value);
    }

    const updatedArray = educationSetting.map((obj, i) => {
      return { ...obj, value: targetArr.join(",") };
    });
    setEducationSetting(updatedArray);
  };

  const handleEducationClose = () => {
    setModalOpen7(false);
    setEducationSetting(settingData?.data[39]?.settings);
  };

  const handleEducationSave = () => {
    let updatedItems = [];

    for (let i = 0; i < educationSetting?.length; i++) {
      let myObj = {
        setting_master_id: educationSetting[i].id,
        value: educationSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen7(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Education Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleJobChange = (targetCheck, value) => {
    let targetArr = jobSetting[0]?.value.split(",");

    if (targetCheck) {
      targetArr.push(value);
    } else {
      targetArr = targetArr.filter((item) => item !== value);
    }

    const updatedArray = jobSetting.map((obj, i) => {
      return { ...obj, value: targetArr.join(",") };
    });
    setJobSetting(updatedArray);
  };

  const handleJobClose = () => {
    setModalOpen8(false);
    setJobSetting(settingData?.data[40]?.settings);
  };

  const handleJobSave = () => {
    let updatedItems = [];

    for (let i = 0; i < jobSetting?.length; i++) {
      let myObj = {
        setting_master_id: jobSetting[i].id,
        value: jobSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen8(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Job information Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleEmployerChange = (targetCheck, value) => {
    let targetArr = employerSetting[0]?.value.split(",");

    if (targetCheck) {
      targetArr.push(value);
    } else {
      targetArr = targetArr.filter((item) => item !== value);
    }

    const updatedArray = employerSetting.map((obj, i) => {
      return { ...obj, value: targetArr.join(",") };
    });
    setEmployerSetting(updatedArray);
  };

  const handleEmployerClose = () => {
    setModalOpen9(false);
    setEmployerSetting(settingData?.data[41]?.settings);
  };

  const handleEmployerSave = () => {
    let updatedItems = [];

    for (let i = 0; i < employerSetting?.length; i++) {
      let myObj = {
        setting_master_id: employerSetting[i].id,
        value: employerSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen9(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Employer Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleAdChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = adSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setAdSetting(updatedArray);
  };

  const handleAdClose = () => {
    setModalOpen10(false);
    setAdSetting(settingData?.data[42]?.settings);
  };

  const handleAdSave = () => {
    let updatedItems = [];

    for (let i = 0; i < adSetting?.length; i++) {
      let myObj = {
        setting_master_id: adSetting[i].id,
        value: adSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen10(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Ads beyond NobelPage Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleInteractionChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = interactionSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setInteractionSetting(updatedArray);
  };

  const handleInteractionClose = () => {
    setModalOpen11(false);
    setInteractionSetting(settingData?.data[43]?.settings);
  };

  const handleInteractionSave = () => {
    let updatedItems = [];

    for (let i = 0; i < interactionSetting?.length; i++) {
      let myObj = {
        setting_master_id: interactionSetting[i].id,
        value: interactionSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen11(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Interaction Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  const handleRelatedChange = (value) => {
    let targetValue;
    if (value) {
      targetValue = "1";
    } else {
      targetValue = "0";
    }

    const updatedArray = relatedSetting.map((obj, i) => {
      return { ...obj, value: targetValue };
    });
    setRelatedSetting(updatedArray);
  };

  const handleRelatedClose = () => {
    setModalOpen12(false);
    setRelatedSetting(settingData?.data[44]?.settings);
  };

  const handleRelatedSave = () => {
    let updatedItems = [];

    for (let i = 0; i < relatedSetting?.length; i++) {
      let myObj = {
        setting_master_id: relatedSetting[i].id,
        value: relatedSetting[i]?.value,
      };
      updatedItems.push(myObj);
    }

    let updateData = {
      setting: updatedItems,
    };

    setModalOpen12(false);
    dispatch(updateSettingDetails(updateData, token)).then((res) => {
      dispatch(getUserSetting(0, token));
      if (res.success) {
        toast.success("Ad related action Settings updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "visibility",
        });
      }
    });
  };

  useEffect(() => {
    if (settingData) {
      setProfileSettings(settingData?.data[33]?.settings);
      setConnectionSetting(settingData?.data[34]?.settings);
      setLocationSetting(settingData?.data[35]?.settings);
      setDemoSetting(settingData?.data[36]?.settings);
      setCompanySetting(settingData?.data[37]?.settings);
      setGroupSetting(settingData?.data[38]?.settings);
      setEducationSetting(settingData?.data[39]?.settings);
      setJobSetting(settingData?.data[40]?.settings);
      setEmployerSetting(settingData?.data[41]?.settings);
      setAdSetting(settingData?.data[42]?.settings);
      setInteractionSetting(settingData?.data[43]?.settings);
      setRelatedSetting(settingData?.data[44]?.settings);
    }
  }, [settingData]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="heading">
            <h1>Advertisement</h1>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="search">
            <SearchSettings
              results={results}
              value={selectedProfile?.name}
              renderItem={(item) => <p>{item.name}</p>}
              onChange={handleChange}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              onSelect={(item) => setSelectedProfile(item)}
            />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="profile-information">
          <h4>Share Profile Data</h4>
          <ul>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal1()}
            >
              Profile data
            </li>
          </ul>
        </div>
        <div className="line"></div>
        <div className="profile-information">
          <div className="d-flex justify-content-between switch-btn">
            <h4>Data Collection for Ads on NobelPage</h4>
            <div className="switch-box">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <ul>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal2()}
            >
              Connections
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal3()}
            >
              Location
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal4()}
            >
              Demographics
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal5()}
            >
              Companies you follow
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal6()}
            >
              Groups
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() =>
                navigate("/membership", { state: { isAddOns: true } })
              }
            >
              Events
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal7()}
            >
              Education
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal8()}
            >
              Job information
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal9()}
            >
              Employer
            </li>
          </ul>
        </div>
        <div className="line"></div>
        <div className="profile-information">
          <h4>Third Party Data</h4>
          <ul>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal10()}
            >
              Ads beyond NobelPage
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal11()}
            >
              Interaction with other businesses
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() => toggleModal12()}
            >
              Ad related actions
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() =>
                navigate("/membership", { state: { isAddOns: true } })
              }
            >
              Sharing profile data to recruiters other than NobelPage
            </li>
            <li
              className={isActive ? "active" : null}
              onClick={() =>
                navigate("/membership", { state: { isAddOns: true } })
              }
            >
              Sharing profile data to 3rd party company for background check
            </li>
          </ul>
        </div>
      </div>
      <Modal
        isOpen={modalOpen1}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleProfileClose}>
          <h4>Profile data</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Profile data on ads</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        profileSettings?.length &&
                          parseInt(profileSettings[0]?.value)
                      )}
                      onChange={(e) => handleProfileChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleProfileClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleProfileSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen2}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleConnectionClose}>
          <h4>Connections</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing and
            localizing relevant connections.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Use connections data</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        connectionSetting?.length &&
                          parseInt(connectionSetting[0]?.value)
                      )}
                      onChange={(e) => handleConnectionChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleConnectionClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleConnectionSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen3}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleLocationClose}>
          <h4>Location</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing and
            localizing your profile feed.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Use Location data</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        locationSetting?.length &&
                          parseInt(locationSetting[0]?.value)
                      )}
                      onChange={(e) => handleLocationChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleLocationClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleLocationSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen4}
        className="last-name-radio-list-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleDemoClose}>
          <h4>Demographics</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="d-flex align-items-center justify-content-between mt-4">
            <h6 className="gray-light400 mt-0">Use your age for ads</h6>
            <div className="switch-box">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={Boolean(
                    demoSetting?.length && parseInt(demoSetting[0]?.value)
                  )}
                  onChange={(e) => handleDemoChange(e.target.checked, 0)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <p className="gray-light400 mt-4">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="d-flex align-items-center justify-content-between mt-4">
            <h6 className="gray-light400 mt-0">Use your gender for ads</h6>
            <div className="switch-box">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={Boolean(
                    demoSetting?.length && parseInt(demoSetting[1]?.value)
                  )}
                  onChange={(e) => handleDemoChange(e.target.checked, 1)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleDemoClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleDemoSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen5}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleCompanyClose}>
          <h4>Companies you follow</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Use followed companies data</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        companySetting?.length &&
                          parseInt(companySetting[0]?.value)
                      )}
                      onChange={(e) => handleCompanyChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleCompanyClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleCompanySave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen6}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleGroupClose}>
          <h4>Groups</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Use groups data</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        groupSetting?.length && parseInt(groupSetting[0]?.value)
                      )}
                      onChange={(e) => handleGroupChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleGroupClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleGroupSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen7}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleEducationClose}>
          <h4>Education</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li className="custom-check">
                <input
                  type="checkbox"
                  checked={
                    educationSetting?.length &&
                    educationSetting[0]?.value.split(",").includes("1")
                  }
                  onChange={(e) => handleEducationChange(e.target.checked, "1")}
                />
                <span className="title-list">Schools</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    educationSetting?.length &&
                    educationSetting[0]?.value.split(",").includes("2")
                  }
                  onChange={(e) => handleEducationChange(e.target.checked, "2")}
                />
                <span className="title-list">Field of study</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    educationSetting?.length &&
                    educationSetting[0]?.value.split(",").includes("3")
                  }
                  onChange={(e) => handleEducationChange(e.target.checked, "3")}
                />
                <span className="title-list">Degrees</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    educationSetting?.length &&
                    educationSetting[0]?.value.split(",").includes("4")
                  }
                  onChange={(e) => handleEducationChange(e.target.checked, "4")}
                />
                <span className="title-list">Skills</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    educationSetting?.length &&
                    educationSetting[0]?.value.split(",").includes("5")
                  }
                  onChange={(e) => handleEducationChange(e.target.checked, "5")}
                />
                <span className="title-list">Last graduation year</span>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleEducationClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={() => handleEducationSave()}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen8}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleJobClose}>
          <h4>Job Information</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li className="custom-check">
                <input
                  type="checkbox"
                  checked={
                    jobSetting?.length &&
                    jobSetting[0]?.value.split(",").includes("1")
                  }
                  onChange={(e) => handleJobChange(e.target.checked, "1")}
                />
                <span className="title-list">Current Job</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    jobSetting?.length &&
                    jobSetting[0]?.value.split(",").includes("2")
                  }
                  onChange={(e) => handleJobChange(e.target.checked, "2")}
                />
                <span className="title-list">Past Jobs</span>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleJobClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleJobSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen9}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleEmployerClose}>
          <h4>Employer</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li className="custom-check">
                <input
                  type="checkbox"
                  checked={
                    employerSetting?.length &&
                    employerSetting[0]?.value.split(",").includes("1")
                  }
                  onChange={(e) => handleEmployerChange(e.target.checked, "1")}
                />
                <span className="title-list">Current company</span>
              </li>
              <li>
                <input
                  type="checkbox"
                  checked={
                    employerSetting?.length &&
                    employerSetting[0]?.value.split(",").includes("2")
                  }
                  onChange={(e) => handleEmployerChange(e.target.checked, "2")}
                />
                <span className="title-list">Past companies</span>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleEmployerClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleEmployerSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen10}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleAdClose}>
          <h4>Ads beyond NobelPage</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">Ads outside of NobelPage</span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        adSetting?.length && parseInt(adSetting[0]?.value)
                      )}
                      onChange={(e) => handleAdChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleAdClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleAdSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen11}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleInteractionClose}>
          <h4>Interaction with businesses</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">
                  Use data you've given to businesses
                </span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        interactionSetting?.length &&
                          parseInt(interactionSetting[0]?.value)
                      )}
                      onChange={(e) =>
                        handleInteractionChange(e.target.checked)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleInteractionClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleInteractionSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalOpen12}
        className="profile-data-modal setting-modal-default-modal"
      >
        <ModalHeader toggle={handleRelatedClose}>
          <h4>Ad-related actions</h4>
        </ModalHeader>
        <ModalBody>
          <p className="gray-light400 mt-0">
            Activate to enhance your user experience by customizing the below
            information.
          </p>
          <div className="checkbox-box mt-3">
            <ul>
              <li>
                <span className="title-list">
                  Use your data for ad insights
                </span>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Boolean(
                        relatedSetting?.length &&
                          parseInt(relatedSetting[0]?.value)
                      )}
                      onChange={(e) => handleRelatedChange(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="text-end"
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "10px",
            }}
          >
            <div className="btn btn-outline" onClick={handleRelatedClose}>
              Close
            </div>
            <div className="btn btn-blue" onClick={handleRelatedSave}>
              Save
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Advertisement;
