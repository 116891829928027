import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import FormInput from "../../../core/components/Inputs/FormInput";
import placeholder from "../../../assets/img/placeholder_user.jpg";
import { toast } from "react-toastify";
import { call } from "../../../api/apiCall";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { confirmPassTransferPage } from "../../../store/pages/pagesAction";
import { useNavigate } from "react-router-dom";

const HibernateAccountModal = (props) => {
  const { modal, toggle, title, data } = props;
  const { user } = useSelector((state) => state.auth);
  const { profileData } = useSelector((state) => state.profile);
  const [responseMsg, setResponseMsg] = useState("");
  const [value, setValue] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateValue = (event) => {
    setBtnStatus(true);
    setValue(event.target.value);
    setPassErrorMessage("");
  };
  const updateMode = async () => {
    try {
      let token = localStorage.getItem("accessToken");
      let updateData = {
        setting: [
          {
            setting_master_id: 24,
            value: value,
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        setResponseMsg(res.message);
        setTimeout(() => {
          setResponseMsg(false);
          toggle();
        }, 2000);
        alert("This feature will be implemented in the future milestone");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPass = () => {
    const temp = { password: value };
    dispatch(confirmPassTransferPage(temp)).then((res) => {
      if (res.success == true) {
        setCloseModal(true);
      }
    });
  };

  const handleHibernate = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const res = await call(
        "patch",
        `api/v1/user/hybernate-account`,
        null,
        null,
        token
      );
      if (res.data.success === true) {
        toast.success("You have hybernate your account.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else alert(res.data.message);
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
      return;
    }
  };

  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!value) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(value)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    confirmPass();
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                <p>
                  You will be logged off on all devices. Your account including
                  WorkerPool profile and related pages, groups will be hidden
                  for 30 days, log in to reactivate. After that, it will be
                  deactivated for 30 days and can only be reactivated via email
                  link or support. After 60 days, it will be permanently
                  deleted.
                </p>
                <div className="d-flex gap-4">
                  <div className="profile-img">
                    <img
                      src={profileData?.profile_img || placeholder}
                      className="image-fit rounded-circle"
                      style={{ width: "125px" }}
                      alt=""
                    />
                  </div>
                  <div className="pro">
                    <div className="text">
                      <h6 className="mt-2 text-dark">
                        {profileData?.first_name + " " + profileData?.last_name}
                      </h6>
                      <p>{profileData?.profile_headline}</p>
                    </div>

                    {/* <p>Please insert your password. </p> */}
                    <div className="position-relative mb-30">
                      <FormInput
                        type="password"
                        label={"Please insert your password."}
                        onChange={(e) => updateValue(e)}
                        placeholder="Password"
                        maxLength="24"
                        error={passErrorMessage}
                        containerClassName={
                          passErrorMessage ? "error-form" : ""
                        }
                      />
                    </div>
                    <div
                      className={
                        btnStatus ? "btn btn-blue" : "btn btn-blue disabledItem"
                      }
                      onClick={() => validation()}
                      disabled={btnStatus}
                    >
                      Hibernate Account
                    </div>
                  </div>
                </div>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>

      {closeModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setCloseModal(!closeModal)}
          title=" Hybernate Account Confirmation"
          deleteConfirm={() => {
            handleHibernate();
          }}
          text={"Are you sure? You want to hybernate this account."}
          transform={true}
        />
      )}
    </div>
  );
};

export default HibernateAccountModal;
