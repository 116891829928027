import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserSkill,
  getUserProfile,
  getProfileCount,
} from "../../../store/profile/profileActions";
import { useLocation, useParams } from "react-router-dom";
import * as html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Profile from "./Profile";
import moment from "moment";
import { sendConnectionRequest } from "../../../store/connections/connectionsActions";
import axios from "axios";
import {
  updateUserDetails,
  deleteVideo,
  profile_follower,
  profile_unfollower,
  public_posts_list,
  remove_connection,
} from "../../../store/profile/profileActions";
import { block_user } from "../../../store/blockUserList/blockedUsersActions";
import { getSuggestedArticles } from "../../../store/articles/articleActions";
import { getSuggestedPeopleList } from "../../../store/people/peopleActions";
import { postLead } from "../../../store/leads/leadsActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getScreeningRequest } from "../../../store/backgroundScreening/backgroundScreeningAction";

var sections = [
  {
    name: "Activity",
    route: "/activity",
  },
  {
    name: "Recommendations",
    route: "/recommendations",
  },
  {
    name: "Interests",
    route: "/interests",
  },
  {
    name: "Social Media Channel",
    route: "/socialmedia",
  },
];

export default function ProfileContainer() {
  const location = useLocation();
  const params = useParams();

  const { id } = useParams();
  const navigate = useNavigate();

  let pofileModal = location?.state?.pofileModal;
  let expModal = location?.state?.expModal;
  let eduModal = location?.state?.eduModal;
  let contactInfoModal = location?.state?.contactInfoModal;
  let bySearch = location?.state?.search || false;

  const { profileData } = useSelector((state) => state.profile);
  const { public_post } = useSelector((state) => state.profile);
  const { isWebView } = useSelector((state) => state.auth);
  const { isPageLoading } = useSelector((state) => state.loader);
  const pdfRef = useRef(null);
  const node = useRef();

  const [editmodal, setEditmodal] = useState(pofileModal ? true : false);
  const [editAbout, setEditAbout] = useState(false);
  const [addExpModal, setAddExpModal] = useState(expModal ? true : false);
  const [addSkillModal, setAddSkillModal] = useState(false);
  const [educationModal, setEducationModal] = useState(eduModal ? true : false);
  const [courseModal, setCourseModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [licenseCertificateModal, setLicenseCertificateModal] = useState(false);
  const [awardsHonorsModal, setAwardsHonorsModal] = useState(false);
  const [sectionModal, setSectionModal] = useState(false);
  const [contactinfoModal, setContactinfoModal] = useState(
    contactInfoModal ? true : false
  );
  const [addContactInfoModal, setAddContactInfoModal] = useState(false);
  const [giveRecommendation, setGiveRecommendation] = useState(false);
  const [addIntroModal, setAddIntroModal] = useState(false);
  const [addResumeModal, setAddResumeModal] = useState(false);
  const [editExperienceData, setEditExperienceData] = useState("");
  const [editEducationData, setEditEducationData] = useState("");
  const [editCourseData, setEditCourseData] = useState("");
  const [editHonorsAwardsData, setEditHonorsAwardsData] = useState("");
  const [editLanguageData, setEditLanguageData] = useState("");
  const [editLicenseData, setEditLicenseData] = useState("");
  const [editSkillsData, setEditSkillsData] = useState();
  const [showAllSkills, setShowAllSkills] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [dropDown, setDropDown] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [backgroundPhotoModal, setBackgroundPhotoModal] = useState(false);
  const [creatorModeModal, setCreatorModeModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [isEditRecommend, setIsEditRecommend] = useState(false);

  const [askRecommendation, setAskRecommendation] = useState(false);
  const [writeRecommendationModal, setWriteRecommendationModal] =
    useState(false);
  const [writeTitle, setWriteTitle] = useState();
  const [memberView, setMemberView] = useState(false);
  const [mutualConnectionModal, setMutualConnectionModal] = useState(false);
  const [npMailModal, setNpMailModal] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.loader);
  const { suggested_people } = useSelector((state) => state.people);
  const { suggested_articles } = useSelector((state) => state.article);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    let profileCountData = {
      profile_id: JSON.parse(params.id),
      view_type: "profile",
      search: bySearch,
    };

    if (user?.data?.id !== JSON.parse(params.id)) {
      dispatch(getProfileCount(profileCountData, token));
    }
  }, []);

  let url = new URL(document.location).searchParams;
  let mode = url.get("mode");
  // console.log(mode);

  useEffect(() => {
    let id = params.id;
    if (id) {
      dispatch(getUserProfile(id, token)).then((response) => {
        if (response.success === false) return navigate("/error");
        else if (response?.success === true && !response?.data)
          toast.error(response?.message);
      });
    }
  }, [user, params.id]);

  useEffect(() => {
    let token = window.localStorage.getItem("accessToken");
    dispatch(
      getSuggestedArticles(
        token,
        "suggested",
        { type: "suggested", skip: 0, limit: 10 },
        true
      )
    );
    dispatch(getSuggestedPeopleList({ type: "suggested", skip: 0, limit: 5 }));
    dispatch(getScreeningRequest({ limit: 100, skip: 0 }));
  }, []);

  useEffect(() => {
    let id = params.id;
    dispatch(
      public_posts_list({ user_id: id, is_description: true, is_media: true })
    ).then(() => {});
  }, []);

  const deleteSkill = (skill) => {
    const skillId = skill.id;
    dispatch(deleteUserSkill(skillId, token))
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePdf = () => {
    const input = pdfRef.current;

    html2canvas(input, { useCORS: true }).then((canvas) => {
      console.log(canvas);
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      doc.save("Download.pdf");
    });
  };

  const clickOutsideDropdown = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setDropDown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", clickOutsideDropdown);
    };
  }, [dropDown]);

  const map = {};
  profileData?.experiences?.forEach((obj) => {
    const { company_name, value } = obj;
    if (map[company_name]) {
      map[company_name].push(obj);
    } else {
      map[company_name] = [obj];
    }
  });

  let newExperienceData = Object.entries(map).map(([key, value]) => ({
    [key]: value,
  }));

  newExperienceData = newExperienceData.map((exp) => {
    exp.is_visible = true;
    Object.values(exp)[0].map((exp2) => {
      if (!exp2.is_visible) exp.is_visible = false;
    });
    return exp;
  });

  const dateUtility = (a, b) => {
    if (b === null) {
      let startDate = moment(a);
      let endDate = moment();

      let yearDiff = endDate.diff(startDate, "years");
      startDate.add(yearDiff, "years");

      let monthDiff = endDate.diff(startDate, "months");
      startDate.add(monthDiff, "months");

      return (
        <span>
          {yearDiff > 0 && yearDiff + (yearDiff > 1 ? " years" : " year")}{" "}
          {monthDiff > 0 && monthDiff + (monthDiff > 1 ? " months" : " month")}{" "}
        </span>
      );
    } else {
      let startDate = moment(a);
      let endDate = moment(b);

      let yearDiff = endDate.diff(startDate, "years");
      startDate.add(yearDiff, "years");

      let monthDiff = endDate.diff(startDate, "months");
      startDate.add(monthDiff, "months");

      return (
        <span>
          {yearDiff > 0 && yearDiff + (yearDiff > 1 ? " years" : " year")}{" "}
          {monthDiff > 0
            ? monthDiff + (monthDiff > 1 ? " months" : " month")
            : yearDiff == 0 && "0 month"}
        </span>
      );
    }
  };

  const isAllowedToEdit = () => {
    if (user?.data?.id === JSON.parse(params.id) && mode !== "member") {
      return true;
    } else return false;
  };

  const sendConnectionHandle = () => {
    let id = JSON.parse(params.id);
    dispatch(sendConnectionRequest(id)).then((res) => {
      toast.success("Connection request sent successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const handleRemovePicture = (type) => {
    if (type === "profile") {
      let formatProfileData = {
        profile_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    } else if (type === "background") {
      let formatProfileData = {
        background_img: "",
      };
      dispatch(updateUserDetails(formatProfileData, token));
    }
  };

  const handleBannerPicture = async (file, type) => {
    const newImage = new File([file], "newEventCropped", { type: "image/png" });

    try {
      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formData = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };
      formData.append("image", newImage);

      await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
        if (type === "profile") {
          let formatProfileData = {
            profile_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        } else if (type === "background") {
          let formatProfileData = {
            background_img: res?.data?.urlsArray[0],
          };
          dispatch(updateUserDetails(formatProfileData, token));
        }
      });
    } catch (err) {
      console.log(err);
    }
    // setProfilePhotoModal(false);
  };

  const deleteIntroVideo = () => {
    // const UPLOAD_URL = "";
    // const headers = {
    //   "Accept-Language": "en",
    //   "content-type": "multipart/form-data",
    // };

    // await axios.post(UPLOAD_URL, formData, { headers }).then((res) => { })

    let token = localStorage.getItem("accessToken");
    let data = {
      intro_video: null,
    };
    dispatch(deleteVideo(data, token)).then((response) => {});
  };

  const create_follower = () => {
    let id = JSON.parse(params.id);
    dispatch(profile_follower(id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const create_unfollower = () => {
    let id = JSON.parse(params.id);
    dispatch(profile_unfollower(id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const addLead = () => {
    let id = JSON.parse(params.id);
    dispatch(postLead({ user_id: id }))
      .then((res) => {
        toast.success("Lead Added successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setDropDown(false);
      })
      .catch((e) => {
        toast.success(e.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const sendRequest = (id, mode, post_id) => {
    dispatch(sendConnectionRequest(id, mode, post_id)).then((res) => {
      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const peopleRemoveConnection = () => {
    dispatch(remove_connection(id, null, null)).then((res) => {
      // toast.success(res.message, {
      //   position: toast.POSITION.BOTTOM_LEFT,
      // });
    });
  };

  const blockUser = () => {
    dispatch(block_user(id)).then((res) => {
      alert(res.message);
    });
  };

  function abbreviateNumber(value) {
    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  return (
    <Profile
      profileData={profileData}
      deleteSkill={deleteSkill}
      handlePdf={handlePdf}
      editmodal={editmodal}
      setEditmodal={setEditmodal}
      editAbout={editAbout}
      setEditAbout={setEditAbout}
      addExpModal={addExpModal}
      setAddExpModal={setAddExpModal}
      addSkillModal={addSkillModal}
      setAddSkillModal={setAddSkillModal}
      educationModal={educationModal}
      setEducationModal={setEducationModal}
      courseModal={courseModal}
      setCourseModal={setCourseModal}
      languageModal={languageModal}
      setLanguageModal={setLanguageModal}
      licenseCertificateModal={licenseCertificateModal}
      setLicenseCertificateModal={setLicenseCertificateModal}
      sectionModal={sectionModal}
      setSectionModal={setSectionModal}
      contactinfoModal={contactinfoModal}
      setContactinfoModal={setContactinfoModal}
      addContactInfoModal={addContactInfoModal}
      setAddContactInfoModal={setAddContactInfoModal}
      editExperienceData={editExperienceData}
      setEditExperienceData={setEditExperienceData}
      editEducationData={editEducationData}
      setEditEducationData={setEditEducationData}
      editCourseData={editCourseData}
      setEditCourseData={setEditCourseData}
      editLanguageData={editLanguageData}
      setEditLanguageData={setEditLanguageData}
      editLicenseData={editLicenseData}
      setEditLicenseData={setEditLicenseData}
      editSkillsData={editSkillsData}
      setEditSkillsData={setEditSkillsData}
      showAllSkills={showAllSkills}
      setShowAllSkills={setShowAllSkills}
      dropDown={dropDown}
      setDropDown={setDropDown}
      pdfRef={pdfRef}
      newExperienceData={newExperienceData}
      dateUtility={dateUtility}
      croppedImage={croppedImage}
      setCroppedImage={setCroppedImage}
      node={node}
      sections={sections}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      isAllowedToEdit={isAllowedToEdit}
      sendConnectionHandle={sendConnectionHandle}
      isLoading={isLoading}
      profilePhotoModal={profilePhotoModal}
      setProfilePhotoModal={setProfilePhotoModal}
      handleBannerPicture={handleBannerPicture}
      backgroundPhotoModal={backgroundPhotoModal}
      setBackgroundPhotoModal={setBackgroundPhotoModal}
      awardsHonorsModal={awardsHonorsModal}
      setAwardsHonorsModal={setAwardsHonorsModal}
      editHonorsAwardsData={editHonorsAwardsData}
      setEditHonorsAwardsData={setEditHonorsAwardsData}
      user_id={params.id}
      giveRecommendation={giveRecommendation}
      setGiveRecommendation={setGiveRecommendation}
      addIntroModal={addIntroModal}
      setAddIntroModal={setAddIntroModal}
      addResumeModal={addResumeModal}
      setAddResumeModal={setAddResumeModal}
      creatorModeModal={creatorModeModal}
      setCreatorModeModal={setCreatorModeModal}
      deleteIntroVideo={deleteIntroVideo}
      create_follower={create_follower}
      create_unfollower={create_unfollower}
      verificationModal={verificationModal}
      setVerificationModal={setVerificationModal}
      handleRemovePicture={handleRemovePicture}
      isPageLoading={isPageLoading}
      closeModal={closeModal}
      setCloseModal={setCloseModal}
      deleteItem={deleteItem}
      setDeleteItem={setDeleteItem}
      askRecommendation={askRecommendation}
      setAskRecommendation={setAskRecommendation}
      writeRecommendationModal={writeRecommendationModal}
      setWriteRecommendationModal={setWriteRecommendationModal}
      writeTitle={writeTitle}
      setWriteTitle={setWriteTitle}
      isEditRecommend={isEditRecommend}
      setIsEditRecommend={setIsEditRecommend}
      addLead={addLead}
      suggested_people={suggested_people}
      sendRequest={sendRequest}
      memberView={memberView}
      setMemberView={setMemberView}
      my_articles={suggested_articles}
      mode={mode}
      public_post={public_post}
      mutualConnectionModal={mutualConnectionModal}
      setMutualConnectionModal={setMutualConnectionModal}
      user={user}
      abbreviateNumber={abbreviateNumber}
      peopleRemoveConnection={peopleRemoveConnection}
      blockUser={blockUser}
      npMailModal={npMailModal}
      setNpMailModal={setNpMailModal}
      id={id}
    />
  );
}
