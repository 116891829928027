import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import locationIcon from "../../assets/img/location.svg";
import setttingNobelLogo from "../../assets/img/setttingNobelLogo.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import tick from "../../assets/img/green-tick.svg";
import cross from "../../assets/img/cross.png";
import gold from "../../assets/img/gold.svg";
import { useNavigate } from "react-router-dom";
import { updateNextPlan } from "../../store/auth/authActions";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MembershipDetails = (props) => {
  const {
    start,
    setStart,
    duration,
    setDuration,
    types,
    membershipPlanName,
    isAddOns,
    membershipDetails,
    user,
    location,
    isCurrent,
    setIsCurrent,
    price,
    setPrice,
    isValid,
    setIsValid,
  } = props;
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addOnRef = useRef();
  const currentMemebrships = ["Starter", "Nobel", "Nobel Plus", "Scout"];
  const [topfeatures, setTopFeatures] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [show, setShow] = useState(false);
  const [cvListedText, setCvListedText] = useState(
    "Can use many layouts download and generate pdf and editable versions and Can upload own CV."
  );
  const [backgroundCheckText, setBackgroundCheckText] = useState(
    "Enjoy up to 50% Discount comparing to unpaid memberships"
  );
  const [jobPostingText, setJobPostingText] = useState();

  function dateFormatter(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day} ${monthName} ${year}`;
  }

  function monthDiff(d1, d2) {
    if (d1 > d2) {
      let temp = d1;
      d1 = d2;
      d2 = temp;
    }
    let year1 = d1.getFullYear();
    let month1 = d1.getMonth();
    let year2 = d2.getFullYear();
    let month2 = d2.getMonth();
    let yearDifference = year2 - year1;
    let monthDifference = month2 - month1;
    let totalMonthsDifference = yearDifference * 12 + monthDifference;

    return totalMonthsDifference;
  }

  function daysDiff(date1, date2) {
    const date1Midnight = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const date2Midnight = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );

    // Convert both dates to milliseconds
    const time1 = date1Midnight.getTime();
    const time2 = date2Midnight.getTime();

    // Calculate the absolute difference in milliseconds
    const differenceInMillis = Math.abs(time2 - time1);

    // Convert the difference from milliseconds to days
    const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

    return differenceInDays;
  }

  const dateDifference = useMemo(() => {
    if (user?.data?.membership_expiry) {
      const months = monthDiff(
        new Date(user?.data?.membership_expiry),
        new Date(user?.data?.membership_start)
      );
      return months;
    } else {
      return null;
    }
  }, [user]);

  // const adjustedMonthlyPrize = useMemo(() => {
  //   if (currentMemebrships.indexOf(user?.data?.membership?.name) < currentMemebrships.indexOf(membershipPlanName) && dateDifference == "1") {
  //     const monthPrize1 = types?.data[user?.data?.membership?.id - 1]?.monthlyPrize
  //     const dailyRateMembership1 = monthPrize1 / 30
  //     const currDate = new Date()
  //     const startDate = new Date(user?.data?.membership_start)
  //     const daysDifference = daysDiff(startDate, currDate)
  //     const amtSpent = dailyRateMembership1 * daysDifference
  //     const remainingAmt = monthPrize1 - amtSpent
  //     const toPayAmt = membershipDetails[0]?.monthlyPrize - remainingAmt
  //     return Math.round(toPayAmt)
  //   } else {
  //     return membershipDetails[0]?.monthlyPrize
  //   }
  // }, [membershipPlanName])

  // const adjustYearlyPrize = useMemo(() => {
  //   if (currentMemebrships.indexOf(user?.data?.membership?.name) < currentMemebrships.indexOf(membershipPlanName)) {
  //     if (dateDifference == "1") {
  //       const monthPrize1 = types?.data[user?.data?.membership?.id - 1]?.monthlyPrize
  //       const dailyRateMembership1 = monthPrize1 / 30
  //       const currDate = new Date()
  //       const startDate = new Date(user?.data?.membership_start)
  //       const daysDifference = daysDiff(startDate, currDate)
  //       const amtSpent = dailyRateMembership1 * daysDifference
  //       const remainingAmt = monthPrize1 - amtSpent

  //       const yearPrize = membershipDetails[0]?.yearlyPrize
  //       const remainingDays = 365 - daysDifference
  //       const dailyPrize = yearPrize / 365
  //       const remainingDaysPrize = dailyPrize * remainingDays
  //       const toPayAmt = remainingDaysPrize - remainingAmt
  //       return Math.round(toPayAmt)
  //     } else if (dateDifference == "12") {
  //       const yearPrize = types?.data[user?.data?.membership?.id - 1]?.yearlyPrize
  //       const dailyRateYearly = yearPrize / 365
  //       const currDate = new Date()
  //       const startDate = new Date(user?.data?.membership_start)
  //       const daysDifference = daysDiff(startDate, currDate)
  //       const amtSpent = dailyRateYearly * daysDifference
  //       const remainingAmt = yearPrize - amtSpent

  //       const yearPrizeNew = membershipDetails[0]?.yearlyPrize
  //       const remainingDays = 365 - daysDifference
  //       const dailyPrize = yearPrizeNew / 365
  //       const remainingDaysPrize = dailyPrize * remainingDays
  //       const toPayAmt = remainingDaysPrize - remainingAmt
  //       return Math.round(toPayAmt)
  //     } else {
  //       return membershipDetails[0]?.yearlyPrize
  //     }
  //   }
  //   else if (currentMemebrships.indexOf(user?.data?.membership?.name) == currentMemebrships.indexOf(membershipPlanName) && dateDifference == "1") {
  //     const monthPrize1 = types?.data[user?.data?.membership?.id - 1]?.monthlyPrize
  //     const dailyRateMembership1 = monthPrize1 / 30
  //     const currDate = new Date()
  //     const startDate = new Date(user?.data?.membership_start)
  //     const daysDifference = daysDiff(startDate, currDate)
  //     const amtSpent = dailyRateMembership1 * daysDifference
  //     const remainingAmt = monthPrize1 - amtSpent

  //     const yearPrize = membershipDetails[0]?.yearlyPrize
  //     const remainingDays = 365 - daysDifference
  //     const dailyPrize = yearPrize / 365
  //     const remainingDaysPrize = dailyPrize * remainingDays
  //     const toPayAmt = remainingDaysPrize - remainingAmt
  //     return Math.round(toPayAmt)
  //   }
  //   else {
  //     return membershipDetails[0]?.yearlyPrize
  //   }
  // }, [membershipPlanName])

  const handleBuyPlan = () => {
    if (isValid) {
      if (isCurrent) {
        navigate("/membership/price", {
          state: {
            membershipPlanName,
            isCurrent,
            duration,
            start,
            membershipData: {
              id: membershipDetails[0].id,
              name: membershipDetails[0].name,
              background: membershipDetails[0].background,
            },
          },
        });
      } else {
        setShow(true);
      }
    } else {
      toast.error("Please select an option.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const handleAccept = () => {
    const data = {
      next_membership_id: membershipDetails[0].id,
      next_membership_duration: duration,
      is_membership_active: true,
    };
    const membershipData = {
      next_membership: {
        id: membershipDetails[0].id,
        name: membershipDetails[0].name,
        background: membershipDetails[0].background,
      },
      next_membership_duration: duration,
      is_membership_active: true,
    };

    dispatch(updateNextPlan(data, membershipData, token))
      .then((res) => {
        if (res.success === true) {
          toast.success("Billing Cycle Changed.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(() => {
            setIsDone(!isDone);
            navigate("/setting");
          }, 3000);
        }
      })
      .catch((error) => {
        toast.success("Something went wrong Please try again later", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setIsDone(!isDone);
      });
  };

  const handleChange = (e, isCurrentFlag, durationCheck, startDate) => {
    setPrice(e.target.value);
    setIsCurrent(isCurrentFlag);
    setDuration(durationCheck);
    setStart(startDate);
    setIsValid(true);
  };

  useEffect(() => {
    if (isAddOns) addOnRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (membershipPlanName === "Starter") {
      setCvListedText(
        "One layout only can be used for applying inside the page and for downloading as PDF non-editable. Can also upload own CV"
      );
      setBackgroundCheckText("");
    }
    if (membershipPlanName === "Nobel") {
      setJobPostingText("Only 1 Job per month free then Add-ons");
    }
    if (membershipPlanName === "Nobel Plus") {
      setJobPostingText("10 Jobs per month free then Add-ons");
    }
    if (membershipPlanName === "Scout") {
      setJobPostingText("unlimited");
    }
  }, []);

  return (
    <div className="borders ">
      <div className="row my-5 box-active border-radius-5 p-10">
        <div className="col-lg-12 pl-20 p-20">
          <h1>{membershipPlanName} Membership</h1>
          <div className="row">
            <div className="col-lg-8">
              <p>
                Your Location : <img src={locationIcon} alt="location" />
                <span className="text-grey"> {location}</span>
              </p>
              <p className="my-3">{membershipDetails[0]?.description}</p>

              <ul>
                {membershipDetails[0]?.feautures.map((item, index) => (
                  <>
                    <li className="my-3 topfeature" key={index}>
                      <img
                        src={item?.checkmark === true ? tick : cross}
                        alt=""
                      />
                      {item?.Feauture?.name}
                    </li>
                    {/* <ul style={{ listStyle: "disc" }}>
                      <li className="ms-5 sub-heading">
                        {item?.Feauture?.description}
                      </li>
                    </ul> */}
                  </>
                ))}
              </ul>
            </div>
            <div className="col-lg-4">
              <img
                src={
                  membershipDetails[0]?.image ||
                  require("../../assets/img/job.png")
                }
                style={{ width: "100%" }}
                alt="job"
              />
              <p className="">
                {membershipDetails[0]?.image_description || ""}
              </p>
              {membershipPlanName !== "Starter" ? (
                <>
                  <div className="bottom d-flex align-items-baseline">
                    <input
                      type="radio"
                      name="fav_language"
                      className="me-3"
                      style={{ width: "30px" }}
                      checked={price == membershipDetails[0]?.monthlyPrize}
                      disabled={
                        currentMemebrships.indexOf(membershipPlanName) ==
                          currentMemebrships.indexOf(
                            user?.data?.membership?.name
                          ) && dateDifference == "1"
                      }
                      value={membershipDetails[0]?.monthlyPrize}
                      onChange={(e) =>
                        handleChange.apply(
                          this,
                          currentMemebrships.indexOf(membershipPlanName) <
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            )
                            ? [e, false, "month", null]
                            : currentMemebrships.indexOf(membershipPlanName) ==
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "12"
                            ? [e, false, "month", null]
                            : currentMemebrships.indexOf(membershipPlanName) >
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "1"
                            ? [e, true, "month", user?.data?.membership_start]
                            : currentMemebrships.indexOf(membershipPlanName) >
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "12"
                            ? [e, false, "month", null]
                            : [e, true, "month", user?.data?.membership_start]
                        )
                      }
                    />
                    <div className="details">
                      <div className="price ">
                        <span>
                          {/* <del>${membershipDetails[0]?.monthlyTotalPrize}</del>{" "} */}
                          $ {membershipDetails[0]?.monthlyPrize} / per month
                        </span>
                      </div>
                      {currentMemebrships.indexOf(membershipPlanName) <
                      currentMemebrships.indexOf(user?.data?.membership?.name)
                        ? "You are downgrading. This will be your next subscription once your current cycle finishes."
                        : currentMemebrships.indexOf(membershipPlanName) ==
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "12"
                        ? "You are changing billing cycle. This will be your billing cycle once your current billing cycle ends."
                        : currentMemebrships.indexOf(membershipPlanName) ==
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "1"
                        ? "This is your current cycle."
                        : currentMemebrships.indexOf(membershipPlanName) >
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "1"
                        ? "You are upgrading the membership. Price has been adjusted accordingly based on your last transaction."
                        : currentMemebrships.indexOf(membershipPlanName) >
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "12"
                        ? "You are upgrading with monthly cycle. This will begin once your current cycle ends."
                        : "You are upgrading."}
                      {/* <div className="sav my-2">
                        <span className="blue-color">
                          You Saved {membershipDetails[0]?.discount}%
                        </span>
                      </div> */}
                    </div>
                  </div>
                  <div className="bottom d-flex align-items-baseline">
                    <input
                      type="radio"
                      name="fav_language"
                      className="me-3"
                      style={{ width: "30px" }}
                      checked={price == membershipDetails[0]?.yearlyPrize}
                      disabled={
                        currentMemebrships.indexOf(membershipPlanName) ==
                          currentMemebrships.indexOf(
                            user?.data?.membership?.name
                          ) && dateDifference == "12"
                      }
                      value={membershipDetails[0]?.yearlyPrize}
                      onChange={(e) =>
                        handleChange.apply(
                          this,
                          currentMemebrships.indexOf(membershipPlanName) <
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            )
                            ? [e, false, "year", null]
                            : currentMemebrships.indexOf(membershipPlanName) ==
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "1"
                            ? [e, true, "year", user?.data?.membership_start]
                            : currentMemebrships.indexOf(membershipPlanName) >
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "12"
                            ? [e, true, "year", user?.data?.membership_start]
                            : currentMemebrships.indexOf(membershipPlanName) >
                                currentMemebrships.indexOf(
                                  user?.data?.membership?.name
                                ) && dateDifference == "1"
                            ? [e, true, "year", user?.data?.membership_start]
                            : [e, true, "year", user?.data?.membership_start]
                        )
                      }
                    />
                    <div className="details">
                      <div className="price ">
                        <span>
                          <del>${membershipDetails[0]?.yearlyTotalPrize}</del> ${" "}
                          {membershipDetails[0]?.yearlyPrize} / per year
                        </span>
                      </div>
                      {currentMemebrships.indexOf(membershipPlanName) <
                      currentMemebrships.indexOf(user?.data?.membership?.name)
                        ? "You are downgrading. This will be your next subscription once your current cycle finishes."
                        : currentMemebrships.indexOf(membershipPlanName) ==
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "12"
                        ? "This is your current cycle."
                        : currentMemebrships.indexOf(membershipPlanName) ==
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "1"
                        ? "You are changing billing cycle. Price has been adjusted accordingly based on your last transaction."
                        : currentMemebrships.indexOf(membershipPlanName) >
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "12"
                        ? "You are upgrading the membership. Price has been adjusted accordingly based on your last transaction."
                        : currentMemebrships.indexOf(membershipPlanName) >
                            currentMemebrships.indexOf(
                              user?.data?.membership?.name
                            ) && dateDifference == "1"
                        ? "You are upgrading with annual cycle. Price has been adjusted accordingly based on your last transaction."
                        : "You are upgrading."}
                      <div className="sav my-2">
                        <span className="blue-color">
                          You Saved {membershipDetails[0]?.discount}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-blue ml-30"
                    onClick={() => handleBuyPlan()}
                  >
                    Buy Plan
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="p-md-4 mt-sm-0 mt-5">
          <div className="d-flex mb-30" ref={addOnRef}>
            <h6 className=" me-lg-5">Add on packages for </h6>
            <h6>
              {membershipPlanName !== "Starter" ? (
                <img src={gold} alt="" className="me-2" />
              ) : (
                <></>
              )}{" "}
              {membershipPlanName}
            </h6>
          </div>
          <table class="table odd table-borderless table-striped">
            {/* <thead class="thead-dark"></thead> */}
            <tbody>
              <tr>
                <th className="blue-color">Feature Name</th>
                <th className="blue-color">Price</th>
                <th className="blue-color">Duration</th>
              </tr>
              {membershipDetails[0]?.addOns?.map((item, index) => (
                <tr className="grey" key={index}>
                  <td style={{ width: "60%" }}>
                    <div className="d-flex gap-3">
                      <img src={tick} alt="tick" />
                      <p>{item?.name}</p>
                    </div>
                  </td>
                  <td>
                    <p>
                      {item?.add_on_type === null
                        ? item?.Feauture_Values?.map((item2, index) => (
                            <>
                              {item2?.d_type === "null"
                                ? "-"
                                : item2?.d_type === "price"
                                ? `$${item2?.d_value}`
                                : item2?.d_type === "day_price"
                                ? `$${item2?.value}(${item2?.type}),`
                                : item2?.d_type === "people_price"
                                ? `$${item2?.value}(${item2?.type}),`
                                : "NA"}
                            </>
                          ))
                        : item?.Feauture_Values?.map((item2, index) => (
                            <>
                              {item2?.d_type === "null"
                                ? "-"
                                : item2?.d_type === "price"
                                ? `$${item2?.value}(${item?.add_on_value} ${item?.add_on_type})`
                                : item2?.d_type === "day_price"
                                ? `$${item2?.value}(${item2?.type}),`
                                : item2?.d_type === "people_price"
                                ? `$${item2?.value}(${item2?.type}),`
                                : "NA"}
                            </>
                          ))}
                    </p>
                  </td>
                  <td>
                    <p>End of the subsciption</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mb-30 mt-30">
            <h6 className=" me-lg-5">Extra Charges </h6>
          </div>
          <table class="table odd table-borderless table-striped">
            {/* <thead class="thead-dark"></thead> */}
            <tbody>
              <tr>
                <th className="blue-color">Feature Name</th>
                <th className="blue-color">Packages</th>
                <th className="blue-color">Price</th>
              </tr>
              {membershipDetails[0]?.extraCharges?.map((item, index) => (
                <tr className="grey" key={index}>
                  <td style={{ width: "60%" }}>
                    <div className="d-flex gap-3">
                      <img src={tick} alt="tick" />
                      <p>{item?.name}</p>
                    </div>
                  </td>
                  <td>
                    <p>
                      {item.Feauture_Values?.map((data, index) => (
                        <p>{data.type}</p>
                      ))}
                    </p>
                  </td>
                  <td>
                    <p>
                      {item.Feauture_Values?.map((data, index) => (
                        <p>{data.value}</p>
                      ))}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mb-30 mt-30">
            <h6 className=" me-lg-5">Background Checks Pricing</h6>
          </div>
          <table class="table odd table-borderless table-striped">
            {/* <thead class="thead-dark"></thead> */}
            <tbody>
              <tr>
                <th className="blue-color">Feature Name</th>
                <th className="blue-color">Conditions</th>
              </tr>
              {membershipDetails[0]?.backgroundChecks?.map((item, index) => (
                <tr className="grey" key={index}>
                  <td style={{ width: "60%" }}>
                    <div className="d-flex gap-3">
                      <img src={tick} alt="tick" />
                      <p>{item?.name}</p>
                    </div>
                  </td>
                  <td>
                    <p>
                      {item.Feauture_Values?.map((data, index) => (
                        <p>
                          {data.value} {data.type}
                        </p>
                      ))}
                    </p>
                  </td>
                  {/* <td>
                    <p>
                      {item.Feauture_Values?.map((data, index) => (
                        <p>{data.value}</p>
                      ))}
                    </p>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={show}
        className="nobel-plus-account-modal setting-modal-default-modal"
        centered
      >
        <ModalHeader id="membership-modal">
          <img src={setttingNobelLogo} alt="Logo" />
        </ModalHeader>
        <ModalBody>
          <h3>Changing Membership Plan</h3>
          <p style={{ marginTop: "10px" }}>
            You are changing membership plan to <i>{membershipPlanName}</i>.
            This change will take place once your current billing cycle ends on{" "}
            <i>{dateFormatter(new Date(user?.data?.membership_expiry))}.</i>
          </p>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <div
            style={{ marginLeft: "auto", marginRight: "0px", display: "block" }}
          >
            <button
              className="btn btn-outline mr-10"
              onClick={() => setShow(!show)}
            >
              Cancel
            </button>
            <button
              className="btn btn-blue"
              disabled={isDone}
              onClick={() => handleAccept()}
            >
              Accept
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default MembershipDetails;
