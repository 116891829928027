import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import crown from "../../assets/img/crown.svg";
import gold from "../../assets/img/gold.svg";
import "./advertisement.scss";
import {
  getMembershipImage,
  getMembershipType,
} from "../../store/membership/membershipActions";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Advertisement(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { membershipTypes } = useSelector((state) => state.membership);
  const { user } = useSelector((state) => state.auth);
  const membershipName = user?.data?.membership?.name;

  const [modal, setModal] = useState(false);
  const [popupData, setPopupData] = useState();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (location?.state?.commingFromLogin && membershipName != "Scout") {
      setTimeout(() => {
        setModal(true);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getMembershipImage()).then((response) => {
      setPopupData(response.data);
    });
  }, []);

  useEffect(() => {
    dispatch(getMembershipType()).then((res) => {});
  }, []);

  return (
    <div className="membership_modal">
      <Link to="/membership">
        <div
          className={
            props.type === `wallet`
              ? "advertisement min_height_ads"
              : "advertisement"
          }
          // onClick={toggle}
        >
          <h3>
            <img
              src={crown}
              alt=""
              className="img-fluid me-3"
              style={{ width: "30px" }}
            />{" "}
            Discover Our Memberships
          </h3>
        </div>
      </Link>

      <Modal
        style={{ maxWidth: "1190px" }}
        className="modal-xl member_modal"
        isOpen={modal}
        toggle={toggle}
        {...props}
        centered
      >
        <div
          style={{
            background: `#0086c1 url(${popupData?.media}) 70% 0 no-repeat`,
            backgroundSize: "cover",
            height: "auto",
          }}
        >
          <ModalHeader toggle={toggle}> </ModalHeader>
          <ModalBody style={{ overflow: "hidden", maxHeight: "fit-content" }}>
            <div className="title">
              <h2>{popupData?.title || "Explore our memberships"}</h2>
              <p>
                {popupData?.subtitle ||
                  `Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
                phasellus mollis sit aliquam sit nullam.`}
              </p>
            </div>
            <div className="main-box">
              {/* <div className="box">
                <div className="flex">
                  <h2>Nobel</h2>
                  <img src={gold} alt="" />
                </div>
                <ul>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                </ul>
                <div className="bottom">
                  <div className="price">
                    <span>Free</span>
                    <span className="orange"> $ 99 /Year</span>
                  </div>
                  <div className="sav my-2">
                    <span>You Saved 20%</span>
                  </div>
                  <div className="more">
                    <a href="/membership">More details</a>
                  </div>
                </div>
              </div> */}

              {membershipTypes?.data?.map((plan, index, { length }) => {
                return (
                  <div
                    className={
                      "box " +
                      (plan?.name === membershipName ? "active-box" : "")
                    }
                    key={index}
                  >
                    <div className="flex">
                      <h2>{plan?.name}</h2>
                      <img src={plan?.icon === "NP Gold" ? gold : ""} alt="" />
                    </div>
                    <ul>
                      {plan?.feautures?.map((feature, index) => {
                        if (index < 6) {
                          return <li key={index}>{feature?.Feauture?.name}</li>;
                        }
                      })}
                    </ul>
                    {plan?.name !== "Starter" ? (
                      <div className="bottom">
                        <div className="price">
                          <span className="orange">
                            {" "}
                            ${plan?.yearlyPrize} /Year
                          </span>
                        </div>

                        <div className="sav my-2">
                          <span>You Saved {plan?.discount || 0}%</span>
                        </div>
                        {/* <div className="more">
                        
                          <a href="/membership">More details</a>
                        </div> */}
                      </div>
                    ) : (
                      // <div className="bottom">
                      <div className="free">
                        <span>Free</span>
                        {/* <div className="more">
                          <span>Current plan</span>
                      
                        </div> */}
                      </div>
                      // </div>
                    )}

                    {plan?.name === membershipName ? (
                      <div
                        className="moreNew"
                        style={{
                          marginTop: plan?.name === "Starter" ? "37px" : "23px",
                        }}
                      >
                        {/* <a href="/membership"></a> */}
                        <Link to="/membership">
                          <span style={{ color: "rgb(4, 50, 163)" }}>
                            Current plan
                          </span>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className="moreNew"
                        style={{
                          backgroundColor: "rgb(4, 50, 163)",
                          marginTop: plan?.name === "Starter" ? "37px" : "23px",
                        }}
                      >
                        <Link to="/membership">More details</Link>
                      </div>
                    )}
                  </div>
                );
                // } else return null;
              })}

              {/* <div className="box">
                <div className="flex">
                  <h2>Nobel Plus</h2>
                  <img src={gold} alt="" />
                </div>
                <ul>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                </ul>
                <div className="bottom">
                  <div className="price">
                    <span>
                      <del>$111</del>
                    </span>
                    <span className="orange"> $ 99 /Year</span>
                  </div>
                  <div className="sav my-2">
                    <span>You Saved 20%</span>
                  </div>
                  <div className="more">
                    <a href="/membership">More details</a>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="flex">
                  <h2>Scout</h2>
                  <img src={gold} alt="" />
                </div>
                <ul>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                  <li>Stand out and get in touch with hiring managers</li>
                </ul>
                <div className="bottom">
                  <div className="price">
                    <span>
                      <del>$111</del>
                    </span>
                    <span className="orange"> $ 99 /Year</span>
                  </div>
                  <div className="sav my-2">
                    <span>You Saved 20%</span>
                  </div>
                  <div className="more">
                    <a href="/membership">More details</a>
                  </div>
                </div>
              </div> */}
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/* <div className="ad">
                <span>Ad</span>
                <div className="dot"></div>
                <div className="banner">
                  <div className="caption">
                    <h4>Boost your digital marketing credibility for free </h4>
                    <a href="/">Learn more</a>
                  </div>
                </div>
              </div> */}
    </div>
  );
}
