import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";

const DarkModeModal = (props) => {
  const { modal, toggle, title, data } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [responseMsg, setResponseMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    try {
      let token = user?.token;
      let id = 7;
      let mode = false;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element["key"] === "dark_mode") {
          let { value } = element.settings[0];
          if (value == 1) {
            mode = true;
          }
        }
      }

      setDarkMode(mode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updateMode = (event) => {
    try {
      let token = localStorage.getItem("accessToken");
      setDarkMode(event.target.checked);
      let value = 0;
      if (event.target.checked === true) {
        value = 1;
        setDarkMode(false);
        setErrorMsg("This feature will be coming soon.");
        setTimeout(() => {
          toggle();
        }, 3000);
        return;
      }
      let updateData = {
        setting: [
          {
            setting_master_id: 21,
            value: value + "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        setResponseMsg(res.message);
        dispatch(getUserSetting(0, token));
        setTimeout(() => {
          setResponseMsg(false);
        }, 3000);
        // alert(
        //   "This feature will be implemented in the future milestone"
        // );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                <p>
                  By default, it is in bright mode, but you can choose to
                  activate dark mode.
                </p>
                <div className="d-flex justify-content-between">
                  <div className="enable">Enable Dark Mode</div>
                  <div className="switch">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={(e) => updateMode(e)}
                        checked={darkMode}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}

                {errorMsg && !responseMsg && (
                  <label className="label-input100 w-100 text-center text-danger">
                    {errorMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DarkModeModal;
